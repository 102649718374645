import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerActivityTracking from 'modules/activityTrackings/components/DatePickerActivityTracking';
import FormCustomerAndOffice from './FormCustomerAndOffice';
import InfoDetailCustomer from '../components/InfoDetailCustomer';
import { getFormatDate } from '../helper';
import { fetchListActivityTracking, updateDataCustomerInfoActivityTracking } from '../slice';

import InfoDetailCustomerPDF from '../components/InfoDetailCustomerPDF';
export default function ActivityTracking({ printRef }) {

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const customers = useSelector((state) => state.customers.filteredCustomers);

  const [defaultValues, setDefaultValues] = useState({
    customer: '',
    office: '',
    date_from: '',
    date_to: '',
  });
  const [invalidTime, setInvalidTime] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues
  });

  const handleSetInvalidTime = (data) => {
    setInvalidTime(data);
  }

  // useEffect(() => {
  //   return () => {
  //     setInvalidTime(false);
  //   };
  // }, [])

  const token = localStorage.getItem('access_token');

  const resetValues = {
    customer: '',
    office: '',
    date_from: '',
    date_to: '',
  }

  const today = new Date();
  const priorDate = new Date(new Date().setDate(today.getDate() - 30));

  useEffect(() => {
    if ((customers?.[0])) {
      const newDefaultValues = {
        customer: customers?.[0],
        office: { office_name: 'All Offices', id: -1, hcp_id: -1, office_address: 'All Addresses', office_city: null, office_state: null, office_zip_code: null },
        date_from: priorDate,
        date_to: today
      };

      const payload = {
        hcp_id: customers?.[0]?.id,
        token,
        office_id: -1,
        date_from: getFormatDate(priorDate, 'be'),
        date_to: getFormatDate(today, 'be'),
      }

      const payloadUpdateState = {
        customer: customers?.[0],
        office: { office_name: 'All Offices', id: -1, hcp_id: -1, office_address: 'All Addresses', office_city: null, office_state: null, office_zip_code: null },
        date_from: getFormatDate(priorDate, 'fe'),
        date_to: getFormatDate(today, 'fe'),
      }

      dispatch(fetchListActivityTracking({
        ...payload
      }));

      dispatch(updateDataCustomerInfoActivityTracking({ data: payloadUpdateState }));


      setDefaultValues(newDefaultValues);
      methods.reset(newDefaultValues);
    }
  }, [customers]);

  const onSubmit = (data) => {
    const payload = {
      hcp_id: data.customer.id,
      office_id: data.office.id,
      date_from: getFormatDate(data.date_from, 'be'),
      date_to: getFormatDate(data.date_to, 'be')
    }

    const payloadUpdateState = {
      customer: data.customer,
      office: data.office,
      date_from: getFormatDate(data.date_from, 'fe'),
      date_to: getFormatDate(data.date_to, 'fe')
    }

    dispatch(fetchListActivityTracking({
      ...payload
    }));

    dispatch(updateDataCustomerInfoActivityTracking({ data: payloadUpdateState }));

  };

  const renderFormCustomerAndOffice = useMemo(() => {
    return <FormCustomerAndOffice />;
  }, []);

  const renderDatePickerActivityTracking = useMemo(() => {
    return <DatePickerActivityTracking handleSetInvalidTime={handleSetInvalidTime} />;
  }, []);

  const renderInfoDetailCustomer = useMemo(() => {
    return <InfoDetailCustomer defaultValues={defaultValues} />;
  }, []);

  return (
    <FormProvider {...methods}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 2, background: '#ffffff', borderRadius: '8px', width: '100%', gap: 4, position: 'relative', boxShadow: '0px 2px 1px -1px #00000033,0px 1px 1px 0px #00000033,0px 1px 3px 0px #0000001f', mb: 2, alignItems: 'start' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <form style={{ width: '100%', display: 'flex' }} onSubmit={methods.handleSubmit(onSubmit)}>
              {renderFormCustomerAndOffice}
              {renderDatePickerActivityTracking}
            </form>
          </Box>
          <Box sx={{ position: 'absolute', right: '20px' }}>
            <Button onClick={methods.handleSubmit(onSubmit)} sx={{ width: '135px', color: '##1F1F1F', padding: 1.5 }} variant='contained'>Apply</Button>
          </Box>
        </Box>
        {renderInfoDetailCustomer}
        <div className='table-activity' print ref={printRef}>
          <InfoDetailCustomerPDF />
        </div>
      </Box>
    </FormProvider>
  );
}

ActivityTracking.propTypes = {
  printRef: PropTypes.any,
  props: PropTypes.any,
};
