import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { requestToast } from 'modules/ui/slice';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import ScrollDialog from 'components/ScrollDialog';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { machineTypes } from 'modules/machines/components/Dialogs';
import { useSocketDataContext } from 'components/WebSocketProvider';
import socketActions from 'components/WebSocketProvider/actions';

import { TOAST_TYPE } from 'modules/ui/constants';
import Button from '@mui/material/Button';
import { Modal as MuiModal } from '@mui/material';
import { InputText } from 'components/InputText';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '4px',
  outline: 'none',
};

export default function ViewMachineSettingModal({ showModal, onClose, rowSelected }) {
  const socketContext = useSocketDataContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [targetTorque, setTargetTorque] = useState('');
  const [currentTorque, setCurrentTorque] = useState('');
  const [data, setData] = useState({
    potConversionFactor: 0,
    lcRating: 0,
    dynamicPercent: 0,
    gaugeSets: 0,
    testOptions: '',
    machineForce: 0,
    lcMilivolt: 0,
    lcFactor: 0,
    noise: 0,
    calibrationRatio: 0,
  });

  const defaultValues = {
    potConversionFactor: '',
    lcRating: '',
    dynamicPercent: '',
    gaugeSets: '',
    testOptions: '',
    machineForce: '',
    lcMilivolt: '',
    lcFactor: '',
    noise: '',
    calibrationRatio: '',
  };

  const {
    control,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (!showModal || !rowSelected) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.GET_MANUAL_CALIBRATION,
      u12_id: rowSelected.machine.u12_id,
      mac_address: rowSelected.machine.mac_addr,
    });
  }, [showModal]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (
      message?.pot_conversion_factor === undefined ||
      message?.lc_rating === undefined ||
      message?.lc_milivolt === undefined
    ) {
      return;
    }

    setData({
      potConversionFactor: message?.pot_conversion_factor,
      lcRating: message?.lc_rating,
      dynamicPercent: message?.dynamic_percent,
      gaugeSets: message?.gauge_sets,
      testOptions: message?.test_options,
      machineForce: message?.machine_force,
      lcMilivolt: message?.lc_milivolt,
      lcFactor: message?.lc_factor,
      noise: message?.noise,
      calibrationRatio: message.calibration_ratio,
    });
    setValue('potConversionFactor', message?.pot_conversion_factor);
    setValue('lcRating', message?.lc_rating);
    setValue('dynamicPercent', message?.dynamic_percent);
    setValue('gaugeSets', message?.gauge_sets);
    setValue('testOptions', message?.test_options);
    setValue('machineForce', message?.machine_force);
    setValue('lcMilivolt', message?.lc_milivolt);
    setValue('lcFactor', message?.lc_factor);
    setValue('noise', message?.noise);
    setValue('calibrationRatio', message?.calibration_ratio);
  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (showModal) {
      reset(defaultValues);
    }
  }, [showModal]);

  const contentRender = () => {
    switch (step) {
      case 1:
        return ViewContent();
      case 2:
        return EditContent();
      default:
        return null;
    }
  };

  const allowDecimal = (e) => {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code >= 46 && code < 58) // numeric (0-9) and . /
    ) {
      e.preventDefault();
    }
  };

  const getErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
  };

  const EditContent = () => {
    return (
      <form>
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '500px' }}>
          <TextField
            margin='normal'
            fullWidth
            id='name'
            value={rowSelected.machine.name}
            label={t('machine:addDialog.name')}
            disabled
            inputProps={{
              maxLength: 255,
            }}
          />
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel>{t('machine:addDialog.machineType')}</InputLabel>
            <Select disabled value={rowSelected.machine.machine_type} label={t('machine:addDialog.machineType')}>
              {machineTypes.map((type) => (
                <MenuItem key={type.id} value={type.name}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            sx={{ mt: 4 }}
            disabled
            margin='normal'
            fullWidth
            id='number'
            value={rowSelected.machine.serial_no}
            label={t('machine:addDialog.serialNo')}
            inputProps={{
              maxLength: 13,
            }}
          />
          <Controller
            name='potConversionFactor'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.potConversionFactor}
                helperText={getErrorMessage(errors.potConversionFactor)}
                margin='normal'
                fullWidth
                id='potConversionFactor'
                value={value}
                label='Pot Conversion Factor'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='lcRating'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.lcRating}
                helperText={getErrorMessage(errors.lcRating)}
                margin='normal'
                fullWidth
                id='lcRating'
                value={value}
                label='Load cell Rating'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='lcFactor'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.lcFactor}
                helperText={getErrorMessage(errors.lcFactor)}
                margin='normal'
                fullWidth
                id='lcFactor'
                value={value}
                label='Load cell Factor'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='calibrationRatio'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.lcFactor}
                helperText={getErrorMessage(errors.lcFactor)}
                margin='normal'
                fullWidth
                id='calibrationRatio'
                value={value}
                label='Calibration ratio'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='dynamicPercent'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.dynamicPercent}
                helperText={getErrorMessage(errors.dynamicPercent)}
                margin='normal'
                fullWidth
                id='dynamicPercent'
                value={value}
                label='Dynamic %'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='gaugeSets'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.gaugeSets}
                helperText={getErrorMessage(errors.gaugeSets)}
                margin='normal'
                fullWidth
                id='gaugeSets'
                value={value}
                label='Gauge Sets'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='testOptions'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!errors.testOptions}
                helperText={getErrorMessage(errors.testOptions)}
                margin='normal'
                fullWidth
                id='testOptions'
                value={value}
                label='Test Options'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='machineForce'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.machineForce}
                helperText={getErrorMessage(errors.machineForce)}
                margin='normal'
                fullWidth
                id='machineForce'
                value={value}
                label='Machine Force'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='noise'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.noise}
                helperText={getErrorMessage(errors.noise)}
                margin='normal'
                fullWidth
                id='noise'
                value={value}
                label='Noise'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
          <Controller
            name='lcMilivolt'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onKeyPress={(e) => {
                  allowDecimal(e);
                }}
                error={!!errors.lcMilivolt}
                helperText={getErrorMessage(errors.lcMilivolt)}
                margin='normal'
                fullWidth
                id='lcMilivolt'
                value={value}
                label='LC Millivolts'
                onChange={onChange}
                inputProps={{
                  maxLength: 13,
                }}
              />
            )}
          />
        </Box>
      </form>
    );
  };

  const ViewContent = () => {
    const machineData = rowSelected;

    const onCancelAutoCalculationHandler = () => {
      setTargetTorque('');
      setCurrentTorque('');
      setOpen(false);
    };
    const onAutoCalculationHandler = () => {
      socketContext.sendJsonMessage({
        request: socketActions.AUTO_CALIBRATION_TOURQUE,
        u12_id: rowSelected.machine.u12_id,
        mac_address: rowSelected.machine.mac_addr,
        target_pound: Number(targetTorque),
        current_pound: Number(currentTorque),
      });
      setTimeout(() => {
        socketContext.sendJsonMessage({
          request: socketActions.GET_MANUAL_CALIBRATION,
          u12_id: rowSelected.machine.u12_id,
          mac_address: rowSelected.machine.mac_addr,
        });
      }, 1000);

      setTargetTorque('');
      setCurrentTorque('');
      setOpen(false);
    };

    if (!machineData || !machineData.machine) return null;
    return (
      <Box sx={{ display: 'flex', gap: '100px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 0',
            gap: '30px',
            minWidth: '200px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine name
              </Typography>
              <div>{machineData.machine.name}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine Type
              </Typography>
              <div>{machineData.machine.machine_type}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine Serial Number
              </Typography>
              <div>{machineData.machine.serial_no}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Pot Conversion Factor
              </Typography>
              <div>{data.potConversionFactor}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Load Cell Rating
              </Typography>
              <div>{data.lcRating}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Load Cell Factor
              </Typography>
              <div>{data.lcFactor}</div>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Calibration ratio
              </Typography>
              <div>{data.calibrationRatio}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Button onClick={() => setOpen(true)} variant={'contained'}>
                PEAK TORQUE CALCULATION
              </Button>
            </Box>
          </Box>
          <MuiModal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Box sx={{ ...style }}>
              <Box>
                <Box>
                  <div id='child-modal-description'>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>Current Torque</span>
                          <InputText type={'number'} onChange={(e) => setCurrentTorque(e)} value={currentTorque} />
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                          }}
                        >
                          <span style={{ fontWeight: 700 }}>Target Torque</span>
                          <InputText type={'number'} onChange={(e) => setTargetTorque(e)} value={targetTorque} />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{''}</span>
                        <span style={{ display: 'flex', gap: '8px' }}>
                          <Button onClick={() => onCancelAutoCalculationHandler()} variant={'outlined'}>
                            Cancel
                          </Button>
                          <Button variant={'contained'} onClick={() => onAutoCalculationHandler()}>
                            Ok
                          </Button>
                        </span>
                      </Box>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
          </MuiModal>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 0',
            gap: '30px',
            minWidth: '200px',
          }}
        >
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Dynamic %
              </Typography>
              <div>{data.dynamicPercent}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Gauge Sets
              </Typography>
              <div>{data.gaugeSets}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Test Options
              </Typography>

              <div>{data.testOptions}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine Force
              </Typography>

              <div>{data.machineForce}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                LC Millivolts
              </Typography>
              <div>{data.lcMilivolt}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                minWidth: '130px',
              }}
            >
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Noise
              </Typography>
              <div>{data.noise}</div>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleSubmitModal = async () => {
    if (step === 1) {
      setStep(2);
    } else {
      if (!isEmpty(errors)) return;

      try {
        await socketContext.sendJsonMessage({
          request: socketActions.MANUAL_CALIBRATION,
          u12_id: rowSelected.machine.u12_id,
          mac_address: rowSelected.machine.mac_addr,
          pot_conversion_factor: getValues('potConversionFactor'),
          lc_rating: getValues('lcRating'),
          lc_factor: getValues('lcFactor'),
          lc_millivolts: getValues('lcMilivolt'),
          dynamic_percent: getValues('dynamicPercent'),
          gauge_sets: getValues('gaugeSets'),
          test_options: getValues('testOptions'),
          machine_force: getValues('machineForce'),
          noise: getValues('noise'),
          calibration_ratio: getValues('calibrationRatio'),
        });
      } catch (error) {
        console.warn(error);
      }
      dispatch(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'The value has been updated!',
        })
      );

      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    onClose();
    setTimeout(() => {
      setStep(1);
    }, 500);
  };

  return (
    <ScrollDialog
      hasCloseIcon={false}
      title='Machine Specification Setting'
      message={contentRender()}
      handleCloseModal={handleCloseModal}
      handleSubmitModal={handleSubmitModal}
      handleSubmitSecondaryAction={handleCloseModal}
      openModal={showModal}
      secondaryActionText='Cancel'
      primaryActionText={step === 1 ? 'Edit' : 'Save'}
    />
  );
}

ViewMachineSettingModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  onCalibrationSelected: PropTypes.func,
  rowSelected: PropTypes.object,
};
