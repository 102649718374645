import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab, Container, Typography, Box } from '@mui/material';

import { TabPanel } from 'components/Tab';
import GeneralList from 'modules/machines/containers/GeneralList';
import NewList from 'modules/machines/containers/NewList';
import { useSocketDataContext } from 'components/WebSocketProvider';
import { fetchDetectedMachines, fetchMachines } from 'modules/machines/slice';
import isEmpty from 'lodash/isEmpty';

export default function ManageMachines() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const socketContext = useSocketDataContext();
  const machinesDetected = useSelector((state) => state.machines.detectedMachines);
  const machines = useSelector((state) => state.machines.machines);

  const machinesMaintenance = machines?.filter(mc => mc.machine.status === 3);

  const getMachineDetected = (arrayDetected, arrayMaintenance) => {
    if (isEmpty(arrayDetected) && isEmpty(arrayMaintenance)) {
      return [];
    };
    const result = arrayDetected?.filter(itemDetected => {
      const isDuplicate = arrayMaintenance.some(itemMaintenance => {
        if (itemMaintenance.machine.u12_id === itemDetected.u12_id && itemMaintenance.machine.mac_addr === itemDetected.mac_address) {
          return
        }

        return itemMaintenance.machine.u12_id === itemDetected.u12_id || itemMaintenance.machine.mac_addr === itemDetected.mac_address;
      })

      return !isDuplicate;
    })

    return result
  }

  const machineDetectAfterFilter = getMachineDetected(machinesDetected, machinesMaintenance);

  const [selectedTab, setSelectedTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  // if there is new param in url, select the new tab
  useEffect(() => {
    const newParam = searchParams.get('new');
    if (newParam) {
      setSelectedTab(1);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('new')) {
      dispatch(fetchMachines());
    }

    dispatch(fetchDetectedMachines());
  }, [socketContext.shouldRefreshMachines]);

  const handleChange = (event, newTab) => {
    if (newTab === 1) {
      setSearchParams({ new: 1 });
    } else {
      setSearchParams({});

      // update length count New List tab
      dispatch(fetchDetectedMachines());
    }

    setSelectedTab(newTab);
  };

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          boxShadow: 3,
          pt: 3,
          bgcolor: '#E2E4E7',
          '.MuiTab-root': {
            letterSpacing: '1.25px',
          },
          '.Mui-selected': {
            backgroundColor: '#FFF',
            color: 'primary.main',
            borderBottom: '2px solid',
          },
          '.MuiTabs-indicator': {
            display: 'none !important',
          },
        }}
      >
        <Typography
          sx={{
            top: '50%',
            transform: 'translate(24px, -50%)',
            position: 'absolute',
            fontSize: '1.5rem',
          }}
          variant='h6'
          component='div'
        >
          {t('machine:title')}
        </Typography>
        <Tabs value={selectedTab} onChange={handleChange} centered>
          <Tab label='Machine List' />
          <Tab label={`New (${machineDetectAfterFilter.length})`} />
        </Tabs>
      </Box>
      <Container
        sx={{
          minHeight: 'calc(100vh - 68px - 72px)',
          backgroundColor: 'grey1',
          '.tab-container': {
            px: { xs: 2, sm: 1, md: 0 },
          },
        }}
        maxWidth={false}
      >
        <TabPanel value={selectedTab} index={0}>
          <GeneralList />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <NewList />
        </TabPanel>
      </Container>
    </Box>
  );
}