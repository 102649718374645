import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

export default function NewMachinesList({
  headers,
  data,
  actions,
  overrideGetActions,
  onRequestSort,
  order,
  orderBy,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getHeaders = () => {
    return headers.map((header) => (
      <TableCell
        sx={{
          width: header.width,
        }}
        padding={header.disablePadding ? 'none' : 'normal'}
        align={header.align}
        key={header.title}
        sortDirection={orderBy === header.id ? order : false}
      >
        {header.sortable ? (
          <TableSortLabel
            active={orderBy === header.id}
            direction={orderBy === header.id ? order : 'asc'}
            onClick={createSortHandler(header.id)}
          >
            {orderBy === header.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
            {header.title}
          </TableSortLabel>
        ) : (
          header.title
        )}
      </TableCell>
    ));
  };

  const getRows = () => {
    return data.map((row) => (
      <TableRow key={row.macAddress}>
        {Object.keys(row).map((key) => {
          return headers.map((header) => {
            if (header.dataField === key) {
              return (
                <TableCell align={header.align} key={key}>
                  {header.format ? header.format(row[key]) : row[key]}
                </TableCell>
              );
            }
          });
        })}
        {actions && <TableCell align="center">{getActions(row)}</TableCell>}
      </TableRow>
    ));
  };

  const getActions = (row) => {
    if (overrideGetActions) {
      return overrideGetActions(row);
    }
    return actions.map((action) => action.title);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 90px - 75px - 24px)' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {getHeaders()}
              {actions && (
                <TableCell
                  sx={{
                    width: '20%',
                  }}
                  padding="none"
                  align="center"
                  key="actions"
                ></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length ? (
              <TableRow>
                <TableCell
                  sx={{
                    background: '#FFCC80',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                  colSpan={7}
                >
                  There is no machine detected.
                </TableCell>
              </TableRow>
            ) : null}
            {getRows()}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

NewMachinesList.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  overrideGetActions: PropTypes.func,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
};
