import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

export default function EditProfileDialog({
  errorCode,
  currentUser,
  onSubmitEditProfileDialog,
  openEditProfileDialog,
  handleCloseEditProfileDialog,
}) {
  const defaultValues = {
    firstName: currentUser.first_name,
    lastName: currentUser.last_name,
  };

  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (openEditProfileDialog) {
      reset(defaultValues);
    }
  }, [openEditProfileDialog]);

  const onSubmit = (data) => {
    setSubmitting(true);
    if (
      data.firstName === currentUser.first_name &&
      data.lastName === currentUser.last_name
    ) {
      handleCloseEditProfileDialog();
      return;
    }
    if (onSubmitEditProfileDialog) {
      onSubmitEditProfileDialog({
        first_name: data.firstName.trim(),
        last_name: data.lastName.trim(),
      });
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern')
      return t('customer:addPatientDialog.errors.invalidNamePattern');
  };

  const closeModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (handleCloseEditProfileDialog) {
      handleCloseEditProfileDialog();
    }
    reset(defaultValues);
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openEditProfileDialog}
        onClose={closeModal}
      >
        <DialogTitle>{t('user:profile.editDialog.title')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorCode && errorCode !== 0 ? (
              <Alert severity="error">{errorCode}</Alert>
            ) : null}
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9 ]*$/,
                validate: {
                  required: (v) => !!v.trim(),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={!!errors.firstName}
                  helperText={getNameErrorMessage(errors.firstName)}
                  margin="normal"
                  fullWidth
                  id="firstName"
                  value={value}
                  label={t('common:firstName')}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9 ]*$/,
                validate: {
                  required: (v) => !!v.trim(),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={!!errors.lastName}
                  helperText={getNameErrorMessage(errors.lastName)}
                  margin="normal"
                  fullWidth
                  value={value}
                  id="lastName"
                  label={t('common:lastName')}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            {t('common:update')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditProfileDialog.propTypes = {
  errorCode: PropTypes.number,
  openEditProfileDialog: PropTypes.bool,
  handleCloseEditProfileDialog: PropTypes.func,
  onSubmitEditProfileDialog: PropTypes.func,
  currentUser: PropTypes.object,
};
