import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthDataContext } from 'components/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import LockResetIcon from '@mui/icons-material/LockReset';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import PasswordDialog from './components/PasswordDialog';
import EditProfileDialog from './components/EditProfileDialog';
import AccountAvatar from './components/AccountAvatar';

import { openDialog, closeDialog } from 'lib/dialog.js';
import { DIALOGS } from 'modules/dialogs/constants';
import { requestUpdateProfile, requestChangeUserPassword } from 'modules/profile/slice';
import { requestLogout } from 'modules/auth/slice';

export default function UserAccount({ signOutUrl }) {
  const context = useAuthDataContext();
  const errorCode = useSelector((state) => state.profile.errorCode);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = localStorage.getItem('access_token');

  const openPasswordDialog = useSelector((state) => state.dialogs[DIALOGS.CHANGE_PASSWORD]);

  const handleOpenPasswordDialog = () => {
    openDialog(dispatch, DIALOGS.CHANGE_PASSWORD);
  };

  const handleClosePasswordDialog = () => {
    closeDialog(dispatch, DIALOGS.CHANGE_PASSWORD);
  };

  const onSubmitPasswordDialog = (data) => {
    dispatch(requestChangeUserPassword({ ...data }));
  };

  const openEditProfileDialog = useSelector((state) => state.dialogs[DIALOGS.CHANGE_PROFILE]);

  const handleOpenEditProfileDialog = () => {
    openDialog(dispatch, DIALOGS.CHANGE_PROFILE);
  };

  const handleCloseEditProfileDialog = () => {
    closeDialog(dispatch, DIALOGS.CHANGE_PROFILE);
  };

  const onSubmitEditProfileDialog = (data) => {
    dispatch(requestUpdateProfile({ ...data }));
  };

  const openProfileDialog = () => {
    handleOpenEditProfileDialog();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // handleClose();
    dispatch(requestLogout({ token }));
    navigator(signOutUrl);
  };
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <AccountAvatar user={context.currentUser} setAnchorEl={setAnchorEl} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Tooltip title={`${context.currentUser.first_name} ${context.currentUser.last_name}`}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  maxWidth: '250px',
                  whiteSpace: 'noWrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {context.currentUser.first_name} {context.currentUser.last_name}
              </Typography>
            </Tooltip>
            <Tooltip title={`${context.currentUser.email}`}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  maxWidth: '250px',
                  whiteSpace: 'noWrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {context.currentUser.email}
              </Typography>
            </Tooltip>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={openProfileDialog}>
          <ListItemIcon>
            <ManageAccountsIcon fontSize='small' />
          </ListItemIcon>
          {t('auth:editProfile')}
        </MenuItem>
        <MenuItem onClick={handleOpenPasswordDialog}>
          <ListItemIcon>
            <LockResetIcon fontSize='small' />
          </ListItemIcon>
          {t('auth:changePassword')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          {t('auth:logout')}
        </MenuItem>
      </Menu>
      <PasswordDialog
        errorCode={errorCode}
        onSubmitPasswordDialog={onSubmitPasswordDialog}
        openPasswordDialog={openPasswordDialog}
        handleClosePasswordDialog={handleClosePasswordDialog}
      />
      <EditProfileDialog
        onSubmitEditProfileDialog={onSubmitEditProfileDialog}
        errorCode={errorCode}
        currentUser={context.currentUser}
        openEditProfileDialog={openEditProfileDialog}
        handleCloseEditProfileDialog={handleCloseEditProfileDialog}
      />
    </>
  );
}

UserAccount.propTypes = {
  signOutUrl: PropTypes.string,
};
