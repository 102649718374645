import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { CircularProgress, Stack } from '@mui/material';

export default function CustomersList({
  isSearching,
  headers,
  data,
  actions,
  overrideGetActions,
  onRequestSort,
  order,
  orderBy,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const isLoading = useSelector(state => state.customers.isLoading)
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getHeaders = () => {
    return headers.map((header) => (
      <TableCell
        sx={{
          width: header.width,
        }}
        padding={header.disablePadding ? 'none' : 'normal'}
        align={header.align}
        key={header.title}
        sortDirection={orderBy === header.id ? order : false}
      >
        {header.sortable ? (
          <TableSortLabel
            active={orderBy === header.id}
            direction={orderBy === header.id ? order : 'asc'}
            onClick={createSortHandler(header.id)}
          >
            {orderBy === header.id ? (
              <Box component='span' sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
            {header.title}
          </TableSortLabel>
        ) : (
          header.title
        )}
      </TableCell>
    ));
  };

  const getRows = () => {
    if (isLoading) {
      return <TableRow >
        {actions && (
          <TableCell align='center' colSpan={headers.length}>
            <CircularProgress size={24} />
          </TableCell>
        )}
      </TableRow>
    }
    return data.map((row, i) => (
      <TableRow onClick={() => handleRowClick(row)} key={row.name + i}>
        {Object.keys(row).map((key) => {
          return headers.map((header) => {
            if (header.dataField === key) {
              return (
                <TableCell align={header.align} key={key}>
                  {header.format ? header.format(row[key]) : row[key]}
                </TableCell>
              );
            }
            return null;
          });
        })}
        {actions && (
          <TableCell align='center'>
            <IconButton onClick={handleClick} aria-label='more'>
              <MoreVertIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    ));
  };

  const onActionClick = (action) => {
    if (action.callback) {
      action.callback(selectedRow);
    }
    handleClose();
  };

  const getActions = () => {
    if (overrideGetActions) {
      return overrideGetActions(selectedRow, handleClose);
    }
    return actions.map((action) => (
      <MenuItem key={action.title} onClick={() => onActionClick(action)}>
        {action.title}
      </MenuItem>
    ));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 90px - 75px - 80px)' }} >
        <Table stickyHeader aria-label='sticky table'>
          {isSearching && data && !data.length && <caption>{t('common:errors.noResults')}</caption>}
          {!isSearching && !isLoading && (!data || (data && !data.length)) ? (
            <caption>{t('common:noData')}</caption>
          ) : null}

          <TableHead>
            <TableRow>
              {getHeaders()}
              {actions && (
                <TableCell
                  sx={{
                    width: '10%',
                  }}
                  padding='none'
                  align='center'
                  key='actions'
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{getRows()}</TableBody>
        </Table>
        {actions && (
          <Menu id='table-action' anchorEl={anchorEl} open={open} onClose={handleClose}>
            {getActions()}
          </Menu>
        )}
      </TableContainer>
    </Paper>
  );
}

CustomersList.propTypes = {
  isSearching: PropTypes.bool,
  headers: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  overrideGetActions: PropTypes.func,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
};
