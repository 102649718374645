import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useAuthDataContext } from 'components/AuthProvider';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import UI from 'modules/ui/UI';
import CurrentUser from 'modules/auth/containers/currentUser';
import Dialogs from 'modules/dialogs/containers/Dialogs';
import { Main } from './Main/Main';
import { DrawerHeader } from './DrawerHeader/DrawerHeader';
import { BionexLinks } from './Links';
import AdminHeader from './AdminHeader';
import Version from './version';

const drawerWidth = 300;

export default function AppLayout({ children }) {
  const theme = useTheme();
  const context = useAuthDataContext();
  const [open, setDrawerOpen] = useState(false);
  const currentUser = context.currentUser;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (!currentUser || (currentUser && !currentUser.id)) {
      handleDrawerClose();
    } else {
      // open the drawer on all pages
      handleDrawerOpen();
    }
  }, [currentUser, location]);

  const getHeader = () => {
    if (!currentUser || (currentUser && !currentUser.id)) {
      return null;
    }
    return <AdminHeader drawerWidth={drawerWidth} open={open} handleDrawerOpen={handleDrawerOpen} />;
  };

  const getLinks = () => {
    return <BionexLinks open={open} />;
  };

  return (
    <Box sx={{ display: 'flex' }} id={'heheh'}>
      {/* left side drawer menu */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: 'black',
            color: 'white',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <DrawerHeader>
          <IconButton
            sx={{
              color: 'white',
            }}
            size='large'
            onClick={handleDrawerClose}
          >
            {theme.direction === 'ltr' ? <MenuOpenIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>{getLinks()}</List>
      </Drawer>
      {/* UI elements */}
      <UI />
      {/* main container */}
      <Main open={open}>
        {/* current user context */}
        <CurrentUser />
        {/* dialogs that can be opened anywhere */}
        <Dialogs />
        {getHeader(currentUser)}
        {children}
      </Main>
      <Version version='v2.30.4' />
    </Box>
  );
}

AppLayout.propTypes = {
  children: PropTypes.element,
};
