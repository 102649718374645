import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

export default function ConfirmDialog({
  title,
  message,
  confirmText,
  noCancel,
  openConfirmDialog,
  handleCloseConfirmDialog,
  handleSubmitConfirmDialog,
  backdropProps,
}) {
  const { t } = useTranslation();

  const closeModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (handleCloseConfirmDialog) {
      handleCloseConfirmDialog();
    }
  };

  const onSubmit = (e) => {
    e.currentTarget.disabled = true;
    if (handleSubmitConfirmDialog) {
      handleSubmitConfirmDialog();
    }
  };

  return (
    <div>
      <Dialog maxWidth='xs' BackdropProps={backdropProps} onClose={closeModal} open={openConfirmDialog}>
        <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
        <DialogContent>
          <Typography sx={{ color: '#000' }} variant='p' component='div'>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          {noCancel ? null : (
            <Button variant='outlined' sx={{ border: '1px solid #e2e4e7' }} onClick={closeModal}>
              {' '}
              {t('common:cancel')}
            </Button>
          )}
          <Button variant='contained' sx={{ color: 'white' }} onClick={onSubmit} autoFocus>
            {confirmText || 'Agree'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  handleCloseConfirmDialog: PropTypes.func,
  handleSubmitConfirmDialog: PropTypes.func,
  message: PropTypes.any,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  openConfirmDialog: PropTypes.bool,
  noCancel: PropTypes.bool,
  backdropProps: PropTypes.object,
};
