import { BionexSignIn } from 'modules/auth/containers/SignIn';
import NotFoundPage from '../../components/NotFoundPage';
import SignOut from './containers/SignOut';
import { BionexForgotPassword } from 'modules/auth/containers/ForgotPassword';
import { BionexAcceptInvite } from 'modules/auth/containers/AcceptInvite';
import { BionexResetPassword } from 'modules/auth/containers/ResetPassword';

const route =  [
  {
    path: '*',
    element: <NotFoundPage />,
    public: true,
  },
  {
    path: '/sign-in',
    element: <BionexSignIn />,
  },
  {
    path: '/sign-out',
    element: <SignOut />,
    public: true,
  },
  {
    path: '/forgot-password',
    element: <BionexForgotPassword />,
    public: true,
  },
  {
    path: '/users/accept-invite',
    element: <BionexAcceptInvite />,
  },
  {
    path: '/users/reset-password',
    element: <BionexResetPassword />,
  },
];

export default route