import ENV_VARIABLES from 'config/variables';

export const DETECTED_MACHINE_STATUS = {
  FAILED: -1,
  READY: 0,
  CONFIGURING: 1,
  COMPLETED: 2,
};

export const LIST_MODE_AUDIT_LOG = [
  {
    id: 1,
    name: 'Online',
    value: 'online'
  },
  {
    id: 2,
    name: 'Offline',
    value: 'offline'
  },
]

export const LIST_MODULE_AUDIT_LOG = [
  {
    id: 1,
    name: 'Authentication',
    value: 'authentication'
  },
  {
    id: 2,
    name: 'User management',
    value: 'user_management'
  },
  {
    id: 3,
    name: 'Customer management',
    value: 'customer_management'
  },
  {
    id: 4,
    name: 'Roles and permissions',
    value: 'role_permissions'
  },
  {
    id: 5,
    name: 'Machine management',
    value: 'machine_management'
  },
  {
    id: 6,
    name: 'Manage software update',
    value: 'manage_software_update'
  },
  {
    id: 7,
    name: 'Account settings',
    value: 'account_settings'
  }
];

export const LIST_ACTION_AUDIT_LOG = [
  { id: 1, name: 'Successfully log in' },
  { id: 2, name: 'Failed to log in' },
  { id: 3, name: 'Account has been locked' },
  { id: 4, name: 'Session timeout' },
  { id: 5, name: 'Log out' },
  { id: 6, name: 'Add new user' },
  { id: 7, name: 'User accepted invite' },
  { id: 8, name: 'Delete user' },
  { id: 9, name: 'Suspend user' },
  { id: 10, name: 'Restore user' },
  { id: 11, name: 'Add customer' },
  { id: 12, name: 'Customer accepted invite' },
  { id: 13, name: 'Edit customer' },
  { id: 14, name: 'Access customer site' },
  { id: 15, name: 'Suspend customer' },
  { id: 16, name: 'Restore customer' },
  { id: 17, name: 'Update newest software' },
  { id: 18, name: 'Manage feature options' },
  { id: 19, name: 'Add new role' },
  { id: 20, name: 'Edit permissions' },
  { id: 21, name: 'Remove role' },
  { id: 22, name: 'Add machine' },
  { id: 23, name: 'Assign machine' },
  { id: 24, name: 'Edit machine' },
  { id: 25, name: 'Remove machine' },
  { id: 26, name: 'Machine calibration - Potentiometer' },
  { id: 27, name: 'Machine calibration - Strain Gauge' },
  { id: 28, name: 'Machine calibration - Machine Debug' },
  { id: 29, name: 'Machine calibration - Machine Specification Setting' },
  { id: 30, name: 'Enable remote access' },
  { id: 31, name: 'Upload new version' },
  { id: 32, name: 'Edit profile' },
  { id: 33, name: 'Change password' },
]

export const END_POINT = {
  getExamples: {
    url: (exampleId) => `${ENV_VARIABLES.API_SERVER}examples?example_id=${exampleId}`,
  },
  requestExportAuditLog: {
    url: `${ENV_VARIABLES.API_SERVER}logging/export-excel`,
    method: 'GET'
  },
  requestFetchListActivityTracking: {
    url: `${ENV_VARIABLES.API_SERVER}activity_tracking`,
    method: 'GET'
  },
  requestLoggingRecordAction: {
    url: `${ENV_VARIABLES.API_SERVER}logging/record`,
    method: 'POST'
  }
};
