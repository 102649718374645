import styled from '@emotion/styled';

const InfinityTableContainer = styled('div')`
  width: 100%;
  height: 100%;

  & .infinity-row:hover {
    background: #ed9a0014 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  & .row {
    border: 1px solid rgb(226, 228, 231);

    &:not(:last-of-type) {
      border-bottom: 0 !important;
    }
    padding: 0 48px;
  }
`;

export default InfinityTableContainer;
