import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useContext, useState } from 'react';
import { StepReplaceContext } from '../Dialogs/ReplaceMachineDialog';

export default function ContentStepSyncData({ }) {
  const { t } = useTranslation();

  const stepReplaceData = useContext(StepReplaceContext);
  const { closeModal, handleNext } = stepReplaceData;

  const renderContent = () => {
    return (
      <Box>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' component='div'>
          {`${t('machine:contentStepSync.considerText')}`}
        </Typography>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' component='div'>
          {`${t('machine:contentStepSync.warningText')}`}
        </Typography>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' component='div'>
          {`${t('machine:contentStepSync.confirmText')}`}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant='outlined' sx={{ border: '1px solid #e2e4e7' }} onClick={closeModal}>
            {t('machine:contentStepSync.textBtnCancel')}
          </Button>
          <Button variant='contained' sx={{ color: 'white' }} onClick={handleNext} autoFocus>
            {t('machine:contentStepSync.textBtnConfirm')}
          </Button>
        </Box>
      </Box>
    );
  };

  return renderContent();
}

ContentStepSyncData.propTypes = {
}
