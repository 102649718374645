import { useState, useEffect, useRef } from 'react';
import { Modal as MuiModal } from '@mui/material';
const { format } = require('date-fns');

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import CustomersList from 'modules/customers/components/CustomersList';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { style, headers } from '../constants';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IndeterminateProgress from 'components/IndeterminateProgress';
import { listVersion, requestUpdateSoftwareVersion } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { dateParserWithTimezone } from '../../../helpers';

export default function ManageSoftware() {
  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const isFetching = useSelector((state) => state.software.isFetching);
  const versions = useSelector((state) => state.software.versions);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('hcp_name');
  const [open, setOpen] = useState(false);
  const [progressModal, setProgressModal] = useState(false);
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');
  const [isRequest, setIsRequest] = useState(false);
  const [isFormDataReady, setIsFormDataReady] = useState(false);
  const [data, setData] = useState([]);

  const defaultValues = {
    releaseName: '',
    releaseVersion: '',
  };

  const {
    reset,
    control,
    handleSubmit,
    watch,
    formState: {},
  } = useForm({ mode: 'all', defaultValues });
  const formData = watch();

  useEffect(() => {
    dispatch(listVersion());
  }, []);

  useEffect(() => {
    if (!versions || (versions && versions.length === 0)) {
      return;
    }

    const d = [];

    versions.map((version, index) => {
      const localDate = dateParserWithTimezone(version.created_at);

      d.push({
        key: index,
        fileName: `${version.name} ${version.version}`,
        publish: localDate ? format(localDate, "MM/dd/yyyy 'at' hh:mm a") : '',
        size: version.size,
      });
    });

    setData(d);
  }, [versions]);

  useEffect(() => {
    if (!progressModal) {
      setFileName('');
      setFile('');
      reset();
    }
  }, [progressModal]);

  useEffect(() => {
    if (isFetching) {
      setIsRequest(true);
    }

    if (isRequest && !isFetching) {
      setProgressModal(false);
      setIsRequest(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!formData.releaseName || !formData.releaseVersion || !file) {
      setIsFormDataReady(false);
      return;
    }

    setIsFormDataReady(true);
  }, [file, formData]);

  const closeModalHandler = () => {
    setOpen(false);
    setFile('');
    setFileName('');
    fileInput.current = null;
    reset();
  };

  const closeProcessModalHandler = () => {
    setProgressModal(false);
    setFile('');
    setFileName('');
    fileInput.current = null;
    reset();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onFileChangeHandler = (e) => {
    if (!e.target.value || e.target.files.length === 0) {
      return;
    }

    let name = e.target.files[0].name;

    if (name.length > 25) {
      name = `${name.substring(0, 11)}...${name.substring(name.length - 11)}`;
    }
    setFile(e.target.files[0]);
    setFileName(name);
  };

  const onSubmitHandler = (value) => {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(requestUpdateSoftwareVersion({ file: formData, version: value.releaseVersion, name: value.releaseName }));
    setOpen(false);
    setProgressModal(true);
  };

  const linkTextRender = () => {
    if (!fileName) {
      return (
        <Box>
          <span
            style={{ cursor: 'pointer', fontWeight: 700 }}
            onClick={() => fileInput.current && fileInput.current.click()}
          >
            Select File
          </span>
          &nbsp;to upload
        </Box>
      );
    }

    return (
      <Box>
        <span
          style={{ cursor: 'pointer', fontWeight: 700 }}
          onClick={() => fileInput.current && fileInput.current.click()}
        >
          Select Other File
        </span>
      </Box>
    );
  };

  const contentRender = () => {
    return (
      <Box>
        <Box sx={{ pt: '20px' }}>
          <form style={{ display: 'flex', flexDirection: 'column', gap: 20 }} onSubmit={handleSubmit(onSubmitHandler)}>
            <Controller
              name='releaseName'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  sx={{ margin: 0 }}
                  id='releaseName'
                  value={value}
                  label='Release Name*'
                  onChange={(e) => {
                    if (e.target.value.length <= 100) {
                      onChange(e);
                    }
                  }}
                />
              )}
            />
            <Controller
              name='releaseVersion'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ margin: 0 }}
                  fullWidth
                  id='releaseVersion'
                  value={value}
                  label='Release Version*'
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      onChange(e);
                    }
                  }}
                />
              )}
            />
            <Box>
              <input
                onChange={onFileChangeHandler}
                type='file'
                ref={fileInput}
                style={{ display: 'none' }}
                accept={'.shiv'}
              />
              {linkTextRender()}
              <Box>{fileName}</Box>
            </Box>

            <Button
              sx={{
                py: '10px',
                width: '100%',
                color: 'black',
                boxShadow: 'none',
                textTransform: 'none',
              }}
              type={'submit'}
              disabled={!isFormDataReady}
              variant={'contained'}
            >
              <div style={{ fontSize: '14px', textTransform: 'uppercase' }}>Upload File</div>
            </Button>
          </form>
        </Box>
      </Box>
    );
  };

  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 70px)',
        backgroundColor: 'grey1',
      }}
      maxWidth={false}
    >
      <Box>
        <Toolbar
          sx={{
            pl: { xs: 2, sm: 1, md: 0 },
            pr: { xs: 1, sm: 1, md: 0 },
            py: { xs: 2, sm: 2, md: 2 },
            pb: { xs: 2, sm: 2, md: 2 },
          }}
        >
          <Typography sx={{ display: 'flex', fontSize: '1.5rem' }} variant='h6' component='div'>
            Software Update
          </Typography>
          <Button sx={{ ml: 'auto' }} variant='contained' size='large' onClick={() => setOpen(true)}>
            {'Upload new version'}
          </Button>
        </Toolbar>

        <CustomersList
          headers={headers}
          data={data}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <MuiModal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
          <Box sx={{ ...style }}>
            <Box>
              <Box
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ width: '100%' }}>Upload New Version</Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                  }}
                >
                  <IconButton sx={{ padding: 0 }} onClick={closeModalHandler}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box>
                <div id='child-modal-description'>{contentRender()}</div>
              </Box>
            </Box>
          </Box>
        </MuiModal>

        <MuiModal open={progressModal} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
          <Box sx={{ ...style }}>
            <Box>
              <Box
                sx={{
                  fontSize: '18px',
                  fontWeight: 700,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ width: '100%' }}>File Uploading</Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                  }}
                >
                  <IconButton sx={{ padding: 0 }} onClick={closeProcessModalHandler}>
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ py: '10px', display: 'flex', gap: 3, flexDirection: 'column' }}>
                <Box sx={{ fontSize: '16px' }}>
                  This may take a few moments to process. Please do not close the browser.
                </Box>
                <IndeterminateProgress />
              </Box>
            </Box>
          </Box>
        </MuiModal>
      </Box>
    </Container>
  );
}
