import PropTypes from 'prop-types';
import { useAuthDataContext } from 'components/AuthProvider';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PrivateRoute from 'components/AppRoute/PrivateRoute';
import PublicRoute from 'components/AppRoute/PublicRoute';
import ProtectedRoute from 'components/AppRoute/ProtectedRoute';
import { useSocketDataContext } from '../WebSocketProvider';
import ENV_VARIABLES from '../../config/variables';
// import routes from '../../routes.js';

export default function AppRoute({ routes }) {
  const dispatch = useDispatch();
  const socketContext = useSocketDataContext();

  const { currentUser, token } = useAuthDataContext();

  const isAuthenticated = () => !!(currentUser && currentUser.id);

  const isAdmin = () => {
    return currentUser?.roles?.filter((role) => role.name === 'Admin' || role.name === 'BioneX Admin').length > 0;
  };

  if (token && !isAuthenticated()) {
    return null;
  }

  if (localStorage.getItem('access_token')) {
    const accessToken = localStorage.getItem('access_token');
    socketContext.setSocketUrl(`${ENV_VARIABLES.WS_SERVER}?access_token=${accessToken}`);
  }

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          path={route.path}
          key={route.path}
          state={route.state}
          element={
            route?.private ? (
              <PrivateRoute isAdmin={isAdmin()} authed={isAuthenticated()}>
                {route.element}
              </PrivateRoute>
            ) : route?.protected ? (
              <ProtectedRoute isAdmin={isAdmin()}>{route.element}</ProtectedRoute>
            ) : (
              <PublicRoute authed={isAuthenticated()} isAdmin={isAdmin()} route={route} dispatch={dispatch}>
                {route.element}
              </PublicRoute>
            )
          }
        />
      ))}
    </Routes>
  );
}

AppRoute.propTypes = {
  routes: PropTypes.array,
};
