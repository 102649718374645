import { Box, Button, Typography } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function SuccessCreateCustomer({ createdCustomer, isOpenedOutside, handleAddMoreCustomer, closeModal }) {
  const { t } = useTranslation();

  return <Box>
    <Box
      sx={{
        display: 'flex',
        color: '#2e7d32',
        pt: 5,
        pb: 3,
        alignItems: 'center',
      }}
    >
      <CheckCircleOutlineRoundedIcon
        sx={{
          mr: 1,
          fontSize: '2.3rem',
        }}
      />
      <Typography
        sx={{
          fontSize: '1.1rem',
          fontWeight: 'bold',
        }}
      >
        {createdCustomer.isNewInvite
          ? t('customer:confirmInviteDialog.ownerChanged')
          : t('customer:confirmInviteDialog.newCustomer')}
      </Typography>
    </Box>
    <Typography variant='body2'>
      {t('customer:confirmInviteDialog.message')}
      <strong>
        {' '}
        &quot;{createdCustomer.owner_info.first_name} {createdCustomer.owner_info.last_name}&quot;
      </strong>{' '}
      at <strong>{createdCustomer.owner_info.email}</strong>.
    </Typography>
    {isOpenedOutside && (
      <Typography sx={{ mt: 1 }} variant='body2'>
        {t('customer:inviteDialog.toView')} <Link to={mainRoute}>{t('common:clickHere')}</Link>.
      </Typography>
    )}
    <Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
      <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
        {t('common:close')}
      </Button>
      {!createdCustomer.isNewInvite && (
        <Button onClick={handleAddMoreCustomer} variant='contained'>
          {t('customer:confirmInviteDialog.addAnotherHCP')}
        </Button>
      )}
    </Box>
  </Box>
}

SuccessCreateCustomer.propTypes = {
  createdCustomer: PropTypes.any,
  isOpenedOutside: PropTypes.any,
  handleAddMoreCustomer: PropTypes.func,
  closeModal: PropTypes.func
};