import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useSocketDataContext } from 'components/WebSocketProvider';
import socketActions from 'components/WebSocketProvider/actions';
import ScrollDialog from '../../../../components/ScrollDialog';

export default function DebugMachineDialog({ machine, error, openDebugMachineDialog, handleCloseDebugMachineDialog }) {
  const { t } = useTranslation();
  const socketContext = useSocketDataContext();
  const [isSubmitting, setSubmitting] = useState(false);

  const [success, setSuccessState] = useState(false);

  const [value, setValue] = useState({
    machineName: '',
    machineSerial: '',
    machineType: '',
    lodeCellPlus: '',
    lodeCellMinus: '',
    pot: '',
    angleRange: '',
    switchState: '',
    pound: 0,
  });

  useEffect(() => {
    if (!openDebugMachineDialog) {
      return;
    }

    return () => {
      socketContext.sendJsonMessage({
        request: socketActions.CLOSE_DEBUG_MACHINE,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    };
  }, []);

  useEffect(() => {
    if (!openDebugMachineDialog || !machine || !machine.machine) {
      return;
    }

    setValue((prevState) => ({
      ...prevState,
      machineName: machine.machine.name,
      machineSerial: machine.machine.serial_no,
      machineType: machine.machine.machine_type,
    }));

    socketContext.sendJsonMessage({
      request: socketActions.OPEN_DEBUG_MACHINE,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (message && message.u12_id && message.signal === socketActions.OPEN_DEBUG_MACHINE) {
      setValue((prevState) => ({
        ...prevState,
        lodeCellPlus: message.plode_cell,
        lodeCellMinus: message.nlode_cell,
        pot: message.pot,
        angleRange: message.degree,
        switchState: message.switch,
        pound: message.pound,
      }));
    }
  }, [openDebugMachineDialog, machine, socketContext.messageHistory]);

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  const closeModal = () => {
    if (machine && machine.machine) {
      socketContext.sendJsonMessage({
        request: socketActions.CLOSE_DEBUG_MACHINE,
        u12_id: machine.machine.u12_id,
        mac_address: machine.machine.mac_addr,
      });
    }

    if (handleCloseDebugMachineDialog) {
      handleCloseDebugMachineDialog();
    }

    setSuccessState(false);
    setValue({
      machineName: '',
      machineSerial: '',
      machineType: '',
      lodeCellPlus: '',
      lodeCellMinus: '',
      pot: '',
      angleRange: '',
      switchState: '',
    });
  };

  const onSubmit = () => {
    setSubmitting(true);
    const submitData = { ...value };
    submitData.id = machine.id;
    if (handleCloseDebugMachineDialog) {
      handleCloseDebugMachineDialog(submitData);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);

    socketContext.sendJsonMessage({
      request: socketActions.CLOSE_DEBUG_MACHINE,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });
  };

  const renderContent = () => {
    return (
      <Box sx={{ width: '100%', position: 'relative' }} role='presentation'>
        {success ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                color: '#2e7d32',
                pt: 5,
                pb: 3,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  mr: 1,
                  fontSize: '2.3rem',
                }}
              />
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                {t('machine:confirmAddMachine.newMachine')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:close')}
              </Button>
            </Box>
          </Box>
        ) : (
          <form>
            <Box>
              {error && error.code !== 400 && <Alert severity='error'>{error.message}</Alert>}

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{t('machine:machineTable.name')}</div>

                  <div style={{ fontSize: '18px', fontWeight: 600 }}> {value.machineName}</div>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{t('machine:machineTable.machineType')}</div>

                  <div style={{ fontSize: '18px', fontWeight: 600 }}> {value.machineType}</div>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <div>{t('machine:machineTable.serialNo')}</div>

                  <div style={{ fontSize: '18px', fontWeight: 600 }}> {value.machineSerial}</div>
                </Box>
              </Box>
              <br />

              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    background: '#E9ECEF',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ fontSize: '18px', fontWeight: 600 }}>{t('machine:debugDialog.lodeCellPlus')}</div>
                  <div style={{ fontSize: '30px', fontWeight: 600 }}> {value.lodeCellPlus}</div>
                </Box>

                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    background: '#E9ECEF',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ fontSize: '18px', fontWeight: 600 }}>{t('machine:debugDialog.lodeCellMinus')}</div>
                  <div style={{ fontSize: '30px', fontWeight: 600 }}> {value.lodeCellMinus}</div>
                </Box>
              </Box>

              <Box sx={{ height: '8px' }}></Box>

              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    background: '#E9ECEF',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ fontSize: '18px', fontWeight: 600 }}>{t('machine:debugDialog.pot')}</div>
                  <div style={{ fontSize: '30px', fontWeight: 600 }}> {value.pot}</div>
                </Box>
                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    background: '#E9ECEF',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ fontSize: '18px', fontWeight: 600 }}>{t('machine:debugDialog.currentAngle')}</div>
                  <div style={{ fontSize: '30px', fontWeight: 600 }}> {value.angleRange}</div>
                </Box>
                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    background: '#E9ECEF',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ fontSize: '18px', fontWeight: 600 }}>{t('machine:debugDialog.switchState')}</div>
                  <div style={{ fontSize: '30px', fontWeight: 600 }}> {value.switchState}</div>
                </Box>
                <Box
                  sx={{
                    padding: '8px',
                    borderRadius: '8px',
                    background: '#E9ECEF',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div style={{ fontSize: '18px', fontWeight: 600 }}>{t('machine:debugDialog.torque')}</div>
                  <div style={{ fontSize: '30px', fontWeight: 600 }}> {Math.abs(Math.round(value.pound))}</div>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Box>
    );
  };

  return (
    <ScrollDialog
      fullWidth={true}
      maxWidth={'sm'}
      hasCloseIcon={false}
      title={t('machine:debugDialog.title')}
      message={renderContent()}
      handleCloseModal={onClose}
      handleSubmitModal={onSubmit}
      primaryActionText={'End Debugging'}
      openModal={openDebugMachineDialog}
    />
  );
}

DebugMachineDialog.propTypes = {
  machine: PropTypes.object,
  customers: PropTypes.array,
  anchor: PropTypes.string,
  error: PropTypes.object,
  onSubmitDebugMachineDialog: PropTypes.func,
  openDebugMachineDialog: PropTypes.bool,
  handleCloseDebugMachineDialog: PropTypes.func,
};
