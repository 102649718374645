import { styled } from '@mui/material/styles';
import ENV_VARIABLES from '../../config/variables';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '4px',
  outline: 'none',
};

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
}));
export const seedData = [];

export const headers = [
  {
    id: 'fileName',
    title: 'File Name',
    dataField: 'fileName',
    align: 'center',
    disablePadding: false,
    sortable: false,
    width: '30%',
  },
  {
    id: 'publish',
    title: 'Publication date',
    dataField: 'publish',
    align: 'center',
    disablePadding: false,
    sortable: false,
    width: '40%',
  },
  {
    id: 'size',
    title: 'Size',
    dataField: 'size',
    align: 'center',
    disablePadding: false,
    sortable: false,
    width: '30%',
  },
];

export const END_POINT = {
  listVersion: {
    url: `${ENV_VARIABLES.API_SERVER}machines/versions`,
  },
  uploadVersionFile: {
    url: (version, name) => `${ENV_VARIABLES.API_SERVER}machines/software-version?version=${version}&name=${name}`,
  },
  pushVersionToHcp: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}machines/push_update_version/${hcpId}`,
  },

  getLatestVersion: {
    url: `${ENV_VARIABLES.API_SERVER}machines/version`,
  },
};
