import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  requestInviteUser,
  requestInviteUserSuccess,
  requestInviteUserFailure,
  requestDeleteUser,
  requestDeleteUserSuccess,
  requestDeleteUserFailure,
  requestSuspendUser,
  requestSuspendUserSuccess,
  requestSuspendUserFailure,
  requestRestoreUser,
  requestRestoreUserSuccess,
  requestRestoreUserFailure,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailure,
} from './slice';

import { END_POINT } from './constants';
import { requestToast } from 'modules/ui/slice';
import { TOAST_TYPE } from 'modules/ui/constants';

function* getUsers() {
  try {
    const { data } = yield axios.get(END_POINT.getUsers.url);
    if (data.data) {
      yield put(fetchUsersSuccess(data.data));
    } else {
      yield put(fetchUsersFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchUsersFailure(e.message));
  }
}

function* inviteUser(action) {
  try {
    const { data } = yield axios.post(END_POINT.inviteUser.url, {
      first_name: action.payload.firstName,
      last_name: action.payload.lastName,
      email: action.payload.email,
      role_id: action.payload.role,
    });
    if (data.code === 201) {
      yield put(requestInviteUserSuccess(data));
      yield put(fetchUsers());
    } else {
      yield put(requestInviteUserFailure(data));
    }
  } catch (e) {
    yield put(requestInviteUserFailure(e.message));
  }
}

function* deleteUser(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.manageUser.url(action.payload.userId),
      {
        action: 'delete',
      }
    );
    if (data.code === 200) {
      yield put(requestDeleteUserSuccess(data));
      yield put(fetchUsers());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" deleted.`,
        })
      );
    } else {
      yield put(requestDeleteUserFailure(data));
    }
  } catch (e) {
    yield put(requestDeleteUserFailure(e.message));
  }
}

function* suspendUser(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.manageUser.url(action.payload.userId),
      {
        action: 'suspend',
      }
    );
    if (data.code === 200) {
      yield put(requestSuspendUserSuccess(data));
      yield put(fetchUsers());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" suspended.`,
        })
      );
    } else {
      yield put(requestSuspendUserFailure(data));
    }
  } catch (e) {
    yield put(requestSuspendUserFailure(e.message));
  }
}

function* restoreUser(action) {
  try {
    const { data } = yield axios.put(
      END_POINT.manageUser.url(action.payload.userId),
      {
        action: 'active',
      }
    );
    if (data.code === 200) {
      yield put(requestRestoreUserSuccess(data));
      yield put(fetchUsers());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" restored.`,
        })
      );
    } else {
      yield put(requestRestoreUserFailure(data));
    }
  } catch (e) {
    yield put(requestRestoreUserFailure(e.message));
  }
}

function* getRoles() {
  try {
    const { data } = yield axios.get(END_POINT.getRoles.url);
    yield put(fetchRolesSuccess(data.data));
  } catch (e) {
    yield put(fetchRolesFailure(e.message));
  }
}

function* usersSaga() {
  yield takeLatest(fetchUsers, getUsers);
  yield takeLatest(fetchRoles, getRoles);
  yield takeLatest(requestInviteUser, inviteUser);
  yield takeLatest(requestDeleteUser, deleteUser);
  yield takeLatest(requestSuspendUser, suspendUser);
  yield takeLatest(requestRestoreUser, restoreUser);
}

export default usersSaga;
