import { createContext, useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useAuthDataContext } from 'components/AuthProvider';
import SocketNotification from './SocketNotification';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import socketActions from './actions';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { DIALOGS } from 'modules/dialogs/constants';

export const SocketDataContext = createContext(null);

const WebSocketProvider = (props) => {
  const { currentUser, token } = useAuthDataContext();
  const machines = useSelector((state) => state.machines.machines);
  const [socketUrl, setSocketUrl] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [shouldRefreshMachines, setRefreshMachines] = useState(0);
  const [detectedMachines, setDetectedMachines] = useState([]); // use for machine counts on notification, will be resetted to 0 when notification is closed
  const [openNoti, setOpenNoti] = useState({ open: false, message: '' });
  const [openPersistentNoti, setOpenPersistentNoti] = useState(false);
  const [snackPersistMessage, setPersistSnackMessage] = useState('');
  let pingPongSocketConnectionInterval = 0;
  const pairPingPongTimeStampRef = useRef(0);

  const isAuthenticated = () => !!(currentUser && currentUser.id);

  const isAdmin = () => {
    return currentUser?.roles?.filter((role) => role.name === 'Admin' || role.name === 'BioneX Admin').length > 0;
  };

  const openReplaceDialog = useSelector((state) => state.dialogs[DIALOGS.REPLACE_MACHINE_DIALOG]);

  const pingPongSocketConnection = () => {
    // check ping pong socket connection every 30s
    pingPongSocketConnectionInterval = setInterval(() => {
      sendJsonMessage({ request: socketActions.PING });

      const reachPingTime = (Date.now() - pairPingPongTimeStampRef.current) / 1000;
      if (reachPingTime > 300) {
        console.warn('PING 10 times but server not response');
        pairPingPongTimeStampRef.current = Date.now();
      }
    }, 30000);
  };

  const handelChangeWS = useCallback((url) => {
    if (url !== socketUrl) {
      setSocketUrl(url);
    }
  }, []);

  const handleSetRefreshMachines = () => {
    setRefreshMachines(() => shouldRefreshMachines + 1);
  };

  const debounceSetRefreshMachine = debounce(handleSetRefreshMachines, 700);

  const { sendJsonMessage, sendMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.warn('onOpen');

      pingPongSocketConnection();
      pairPingPongTimeStampRef.current = Date.now();
    },
    onClose: () => {
      console.warn('onClose', pingPongSocketConnectionInterval);

      clearInterval(pingPongSocketConnectionInterval);
    },
    shouldReconnect: () => true,
  });

  useEffect(() => {
    console.warn('readyState', readyState);

    if (((token && !isAuthenticated()) || !isAdmin()) && readyState === ReadyState.OPEN) {
      // sendJsonMessage({ request: 'STOP' });
      setOpenNoti({ open: false, message: '' });
      setOpenPersistentNoti(false);
      setMessageHistory([]);
    }
  }, [readyState, currentUser]);

  // const getMachineDetected = (arrayDetected, arrayMaintenance) => {
  //   if (isEmpty(arrayDetected) && isEmpty(arrayMaintenance)) {
  //     return [];
  //   };
  //   const result = arrayDetected?.filter(itemDetected => {
  //     const isDuplicate = arrayMaintenance.some(itemMaintenance => {
  //       if (itemMaintenance.machine.u12_id === itemDetected && itemMaintenance.machine.mac_addr === itemDetected) {
  //         return
  //       }
  //       return itemMaintenance.machine.u12_id === itemDetected || itemMaintenance.machine.mac_addr === itemDetected;
  //     })

  //     return !isDuplicate;
  //   })

  //   return result
  // };

  useEffect(() => {
    console.warn('lastJsonMessage', lastJsonMessage);

    if (lastJsonMessage && lastJsonMessage.signal === socketActions.PONG) {
      pairPingPongTimeStampRef.current = Date.now();

      return;
    }

    if (!lastJsonMessage || !lastJsonMessage.u12_id) {
      return;
    }

    setMessageHistory((prev) => prev.concat(lastJsonMessage));

    if (
      (lastJsonMessage.signal === socketActions.NEW_MACHINE_DETECTED ||
        lastJsonMessage.signal === socketActions.MACHINE_DETECTED) &&
      !detectedMachines.includes(lastJsonMessage.u12_id)
    ) {
      setDetectedMachines((prev) => prev.concat(lastJsonMessage.u12_id));

      // const machinesMaintenance = machines?.filter(mc => mc.machine.status === 3);
      // const detectedMcCountFinal = getMachineDetected(detectedMachines, machinesMaintenance)
      if (!openPersistentNoti) {
        setOpenPersistentNoti(true);
      }
    }

    if (
      lastJsonMessage.signal === socketActions.NEW_MACHINE_DISCONNECTED ||
      lastJsonMessage.signal === socketActions.MACHINE_DISCONNECTED
    ) {
      setDetectedMachines((prev) => prev.filter((machine) => machine !== lastJsonMessage.u12_id));
      setOpenPersistentNoti(false);
    }

    if (
      lastJsonMessage.signal === socketActions.NEW_MACHINE_DETECTED ||
      lastJsonMessage.signal === socketActions.MACHINE_DETECTED ||
      lastJsonMessage.signal === socketActions.NEW_MACHINE_DISCONNECTED ||
      lastJsonMessage.signal === socketActions.MACHINE_DISCONNECTED
    ) {
      // setRefreshMachines(() => shouldRefreshMachines + 1);
      debounceSetRefreshMachine();
    }

    if (
      lastJsonMessage.signal === socketActions.NEW_MACHINE_CONFIGURED ||
      lastJsonMessage.signal === socketActions.MACHINE_CONFIGURED
    ) {
      setOpenNoti({ open: true, message: 'Machines configuration completed.' });
      setOpenPersistentNoti(false);
      debounceSetRefreshMachine();
    }

    if (lastJsonMessage.signal === socketActions.CONFIGURATION_FAILED) {
      setOpenNoti({ open: true, message: 'Machines configuration failed.', severity: 'error' });
      setOpenPersistentNoti(false);
    }

    return () => {
      if (lastJsonMessage && lastJsonMessage.signal === socketActions.PONG) {
        return;
      }

      setRefreshMachines(0);
      setMessageHistory([]);
      // setOpenNoti({ open: false, message: '' });
    };
  }, [lastJsonMessage, setMessageHistory]);

  useEffect(() => {
    if (detectedMachines.length > 0) {
      notifyMachineCount(detectedMachines.length);
    } else {
      handleCloseSnackBar();
    }
  }, [detectedMachines]);

  const notifyMachineCount = (count) => {
    if (count === 0) {
      return;
    }

    if (!openPersistentNoti) {
      setOpenPersistentNoti(true);
    }

    setPersistSnackMessage(`${count} new machine${count > 1 ? 's' : ''} detected.`);
    return;
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNoti({ open: false, message: '', type: openNoti.type });
  };

  const clearMessageHistory = () => {
    setMessageHistory([]);
  };

  const handleClosePersistentSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenPersistentNoti(false);
  };
  const canShowToast = (token && !isAuthenticated()) || !isAdmin() || openReplaceDialog;
  return (
    <>
      {canShowToast ? null : (
        <SocketNotification
          openPersistentSnackbar={openPersistentNoti}
          openSnackbar={openNoti}
          setDetectedMachines={setDetectedMachines}
          handleClosePersistentSnackBar={handleClosePersistentSnackBar}
          handleCloseSnackBar={handleCloseSnackBar}
          snackPersistMessage={snackPersistMessage}
        />
      )}
      <SocketDataContext.Provider
        value={{
          messageHistory,
          lastJsonMessage,
          readyState,
          sendMessage,
          sendJsonMessage,
          clearMessageHistory,
          shouldRefreshMachines, // a trigger to refresh the machine list
          setSocketUrl: handelChangeWS,
        }}
        {...props}
      />
    </>
  );
};

export const useSocketDataContext = () => useContext(SocketDataContext);

export default WebSocketProvider;

/*
      const machinesMaintenance = machines?.filter(mc => mc.machine.status === 3);
      const detectedMcCountFinal = getMachineDetected(detectedMachines, machinesMaintenance)
      // if (detectedMcCountFinal?.length > 0) {
      notifyMachineCount(detectedMcCountFinal.length);
      // return;
      // }
*/
