import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { DebugMachineDialog } from 'modules/machines/components/Dialogs';

import { DIALOGS } from 'modules/dialogs/constants';
import { closeDialog } from 'lib/dialog.js';

export default function DebugMachine({ selectedRow }) {
  const dispatch = useDispatch();

  const openDebugMachineDialog = useSelector((state) => state.dialogs[DIALOGS.DEBUG_MACHINE_DIALOG]);
  const error = useSelector((state) => state.machines.error);

  const handleCloseDebugMachineDialog = () => {
    closeDialog(dispatch, DIALOGS.DEBUG_MACHINE_DIALOG);
  };

  const onSubmitDebugMachineDialog = () => {
    // console.warn('onSubmitDebugMachineDialog', data);
  };

  return (
    <DebugMachineDialog
      anchor='right'
      machine={selectedRow}
      error={error}
      onSubmitDebugMachineDialog={onSubmitDebugMachineDialog}
      openDebugMachineDialog={openDebugMachineDialog}
      handleCloseDebugMachineDialog={handleCloseDebugMachineDialog}
    />
  );
}

DebugMachine.propTypes = {
  selectedRow: PropTypes.object,
};
