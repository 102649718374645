/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
const TargetTag = ({ isLock, value, isTarget }) => {
  const [textColor, setTextColor] = useState('black');
  const [tagBackground, setTagBackground] = useState('initial');
  useEffect(() => {
    if (isLock) {
      setTextColor('#666666');
      setTagBackground('#e2e4e7');
    } else {
      if (isTarget) {
        setTextColor('#59a211');
        setTagBackground('#f1ffe3');
      } else {
        setTextColor('#666666');
        setTagBackground('#fdfdfd');
      }
    }
  }, [isLock, isTarget]);
  return (
    <div
      className={'target-tag'}
      css={css`
        border: 1px solid ${textColor};
        background: ${tagBackground};
      `}
    >
      {isTarget && (
        <svg width={'1rem'} height={'1rem'} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
          <g data-name='Layer 2'>
            <path
              fill={!isLock ? '#59a211' : '#666666'}
              d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm4.3 7.61-4.57 6a1 1 0 0 1-.79.39 1 1 0 0 1-.79-.38l-2.44-3.11a1 1 0 0 1 1.58-1.23l1.63 2.08 3.78-5a1 1 0 1 1 1.6 1.22z'
              data-name='checkmark-circle-2'
            />
          </g>
        </svg>
      )}

      <span
        css={css`
          color: ${textColor};
        `}
      >
        Target Angle
      </span>
      <span
        css={css`
          color: ${textColor};
        `}
        className={'value'}
      >
        {value}°
      </span>
    </div>
  );
};

TargetTag.propTypes = {
  isLock: PropTypes.bool,
  value: PropTypes.number,
  isTarget: PropTypes.bool,
};

export default TargetTag;
