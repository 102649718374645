import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import ManLogo from 'assets/images/png/man_logo.png';
import TextLogo from 'assets/images/SVG/BioneX_logo.svg';
import { requestLogin, resetReasonLogin, resetRequireTwoFa } from 'modules/auth/slice';
import { DEVICE_UUID } from 'modules/auth/constants';
import isNil from 'lodash/isNil';
import { ClientJS } from 'clientjs';
import TwoFactorAuthentification from '../TwoFactorAuthentification';
import { getCookie } from 'helpers';

export default function BionexSignIn() {
  const client = new ClientJS(); // Create A New Client Object
  const fingerprint = client.getFingerprint(); // Get Client's Fingerprint
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const listOTP = [...Array.from({ length: 6 })];

  const invitedUser = useSelector((state) => state.auth.invitedUser);
  const [showPassword, setShowPass] = useState(false);
  const errorCode = useSelector((state) => state.auth.errorCode);
  const status = useSelector((state) => state.auth.status);
  const [searchParams] = useSearchParams();
  const [successMessage, setSuccessMessage] = useState('');
  const isRequireTwoFa = useSelector((state) => state.auth.isRequireTwoFa);
  const token = useSelector((state) => state.auth.token);
  const [openTwoFa, setOpenTwoFa] = useState(false);
  const [email, setEmail] = useState('');
  const [dataVerifyOtp, setDataVerifyOtp] = useState({
    otp_code: listOTP,
    remember_me: false,
  });

  const defaultValues = {
    password: '',
    email: invitedUser ? invitedUser.email : '',
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    // from reset-password
    if (invitedUser && invitedUser.email && searchParams.get('from') === 'reset-password') {
      setSuccessMessage(t('auth:resetHcpPasswordSuccess'));
    }

    // from accept-invite
    if (invitedUser && invitedUser.email && searchParams.get('from') === 'accept-invite') {
      setSuccessMessage(t('auth:acceptInviteSuccess'));
    }

    return () => {
      setSuccessMessage('');
    };
  }, [invitedUser]);

  // logic two fa
  const onSubmit = (data) => {
    const deviceUuid = getCookie(DEVICE_UUID);
    setEmail(data.email.toLowerCase());
    if (!isNil(deviceUuid)) {
      dispatch(
        requestLogin({
          email: data.email.toLowerCase(),
          password: data.password,
          device_id: fingerprint,
          device_uuid: deviceUuid
        })
      );
      return;
    };

    dispatch(
      requestLogin({
        email: data.email.toLowerCase(),
        password: data.password,
        device_id: fingerprint
      })
    );
  };

  // two fa
  const onSetDataVerifyOtp = useCallback(
    (data) => {
      setDataVerifyOtp((prev) => { return { ...prev, ...data } });
    },
    [dataVerifyOtp],
  )

  const handleOpenTwoFaDialog = () => {
    setOpenTwoFa(true);
  }

  useEffect(() => {
    if (isRequireTwoFa) {
      handleOpenTwoFaDialog();
    }
  }, [isRequireTwoFa]);

  useEffect(() => {
    if (!isNil(token)) {
      setOpenTwoFa(false);
      onSetDataVerifyOtp({ otp_code: listOTP });
    }

  }, [token]);

  const handleBackToLogin = () => {
    setOpenTwoFa(false);
    dispatch(resetRequireTwoFa());
    dispatch(resetReasonLogin());
  }

  //
  const handleClickShowPassword = () => {
    setShowPass(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getErrorMessage = (code) => {
    if (code === 400 || code === 404) {
      return t('auth:errors.invalidCredentials');
    }
    if (code === 403) {
      if (status === -1) {
        return t('auth:errors.suspended');
      }
      if (status === 2) {
        return t('auth:errors.accountLock');
      }
      return t('auth:errors.sessionExpired');
    }
    return t('auth:errors.sessionInvalid');
  };

  return (
    <>
      {openTwoFa ?
        <TwoFactorAuthentification
          dataVerifyOtp={dataVerifyOtp}
          onSetDataVerifyOtp={onSetDataVerifyOtp}
          handleBackToLogin={handleBackToLogin}
          email={email}
        />
        :
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'black',
              minHeight: '100vh',
              justifyContent: 'center',
              position: 'relative',
            }}
            maxWidth={false}
            disableGutters={true}
          >
            <Box
              component='img'
              sx={{
                position: 'absolute',
                height: 'auto',
                maxHeight: '550px',
                maxWidth: '100%',
                opacity: 0.5,
                zIndex: 1,
                transform: 'translateY(-70px)',
              }}
              alt='Picture of a man'
              src={ManLogo}
            />
            <Box
              sx={{
                maxWidth: '380px',
                px: 3,
                zIndex: 3,
              }}
            >
              <Typography sx={{ mb: 2 }} color='white' variant='h5'>
                {t('auth:loginAsBionex')}
              </Typography>
              {successMessage && (
                <Alert sx={{ my: 2 }} severity='success'>
                  {successMessage}
                </Alert>
              )}
              {errorCode && errorCode !== 0 ? (
                <Alert sx={{ my: 2 }} severity='error'>
                  {getErrorMessage(errorCode)}
                </Alert>
              ) : null}

              <Controller
                name='email'
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{
                      '& .MuiInputLabel-root': {
                        '&.MuiInputLabel-shrink': {
                          top: '-10px',
                          color: 'grey1',
                        },

                        '&.Mui-focused': {
                          color: 'primary.main',
                          top: '-10px',
                        },
                      },
                    }}
                    error={!!errors.email}
                    helperText={errors.email && t('common:errors.required')}
                    margin='normal'
                    fullWidth
                    id='email'
                    value={value}
                    label={t('common:emailAddress')}
                    inputProps={{
                      maxLength: 255,
                    }}
                    autoComplete='email'
                    onChange={onChange}
                  />
                )}
              />
              <FormControl error={!!errors.password} fullWidth sx={{ mt: 3 }}>
                <InputLabel
                  sx={{
                    '&.MuiInputLabel-shrink': {
                      top: '-10px',
                      color: 'grey1',
                    },
                    '&.Mui-focused': {
                      color: 'primary.main',
                      top: '-10px',
                    },
                  }}
                  htmlFor='component-outlined'
                >
                  {t('common:password')}
                </InputLabel>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <OutlinedInput
                      id='component-outlined'
                      label={t('common:password')}
                      type={showPassword ? 'text' : 'password'}
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 50,
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            sx={{
                              color: errors.password ? '#d32f2f' : '#ED9A00',
                            }}
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <FormHelperText error={!!errors.password}>{errors.password && t('common:errors.required')}</FormHelperText>
              </FormControl>
              <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                <Link href='/forgot-password' underline='none'>
                  {t('auth:forgotPassword')}
                </Link>
                <Button type='submit' variant='contained' sx={{ ml: 'auto' }} size='large'>
                  {t('auth:login')}
                </Button>
              </Box>
            </Box>

            <Box
              component='img'
              sx={{
                position: 'absolute',
                height: 'auto',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '50%',
                bottom: '10%',
                maxWidth: '600px',
                zIndex: 2,
              }}
              alt='Bionex'
              src={TextLogo}
            />
          </Container>
        </form>
      }
    </>
  );
}
