import PropTypes from 'prop-types';
import OfficeCpn from './OfficeCpn';

export default function ListOffice({ remove, fields }) {

  return (fields || [])?.map((office, index) => {
    return <OfficeCpn office={office} key={`${office.uuid}+${index + 1}`} index={index} remove={remove} />
  })

}

ListOffice.propTypes = {
  remove: PropTypes.func,
  fields: PropTypes.array,
};