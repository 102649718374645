import { Autocomplete, Box, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ENV_VARIABLES from 'config/variables';
import { getDomainErrorMessage, getNameErrorMessage } from 'helpers';
import PropTypes from 'prop-types';

export default function BusinessCpn({ isEditCustomer }) {

  const { t } = useTranslation();
  const [stateSingle, setStateSingle] = useState(null);
  const [citySingle, setCitySingle] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  const { control, formState, watch, getValues } = useFormContext();
  const states = useSelector((state) => state.customers.states);

  const { errors } = formState;

  useEffect(() => {
    const stateValue = getValues('state');
    if (stateValue) {
      const state = states.find((state) => {
        return state.name === stateValue
      });
      setStateSingle(state);
    }
  }, []);

  useEffect(() => {
    if (citiesOptions) {
      const cityValue = getValues('city');
      if (cityValue) {
        const city = citiesOptions.find((city) => {
          return city.name === cityValue
        });
        setCitySingle(city)
      }
    }

    return () => {

    }
  }, [citiesOptions])


  useEffect(() => {
    if (stateSingle) {
      setCitiesOptions([]);
      axios
        .get(`${ENV_VARIABLES.API_SERVER}location/countries/US/regions/${stateSingle.iso2}/cities?limit=9999`)
        .then((resp) => {
          let cities = [{ name: 'None', id: -1 }];
          if (resp.data) {
            cities = [...cities, ...resp.data];
          }
          setCitiesOptions(cities);
        });
      return;
    }

    return () => {

    }
  }, [stateSingle]);

  useEffect(() => {
    setTimeout(() => {

    }, 1000);
  }, []);

  return <Box>
    <Controller
      name='hcpName'
      control={control}
      rules={{
        required: true,
        validate: {
          required: (v) => !!v.trim(),
        },
      }}
      render={({ field: { onChange, value } }) => (
        <TextField
          error={!!errors.hcpName}
          helperText={errors.hcpName && t('common:errors.required')}
          margin='normal'
          fullWidth
          id='hcpName'
          value={value}
          label={t('customer:hcp.hcpName')}
          onChange={onChange}
          inputProps={{
            maxLength: 255,
          }}
        />
      )}
    />
    <Controller
      name='subDomain'
      control={control}
      rules={{
        required: true,
        validate: {
          required: (v) => !!v.trim(),
        },
        pattern: /^[a-zA-Z]$|^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/,
      }}
      render={({ field: { onChange, value } }) => (
        <TextField
          error={!!errors.subDomain}
          helperText={getDomainErrorMessage(errors.subDomain, t)}
          margin='normal'
          fullWidth
          id='hcpName'
          value={value}
          disabled={isEditCustomer}
          label={'Domain Name'}
          onChange={(event) => {
            onChange(event.target.value.toLowerCase());
          }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>.bionexusa.com</InputAdornment>,
          }}
          inputProps={{
            maxLength: 255,
          }}
        />
      )}
    />
  </Box>
}

BusinessCpn.propTypes = {
  isEditCustomer: PropTypes.bool,
};