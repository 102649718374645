import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { PasswordInstructions } from 'modules/auth/components/Instructions';
import { PublicPageLoading } from 'components/Loading';
import TextLogo from 'assets/images/SVG/BioneX_logo.svg';
import ManLogo from 'assets/images/png/half_man_logo.png';

import { requestResetUserPassword, requestCheckForgotPasswordToken, clearError } from 'modules/auth/slice';

export default function BionexResetPassword() {
  const defaultValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const errorCode = useSelector((state) => state.auth.errorCode);
  const resetUser = useSelector((state) => state.auth.invitedUser); // resusing the same reducer object with invite user tasks
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    if (searchParams.get('token')) {
      dispatch(requestCheckForgotPasswordToken(searchParams.get('token')));
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      navigator('/sign-in');
    }
  }, []);

  useEffect(() => {
    if (resetUser && resetUser.email) {
      // reset user password successfully and redirect to bionex user login page
      navigator('/sign-in?from=reset-password');
    }
  }, [resetUser]);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  const [showNewPassword, setShowNewPass] = useState(false);
  const [showConfirmPassword, setShowConfirmPass] = useState(false);

  const onSubmit = (data) => {
    dispatch(
      requestResetUserPassword({
        // passing the email so we can auto fill on login page
        email: resetUser, // resetUser is actually the email because BE only returns the email
        password: data.newPassword,
        token: searchParams.get('token'),
      })
    );
  };

  const getErrorMessage = (code) => {
    if (code === 404) {
      return t('auth:errors.invalidInvite');
    }
    return code;
  };

  const handleClickShowNewPassword = () => {
    setShowNewPass(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const goToLogin = () => {
    dispatch(clearError());
    navigator('/sign-in');
  };

  if (loading) return PublicPageLoading();

  return (
    <Container
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'grey1',
        px: ' 0 !important',
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'black',
          // mx: -4,
          py: 1,
        }}
      >
        <Box
          component='img'
          sx={{
            cursor: 'pointer',
            height: 'auto',
            maxWidth: '18rem',
            pb: 1,
          }}
          onClick={goToLogin}
          alt='Bionex'
          src={TextLogo}
        />
      </Box>
      {!resetUser ? (
        <Box
          sx={{
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: {
              xs: '90%',
              sm: '70%',
              md: '60%',
            },
          }}
        >
          <Box
            component='img'
            sx={{
              height: 'auto',
              maxHeight: {
                xs: '250px',
                sm: '300px',
                md: '370px',
              },
              mr: 3,
            }}
            alt='Picture of a man'
            src={ManLogo}
          />
          <Box>
            <Typography variant='h5' component='h5' textAlign='left' gutterBottom sx={{ mb: 2 }}>
              {t('auth:errors.invalidInvite')}
            </Typography>
            <Typography>
              {t('auth:errors.invalidInviteInstruction')}&nbsp;
              <Link
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                }}
                onClick={goToLogin}
              >
                home page.
              </Link>
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Typography variant='h4' component='h4' gutterBottom>
              {t('auth:forgotPasswordForm.resetPassword')}
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Container
              sx={{
                width: {
                  xs: '90%',
                  sm: '70%',
                  md: '40%',
                },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {PasswordInstructions(t)}
              {errorCode && errorCode !== 0 ? (
                <Alert sx={{ my: 2 }} severity='error'>
                  {getErrorMessage(errorCode)}
                </Alert>
              ) : null}
              <FormControl error={!!errors.newPassword} fullWidth sx={{ mt: 3 }}>
                <InputLabel htmlFor='component-outlined'>{t('common:newPassword')}</InputLabel>
                <Controller
                  name='newPassword'
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\#?!$%^&@*\-+():"'<>,/.~_=[\]|`{};]).{8,}$/,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <OutlinedInput
                      id='new-password'
                      label={t('common:newPassword')}
                      type={showNewPassword ? 'text' : 'password'}
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 50,
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            sx={{
                              color: errors.newPassword ? '#d32f2f' : '#ED9A00',
                            }}
                            aria-label='toggle password visibility'
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseDownPassword}
                            edge='end'
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <FormHelperText error={!!errors.newPassword}>
                  {errors.newPassword && errors.newPassword.type === 'required' && t('common:errors.required')}
                  {errors.newPassword &&
                    errors.newPassword.type === 'pattern' &&
                    t('user:password.editDialog.errors.invalidPattern')}
                </FormHelperText>
              </FormControl>
              <FormControl error={!!errors.confirmPassword} fullWidth sx={{ mt: 3 }}>
                <InputLabel htmlFor='component-outlined'>{t('common:confirmNewPassword')}</InputLabel>
                <Controller
                  name='confirmPassword'
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      match: (v) => v === getValues('newPassword'),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <OutlinedInput
                      id='confirm-password'
                      label={t('common:confirmNewPassword')}
                      type={showConfirmPassword ? 'text' : 'password'}
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        maxLength: 50,
                      }}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            sx={{
                              color: errors.confirmPassword ? '#d32f2f' : '#ED9A00',
                            }}
                            aria-label='toggle password visibility'
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseDownPassword}
                            edge='end'
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <FormHelperText error={!!errors.confirmPassword}>
                  {errors.confirmPassword && errors.confirmPassword.type === 'required' && t('common:errors.required')}
                  {errors.confirmPassword &&
                    errors.confirmPassword.type === 'match' &&
                    t('user:password.editDialog.errors.notMatch')}
                </FormHelperText>
              </FormControl>
              <Box sx={{ display: 'flex', my: 3 }}>
                <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                  Continue
                </Button>
              </Box>
            </Container>
          </form>
        </Box>
      )}
    </Container>
  );
}
