import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { END_POINT } from './constants';
import { fetchListActivityTracking, fetchListActivityTrackingFailed, fetchListActivityTrackingSuccess, requestCountRecordAuditLog, requestCountRecordAuditLogFailed, requestCountRecordAuditLogSuccess, requestExample, requestExampleFailure, requestExampleSuccess, requestExportAuditLogAction, requestExportAuditLogActionFailed, requestExportAuditLogActionSuccess, requestLoggingRecordAction, requestLoggingRecordActionFailed, requestLoggingRecordActionSuccess } from './slice';
import { TOAST_TYPE } from 'modules/ui/constants';
import { requestToast } from 'modules/ui/slice';

function* getExamples(action) {

  try {
    const { data } = yield axios.get(
      `${END_POINT.getExamples.url(action.payload.exampleId)}}`
    );
    if (data.data) {
      yield put(requestExampleSuccess(data.data));
    } else {
      yield put(requestExampleFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(requestExampleFailure(e.message));
  }
};

function* requestExportAuditLogHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.requestExportAuditLog.url,
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`
        },
        responseType: 'blob',
        params: action.payload
      },
    );
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'bionex_audit_log.xlsx');
    document.body.appendChild(link);
    link.click();
    yield put(requestExportAuditLogActionSuccess());
    yield put(
      requestToast({
        type: TOAST_TYPE.SUCCESS,
        message: `Logs download successfully.`,
      })
    );
  } catch (e) {
    yield put(requestExportAuditLogActionFailed(e));
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: `Logs download failure.`,
      })
    );
  }
};

function* requestCountRecordAuditLogHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.requestExportAuditLog.url,
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`
        },
        params: action.payload
      },
    );
    yield put(requestCountRecordAuditLogSuccess(data));
  } catch (e) {
    yield put(requestCountRecordAuditLogFailed(e));
  }
};

function* requestFetchListActivityTrackingHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.requestFetchListActivityTracking.url,
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`
        },
        params: action.payload
      },
    );
    yield put(fetchListActivityTrackingSuccess(data));
  } catch (e) {
    yield put(fetchListActivityTrackingFailed(e));
  }
};

function* requestLoggingRecordActionHandler(action) {
  try {
    yield axios.post(END_POINT.requestLoggingRecordAction.url, { ...action.payload },
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`
        }
      }
    );
    yield put(requestLoggingRecordActionSuccess());
  } catch (e) {
    yield put(requestLoggingRecordActionFailed(e));
  }
}

function* activityTrackingsSaga() {
  yield takeLatest(requestExample, getExamples);
  yield takeLatest(requestExportAuditLogAction, requestExportAuditLogHandler);
  yield takeLatest(requestCountRecordAuditLog, requestCountRecordAuditLogHandler);
  yield takeLatest(fetchListActivityTracking, requestFetchListActivityTrackingHandler);
  yield takeLatest(requestLoggingRecordAction, requestLoggingRecordActionHandler);

}

export default activityTrackingsSaga;
