import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Paper,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { StepReplaceContext } from '../Dialogs/ReplaceMachineDialog';
import { useDispatch, useSelector } from 'react-redux';
import { requestConfirmReplaceMachine, requestGetListCpnAvailableReplace } from 'modules/machines/slice';
import { useSocketDataContext } from 'components/WebSocketProvider';

export default function ContentStepReplace({}) {
  const { t } = useTranslation();

  const socketContext = useSocketDataContext();

  const dispatch = useDispatch();
  const [selectedCpn, setSelectedCpn] = useState([]);

  const stepReplaceData = useContext(StepReplaceContext);
  const { closeModal, handleNext, selectedRow } = stepReplaceData;

  const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);
  const [arrFilter, setArrFilter] = useState([]);
  const [disabledRows, setDisabledRows] = useState({});

  const isReplaceMachineSuccess = useSelector((state) => state.machines.isReplaceMachineSuccess);
  const listCpnAvailableReplace = useSelector((state) => state.machines.listCpnAvailableReplace);

  useEffect(() => {
    if (listCpnAvailableReplace) {
      setArrFilter(listCpnAvailableReplace);
      updateState(listCpnAvailableReplace);
    }

    return () => {};
  }, [listCpnAvailableReplace]);

  useEffect(() => {
    if (!selectedRow) {
      return;
    }
    const interval = setInterval(() => {
      dispatch(
        requestGetListCpnAvailableReplace({
          id: selectedRow.id,
        })
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isReplaceMachineSuccess) {
      handleNext();
    }

    return () => {};
  }, [isReplaceMachineSuccess]);

  const nameComponentMap = {
    u12_id: 'U12',
    mac_address: 'OrangePi',
  };

  const columns = [
    { id: 'part_type', label: 'Component', width: 200, align: 'left' },
    { id: 'value', label: 'Serial Number', width: 200, align: 'left' },
    { id: 'checkbox', label: '', width: 50, align: 'left' },
  ];

  const onConfirmReplaceMachine = () => {
    dispatch(
      requestConfirmReplaceMachine({
        id: selectedRow.id,
        parts: selectedCpn,
      })
    );
  };

  const renderTableCell = ({ id, align, index, value }) => {
    return (
      <TableCell key={id} align={align}>
        <Box sx={{ display: 'flex' }}>
          <Typography>{value}</Typography>
        </Box>
      </TableCell>
    );
  };

  const handleRowClick = (rowSelected) => {
    const selectedExist = selectedCpn.findIndex((row) => rowSelected.value === row.value);
    if (selectedExist === -1) {
      setSelectedCpn((prev) => [...prev, rowSelected]);

      const updatedDisabledRows = { ...disabledRows };

      if (rowSelected.part_type === 'u12_id' || rowSelected.part_type === 'mac_address') {
        arrFilter.forEach((row) => {
          if (row.part_type === rowSelected.part_type && row.value !== rowSelected.value) {
            updatedDisabledRows[row.value] = true;
          }
        });
      }
      setDisabledRows(updatedDisabledRows);

      if (disableConfirmBtn) {
        setDisableConfirmBtn(false);
      }
    } else {
      selectedCpn.splice(selectedExist, 1);

      if (rowSelected.part_type === 'u12_id') {
        const updatedDisabledRows = { ...disabledRows };
        arrFilter
          .filter((item) => item.part_type === 'u12_id')
          .forEach((row) => {
            updatedDisabledRows[row.value] = false;
          });
        setDisabledRows(updatedDisabledRows);
      }

      if (rowSelected.part_type === 'mac_address') {
        const updatedDisabledRows = { ...disabledRows };
        arrFilter
          .filter((item) => item.part_type === 'mac_address')
          .forEach((row) => {
            updatedDisabledRows[row.value] = false;
          });
        setDisabledRows(updatedDisabledRows);
      }

      if (isEmpty(selectedCpn)) {
        setDisableConfirmBtn(true);
      }
    }
  };

  function updateState(inputArray = []) {
    const newState = [];
    for (let index = 0; index < selectedCpn.length; index++) {
      const element = selectedCpn[index];
      const exist = inputArray.findIndex((data) => data.value == element.value);
      if (exist > -1) {
        newState.push(element);
      } else {
        if (element.part_type === 'u12_id') {
          const updatedDisabledRows = { ...disabledRows };
          arrFilter
            .filter((item) => item.part_type === 'u12_id')
            .forEach((row) => {
              updatedDisabledRows[row.value] = false;
            });
          setDisabledRows(updatedDisabledRows);
        }

        if (element.part_type === 'mac_address') {
          const updatedDisabledRows = { ...disabledRows };
          arrFilter
            .filter((item) => item.part_type === 'mac_address')
            .forEach((row) => {
              updatedDisabledRows[row.value] = false;
            });
          setDisabledRows(updatedDisabledRows);
        }
      }
    }
    setSelectedCpn(newState);
    if (isEmpty(newState)) {
      setDisableConfirmBtn(true);
    }
  }
  const renderTable = () => {
    return (
      <Box sx={{ width: '90%', border: '1px solid #CED4DA' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden', display: 'flex', flex: 1, flexDirection: 'column' }}>
          <TableContainer sx={{ width: '100%', height: '220px' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      // sx={{ background: '#00000061' }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.width, fontWeight: 600 }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {arrFilter?.map((row, index) => {
                  const conditionHighlight =
                    !isEmpty(selectedCpn) && selectedCpn.find((element) => element.value === row.value);
                  const labelId = `table-checkbox-replace${index}`;

                  return (
                    <TableRow
                      sx={{
                        '&.Mui-selectedCpn': {
                          background: '#FBEBCC !important',
                        },
                        background: disabledRows[row.value] && '#F8F9FA',
                      }}
                      selected={!!conditionHighlight}
                      hover
                      // role='checkbox'
                      tabIndex={-1}
                      key={row.value}
                    >
                      <TableCell
                        part_type='th'
                        // id={labelId}
                        scope='row'
                        sx={{ ...(disabledRows[row.value] && { color: '#ADB5BD' }) }}
                      >
                        {nameComponentMap[row.part_type]}
                      </TableCell>
                      <TableCell align='left' sx={{ ...(disabledRows[row.value] && { color: '#ADB5BD' }) }}>
                        {row.value}
                      </TableCell>
                      <TableCell padding='checkbox' sx={{ ...(disabledRows[row.value] && { cursor: 'not-allowed' }) }}>
                        <Checkbox
                          disabled={disabledRows[row.value]}
                          sx={{ ...(disabledRows[row.value] && { cursor: 'not-allowed' }) }}
                          onClick={() => handleRowClick(row)}
                          color='primary'
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {isEmpty(arrFilter) && (
              <Box
                sx={{
                  width: '100%',
                  height: '70%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  fontSize: '16px',
                  color: '#868E96',
                }}
              >
                No components detected.
              </Box>
            )}
          </TableContainer>
        </Paper>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' part_type='div'>
          {`${t('machine:contentStepReplace.contentText')}`}
        </Typography>
        <Typography
          sx={{ color: '#000', mb: 2, display: 'flex', justifyContent: 'center', width: '100%' }}
          variant='p'
          part_type='div'
        >
          {renderTable()}
        </Typography>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' part_type='div'>
          {`${t('machine:contentStepReplace.confirmText')}`}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant='outlined' sx={{ border: '1px solid #e2e4e7' }} onClick={closeModal}>
            {t('machine:contentStepReplace.textBtnCancel')}
          </Button>
          <Button
            disabled={disableConfirmBtn}
            variant='contained'
            sx={{ color: 'white' }}
            onClick={onConfirmReplaceMachine}
            autoFocus
          >
            {t('machine:contentStepReplace.textBtnConfirm')}
          </Button>
        </Box>
      </Box>
    );
  };

  return renderContent();
}

ContentStepReplace.propTypes = {};
