import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Collapse from '@mui/material/Collapse';
import PeopleIcon from '@mui/icons-material/People';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import HomeIcon from '@mui/icons-material/Home';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { DIALOGS } from 'modules/dialogs/constants';
import { openDialog } from 'lib/dialog.js';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import HistoryIcon from '@mui/icons-material/History';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

function goTo(link, navigator) {
  if (link.disabled) return;
  if (link.url) navigator(link.url);
  if (link.action) link.action();
}

export default function BionexLinks({ open }) {
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const ADMIN_LINKS = [
    {
      name: 'Home',
      url: '/',
      icon: <HomeIcon />,
    },
    {
      name: 'Manage BioneX Users',
      url: '/manage/users',
      icon: <PeopleIcon />,
      subLinks: [
        {
          name: 'Add User',
          action: () => openDialog(dispatch, DIALOGS.INVITE_USER, true),
        },
      ],
    },
    {
      name: 'Manage Customers',
      url: '/manage/customers',
      icon: <ApartmentIcon />,
      subLinks: [
        {
          name: 'Add Customer',
          action: () => openDialog(dispatch, DIALOGS.INVITE_CUSTOMER, true),
        },
      ],
    },
    {
      name: 'Manage Machines',
      url: '/manage/machines',
      icon: <PrecisionManufacturingIcon />,
    },
    {
      name: 'Manage Software Update',
      url: '/manage/software',
      icon: <BrowserUpdatedIcon />,
    },
    {
      name: 'Manage Audit Log',
      url: '/manage/audit-log',
      icon: <HistoryIcon />,
    },
    {
      name: 'Activity Tracking',
      url: '/manage/activity-tracking',
      icon: <QueryStatsIcon />,
    },
  ];

  const getSubLinks = (link) => {
    if (!link.subLinks) return null;
    return (
      <Collapse sx={{ mb: 2 }} in={open} unmountOnExit>
        <List component='div' disablePadding>
          {link.subLinks.map((subLink) => {
            return (
              <ListItemButton
                sx={{
                  pl: 4,
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    '.MuiTypography-root': {
                      color: '#FFF',
                    },
                  },
                }}
                key={subLink.name}
                onClick={() => goTo(subLink)}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={subLink.name} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    );
  };

  const getBionexLinks = () => {
    return ADMIN_LINKS.map((link) => {
      const color = window.location.pathname === link.url ? 'primary.main' : '#FFF';

      return (
        <Box key={link.url}>
          <ListItem
            sx={{
              '&:hover': {
                backgroundColor: 'primary.main',
                '.MuiListItemButton-root': {
                  color: '#FFF',
                  backgroundColor: 'primary.main',
                },
                '.MuiSvgIcon-root': {
                  color: '#FFF',
                },
              },
            }}
          >
            <ListItemButton disabled={link.disabled} sx={{ color: color }} onClick={() => goTo(link, navigator)}>
              <ListItemIcon
                sx={{
                  color: color,
                }}
              >
                {link.icon}
              </ListItemIcon>
              <ListItemText primary={link.name} />
            </ListItemButton>
          </ListItem>
          {getSubLinks(link)}
        </Box>
      );
    });
  };

  return getBionexLinks();
}
