import PropTypes from 'prop-types';
import { useAuthDataContext } from 'components/AuthProvider';
import Box from '@mui/material/Box';
import TextLogo from 'assets/images/SVG/BioneX_logo.svg';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

import { AppBar } from './AppBar/AppBar';
import UserAccount from 'modules/profile/UserAccount';

export default function AdminHeader({ handleDrawerOpen, open, drawerWidth }) {
  const { currentUser } = useAuthDataContext();

  const navigator = useNavigate();
  if (!currentUser) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar drawerwidth={drawerWidth} position='static' open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            backgroundColor: 'black',
          }}
        >
          <IconButton
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{ color: '#FFF', mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component='img'
            sx={{
              cursor: 'pointer',
              height: 'auto',
              display: { xs: 'none', md: 'block' },
              maxWidth: '250px',
            }}
            alt='Bionex'
            src={TextLogo}
            onClick={() => navigator('/')}
          />
          <Box sx={{ ml: 'auto' }}>
            <UserAccount signOutUrl='/sign-out?from_admin=1' />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

AdminHeader.propTypes = {
  open: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  drawerWidth: PropTypes.number,
};
