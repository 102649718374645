import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function PasswordDialog({
  errorCode,
  openPasswordDialog,
  handleClosePasswordDialog,
  onSubmitPasswordDialog,
}) {
  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const { t } = useTranslation();
  const [showCurrentPassword, setShowCurrentPass] = useState(false);
  const [showNewPassword, setShowNewPass] = useState(false);
  const [showConfirmPassword, setShowConfirmPass] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (!openPasswordDialog) {
      setSubmitting(false);
      reset();
    }
    return () => {
      reset(defaultValues);
    };
  }, [openPasswordDialog]);

  useEffect(() => {
    getErrorMessage(errorCode);
  }, [errorCode]);

  const onSubmit = (data) => {
    setSubmitting(true);
    if (onSubmitPasswordDialog) {
      onSubmitPasswordDialog(data);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPass(!showCurrentPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPass(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (handleClosePasswordDialog) {
      handleClosePasswordDialog();
    }
    resetForm();
  };

  const resetForm = () => {
    reset(defaultValues);
  };

  const getErrorMessage = (code) => {
    let type = '';
    if (code === 400) {
      type = 'incorrect_password';
    }

    if (type) {
      setError('currentPassword', {
        type: type,
      });
    }
  };

  return (
    <div>
      <Dialog open={openPasswordDialog} onClose={closeModal}>
        <DialogTitle>{t('user:password.editDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'black' }}>
            {t('user:password.editDialog.subtitle')}
          </DialogContentText>
          <DialogContentText sx={{ color: 'black' }}>
            &#8226; {t('user:password.editDialog.passwordRules:0')}
            <br />
            &#8226; {t('user:password.editDialog.passwordRules:1')}
            <br />
            &#8226; {t('user:password.editDialog.passwordRules:2')}
            <br />
            &#8226; {t('user:password.editDialog.passwordRules:3')}
            <br />
            &#8226; {t('user:password.editDialog.passwordRules:4')}
          </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              error={!!errors.currentPassword}
              fullWidth
              sx={{ mt: 3 }}
            >
              <InputLabel htmlFor="component-outlined">
                {t('user:password.editDialog.currentPassword')}
              </InputLabel>
              <Controller
                name="currentPassword"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    id="current-password"
                    label={t('user:password.editDialog.currentPassword')}
                    type={showCurrentPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={value}
                    inputProps={{
                      maxLength: 50,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: errors.currentPassword
                              ? '#d32f2f'
                              : '#ED9A00',
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseDownPassword}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <FormHelperText error={!!errors.currentPassword}>
                {errors.currentPassword &&
                  errors.currentPassword.type === 'required' &&
                  t('common:errors.required')}
                {errors.currentPassword &&
                  errors.currentPassword.type === 'incorrect_password' &&
                  t('user:password.editDialog.errors.currentPassword')}
              </FormHelperText>
            </FormControl>
            <FormControl error={!!errors.newPassword} fullWidth sx={{ mt: 3 }}>
              <InputLabel htmlFor="component-outlined">
                {t('user:password.editDialog.newPassword')}
              </InputLabel>
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: true,
                  pattern:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\#?!$%^&@*\-+():"'<>,/.~_=[\]|`{};]).{8,}$/,
                }}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    id="new-password"
                    label={t('user:password.editDialog.newPassword')}
                    type={showNewPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={value}
                    inputProps={{
                      maxLength: 50,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: errors.newPassword ? '#d32f2f' : '#ED9A00',
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <FormHelperText error={!!errors.newPassword}>
                {errors.newPassword &&
                  errors.newPassword.type === 'required' &&
                  t('common:errors.required')}
                {errors.newPassword &&
                  errors.newPassword.type === 'pattern' &&
                  t('user:password.editDialog.errors.invalidPattern')}
              </FormHelperText>
            </FormControl>
            <FormControl
              error={!!errors.confirmPassword}
              fullWidth
              sx={{ mt: 3 }}
            >
              <InputLabel htmlFor="component-outlined">
                {t('user:password.editDialog.confirmPassword')}
              </InputLabel>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    match: (v) => v === getValues('newPassword'),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    id="confirm-password"
                    label={t('user:password.editDialog.confirmPassword')}
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={value}
                    inputProps={{
                      maxLength: 50,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            color: errors.confirmPassword
                              ? '#d32f2f'
                              : '#ED9A00',
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              <FormHelperText error={!!errors.confirmPassword}>
                {errors.confirmPassword &&
                  errors.confirmPassword.type === 'required' &&
                  t('common:errors.required')}
                {errors.confirmPassword &&
                  errors.confirmPassword.type === 'match' &&
                  t('user:password.editDialog.errors.notMatch')}
              </FormHelperText>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('common:update')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PasswordDialog.propTypes = {
  errorCode: PropTypes.number,
  openPasswordDialog: PropTypes.bool,
  handleClosePasswordDialog: PropTypes.func,
  onSubmitPasswordDialog: PropTypes.func,
};
