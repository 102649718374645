import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FilterListIcon from '@mui/icons-material/FilterList';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

const statuses = [
  {
    id: 0,
    name: 'Pending',
  },
  {
    id: 1,
    name: 'Active',
  },
  {
    id: -1,
    name: 'Suspended',
  },
];

export default function Filter({ filterFunction }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowClearFilter, setShowClearFilter] = useState(false);

  const defaultValues = {
    status: '',
  };

  const {
    reset,
    // handleSubmit,
    control,
  } = useForm({ mode: 'all', defaultValues });

  const handleStatusChange = (item) => {
    if (item !== '') {
      setShowClearFilter(true);
    } else {
      setShowClearFilter(false);
    }
    if (filterFunction) {
      filterFunction(item);
    }
  };

  const handleReset = () => {
    reset(defaultValues);
    setShowClearFilter(false);
    if (filterFunction) {
      filterFunction('');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'filter-customer-popper' : undefined;

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-label="filter"
        color="inherit"
      >
        <FilterListIcon sx={{ fontSize: '25px' }} />
      </IconButton>
      {!open && isShowClearFilter && (
        <Button
          sx={{ ml: 2 }}
          onClick={handleReset}
          variant="outlined"
          endIcon={<ClearIcon />}
        >
          Clear Filters
        </Button>
      )}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="right-start"
        transition
        sx={{ zIndex: '100' }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ px: '20px', py: '20px' }}>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 600, mb: 2 }}
                gutterBottom
              >
                Filter
              </Typography>
              <Controller
                name="status"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ minWidth: 200 }} size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={value}
                      label="Status"
                      onChange={(event) => {
                        onChange(event.target.value);
                        handleStatusChange(event.target.value);
                      }}
                    >
                      {statuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
                <Button onClick={handleReset}>Clear All</Button>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

Filter.propTypes = {
  filterFunction: PropTypes.func,
};
