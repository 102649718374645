import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';


import { DIALOGS } from 'modules/dialogs/constants';
import { closeDialog } from 'lib/dialog.js';
import { requestAssignMachine } from 'modules/machines/slice';
import { filterCustomers } from 'modules/customers/slice';
import { ReassignMachineDialog } from 'modules/machines/components/Dialogs';

export default function ReassignMachine({ selectedRow, setSelectedRow, sortData }) {
  const dispatch = useDispatch();

  const openReassignMachineDialog = useSelector((state) => state.dialogs[DIALOGS.REASSIGN_MACHINE_DIALOG]);
  const createdMachine = useSelector((state) => state.machines.createdMachine);
  const customers = useSelector((state) => state.customers.filteredCustomers);
  const error = useSelector((state) => state.machines.error);

  useEffect(() => {
    dispatch(filterCustomers({ filterStatuses: [1, 0] }));
  }, [openReassignMachineDialog]);

  const handleCloseReassignMachineDialog = () => {
    closeDialog(dispatch, DIALOGS.REASSIGN_MACHINE_DIALOG);
  };

  const onSubmitReassignMachineDialog = (data) => {
    // keep the current sort
    data.sortData = sortData;
    dispatch(requestAssignMachine(data));
    // set this data for confirm dialog
    setSelectedRow(data);
  };

  return (
    <ReassignMachineDialog
      anchor='right'
      machine={selectedRow}
      createdMachine={createdMachine}
      onSubmitReassignMachineDialog={onSubmitReassignMachineDialog}
      error={error}
      customers={customers}
      openReassignMachineDialog={openReassignMachineDialog}
      handleCloseReassignMachineDialog={handleCloseReassignMachineDialog}
    />
  );
}

ReassignMachine.propTypes = {
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  sortData: PropTypes.object,
};
