import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FilterListIcon from '@mui/icons-material/FilterList';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

import { Autocomplete, Backdrop, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CustomDateRangePicker from './CustomDateRangePicker';
import CustomizedInputBase from './CustomInputField';
import { format, isAfter, isBefore, isValid, parse, getTime } from 'date-fns';
import isNil from 'lodash/isNil';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';
import { LIST_ACTION_AUDIT_LOG, LIST_MODE_AUDIT_LOG, LIST_MODULE_AUDIT_LOG } from '../constants';
import { fetchUsers } from 'modules/bionexUsers/slice';
export default function Filter({
  handleHideClearFilter,
  handleSetObjectFilter,
  objectFilter,
  handleResetAllFilter,
  onApplyFilter,
  handleOpenDateRangePicker,
  handleCloseDateRangePicker,
  openDateRangePicker,
  handleTogglePopover,
  open,
}) {
  const dispatch = useDispatch();

  const defaultValues = {
    actor: null,
    module: null,
    actionEvent: null,
    from: null,
    to: null,
  };

  const { t } = useTranslation();
  const users = useSelector((state) => state.users.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowClearFilter, setShowClearFilter] = useState(false);
  const [selectedRange, setSelectedRange] = useState({ from: undefined, to: undefined });

  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');
  const [focusFrom, setFocusFrom] = useState(false);
  const [focusTo, setFocusTo] = useState(false);
  const [actors, setActors] = useState([]);
  const [objectContainFilter, setObjectContainFilter] = useState(defaultValues);
  const selectedRangeRef = useRef();

  const {
    reset,
    // handleSubmit,
    control,
  } = useForm({ mode: 'all', defaultValues });


  const handleSetObjectContainFilter = (data) => {
    setObjectContainFilter((prev) => {
      return { ...prev, ...data };
    });
  };

  useEffect(() => {
    return () => {
      setShowClearFilter(false);
    }
  }, [])


  const onCheckFieldNotNull = () => {
    const isAnyFieldNotNull = Object.values(objectContainFilter).some(value => value !== null);
    if (isAnyFieldNotNull) {
      setShowClearFilter(true);
      return
    } else {
      setShowClearFilter(false);
      return;
    }
  }

  const handleOnApplyFilter = () => {
    onApplyFilter();
    onCheckFieldNotNull();
  }

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    if (!isNil(selectedRange.from) && !isNil(selectedRange.to)) {
      handleSetObjectFilter({ from: getTime(selectedRange.from), to: getTime(selectedRange.to) });
    }

    return;
  }, [selectedRange]);

  useEffect(() => {
    if (users && users.map) {
      const convertValue = users?.map((v) => ({
        id: v.id,
        name: `${v?.first_name} ${v?.last_name}`,
        value: v.id,
      }));
      setActors(convertValue);
    }
  }, [users]);

  const handleSetFocusFrom = useCallback(() => {
    setFocusFrom(true);
    setFocusTo(false);
  }, []);

  const handleSetFocusTo = useCallback(() => {
    setFocusTo(true);
    setFocusFrom(false);
  }, []);

  const handleFromChange = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, 'y/MM/dd', new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  const handleToChange = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, 'y/MM/dd', new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    handleSetObjectFilter({ from: getTime(range.from), to: getTime(range.to) });
    selectedRangeRef.current = range;
    if (range?.from) {
      setFromValue(format(range.from, 'y/MM/dd'));
      handleSetObjectContainFilter({ from: range.from })
      setFocusFrom(true);
      setFocusTo(false);
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, 'y/MM/dd'));
      handleSetObjectContainFilter({ from: range.to })
      setFocusTo(true);
      setFocusFrom(false);
    } else {
      setToValue('');
    }
  };

  const handleFromBlur = (e) => {
    setFromValue(e.target.value);
    setFocusFrom(false);
    setFocusTo(false);
    const date = parse(e.target.value, 'y/MM/dd', new Date());
    if (!isValid(date)) {
      setFromValue(format(selectedRangeRef.current.from, 'y/MM/dd'));
      handleSetObjectFilter({ from: getTime(selectedRangeRef.current.from) });
      return;
    }
  };

  const handleToBlur = (e) => {
    setFocusFrom(false);
    setFocusTo(false);
    setToValue(e.target.value);
    const date = parse(e.target.value, 'y/MM/dd', new Date());

    if (!isValid(date)) {
      setToValue(format(selectedRangeRef.current.to, 'y/MM/dd'));
      handleSetObjectFilter({ to: getTime(selectedRangeRef.current.to) });
      return;
    }
  };

  const handleFieldTypeChange = (item, type) => {
    switch (type) {
      case 'actor':
        handleSetObjectFilter({ actor: item });
        break;

      case 'module':
        handleSetObjectFilter({ module: item });
        break;

      case 'action-event':
        handleSetObjectFilter({ actionEvent: item });
        break;

      default:
        break;
    }
  };

  const onResetFilter = () => {
    // reset(defaultValues);
    handleHideClearFilter();
    setObjectContainFilter(defaultValues);
    handleResetAllFilter();
    setFromValue('');
    setToValue('');
    setShowClearFilter(false);
    setSelectedRange({ from: undefined, to: undefined });
    selectedRangeRef.current = null;
    setFocusFrom(false);
    setFocusTo(false);
  };

  const onClearFilter = () => {
    handleHideClearFilter();
    setShowClearFilter(false);
    setObjectContainFilter(defaultValues);
    handleResetAllFilter();
    setFromValue('');
    setToValue('');
    setSelectedRange({ from: undefined, to: undefined });
    selectedRangeRef.current = null;
    setFocusFrom(false);
    setFocusTo(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    handleTogglePopover();
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'filter-customer-popper' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick} size='small' aria-label='filter' color='inherit'>
        <FilterListIcon sx={{ fontSize: '25px' }} />
      </IconButton>
      {!open && isShowClearFilter && (
        <Button sx={{ ml: 2 }} onClick={onClearFilter} variant='outlined' endIcon={<ClearIcon />}>
          Clear Filters
        </Button>
      )}
      <Box sx={{ maxWidth: '300px', height: '100%', background: '#00000080' }}>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement='right-start'
          transition
          sx={{ zIndex: '100', position: 'relative' }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  px: '20px',
                  py: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <IconButton
                  onClick={handleTogglePopover}
                  sx={{ position: 'absolute', top: '-45px', right: '-10px', background: '#ed9a00' }}
                  aria-label='directions'
                >
                  {/* <Button variant={'contained'} sx={{ position: 'absolute', top: '-40px', right: '-10px' }}> */}
                  <CloseRoundedIcon sx={{ color: '#fff' }} />
                  {/* </Button> */}
                </IconButton>
                <Controller
                  name='type'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <FormControl sx={{ width: '280px', mt: 3 }} size='small'>
                        <Autocomplete
                          value={objectContainFilter.actor}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                            handleFieldTypeChange(newValue, 'actor');
                            handleSetObjectContainFilter({ actor: newValue });
                          }}
                          options={actors}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label={t('Actor')} />}
                          isOptionEqualToValue={(option, value) => option.name === value}
                        />
                      </FormControl>
                      <FormControl sx={{ width: '280px', mt: 3 }} size='small'>
                        <Autocomplete
                          value={objectContainFilter.module}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                            handleFieldTypeChange(newValue, 'module');
                            handleSetObjectContainFilter({ module: newValue });
                          }}
                          options={LIST_MODULE_AUDIT_LOG}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label={t('Module')} />}
                          isOptionEqualToValue={(option, value) => option.name === value}
                        />
                      </FormControl>
                      <FormControl sx={{ width: '280px', mt: 3 }} size='small'>
                        <Autocomplete
                          value={objectContainFilter.actionEvent}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                            handleFieldTypeChange(newValue, 'action-event');
                            handleSetObjectContainFilter({ actionEvent: newValue });
                          }}
                          options={LIST_ACTION_AUDIT_LOG}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label={t('Action/Event')} />}
                          isOptionEqualToValue={(option, value) => option.name === value}
                        />
                      </FormControl>
                    </>
                  )}
                />
                <Box sx={{ mt: 3 }}>
                  <CustomizedInputBase
                    selectedRange={selectedRange}
                    fromValue={fromValue}
                    toValue={toValue}
                    handleFromChange={handleFromChange}
                    handleToChange={handleToChange}
                    handleFromBlur={handleFromBlur}
                    handleToBlur={handleToBlur}
                    handleOpenDateRangePicker={handleOpenDateRangePicker}
                    handleCloseDateRangePicker={handleCloseDateRangePicker}
                    handleSetFocusFrom={handleSetFocusFrom}
                    handleSetFocusTo={handleSetFocusTo}
                    focusFrom={focusFrom}
                    focusTo={focusTo}
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  {openDateRangePicker && (
                    <CustomDateRangePicker
                      handleOpenDateRangePicker={handleOpenDateRangePicker}
                      handleCloseDateRangePicker={handleCloseDateRangePicker}
                      openDateRangePicker={openDateRangePicker}
                      selectedRange={selectedRange}
                      handleRangeSelect={handleRangeSelect}
                      focusFrom={focusFrom}
                      focusTo={focusTo}
                    />
                  )}
                </Box>
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end', gap: 1 }}>
                  <Button variant='outlined' onClick={onResetFilter}>
                    Reset All Filter
                  </Button>
                  <Button variant='contained' onClick={handleOnApplyFilter}>
                    Apply filter
                  </Button>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
        <Backdrop
          open={open}
        />
      </Box>
    </div>
  );
}

Filter.propTypes = {
  handleSetObjectFilter: PropTypes.func,
  objectFilter: PropTypes.object,
  handleResetAllFilter: PropTypes.func,
  openDateRangePicker: PropTypes.bool,
  handleOpenDateRangePicker: PropTypes.func,
  handleCloseDateRangePicker: PropTypes.func,
  handleSetOnApply: PropTypes.func,
  handleCloseOnApply: PropTypes.func,
  onApplyFilter: PropTypes.func,
  newUrlDashBoardAndParams: PropTypes.string,
  handleTogglePopover: PropTypes.func,
  open: PropTypes.bool,
  handleHideClearFilter: PropTypes.func
};
