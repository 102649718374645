import { CalibrateContainer } from '../styled';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import { Modal } from '../../../../../components/Modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import UpdateCalibrationModal from './UpdateCalibrationModal';
import UpdatePotRangeModal from './UpdatePotRangeModal';
import ViewMachineSettingModal from './ViewMachineSettingModal';
import { CALIBRATE_ACTION_TYPE } from 'modules/machines/constants';
import { requestLoggingRecordAction } from 'modules/machines/slice';
import { useDispatch } from 'react-redux';

export default function ActionModal({ showModal, onClose, rowSelected, onDebugMachine }) {
  const { t } = useTranslation();

  const calibrateContainer = useRef(null);
  const dispatch = useDispatch();

  const [currentOption, setCurrentOption] = useState(undefined);
  const [showUpdateCalibrationModal, setShowUpdateCalibrationModal] = useState(false);
  const [showViewMachineSettingModal, setShowViewMachineSettingModal] = useState(false);
  const [showPotRangeModal, setShowPotRangeModal] = useState(false);
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (showModal) {
      return;
    }

    if (calibrateContainer.current) {
      calibrateContainer.current.querySelectorAll('.calibrate-btn').forEach((element) => {
        element.classList.remove('calibrate-active');
      });
    }

    setCurrentOption(undefined);
  }, [showModal]);

  const onCalibrateAction = (eventName) => {
    const data = {
      event: eventName,
      data: {
        machine_name: rowSelected?.machine?.name
      },
      module: 'machine_management',
    }

    dispatch(requestLoggingRecordAction({ ...data, token }));
  }

  const onCalibrationSelectedHandler = (value) => {
    switch (value) {
      case 1:
        onCalibrateAction(CALIBRATE_ACTION_TYPE.STRAIN_GAUGE);
        setShowUpdateCalibrationModal(true);
        onClose();
        break;
      case 2:
        onCalibrateAction(CALIBRATE_ACTION_TYPE.DEBUG);
        onDebugMachine(rowSelected);
        onClose();
        break;
      case 3:
        onCalibrateAction(CALIBRATE_ACTION_TYPE.POTENTIOMETER);
        setShowPotRangeModal(true);
        onClose();
        break;
      case 4:
        onCalibrateAction(CALIBRATE_ACTION_TYPE.SPECIFICATION_SETTING);
        setShowViewMachineSettingModal(true);
        onClose();
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        keep={true}
        showModal={showModal}
        onClose={onClose}
        isClose={true}
        title={t('machine:machinesActions.calibrateAction')}
        content={() => (
          <CalibrateContainer ref={calibrateContainer}>
            For the most accurate calibration result, be sure to loosen the chain tightener.
            <Box className={'container'}>
              <div
                id={'calibrate-option-3'}
                onClick={() => onCalibrationSelectedHandler(3)}
                className={'calibrate-btn'}
              >
                <span>Calibrate Potentiometer</span>
              </div>

              <div
                id={'calibrate-option-1'}
                onClick={() => onCalibrationSelectedHandler(1)}
                className={'calibrate-btn'}
              >
                <span>Calibrate Strain Gauge</span>
              </div>
              <div
                id={'calibrate-option-2'}
                onClick={() => onCalibrationSelectedHandler(2)}
                className={'calibrate-btn'}
              >
                <span>Machine Debug</span>
              </div>

              <div
                id={'calibrate-option-4'}
                onClick={() => onCalibrationSelectedHandler(4)}
                className={'calibrate-btn'}
              >
                <span>Machine Specification Setting</span>
              </div>
            </Box>
          </CalibrateContainer>
        )}
      />
      {rowSelected && rowSelected.machine && (
        <div>
          <UpdateCalibrationModal
            rowSelected={rowSelected}
            showModal={showUpdateCalibrationModal}
            onClose={() => setShowUpdateCalibrationModal(false)}
          />

          <UpdatePotRangeModal
            rowSelected={rowSelected}
            showModal={showPotRangeModal}
            onClose={() => setShowPotRangeModal(false)}
          />
          <ViewMachineSettingModal
            rowSelected={rowSelected}
            showModal={showViewMachineSettingModal}
            onClose={() => setShowViewMachineSettingModal(false)}
          />
        </div>
      )}
    </>
  );
}
ActionModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onDebugMachine: PropTypes.func,
  rowSelected: PropTypes.object,
};
