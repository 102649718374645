import { createTheme } from '@mui/material/styles';

export default createTheme({
  shape: {
    pillRadius: 50,
  },
  typography: {
    fontFamily: 'Roboto, Montserrat, sans-serif',
  },
  palette: {
    primary: {
      main: '#ED9A00',
    },
    secondary: {
      main: '#1A1A1A',
    },
    grey1: '#E6E6E6',
    grey2: '#D4D4D4',
    black1: '#1A1A1A',
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: '#fff',
          },
        },
      ],
    },
  },
});
