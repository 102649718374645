import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function BasicTable({ headers, data, actions, overrideGetActions }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  const getHeaders = () => {
    return headers.map((header) => (
      <TableCell
        sx={{
          width: header.width,
        }}
        padding={header.disablePadding ? 'none' : 'normal'}
        align={header.align}
        key={header.title}
      >
        {header.title}
      </TableCell>
    ));
  };

  const getRows = () => {
    return data.map((row, i) => (
      <TableRow onClick={() => handleRowClick(row)} key={row.name + i}>
        {Object.keys(row).map((key) => {
          return headers.map((header) => {
            if (header.dataField === key) {
              return <TableCell key={key}>{header.format ? header.format(row[key]) : row[key]}</TableCell>;
            }
            return <></>
          });
        })}
        {actions && (
          <TableCell align='center'>
            <IconButton onClick={handleClick} aria-label='more'>
              <MoreVertIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    ));
  };

  const onActionClick = (action) => {
    if (action.callback) {
      action.callback(selectedRow);
    }
    handleClose();
  };

  const getActions = () => {
    if (overrideGetActions) {
      return overrideGetActions(selectedRow, handleClose);
    }
    return actions.map((action) => (
      <MenuItem key={action.title} onClick={() => onActionClick(action)}>
        {action.title}
      </MenuItem>
    ));
  };

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 90px - 75px)' }} component={Paper}>
      <Table stickyHeader sx={{ minWidth: '650' }} aria-label='simple table'>
        {!isLoading && (!data || (data && !data.length)) ? <caption> {t('common:noData')}</caption> : null}
        <TableHead>
          <TableRow>
            {getHeaders()}
            {actions && (
              <TableCell
                sx={{
                  width: '10%',
                }}
                padding='none'
                align='center'
                key='actions'
              >
                Actions
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>{getRows()}</TableBody>
      </Table>
      {actions && (
        <Menu id='table-action' anchorEl={anchorEl} open={open} onClose={handleClose}>
          {getActions()}
        </Menu>
      )}
    </TableContainer>
  );
}

BasicTable.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  overrideGetActions: PropTypes.func,
};
