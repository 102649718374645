import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import TextLogo from 'assets/images/SVG/BioneX_logo.svg';
import { requestForgotPassword } from 'modules/auth/slice';

export default function BionexForgotPassword() {
  const defaultValues = {
    email: '',
  };

  const [showInstructions, setShowInstructions] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const errorCode = useSelector((state) => state.auth.errorCode);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  const { t } = useTranslation();

  const onSubmit = (data) => {
    dispatch(requestForgotPassword({ email: data.email.toLowerCase() }));
    setEmailAddress(data.email.toLowerCase());
    setShowInstructions(true);
  };

  const getEmailErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidEmailPattern');
    if (error?.type === 'email_exists') return t('customer:inviteDialog.errors.emailExists');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'black',
          minHeight: '100vh',
          justifyContent: 'center',
          position: 'relative',
        }}
        maxWidth={false}
        disableGutters={true}
      >
        <Box
          sx={{
            maxWidth: '380px',
            px: 3,
            zIndex: 3,
          }}
        >
          {errorCode && errorCode !== 0 ? (
            <Alert sx={{ mb: 2 }} severity='error'>
              {errorCode}
            </Alert>
          ) : null}
          {showInstructions ? (
            <>
              <Typography variant='h4' textAlign='center' color='white' component='h4' gutterBottom>
                {t('auth:forgotPasswordForm.checkInbox')}
              </Typography>
              <Typography variant='body1' textAlign='center' color='white' component='p' gutterBottom>
                {t('auth:forgotPasswordForm.checkInboxInfo')} <strong>{emailAddress}</strong>
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='h4' textAlign='center' color='white' component='h4' gutterBottom>
                {t('auth:forgotPasswordForm.title')}
              </Typography>
              <Typography variant='body2' textAlign='center' color='white' component='p' gutterBottom>
                {t('auth:forgotPasswordForm.subtitle')}
              </Typography>
              <Typography variant='body2' textAlign='center' color='white' component='p' gutterBottom>
                {t('auth:forgotPasswordForm.subtitle2')}
              </Typography>
              <br></br>
              <Controller
                name='email'
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{
                      '& .MuiInputLabel-root': {
                        '&.MuiInputLabel-shrink': {
                          top: '-10px',
                          color: 'grey1',
                        },

                        '&.Mui-focused': {
                          color: 'primary.main',
                          top: '-10px',
                        },
                      },
                    }}
                    error={!!errors.email}
                    helperText={getEmailErrorMessage(errors.email, t)}
                    margin='normal'
                    fullWidth
                    id='email'
                    value={value}
                    label={t('common:emailAddress')}
                    inputProps={{
                      maxLength: 255,
                    }}
                    autoComplete='email'
                    onChange={onChange}
                  />
                )}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                <Button type='submit' variant='contained' sx={{ ml: 'auto', width: '100%' }} size='large'>
                  {t('common:continue')}
                </Button>
              </Box>
            </>
          )}
        </Box>

        <Box
          component='img'
          sx={{
            position: 'absolute',
            height: 'auto',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '50%',
            bottom: '10%',
            maxWidth: '600px',
            zIndex: 2,
          }}
          alt='Bionex'
          src={TextLogo}
        />
      </Container>
    </form>
  );
}
