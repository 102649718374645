import ManageUsers from 'modules/bionexUsers/containers/ManageUsers';
import { AdminDashboard } from 'modules/bionexUsers/components/Dashboard';

const route = [
  {
    path: '/',
    element: <AdminDashboard />,
    private: true,
  },
  {
    path: '/manage/users',
    element: <ManageUsers />,
    private: true,
  },
];
export default route;
