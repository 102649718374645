import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function SocketNotification({
  openSnackbar,
  openPersistentSnackbar,
  handleClosePersistentSnackBar,
  handleCloseSnackBar,
  setDetectedMachines,
  snackPersistMessage,
}) {
  const action = (
    <Box>
      <IconButton
        onClick={() => {
          handleClosePersistentSnackBar();
          setDetectedMachines([]);
        }}
        sx={{ color: 'white' }}
        aria-label='close'
      >
        <CloseOutlinedIcon />
      </IconButton>
    </Box>
  );

  // separate snackbar because they can be open at the same time
  const anchorOrigin = { vertical: 'top', horizontal: 'right' };
  return (
    <Box
      sx={{
        '.MuiSnackbar-root:nth-of-type(2)': {
          top: '102px',
        },
        '.MuiAlert-root': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Snackbar open={openPersistentSnackbar} onClose={handleClosePersistentSnackBar} anchorOrigin={anchorOrigin}>
        <Alert onClose={handleClosePersistentSnackBar} severity='success' sx={{ width: '100%' }} action={action}>
          {snackPersistMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbar.open}
        onClose={handleCloseSnackBar}
        autoHideDuration={4000}
        anchorOrigin={anchorOrigin}
      >
        {openSnackbar.open ? (
          <Alert onClose={handleCloseSnackBar} severity={openSnackbar.severity || 'success'} sx={{ width: '100%' }}>
            {openSnackbar.message}
          </Alert>
        ) : null}
      </Snackbar>
    </Box>
  );
}

SocketNotification.propTypes = {
  openSnackbar: PropTypes.object,
  openPersistentSnackbar: PropTypes.bool,
  setDetectedMachines: PropTypes.func,
  snackPersistMessage: PropTypes.string,
  handleClosePersistentSnackBar: PropTypes.func,
  handleCloseSnackBar: PropTypes.func,
};
