/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import GaugeContainer from './styled';
import TargetTag from './targetTag';
import RangeTag from './RangeTag';

const TOTAL_DEGREE = 220;

const getCluster = (maxValue) => {
  switch (maxValue) {
    case 60:
      return 6;
    case 96:
      return 8;
    case 72:
      return 6;
    case 126:
      return 7;
    case 120:
      return 6;
    case 114:
      return 6;
  }
};

const GaugeSlider = ({
  value,
  maxValue,
  isLock,
  targetValue,
  range,
  middleTitle,
  bottomInfo,
  rangeColorProps,
  minValue = 0,
}) => {
  const ticksRef = useRef(null);
  const pointerRef = useRef(null);
  const targetPointerRef = useRef(null);
  const rangeRef = useRef(null);
  const gaugeRef = useRef(null);
  const [pointerColor, setPointerColor] = useState('black');
  const [targetColor, setTargetColor] = useState('black');
  const [valueColor, setValueColor] = useState('black');
  const [rangeColor, setRangeColor] = useState(rangeColorProps || 'gainsboro');
  const [degree, setDegree] = useState(0);

  useEffect(() => {
    setDegree(TOTAL_DEGREE / maxValue);
    resetTicks();
    initGauge(maxValue);
  }, [maxValue]);

  useEffect(() => {
    return () => {
      if (gaugeRef.current) {
        gaugeRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (!rangeRef.current) {
      return;
    }
    rangeRef.current.style.stroke = `${rangeColor}`;
  }, [rangeColor]);

  useEffect(() => {
    if (!pointerRef) {
      return null;
    }

    if (isLock) {
      setValueColor('black');
      setPointerColor('gray');
      if (rangeColorProps) {
        setRangeColor('#a9a9a9');
      }

      if (targetValue !== undefined) {
        setTargetColor('gray');
      }
    } else {
      setPointerColorHandler();
      if (rangeColorProps) {
        setRangeColor(rangeColorProps);
      }
    }
  }, [isLock]);

  useEffect(() => {
    if (!maxValue || !degree) {
      return null;
    }
    if (targetValue === undefined) {
      return;
    }

    if (!isLock) {
      setPointerColorHandler();
    }

    let v = targetValue;
    if (targetValue < 0 && maxValue === 60) {
      v = (60 - Math.abs(targetValue)) * 0.5;
    }
    if (targetValue >= 0 && maxValue === 60) {
      v = 30 + targetValue * 0.5;
    }

    if (targetValue < 0 && maxValue === 96) {
      v = (96 - Math.abs(targetValue)) * 0.5;
    }
    if (targetValue >= 0 && maxValue === 96) {
      v = 48 + targetValue * 0.5;
    }

    targetPointerRef.current.style = `transform: rotate(${v * degree - 20}deg) translate(-50%, -50%) !important`;
    targetPointerRef.current.style = `transform: rotate(${v * degree - 20}deg) translate(-50%, -50%) !important`;
  }, [targetValue, maxValue, degree]);

  useEffect(() => {
    if (!degree) {
      return;
    }

    if (!maxValue) {
      return;
    }

    if (!isLock) {
      setPointerColorHandler();
    }
    let v = value;
    if (value < 0 && maxValue === 60) {
      v = (60 - Math.abs(value)) * 0.5;
    }
    if (value >= 0 && maxValue === 60) {
      v = 30 + value * 0.5;
    }

    if (value < 0 && maxValue === 96) {
      v = (96 - Math.abs(value)) * 0.5;
    }
    if (value >= 0 && maxValue === 96) {
      v = 48 + value * 0.5;
    }

    pointerRef.current.style = `transform: rotate(${v * degree - 20}deg) translate(-50%, -50%) !important;`;
  }, [value, degree, maxValue]);

  const setPointerColorHandler = () => {
    if (targetValue !== undefined) {
      const r = Math.abs(value - targetValue);
      setTargetColor('black');
      if (r === 0) {
        setTargetColor('green');
        setPointerColor('green');
        setValueColor('green');
        return true;
      }

      if (r > 0 && r < 13) {
        setPointerColor('#ed9a00');
        setValueColor('#ed9a00');
        return true;
      }
      if (r > 12) {
        setPointerColor('red');
        setValueColor('red');
        return true;
      }
    }

    if (range?.length > 0) {
      let color = '';
      if (value >= range[0] && value <= range[1]) {
        color = 'green';
      } else if (value - range[1] <= 12) {
        color = '#ed9a00';
      } else {
        color = 'red';
      }
      setPointerColor(color);
      setValueColor(color);
      return true;
    }

    setPointerColor('black');
  };

  const initGauge = (maxValue) => {
    if (!ticksRef || !maxValue || !pointerRef) {
      return null;
    }
    const CLUSTER = getCluster(maxValue);

    const d = TOTAL_DEGREE / (maxValue - minValue);
    const chunk = (maxValue - minValue) / CLUSTER;
    let degreeCounter = -20;
    for (let i = minValue; i < maxValue + 1; i += 1) {
      const node = document.createElement('span');
      if ((i - minValue) % chunk === 0) {
        const number = document.createElement('div');
        node.style = `transform:rotate(${degreeCounter}deg) translate(-50%,-50%);width:47%;`;
        number.textContent = i.toString();
        number.style = `transform: translate(-120%, -51%) rotate(${-degreeCounter}deg);`;
        node.appendChild(number);
      } else {
        if ((i - minValue) % (chunk / 2) === 0) {
          node.style = `transform:rotate(${degreeCounter}deg) translate(-50%,-50%);width:47%;`;
        } else {
          node.style = `transform:rotate(${degreeCounter}deg) translate(-50%,-50%);width:45%;`;
        }
      }
      degreeCounter += d;
      ticksRef.current.appendChild(node);
    }
    if (!isLock) {
      setPointerColorHandler();
    }
    if (range?.length > 0) {
      const start = (61.5 / maxValue) * range[0] + 0.1;
      const end = (61.5 / maxValue) * (range[1] - range[0]) + 0.1;
      rangeRef.current.style = `stroke-dasharray: 0 ${start} ${end} 100;fill: none; stroke: ${rangeColor}; stroke-width: 3px`;
    }

    pointerRef.current.style = `transform: rotate(${value * d - 20}deg) translate(-50%, -50%) !important;`;
  };

  const resetTicks = () => {
    if (ticksRef) {
      const ticks = ticksRef.current.querySelectorAll('span');
      if (ticks.length <= 1) {
        return;
      }
      for (let i = 2; i < ticks.length; i += 1) {
        ticks[i].remove();
      }
    }
  };

  return (
    <GaugeContainer>
      <div ref={gaugeRef} className='gauge'>
        <div ref={ticksRef} className={'minor-ticks'}>
          <span ref={pointerRef} className='pointer'>
            <div
              css={css`
                background: ${pointerColor};
              `}
            ></div>
          </span>
          {targetValue !== undefined && (
            <span ref={targetPointerRef} className='pointer'>
              <div
                css={css`
                  background: ${targetColor};
                `}
              ></div>
            </span>
          )}
        </div>
        <svg className='circle-container' viewBox='2 -2 28 36' xmlns='http://www.w3.org/2000/svg'>
          <linearGradient>
            <stop className='stop1' offset='0%' />
            <stop className='stop2' offset='100%' />
          </linearGradient>
          <circle
            className='circle-container__background'
            r='16'
            cx='16'
            cy='16'
            shapeRendering='geometricPrecision'
          ></circle>
        </svg>

        {range?.length > 0 && (
          <svg className='circle-container' viewBox='2 -2 28 36' xmlns='http://www.w3.org/2000/svg'>
            <linearGradient>
              <stop className='stop1' offset='0%' />
              <stop className='stop2' offset='100%' />
            </linearGradient>
            <circle ref={rangeRef} r='16' cx='16' cy='16' shapeRendering='geometricPrecision'></circle>
          </svg>
        )}

        <div className='gauge-content'>
          <div>
            <div
              css={css`
                color: ${valueColor};
              `}
              className='gauge-content-value'
            >
              {value}°
            </div>
            <div className='title'>{middleTitle ? middleTitle : isLock ? 'Locked Angle' : 'Current Angle'} </div>
          </div>

          {targetValue !== undefined && (
            <>
              <TargetTag isLock={isLock} value={targetValue} isTarget={Number(value) === Number(targetValue)} />

              <div style={{ fontWeight: 'bold', padding: '20px 0' }}>{bottomInfo}</div>
            </>
          )}

          {range && <RangeTag isLock={isLock} values={range} />}
        </div>
      </div>
    </GaugeContainer>
  );
};

GaugeSlider.propTypes = {
  value: PropTypes.number,
  targetValue: PropTypes.number,
  maxValue: PropTypes.number,
  isLock: PropTypes.bool,
  range: PropTypes.array,
  middleTitle: PropTypes.string,
  bottomInfo: PropTypes.string,
  rangeColorProps: PropTypes.string,
  minValue: PropTypes.number,
};

export default GaugeSlider;
