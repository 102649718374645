import { StatusCodes } from 'http-status-codes';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthDataContext } from 'components/AuthProvider';
import { fetchUserInfo } from 'modules/auth/slice';
import { ACCESS_TOKEN } from 'modules/auth/constants';
import { useEffect } from 'react';
import { useSocketDataContext } from 'components/WebSocketProvider';
import socketActions from 'components/WebSocketProvider/actions';
import { useState } from 'react';
import isNil from 'lodash/isNil';

export default function CurrentUser() {
  const context = useAuthDataContext();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isLogoutSuccess = useSelector((state) => state.auth.isLogoutSuccess);
  const errorCode = useSelector((state) => state.auth.errorCode);
  const forcedLogout = useSelector((state) => state.auth.forcedLogout);
  const socketContext = useSocketDataContext();
  const [isListeing, setListen] = useState(false);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const role = localStorage.getItem('role');

  const token = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    if (!currentUser && !isNil(token) && !isLogoutSuccess) {
      dispatch(fetchUserInfo());
    } else if (currentUser) {
      context.setData({ currentUser });
      if (role === 'BioneX Admin' && !isListeing) {
        setListen(true);
        socketContext.sendJsonMessage({
          request: socketActions.LISTEN,
        });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (errorCode === StatusCodes.UNAUTHORIZED) {
      context.resetData();
      localStorage.removeItem(ACCESS_TOKEN);
      navigator(`/sign-in?redirect_url=${location.pathname}`);
    }
  }, [errorCode]);

  useEffect(() => {
    if (forcedLogout) {
      context.resetData();
      if (role === 'BioneX Admin') {
        navigator(`/sign-out?redirect_url=${location.pathname}&from_admin=true`);
      } else {
        navigator(`/sign-out?redirect_url=${location.pathname}`);
      }
    }
  }, [forcedLogout]);

  return null;
}
