import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ redirectPath = '/sign-in', children, authed, isAdmin }) => {
  if (authed && isAdmin) {
    return children ?? <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

PrivateRoute.propTypes = {
  redirectPath: PropTypes.string,
  authed: PropTypes.bool,
  isAdmin: PropTypes.bool,
  children: PropTypes.element,
};

export default PrivateRoute;
