import { createSlice } from '@reduxjs/toolkit';

export const ActivityTrackingsSlice = createSlice({
  name: 'activityTrackingsSlice',
  initialState: {
    error: null,
    numberRecord: null,
    isRequestCountRecordSuccess: false,
    isDownloadSuccess: false,
    isLoadingActivity: false,
    dataCustomerInfoActivityTracking: {},
    dataActivityTracking: []
  },
  reducers: {
    requestExample: (state) => {
      state.isFetchExample = true;
    },
    requestExampleSuccess: (state) => {
      state.isFetchExample = false;
    },
    requestExampleFailure: (state, action) => {
      state.isFetchExample = false;
      state.fetchExampleError = action.payload;
    },
    requestExportAuditLogAction: () => {
    },
    requestExportAuditLogActionSuccess: (state) => {
      state.isDownloadSuccess = true;
    },
    requestExportAuditLogActionFailed: (state, action) => {
      state.requestExportAuditLogError = action.payload;
    },
    requestCountRecordAuditLog: (state) => {
    },
    requestCountRecordAuditLogSuccess: (state, action) => {
      state.numberRecord = action.payload.rows;
      state.isRequestCountRecordSuccess = true;
    },
    requestCountRecordAuditLogFailed: (state, action) => {
      state.isRequestCountRecordFailed = true;
    },
    fetchListActivityTracking: (state) => {
      state.isFetchListActivity = true;
      state.isLoadingActivity = true;
    },
    fetchListActivityTrackingSuccess: (state, action) => {
      state.isFetchListActivity = false;
      state.dataActivityTracking = action.payload.data;
      state.isFetchListActivitySuccess = true;
      state.isLoadingActivity = false;
    },
    fetchListActivityTrackingFailed: (state, action) => {
      state.fetchListActivityError = action.payload;
      state.isLoadingActivity = false;
    },
    updateDataActivityTracking: (state, action) => {
      state.dataActivityTracking = action.payload.resultSort;
    },
    updateDataCustomerInfoActivityTracking: (state, action) => {
      state.dataCustomerInfoActivityTracking = action.payload.data;
    },
    requestLoggingRecordAction: (state) => {
    },
    requestLoggingRecordActionSuccess: (state) => {
    },
    requestLoggingRecordActionFailed: (state, action) => {
      state.requestLoggingRecordActionError = action.payload;
    }
  }
});
export const {
  requestExample,
  requestExampleSuccess,
  requestExampleFailure,
  requestExportAuditLogAction,
  requestExportAuditLogActionSuccess,
  requestExportAuditLogActionFailed,
  requestCountRecordAuditLog,
  requestCountRecordAuditLogSuccess,
  requestCountRecordAuditLogFailed,
  fetchListActivityTracking,
  fetchListActivityTrackingSuccess,
  fetchListActivityTrackingFailed,
  updateDataActivityTracking,
  updateDataCustomerInfoActivityTracking,
  requestLoggingRecordAction,
  requestLoggingRecordActionSuccess,
  requestLoggingRecordActionFailed
} = ActivityTrackingsSlice.actions;

export default ActivityTrackingsSlice.reducer;
