import { Box, Collapse, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

export default function InfoListAssignMachine({ office, openCollapse, handleCollapse }) {

  const lengthOfAssignMachine = office?.office_assign_machine ? office?.office_assign_machine?.length : 0;

  return <Box sx={{ width: '90%', mb: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography sx={{ fontWeight: 600, color: '#495057', fontSize: '14px' }}>{`Assigned Machines: ${lengthOfAssignMachine}`}</Typography>
      {openCollapse ? <ArrowDropUpIcon sx={{ fontSize: '28px' }} onClick={handleCollapse} /> : <ArrowDropDownIcon sx={{ fontSize: '28px' }} onClick={handleCollapse} />}
    </Box>
    {
      lengthOfAssignMachine > 0
      &&
      <Collapse in={openCollapse}>
        <Box>
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Typography sx={{ width: '280px', fontWeight: 600, color: '#495057', fontSize: '14px', }}>Machine Name</Typography>
            <Typography sx={{ fontWeight: 600, color: '#495057', fontSize: '14px', ml: 2 }}>Serial Number</Typography>
          </Box>
          {office?.office_assign_machine?.map((machine, index) => (
            <Box key={`${machine.name}${index}`} sx={{ display: 'flex', mb: 0.5 }}>
              <Typography sx={{ width: '280px', fontSize: '14px', fontWeight: 400, color: '#343A40' }}>{`${machine.machine_type} ${machine.name}`}</Typography>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#343A40', ml: 2 }}>{machine.serial_no}</Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    }
  </Box>
}

InfoListAssignMachine.propTypes = {
  office: PropTypes.any,
  openCollapse: PropTypes.bool,
  handleCollapse: PropTypes.func
};