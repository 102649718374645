import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  changePassword: {
    url: `${ENV_VARIABLES.API_SERVER}users/password`,
  },
  updateProfile: {
    url: `${ENV_VARIABLES.API_SERVER}users/me`,
  },
};

export const ACCESS_TOKEN = 'access_token';
