import { createSlice } from '@reduxjs/toolkit';
import { TOAST_TYPE } from './constants';

export const UISlice = createSlice({
  name: 'uiSlice',
  initialState: {
    isLoading: false,
    isOpen: false,
    persist: false,
    type: TOAST_TYPE.INFO,
    message: '',
  },
  reducers: {
    beginLoading: () => {},
    beginLoadingSuccess: (state) => {
      state.isLoading = true;
    },
    endLoading: () => {},
    endLoadingSuccess: (state) => {
      state.isLoading = false;
    },
    requestToast: (state) => {
      state.isOpen = false;
    },
    requestToastSuccess: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.persist = action.payload.persist;
      state.isOpen = true;
    },
    requestToastFailure: (state) => {
      state.isOpen = false;
    },
    requestCloseToast: (state) => {
      // state.type = TOAST_TYPE.INFO;
      state.message = '';
      state.isOpen = false;
      state.persist = false;
    },
  },
});
export const {
  beginLoading,
  endLoading,
  beginLoadingSuccess,
  endLoadingSuccess,
  requestToast,
  requestToastSuccess,
  requestToastFailure,
  requestCloseToast,
} = UISlice.actions;

export default UISlice.reducer;
