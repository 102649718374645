import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import { GlobalLoading } from 'components/Loading';
import { requestCloseToast } from 'modules/ui/slice';
import { useSnackbar } from 'notistack';
import useNetwork from 'hooks/useNetwork';

export default function UI() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isOnline = useNetwork();

  const { isOpen, message, type, isLoading, persist } = useSelector(
    (state) => state.ui
  );

  // detect when the user is offline
  useEffect(() => {
    let key = null;
    if (!isOnline) {
      key = enqueueSnackbar('You are offline', {
        variant: 'error',
        persist,
      });
    } else {
      closeSnackbar(key);
    }

    return () => {
      closeSnackbar();
    };
  }, [isOnline]);

  const action = (snackbarId) => (
    <Button
      sx={{
        color: 'white',
      }}
      variant="text"
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      Dismiss
    </Button>
  );

  useEffect(() => {
    if (isOpen) {
      enqueueSnackbar(message, {
        persist: persist ? true : false,
        autoHideDuration: persist ? null : 3000,
        variant: type,
        action: persist ? action : null,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      });
    }

    return () => {
      closeSnackbar();
      requestCloseToast();
    };
  }, [isOpen]);

  // app global loading
  return <GlobalLoading isLoading={isLoading} />;
}
