import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';

export default function DatePickerActivityTracking({ handleSetInvalidTime }) {

  const { control, getValues, watch } = useFormContext();
  const [invalidTime, setInvalidTime] = useState(false);

  const watchFromTo = watch(['date_from', 'date_to']);

  useEffect(() => {
    const toValue = getValues('date_to');
    const fromValue = getValues('date_from');
    if (toValue && fromValue) {
      const calculateTime = toValue.getTime() - fromValue.getTime();
      if (calculateTime < 0) {
        setInvalidTime(true);
        // handleSetInvalidTime(true);
        return;
      } else {
        setInvalidTime(false);
        // handleSetInvalidTime(false);
      }
    }

  }, [watchFromTo]);


  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', gap: 1 }}>
      <Box sx={{ width: '180px', display: 'flex', alignItems: 'center' }}>
        <Controller
          name="date_from"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  defaultValue={null}
                  id="date_from"
                  label="From"
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={invalidTime}
                      sx={{ maxWidth: '180px' }}
                    />
                  )}
                />
              </LocalizationProvider>
            )
          }}
        />
        <Typography sx={{ ml: 1 }}>-</Typography>
      </Box>
      <Box sx={{ width: '172px' }}>
        <Controller
          name="date_to"
          control={control}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                defaultValue={null}
                id="date_to"
                label="To"
                value={value}
                onChange={onChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={invalidTime}
                    helperText={invalidTime && 'The “To” date must be after the “From” date.'}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Box>
    </Box>
  );
}

DatePickerActivityTracking.propTypes = {
  handleSetInvalidTime: PropTypes.func
};
