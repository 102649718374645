import { Modal } from 'components/Modal';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInTimeZone } from 'date-fns-tz';
import socketActions from 'components/WebSocketProvider/actions';
import { useSocketDataContext } from 'components/WebSocketProvider';
import { requestEditMachine } from '../../../slice';
import { useDispatch } from 'react-redux';

export default function UpdateCalibrationModal({ showModal, onClose, onContinue, rowSelected }) {
  const socketContext = useSocketDataContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentRequest, setCurrentRequest] = useState(false);
  const [step, setStep] = useState(1);
  const [lodeCellPlus, setLodeCellPlus] = useState(0);
  const [lodeCellMinus, setLodeCellMinus] = useState(0);
  const [pot, setPot] = useState(0);
  useEffect(() => {
    setStep(1);
    setCurrentRequest(false);
    setLodeCellPlus(0);
    setLodeCellMinus(0);
    setPot(0);
  }, [showModal]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }
    if (message.plode_cell === undefined || message.nlode_cell === undefined || message.pot === undefined) {
      return;
    }

    setLodeCellPlus(message.plode_cell);
    setLodeCellMinus(message.nlode_cell);
    setPot(message.pot);
  }, [socketContext.messageHistory]);

  const onContinueHandler = () => {
    setStep(step + 1);
    if (onContinue) {
      onContinue();
    }
  };

  const onUpdateHandler = () => {
    const data = {
      id: rowSelected.machine.id,
      lodeCellCalibratedAt: new Date().toISOString(),
      macAddress: rowSelected.machine.mac_addr,
      converterId: rowSelected.machine.u12_id,
    };
    dispatch(requestEditMachine(data));
    socketContext.sendJsonMessage({
      request: socketActions.LODE_CELL_CALIBRATION,
      u12_id: data.converterId,
      mac_address: data.macAddress,
      plode_cell: lodeCellPlus,
      nlode_cell: lodeCellMinus,
      pot: pot,
    });
    setCurrentRequest(false);
    setStep(step + 1);
  };

  const calibrateTooltipRender = () => {
    return (
      <span>
        We are about to calibrate the {rowSelected.machine.machine_type} Extension machine.
        <br />
        <br />
        Loosen chain adjuster, release counter-weight, engage movement arm lock and make sure the movement arm is in the
        idle angle of {rowSelected.machine.machine_type === 'Cervical' ? '90' : '18'} degrees.
      </span>
    );
  };

  const getFooterExtra = () => {
    switch (step) {
      case 1:
        return [
          <Button onClick={onClose} key={'calibrate-btn-1'} size={'small'}>
            {t('common:cancel')}
          </Button>,
          <Button onClick={onContinueHandler} key={'calibrate-btn-2'} size={'small'} variant={'contained'}>
            {t('common:continue')}
          </Button>,
        ];
      case 2:
        return [
          <Button onClick={onClose} key={'calibrate-btn-1'} size={'small'}>
            {t('common:cancel')}
          </Button>,
          <Button onClick={onUpdateHandler} key={'calibrate-btn-2'} size={'small'} variant={'contained'}>
            {t('common:update')}
          </Button>,
        ];
      case 3:
        return [
          <Button onClick={onClose} key={'calibrate-btn-1'} size={'small'} variant={'contained'}>
            {t('common:ok')}
          </Button>,
        ];
    }
  };

  const calibrateResultRender = () => {
    let localDate = '';
    if (rowSelected.machine.lode_cell_calibrated_at) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const date = new Date(rowSelected.machine.lode_cell_calibrated_at);

      localDate = formatInTimeZone(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
        timezone,
        'd LLL yyyy'
      );
    }
    const machine = {
      name: rowSelected.machine.name || '',
      serial: rowSelected.machine.u12_id || '',
      lastCalibrated: localDate,
      macAddress: rowSelected.machine.mac_addr,
      type: rowSelected.machine.machine_type,
    };
    if (!currentRequest) {
      socketContext.sendJsonMessage({
        request: socketActions.OPEN_DEBUG_MACHINE,
        u12_id: machine.serial,
        mac_address: machine.macAddress,
      });
      setCurrentRequest(true);
    }

    return (
      <Box>
        <Box>The progress has been done. Here is the result.</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px 0', gap: '30px' }}>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', minWidth: '130px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine Type
              </Typography>
              <div>{machine?.type}</div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine Name
              </Typography>
              <div>{machine.name}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', minWidth: '130px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Machine Serial Number
              </Typography>
              <div>{machine.serial}</div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Last calibrated on
              </Typography>
              <div>{machine.lastCalibrated}</div>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Pot Zero
              </Typography>
              <div>{pot}</div>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '50px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', minWidth: '130px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Load cell +
              </Typography>
              <div>{lodeCellPlus}</div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', minWidth: '130px' }}>
              <Typography variant='span' sx={{ fontSize: '1rem', fontWeight: 500 }}>
                Load cell -
              </Typography>
              <div>{lodeCellMinus}</div>
            </Box>
          </Box>
        </Box>
        <Box>Do you want to update?</Box>
      </Box>
    );
  };

  const calibrateResultSuccessRender = () => {
    return <span>The value has been updated!</span>;
  };

  const contentRender = () => {
    switch (step) {
      case 1:
        return calibrateTooltipRender();
      case 2:
        return calibrateResultRender();
      case 3:
        return calibrateResultSuccessRender();
      default:
        return null;
    }
  };

  return (
    <Modal
      keep={true}
      showModal={showModal}
      onClose={onClose}
      content={contentRender}
      isClose={false}
      extra={getFooterExtra()}
    />
  );
}

UpdateCalibrationModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  onCalibrationSelected: PropTypes.func,
  rowSelected: PropTypes.object,
};
