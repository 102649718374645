import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { AssignMachineDialog } from 'modules/machines/components/Dialogs';

import { DIALOGS } from 'modules/dialogs/constants';
import { closeDialog } from 'lib/dialog.js';
import { requestAssignMachine } from 'modules/machines/slice';
import { filterCustomers } from 'modules/customers/slice';

export default function AssignMachine({ selectedRow, setSelectedRow, sortData }) {
  const dispatch = useDispatch();

  const openAssignMachineDialog = useSelector((state) => state.dialogs[DIALOGS.ASSIGN_MACHINE_DIALOG]);
  const createdMachine = useSelector((state) => state.machines.createdMachine);
  const customers = useSelector((state) => state.customers.filteredCustomers);
  const error = useSelector((state) => state.machines.error);

  useEffect(() => {
    dispatch(filterCustomers({ filterStatuses: [1, 0] }));
  }, [openAssignMachineDialog]);

  const handleCloseAssignMachineDialog = () => {
    closeDialog(dispatch, DIALOGS.ASSIGN_MACHINE_DIALOG);
  };

  const onSubmitAssignMachineDialog = (data) => {
    // keep the current sort
    data.sortData = sortData;
    dispatch(requestAssignMachine(data));
    // set this data for confirm dialog
    setSelectedRow(data);
  };

  return (
    <AssignMachineDialog
      anchor='right'
      machine={selectedRow}
      createdMachine={createdMachine}
      onSubmitAssignMachineDialog={onSubmitAssignMachineDialog}
      error={error}
      customers={customers}
      openAssignMachineDialog={openAssignMachineDialog}
      handleCloseAssignMachineDialog={handleCloseAssignMachineDialog}
    />
  );
}

AssignMachine.propTypes = {
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  sortData: PropTypes.object,
};
