import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { beginLoading, endLoading } from 'modules/ui/slice';

import { ACCESS_TOKEN } from './constants';
let isLoading = false;

const doHttp = (dispatch, action) => {
  axios.interceptors.request.use((request) => {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    if (!isLoading) {
      isLoading = true;
      dispatch(beginLoading());
    }
    if (localStorage.getItem(ACCESS_TOKEN)) {
      request.headers['Authorization'] = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
    }
    return request;
  });
  axios.interceptors.response.use(
    (res) => {
      dispatch(endLoading());
      isLoading = false;

      return res;
    },
    (error) => {
      dispatch(endLoading());
      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        // const currentPath = window.location.pathname;
        if (window.location.href.indexOf('sign-in') === -1) {
          dispatch(action({ code: error.response.status, message: 'Unauthorized' }));
        }
      }
      return Promise.reject(error);
    }
  );
};
export default doHttp;
