import styled from '@emotion/styled';

export const CalibrateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 500;
  & .container {
    display: flex;
    gap: 14px;
    width: 100%;
    flex-direction: column;
  }

  & .calibrate-btn {
    width: 100%;
    height: 52px;
    padding: 10px 24px;
    border-radius: 6px;
    border: 2px solid #c7c7c7;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
  }

  & .calibrate-btn:hover {
    border-color: #ed9a00;
    background: #ffd28014 0 0 no-repeat padding-box;
  }
  & .calibrate-active {
    border-color: #ed9a00;
    background: #ffd28014 0 0 no-repeat padding-box;
  }
`;
