import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const drawerWidth = '50%';
const mainRoute = '/manage/users';
export default function InviteUserDialog({
  error,
  createdUser,
  onSubmitInviteUserDialog,
  openInviteUserDialog,
  handleCloseInviteUserDialog,
  onAddMoreUser,
  roles,
  anchor,
}) {
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  };

  const location = useLocation();
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccessState] = useState(false);
  const [isOpenedOutside, setIsOpenedOutside] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (openInviteUserDialog && location && location.pathname !== mainRoute) {
      setIsOpenedOutside(true);
    }

    if (openInviteUserDialog) {
      reset(defaultValues);
    } else {
      setIsOpenedOutside(false);
    }
  }, [openInviteUserDialog]);

  //when create new User success
  useEffect(() => {
    if (createdUser && createdUser.id) {
      setSuccessState(true);
    }
  }, [createdUser]);

  useEffect(() => {
    if (error && error.code === 400) {
      setError('email', {
        type: 'email_exists',
      });
    }
  }, [error]);

  const handleAddMoreUser = () => {
    resetForm();
    setSuccessState(false);
    if (onAddMoreUser) {
      onAddMoreUser();
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    if (onSubmitInviteUserDialog) {
      onSubmitInviteUserDialog({
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email.trim(),
        role: data.role,
      });
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const resetForm = () => {
    reset(defaultValues);
  };

  const closeModal = () => {
    if (handleCloseInviteUserDialog) {
      handleCloseInviteUserDialog();
    }
    setSuccessState(false);
    reset(defaultValues);
  };

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return null;
  };

  const getEmailErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('user:inviteDialog.errors.invalidEmailPattern');
    if (error?.type === 'email_exists') return t('user:inviteDialog.errors.emailExists');
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidNamePattern');
  };

  const renderContent = () => {
    return (
      <Box sx={{ width: '65%', position: 'relative' }} role='presentation'>
        {success && createdUser ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                color: '#2e7d32',
                pt: 5,
                pb: 3,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  mr: 1,
                  fontSize: '2.3rem',
                }}
              />
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                {t('user:confirmInviteUserDialog.newUser')}
              </Typography>
            </Box>
            <Typography variant='body2'>
              {t('user:confirmInviteUserDialog.message')}
              <strong>
                {' '}
                &quot;{createdUser.first_name} {createdUser.last_name}
                &quot;
              </strong>{' '}
              at <strong>{createdUser.email}</strong>.
            </Typography>
            {isOpenedOutside && (
              <Typography sx={{ mt: 1 }} variant='body2'>
                {t('user:inviteDialog.toView')} <Link to={mainRoute}>{t('common:clickHere')}</Link>.
              </Typography>
            )}
            <Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:close')}
              </Button>
              <Button onClick={handleAddMoreUser} variant='contained'>
                {t('user:confirmInviteUserDialog.addAnotherUser')}
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography sx={{ fontSize: '1.5rem', pt: 2, fontWeight: 500 }} variant='h2'>
                {t('user:inviteDialog.title')}
              </Typography>
              <Typography sx={{ mb: 1 }} variant='body1'>
                {t('user:inviteDialog.subtitle')}
              </Typography>

              {error && error.code !== 400 ? <Alert severity='error'>{error.message}</Alert> : null}
              <Box sx={{ mt: 2 }}>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[a-zA-Z0-9 ]*$/,
                    validate: {
                      required: (v) => !!v.trim(),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      error={!!errors.firstName}
                      helperText={getNameErrorMessage(errors.firstName, t)}
                      margin='normal'
                      fullWidth
                      id='firstName'
                      value={value}
                      label={t('common:firstName')}
                      onChange={onChange}
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  )}
                />
              </Box>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9 ]*$/,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.lastName}
                    helperText={getNameErrorMessage(errors.lastName, t)}
                    margin='normal'
                    fullWidth
                    value={value}
                    id='lastName'
                    label={t('common:lastName')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                )}
              />
              <Controller
                name='email'
                rules={{
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.email}
                    helperText={getEmailErrorMessage(errors.email, t)}
                    margin='normal'
                    fullWidth
                    id='email'
                    value={value}
                    label={t('common:emailAddress')}
                    inputProps={{
                      maxLength: 255,
                    }}
                    onChange={onChange}
                  />
                )}
              />
              <Box sx={{ my: 2 }}>
                <Controller
                  name='role'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl error={!!errors.role} fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select value={value} label='Role' onChange={onChange}>
                        {roles.map((role) =>
                          role.name === 'Admin' ? (
                            <MenuItem key={role.id} value={role.id}>
                              BioneX Admin
                              {/* {role.name} */}
                            </MenuItem>
                          ) : null
                        )}
                        {/* <MenuItem value={0}>BioneX Admin</MenuItem> */}
                      </Select>
                      <FormHelperText error={!!errors.role}>
                        {errors.role && t('common:errors.required')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:cancel')}
              </Button>
              <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant='contained'>
                {t('common:add')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      anchor={anchor}
      open={openInviteUserDialog}
      onClose={onClose}
    >
      {renderContent()}
    </Drawer>
  );
}

InviteUserDialog.propTypes = {
  anchor: PropTypes.string,
  error: PropTypes.any,
  createdUser: PropTypes.object,
  onAddMoreUser: PropTypes.func,
  openInviteUserDialog: PropTypes.bool,
  handleCloseInviteUserDialog: PropTypes.func,
  onSubmitInviteUserDialog: PropTypes.func,
  currentUser: PropTypes.object,
  roles: PropTypes.array,
};
