import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/dialogs/actions';

export const MachinesSlice = createSlice({
  name: 'machinesSlice',
  initialState: {
    error: {},
    detectedMachines: [],
    machines: [],
    hcpConfiguredMachines: [],
    hcpMachines: [],
    createdMachine: null,
    isUpdatingMachine: false,
    isLoadingMachine: false,
    isUnassignCpnSuccess: false,
    isReplaceLaterSuccess: false,
    isReplaceMachineSuccess: false,
    currentProcessReplace: null,
    isGetCurrentProcessSuccess: false,
    listCpnAvailableReplace: [],
    isFinishReplaceSuccess: false,
  },
  reducers: {
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdMachine = null;
    },
    fetchMachines: (state) => {
      state.error = null;
      state.isLoadingMachine = true;
    },
    fetchMachinesSuccess: (state, action) => {
      state.machines = action.payload;
      state.isLoadingMachine = false;
    },
    fetchMachinesFailure: (state, action) => {
      state.machines = [];
      state.error = action.payload;
      state.isLoadingMachine = false;
    },

    updateMachines: (state) => {
      state.error = null;
      state.isUpdatingMachine = true;
    },
    updateMachinesSuccess: (state) => {
      state.isUpdatingMachine = false;
    },
    updateMachinesFailure: (state, action) => {
      state.error = action.payload;
      state.isUpdatingMachine = false;
    },

    fetchDetectedMachines: (state) => {
      state.error = null;
    },
    fetchDetectedMachinesSuccess: (state, action) => {
      state.detectedMachines = action.payload;
    },
    fetchDetectedMachinesFailure: (state, action) => {
      state.detectedMachines = [];
      state.error = action.payload;
    },
    requestAddMachine: (state) => {
      state.error = null;
    },
    requestAddMachineSuccess: (state, action) => {
      state.createdMachine = action.payload.data;
    },
    requestAddMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestEditMachine: (state) => {
      state.error = null;
    },
    requestEditMachineSuccess: () => {
      // state.createdMachine = action.payload.data;
    },
    requestEditMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestAssignMachine: (state) => {
      state.error = null;
    },
    requestRetryRepairMachine: (state) => {
      state.error = null;
      state.isUnassignCpnSuccess = false;
      state.isReplaceMachineSuccess = false;
    },
    initReplaceStepRepairMachine: (state) => {
      state.error = null;
      state.isUnassignCpnSuccess = false;
      state.isReplaceMachineSuccess = false;
    },
    requestAssignMachineSuccess: (state, action) => {
      state.createdMachine = action.payload.data;
    },
    requestAssignMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestRemoveMachine: (state) => {
      state.errorCode = 0;
    },
    requestRemoveMachineSuccess: () => {},
    requestRemoveMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestConfigureMachine: (state) => {
      state.errorCode = 0;
    },
    requestConfigureMachineSuccess: () => {},
    requestConfigureMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestConfigurePreAddedMachine: (state) => {
      state.errorCode = 0;
    },
    requestConfigurePreAddedMachineSuccess: () => {},
    requestConfigurePreAddedMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    // for HCP Configured Machines
    fetchHcpConfiguredMachines: (state) => {
      state.error = null;
    },
    fetchHcpConfiguredMachinesSuccess: (state, action) => {
      state.hcpConfiguredMachines = action.payload;
    },
    fetchHcpConfiguredMachinesFailure: (state, action) => {
      state.hcpConfiguredMachines = [];
      state.error = action.payload;
    },
    // for HCPs Machines
    fetchHcpMachines: (state) => {
      state.error = null;
    },
    fetchHcpMachinesSuccess: (state, action) => {
      state.hcpMachines = action.payload;
    },
    fetchHcpMachinesFailure: (state, action) => {
      state.hcpMachines = [];
      state.error = action.payload;
    },
    requestLoggingRecordAction: () => {},
    requestLoggingRecordActionSuccess: () => {},
    requestLoggingRecordActionFailed: (state, action) => {
      state.requestLoggingRecordActionError = action.payload;
    },
    // for HCPs office
    fetchHcpOffices: (state) => {
      state.error = null;
    },
    fetchHcpOfficeSuccess: (state, action) => {
      state.hcpOffices = action.payload;
    },
    fetchHcpOfficesFailure: (state, action) => {
      state.hcpOffices = [];
      state.error = action.payload;
    },
    requestUnassignMachine: (state) => {
      state.errorCode = 0;
    },
    requestUnassignMachineSuccess: () => {},
    requestUnassignMachineFailure: (state, action) => {
      state.error = action.payload;
    },
    requestUnassignComponent: (state) => {
      state.errorCode = 0;
    },
    requestUnassignComponentSuccess: (state, action) => {
      state.isUnassignCpnSuccess = action.payload.isUnassignCpnSuccess;
    },
    requestUnassignComponentFailure: (state, action) => {
      state.error = action.payload;
    },
    requestGetListCpnAvailableReplace: (state) => {
      state.errorCode = 0;
    },
    requestGetListCpnAvailableReplaceSuccess: (state, action) => {
      state.listCpnAvailableReplace = action.payload;
    },
    requestGetListCpnAvailableReplaceFailure: (state, action) => {
      state.errorCode = action.payload;
    },
    requestConfirmReplaceMachine: (state) => {
      state.errorCode = 0;
      state.currentProcessReplace = null;
    },
    requestConfirmReplaceMachineSuccess: (state, action) => {
      state.isReplaceMachineSuccess = action.payload.isReplaceMachineSuccess;
    },
    requestConfirmReplaceMachineFailure: (state, action) => {
      state.errorCode = action.payload;
    },
    requestReplaceMachineLater: (state) => {
      state.errorCode = 0;
    },
    requestReplaceMachineLaterSuccess: (state, action) => {
      state.currentProcessReplace = null;
      state.isReplaceLaterSuccess = action.payload.isReplaceLaterSuccess;
    },
    requestReplaceMachineLaterFailure: (state, action) => {
      state.errorCode = action.payload;
    },
    requestGetCurrentProcessReplace: (state) => {
      state.errorCode = 0;
      state.isGetCurrentProcessSuccess = true;
    },
    requestGetCurrentProcessReplaceSuccess: (state, action) => {
      state.currentProcessReplace = action.payload;
      state.isGetCurrentProcessSuccess = false;
    },
    requestGetCurrentProcessReplaceFailure: (state, action) => {
      state.errorCode = action.payload;
      state.isGetCurrentProcessSuccess = false;
    },
    requestFinishReplaceMachine: (state) => {
      state.errorCode = 0;
    },
    requestFinishReplaceMachineSuccess: (state, action) => {
      state.isFinishReplaceSuccess = action.payload.isFinishReplaceSuccess;
    },
    requestFinishReplaceMachineFailure: (state, action) => {
      state.errorCode = action.payload;
    },
    resetStateReplaceMachine: (state) => {
      state.isUnassignCpnSuccess = false;
      state.isReplaceLaterSuccess = false;
      state.isReplaceMachineSuccess = false;
      state.currentProcessReplace = null;
      state.isGetCurrentProcessSuccess = false;
      state.listCpnAvailableReplace = [];
    },
    resetDetectedMachines: (state) => {
      state.detectedMachines = [];
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
      state.createdMachine = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchMachines,
  fetchMachinesSuccess,
  fetchMachinesFailure,
  fetchDetectedMachines,
  fetchDetectedMachinesSuccess,
  fetchDetectedMachinesFailure,
  requestAddMachine,
  requestAddMachineSuccess,
  requestAddMachineFailure,
  requestEditMachine,
  requestEditMachineSuccess,
  requestEditMachineFailure,
  requestAssignMachine,
  requestAssignMachineSuccess,
  requestAssignMachineFailure,
  requestRemoveMachine,
  requestRemoveMachineSuccess,
  requestRemoveMachineFailure,
  requestConfigureMachine,
  requestConfigureMachineSuccess,
  requestConfigureMachineFailure,
  requestConfigurePreAddedMachine,
  requestConfigurePreAddedMachineSuccess,
  requestConfigurePreAddedMachineFailure,
  fetchHcpConfiguredMachines,
  fetchHcpConfiguredMachinesSuccess,
  fetchHcpConfiguredMachinesFailure,
  fetchHcpMachines,
  fetchHcpMachinesSuccess,
  fetchHcpMachinesFailure,
  requestLoggingRecordAction,
  requestLoggingRecordActionSuccess,
  requestLoggingRecordActionFailed,
  fetchHcpOffices,
  fetchHcpOfficeSuccess,
  fetchHcpOfficesFailure,
  requestUnassignMachine,
  requestUnassignMachineSuccess,
  requestUnassignMachineFailure,
  requestUnassignComponent,
  requestUnassignComponentSuccess,
  requestUnassignComponentFailure,
  requestGetListCpnAvailableReplace,
  requestGetListCpnAvailableReplaceSuccess,
  requestGetListCpnAvailableReplaceFailure,
  requestConfirmReplaceMachine,
  requestConfirmReplaceMachineSuccess,
  requestConfirmReplaceMachineFailure,
  requestReplaceMachineLater,
  requestReplaceMachineLaterSuccess,
  requestReplaceMachineLaterFailure,
  requestGetCurrentProcessReplace,
  requestGetCurrentProcessReplaceSuccess,
  requestGetCurrentProcessReplaceFailure,
  requestFinishReplaceMachine,
  requestFinishReplaceMachineSuccess,
  requestFinishReplaceMachineFailure,
  resetStateReplaceMachine,
  resetDetectedMachines,
  requestRetryRepairMachine,
  initReplaceStepRepairMachine,
} = MachinesSlice.actions;

export default MachinesSlice.reducer;
