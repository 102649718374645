export const convertObjectFilter = (objectFilter, Prefix = 'var-') => {
  return {
    [`${Prefix}actor`]: objectFilter?.actor?.name?.toLowerCase().replace(/\s+/g, '_') ?? '*',
    [`${Prefix}module`]: objectFilter?.module?.value ?? '*',
    // [`${Prefix}actionEvent`]: objectFilter?.actionEvent?.name?.toLowerCase().replace(/\s+/g, '_') ?? '*',
    [`${Prefix}actionEvent`]: objectFilter?.actionEvent?.name.replace(/\s/g, ' ') ?? '*',
    [`${Prefix}user_id`]: objectFilter?.actor?.value ?? '*',
    from: objectFilter?.from ?? '',
    to: objectFilter?.to ?? ''
  }
}

export const createURL = (baseURL, params) => {
  const url = new URL(baseURL);

  // Iterate over the params object and append each parameter
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      url.searchParams.append(key, params[key] ? params[key] : '');
    }
  }

  return url.toString();
};

export const getFormatDate = (dateTime, type) => {
  if (!dateTime) {
    return;
  }

  if (dateTime && (typeof dateTime === 'string' || dateTime instanceof String)) {
    const [year, month, day] = dateTime.split('/');
    return `${year}-${month}-${day}`;
  }

  const month = dateTime?.getMonth() + 1;
  const day = dateTime?.getDate();
  const year = dateTime?.getFullYear();
  switch (type) {
    case 'be':
      return `${year}-${month}-${day}`;

    case 'fe':
      return `${month}-${day}-${year}`;

    default:
      break;
  }
};


