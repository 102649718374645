import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import debounce from 'lodash/debounce';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
}));

export default function SearchBar({ searchFunction, onApplySearch }) {
  const defaultValues = {
    searchText: '',
  };

  const {
    reset,
    // handleSubmit,
    control,
    // getValues,
    formState: { isDirty },
  } = useForm({ mode: 'all', defaultValues });

  const handleReset = () => {
    reset(defaultValues);
    if (searchFunction) {
      searchFunction('');
    }
    handleTextChange('');
  };

  const handleTextChange = (item) => {
    if (searchFunction) {
      searchFunction(item);
      onApplySearch(item);
    }
  };

  const debouncedHandleTextChange = debounce(handleTextChange, 400);

  return (
    <Search>
      <Controller
        name='searchText'
        control={control}
        rules={{
          required: true,
          validate: {
            required: (v) => !!v.trim(),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            sx={{
              width: '55ch',
            }}
            id='searchText'
            value={value}
            placeholder='Enter text to search'
            onChange={(event) => {
              debouncedHandleTextChange(event.target.value);
              onChange(event.target.value);
            }}
            inputProps={{
              maxLength: 100,
            }}
            InputProps={{
              maxLength: 255,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ color: 'black' }} />
                </InputAdornment>
              ),
              endAdornment: isDirty && (
                <InputAdornment position='end'>
                  <IconButton onClick={handleReset} size='small' aria-label='filter' color='inherit'>
                    <ClearIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Search>
  );
}

SearchBar.propTypes = {
  searchFunction: PropTypes.func,
  onApplySearch: PropTypes.func,
};
