import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, TextField } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import ENV_VARIABLES from 'config/variables';
import axios from 'axios';
export default function InfoCustomerFilterActivityTracking({ }) {

  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customers.filteredCustomers);

  const { t } = useTranslation();

  const { control, getValues, setValue, formState, clearErrors } = useFormContext();

  const { errors } = formState;

  const [hcpSingle, setHcpSingle] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);

  const token = localStorage.getItem('access_token');

  const customerState = getValues('customer');
  useEffect(() => {
    if (customerState) {
      axios
        .get(`${ENV_VARIABLES.API_SERVER}hcps/${customerState.id}/offices`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((resp) => {
          let offices = [{ office_name: 'All Offices', id: -1, hcp_id: -1, office_address: 'All Addresses', office_city: null, office_state: null, office_zip_code: null }];
          if (resp.data) {
            offices = [...offices, ...resp.data.data];
          }
          setOfficeOptions(offices);
        }).catch((error) => {
          console.error('Error fetching offices:', error);
        });;
    }

    return () => {
    }
  }, [customerState]);


  useEffect(() => {
    if (hcpSingle) {
      setOfficeOptions([]);
      axios
        .get(`${ENV_VARIABLES.API_SERVER}hcps/${hcpSingle.id}/offices`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((resp) => {
          let offices = [{ office_name: 'All Offices', id: -1, hcp_id: -1, office_address: 'All Addresses', office_city: null, office_state: null, office_zip_code: null }];
          if (resp.data) {
            offices = [...offices, ...resp.data.data];
          }
          setOfficeOptions(offices);
        }).catch((error) => {
          console.error('Error fetching offices:', error);
        });;
      return;
    }

    return () => {
    }
  }, [hcpSingle]);

  return (
    <Box sx={{ display: 'flex', gap: 3, width: '100%', mr: 3 }}>
      <Box sx={{ minWidth: '200px' }}>
        <Controller
          name='customer'
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ width: '100%' }} size='small'>
              <Autocomplete
                getOptionLabel={(option) => (option ? `${option.hcp_name}` : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    {option.hcp_name}
                  </Box>
                )}
                noOptionsText={t('common:noOptionsDefault')}
                options={customers}
                value={value}
                onChange={(event, item) => {
                  onChange(item);
                  setHcpSingle(item);
                  if (item) {
                    setValue('office', { office_name: 'All Offices', id: -1, hcp_id: -1, office_address: 'All Addresses', office_city: null, office_state: null, office_zip_code: null });
                    clearErrors('office');
                    return;
                  } else {
                    setValue('office', null);
                    setOfficeOptions([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.customer}
                    helperText={!!errors.customer && t('common:errors.required')}
                    autoComplete='customer'
                    label={t('customer:hcp.customer')}
                  />
                )}
              />
            </FormControl>
          )}
        />
      </Box>
      <Box sx={{ minWidth: '200px' }}>
        <Controller
          name='office'
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ width: '100%' }} size='small'>
              <Autocomplete
                getOptionLabel={(option) => (option ? `${option.office_name}` : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    {option.office_name}
                  </Box>
                )}
                noOptionsText={t('common:noOptionsDefault')}
                options={officeOptions}
                defaultValue={officeOptions[0]}
                value={value}
                onChange={(event, item) => {
                  onChange(item);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.office}
                    helperText={!!errors.office && t('common:errors.required')}
                    autoComplete='office'
                    label={t('customer:hcp.office')}
                  />
                )}
              />
            </FormControl>
          )}
        />
      </Box>
    </Box>
  );
}

InfoCustomerFilterActivityTracking.propTypes = {
};