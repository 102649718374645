

import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import axios from 'axios';
import ENV_VARIABLES from 'config/variables';
import { allowAlphaNumericSpace, getNameErrorMessage } from 'helpers';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import InfoListAssignMachine from './InfoListAssignMachine';

export default function MainOfficeCpn({ isEditCustomer, customer, dataMainOffice }) {
  const { t } = useTranslation();

  const { control, formState, watch, getValues, setValue, resetField, setError, reset } = useFormContext();
  const states = useSelector((state) => state.customers.states);
  const [stateSingle, setStateSingle] = useState(null);
  const [citySingle, setCitySingle] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);

  const { errors } = formState;

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  }

  useEffect(() => {

    let statesDefaults = [{ name: 'None', iso2: -1 }];
    if (states) {
      statesDefaults = [...statesDefaults, ...states];
    }

    setStatesOptions(statesDefaults);
    return () => {

    }
  }, [states]);


  useEffect(() => {
    const stateValue = getValues('state');
    if (stateValue) {
      const state = statesOptions.find((state) => {
        return (state.name === stateValue) || (state.name === stateValue.name)
      });
      setStateSingle(state);
    }
  }, [statesOptions]);

  useEffect(() => {
    const cityValue = getValues('city');
    if (citiesOptions) {
      if (cityValue) {
        const city = citiesOptions.find((city) => {
          return (city.name === cityValue) || (city.name === cityValue.name)
        });
        setCitySingle(city)
      }
    }
    return () => {
    }
  }, [citiesOptions]);

  useEffect(() => {
    if (stateSingle) {
      setCitiesOptions([]);
      setLoadingCities(true);
      axios
        .get(`${ENV_VARIABLES.API_SERVER}location/countries/US/regions/${stateSingle.iso2}/cities?limit=9999`)
        .then((resp) => {
          let cities = [{ name: 'None', id: -1 }];
          if (resp.data) {
            cities = [...cities, ...resp.data];
          }
          setCitiesOptions(cities);
          setLoadingCities(false);
        });
      return;
    }

    return () => {

    }
  }, [stateSingle]);

  useEffect(() => {
    setTimeout(() => {

    }, 1000);
  }, []);

  return (
    <Box>
      <Controller
        name='mainOfficeName'
        // control={control}
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            error={!!errors.mainOfficeName}
            helperText={errors.mainOfficeName && t('common:errors.required')}
            disabled={true}
            margin='normal'
            fullWidth
            id='mainOfficeName'
            value={'Main Office (Default)'}
            // label={t('customer:office.mainOfficeName')}
            onChange={onChange}
            inputProps={{
              maxLength: 255,
            }}
          />
        )}
      />

      <Controller
        name='address'
        control={control}
        rules={{
          required: true,
          validate: {
            required: (v) => !!v.trim(),
          },
        }}
        render={({ field: { onChange, value } }) => (
          <TextField
            error={!!errors.address}
            helperText={getNameErrorMessage(errors.address, t)}
            margin='normal'
            fullWidth
            id='address'
            value={value}
            label={t('customer:office.officeAddress')}
            onChange={onChange}
            inputProps={{
              maxLength: 255,
            }}
          />
        )}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '35%',
          }}
        >
          <Controller
            name='state'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id='state'
                getOptionLabel={(option) => (option ? `${option.name}` : '')}
                isOptionEqualToValue={(option, value) =>
                  value === undefined || value === '' || option.id === value.id
                }
                renderOption={(props, option) => (
                  <Box component='li' {...props} key={option.id}>
                    {option.name}
                  </Box>
                )}
                value={stateSingle}
                onChange={(event, item) => {
                  onChange(item);
                  setStateSingle(item);
                  setCitySingle(undefined);
                  setValue('city', undefined);
                }}
                options={statesOptions}
                noOptionsText={t('common:noOptions')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete='state'
                    error={!!errors.state}
                    margin='normal'
                    id='state'
                    helperText={!!errors.state && t('common:errors.required')}
                    label={t('common:state')}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: '35%',
            '& .Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #d32f2f',
            },
          }}
        >
          <Controller
            name={'city'}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  id='city'
                  getOptionLabel={(option) => (option ? `${option.name}` : '')}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === '' || option.id === value.id
                  }
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  disabled={!stateSingle}
                  noOptionsText={t('common:noOptions')}
                  options={citiesOptions}
                  value={citySingle}
                  onChange={(event, item) => {
                    onChange(item);
                    setCitySingle(item);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete='new-password'
                      error={!!errors.city}
                      margin='normal'
                      id='city'
                      helperText={!!errors.city && getNameErrorMessage(errors.city, t)}
                      label={t('common:city')}
                      inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <>
                            {loadingCities ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )
            }}
          />
        </Box>

        <Box
          sx={{
            width: '20%',
          }}
        >
          <Controller
            name='zipCode'
            control={control}
            rules={{
              required: true,
              validate: {
                required: (v) => !!v.trim(),
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <TextField
                  error={!!errors.zipCode}
                  helperText={getNameErrorMessage(errors.zipCode, t)}
                  margin='normal'
                  fullWidth
                  id='zipCode'
                  value={value}
                  label={t('common:zipCode')}
                  onChange={onChange}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
              )
            }}
          />
        </Box>
      </Box>
      {
        (isEditCustomer && dataMainOffice) ? <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
          <InfoListAssignMachine office={dataMainOffice} openCollapse={openCollapse} handleCollapse={handleCollapse} />
        </Box> :

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
            <Typography>{`Assigned Machines: ${0}`}</Typography>
            {openCollapse ? <ArrowDropUpIcon sx={{ fontSize: '28px' }} onClick={handleCollapse} /> : <ArrowDropDownIcon sx={{ fontSize: '28px' }} onClick={handleCollapse} />}
          </Box>
      }
    </Box>
  )
}

MainOfficeCpn.propTypes = {
  isEditCustomer: PropTypes.bool,
  customer: PropTypes.any,
  dataMainOffice: PropTypes.any
};


