import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ScrollDialog({
  noActions,
  maxWidth,
  message,
  title,
  fullWidth,
  hasCloseIcon = true,
  primaryActionText,
  secondaryActionText,
  openModal,
  handleSubmitSecondaryAction,
  handleSubmitModal,
  handleCloseModal,
  backdropProps,
}) {
  const { t } = useTranslation();
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (openModal) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openModal]);

  const closeModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  const onCloseModal = () => {
    if (handleCloseModal) {
      handleCloseModal();
    }
  };

  const onSubmit = () => {
    if (handleSubmitModal) {
      handleSubmitModal();
    }
  };

  const onSubmitSecondaryAction = () => {
    if (handleSubmitSecondaryAction) {
      handleSubmitSecondaryAction();
    }
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth || 'xl'}
      scroll={'paper'}
      BackdropProps={backdropProps}
      onClose={closeModal}
      open={openModal}
    >
      <DialogTitle id='scroll-dialog-title'>
        {title}
        {hasCloseIcon && (
          <IconButton
            aria-label='close'
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
        {message}
      </DialogContent>
      {!noActions && (
        <DialogActions>
          {secondaryActionText ? (
            <Button variant='outlined' onClick={onSubmitSecondaryAction}>
              {secondaryActionText || t('common:cancel')}
            </Button>
          ) : null}
          {primaryActionText ? (
            <Button variant='contained' onClick={onSubmit} autoFocus>
              {primaryActionText || t('common:save')}
            </Button>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  );
}

ScrollDialog.propTypes = {
  maxWidth: PropTypes.string,
  handleSubmitSecondaryAction: PropTypes.func,
  handleSubmitModal: PropTypes.func,
  handleCloseModal: PropTypes.func,
  message: PropTypes.any,
  title: PropTypes.string,
  primaryActionText: PropTypes.string,
  openModal: PropTypes.bool,
  secondaryActionText: PropTypes.string,
  backdropProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  noActions: PropTypes.bool,
};
