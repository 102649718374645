import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DETECTED_MACHINE_STATUS } from 'modules/machines/constants';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { NewMachinesList } from 'modules/machines/components/List';

import { getDateTime } from 'lib/datetime';
import { getMachineConfigureStatus } from 'lib/getStatus';
import ConfigureMachine from './ConfigureMachine';
import { useSocketDataContext } from 'components/WebSocketProvider';
import { fetchDetectedMachines } from 'modules/machines/slice';

import { openDialog } from 'lib/dialog.js';
import { DIALOGS } from 'modules/dialogs/constants';
import socketActions from 'components/WebSocketProvider/actions';

export default function NewList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const socketContext = useSocketDataContext();
  const machines = useSelector((state) => state.machines.detectedMachines);

  const [detectedMachines, setDetectedMachines] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [configuredMachines, setConfiguredMachines] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('detected_at');

  const createData = (originalData, converterId, macAddress, detectedAt, status) => {
    return {
      originalData,
      converterId,
      macAddress,
      detectedAt,
      status,
    };
  };

  // transform machines data to table data
  const data = detectedMachines.map((machine) => {
    return createData(
      machine, // original  use this for actions on the table such as delete, restore, suspend
      machine.u12_id,
      machine.mac_address,
      machine.detected_at,
      machine.status
    );
  });

  // dataField has to match fields in createData function
  const headers = [
    {
      id: 'converterId',
      title: t('machine:machineTable.converterId'),
      dataField: 'converterId',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '20%',
    },
    {
      id: 'macAddress',
      title: t('machine:machineTable.macAddress'),
      dataField: 'macAddress',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '20%',
    },
    {
      id: 'detected_at',
      title: t('machine:machineTable.detectedOn'),
      dataField: 'detectedAt',
      align: 'left',
      disablePadding: false,
      sortable: true,
      width: '20%',
      format: (value) => getDateTime(value),
    },
    {
      id: 'status',
      title: t('admin:customersTable.status'),
      dataField: 'status',
      align: 'center',
      sortable: false,
      disablePadding: false,
      width: '20%',
      format: (value) => getMachineConfigureStatus(value, t),
    },
  ];

  const onConfigureMachine = (row) => {
    setSelectedRow(row);
    handleOpenConfigureMachineDialog();
  };

  const handleOpenConfigureMachineDialog = () => {
    openDialog(dispatch, DIALOGS.CONFIGURE_MACHINE_DIALOG);
  };

  const actions = [
    {
      title: t('machine:machineTable.configure'),
      callback: onConfigureMachine,
    },
  ];

  const getActions = (row) => {
    return actions.map((action) => {
      return (
        <Button
          disabled={
            row.status === DETECTED_MACHINE_STATUS.COMPLETED || row.status === DETECTED_MACHINE_STATUS.CONFIGURING
          }
          variant='contained'
          key={action.title}
          onClick={() => {
            action.callback(row);
          }}
        >
          {action.title}
        </Button>
      );
    });
  };

  useEffect(() => {
    getMachines();
  }, [orderBy, order]);

  // modify the data to be displayed in the table
  // added completed machines to the table or update the status of the machine if it is already in the table
  useEffect(() => {
    const updatedMachines = [...machines];
    if (configuredMachines.length > 0) {
      configuredMachines.forEach((cm) => {
        const index = machines.findIndex((m) => cm.u12_id === m.u12_id);
        if (index > -1) {
          updatedMachines[index] = { ...updatedMachines[index], status: DETECTED_MACHINE_STATUS.COMPLETED };
        } else {
          updatedMachines.push(cm);
        }
      });
    }

    setDetectedMachines(updatedMachines);
  }, [machines, configuredMachines]);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};

    if (!message || !message.u12_id) {
      return;
    }

    if (message.signal === socketActions.NEW_MACHINE_CONFIGURED) {
      // find machine in detected machines where u12_id matches with message.u12_id
      const configuredMachine = detectedMachines.find((machine) => machine.u12_id === message.u12_id);

      if (configuredMachine) {
        setConfiguredMachines((prev) => [...prev, { ...configuredMachine, status: DETECTED_MACHINE_STATUS.COMPLETED }]);
      }
    } else if (message.signal === socketActions.CONFIGURATION_FAILED) {
      getMachines();
    }
  }, [socketContext.messageHistory]);

  const getMachines = () => {
    let data = {};
    data = { ...data, orderBy, order };

    dispatch(fetchDetectedMachines(data));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box>
      <ConfigureMachine selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      <NewMachinesList
        headers={headers}
        data={data}
        order={order}
        actions={actions}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        overrideGetActions={getActions}
      />
    </Box>
  );
}
