import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import InviteCustomerDialog from 'modules/customers/components/InviteCustomerDialog';

import { closeDialog } from 'lib/dialog.js';
import { DIALOGS } from 'modules/dialogs/constants';
import { fetchStates, requestInviteCustomer, requestEditCustomer, clearSuccessAndError } from 'modules/customers/slice';

export default function InviteCustomer({ selectedRow, isEditCustomer, setSelectedRow, sortData }) {
  const dispatch = useDispatch();
  const openInviteCustomerDialog = useSelector((state) => state.dialogs[DIALOGS.INVITE_CUSTOMER]);
  const error = useSelector((state) => state.customers.error);
  const states = useSelector((state) => state.customers.states);
  const createdCustomer = useSelector((state) => state.customers.createdCustomer);

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  const handleCloseInviteCustomerDialog = () => {
    closeDialog(dispatch, DIALOGS.INVITE_CUSTOMER);
  };

  const onSubmitInviteCustomerDialog = (data) => {
    // keep the current sort
    if (sortData) {
      data.sortData = sortData;
    }
    if (isEditCustomer) {
      dispatch(
        requestEditCustomer({
          ...data,
          email: data.email.toLowerCase(),
        })
      );
    } else {
      dispatch(requestInviteCustomer({ ...data, email: data.email.toLowerCase() }));
    }
    // set this data for confirm dialog
    if (setSelectedRow) {
      setSelectedRow(data);
    }
  };

  // functions for confirm invite customer dialog
  const onAddMoreCustomer = () => {
    dispatch(clearSuccessAndError());
  };

  return (
    <InviteCustomerDialog
      anchor='right'
      customer={selectedRow}
      createdCustomer={createdCustomer}
      states={states}
      isEditCustomer={isEditCustomer}
      onAddMoreCustomer={onAddMoreCustomer}
      onSubmitInviteCustomerDialog={onSubmitInviteCustomerDialog}
      error={error}
      openInviteCustomerDialog={openInviteCustomerDialog}
      handleCloseInviteCustomerDialog={handleCloseInviteCustomerDialog}
    />
  );
}

InviteCustomer.propTypes = {
  selectedRow: PropTypes.object,
  isEditCustomer: PropTypes.bool,
  setSelectedRow: PropTypes.func,
  sortData: PropTypes.object,
};

// format offices data
// if (data.offices && data.offices.length > 0) {
//   const offices = data.offices.map((o) => {
//     if (typeof o.id === 'number') {
//       return {
//         id: o.id,
//         office_name: o.name,
//         office_address: '',
//       };
//     } else {
//       return {
//         office_name: o.name,
//         office_address: '',
//       };
//     }
//   });
//   data.offices = offices;
// }