import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Collapse, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TableViewActivityTracking from './TableViewActivityTracking';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { updateDataActivityTracking } from '../slice';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export default function InfoDetailCustomer({ }) {

  const [openCollapse, setOpenCollapse] = useState(true);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const dataActivityTracking = useSelector(state => state.activityTracking.dataActivityTracking);
  const dataCustomerInfoActivityTracking = useSelector(state => state.activityTracking.dataCustomerInfoActivityTracking);

  const dataCustomer = dataCustomerInfoActivityTracking?.customer;
  const dataOffice = dataCustomerInfoActivityTracking?.office;

  const isMainOffice = dataOffice?.is_main_office;

  const getActivityTrackings = () => {
    if (!isEmpty(dataActivityTracking)) {
      const data = [...dataActivityTracking];
      const resultSort = sortDataHandler(data);
      dispatch(updateDataActivityTracking({ resultSort }))
    }
  };

  useEffect(() => {
    getActivityTrackings();
  }, [orderBy, order]);

  const sortDataHandler = (data) => {
    const dataToSort = [...data];
    return dataToSort?.sort((a, b) => {
      // if (order === 'asc') {
      //   return a[orderBy] - b[orderBy];
      // } else {
      //   return b[orderBy] - a[orderBy];
      // }
      if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
        // Handle numerical sorting
        return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
      } else {
        // Handle string sorting
        return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
      }
    });
  }

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  }

  let totalIsometricTestCount = 0;
  let totalDynamicExerciseCount = 0;
  let totalMachineCount = 0;
  let totalSessionCount = 0;
  let totalPatientSetupCount = 0;
  const dataRowTotal = {};

  if (!isEmpty(dataActivityTracking)) {
    dataActivityTracking?.forEach(item => {
      totalIsometricTestCount += item.isometric_test_count;
      totalDynamicExerciseCount += item.dynamic_exercise_count;
      totalSessionCount += item.session_count;
      totalPatientSetupCount += item.patient_setup_count;
    });
    totalMachineCount += dataActivityTracking?.length;
    dataRowTotal.machine_id = -1;
    dataRowTotal.name = (() => <span>Total number: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{totalMachineCount}</span>)();
    dataRowTotal.session_count = totalSessionCount;
    dataRowTotal.patient_setup_count = totalPatientSetupCount;
    dataRowTotal.isometric_test_count = totalIsometricTestCount;
    dataRowTotal.dynamic_exercise_count = totalDynamicExerciseCount;

  }

  const createData = (id, name, session_count, patient_setup_count, isometric_test_count, dynamic_exercise_count) => {
    return {
      id,
      name,
      session_count,
      patient_setup_count,
      isometric_test_count,
      dynamic_exercise_count
    };
  };

  const data = useMemo(() =>
    dataActivityTracking?.map((activity) => {
      return createData(
        activity.machine_id,
        activity.name,
        activity.session_count,
        activity.patient_setup_count,
        activity.isometric_test_count,
        activity.dynamic_exercise_count,
      );
    }), [dataActivityTracking]);

  // dataField has to match fields in createData function
  const headers = [
    {
      id: 'name', //use for sorting
      title: t('user:activityTrackingTable.name'),
      dataField: 'name',
      align: 'left',
      disablePadding: false,
      sortable: true,
      width: '30%',
    },
    {
      id: 'session_count',
      title: t('user:activityTrackingTable.session'),
      dataField: 'session_count',
      align: 'center',
      disablePadding: false,
      sortable: true,
      width: '15%',
    },
    {
      id: 'patient_setup_count',
      title: t('user:activityTrackingTable.patientSetup'),
      dataField: 'patient_setup_count',
      align: 'center',
      disablePadding: false,
      sortable: true,
      width: '15%',
    },
    {
      id: 'isometric_test_count',
      title: t('user:activityTrackingTable.isometric'),
      dataField: 'isometric_test_count',
      align: 'center',
      disablePadding: false,
      sortable: true,
      width: '15%',
    },
    {
      id: 'dynamic_exercise_count',
      title: t('user:activityTrackingTable.dynamic'),
      dataField: 'dynamic_exercise_count',
      align: 'center',
      disablePadding: false,
      sortable: true,
      width: '15%',
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renderInfoOfficeAddress = () => {
    if (!dataCustomerInfoActivityTracking) {
      return;
    }

    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', width: '25%', flexDirection: 'column', mr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 400, color: '#868E96', fontSize: '14px', minWidth: '70px' }}>{`Customer: `}</Typography>
            <Typography sx={{ fontWeight: 400, color: '#212529', fontSize: '14px' }}>{dataCustomer?.hcp_name}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 400, color: '#868E96', fontSize: '14px', minWidth: '70px' }}>Address:</Typography>
            <Typography sx={{ fontWeight: 400, color: '#212529', fontSize: '14px' }}>{`${dataCustomer?.address}, ${dataCustomer?.city}, ${dataCustomer?.state} ${dataCustomer?.zip_code}`}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '25%', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 400, color: '#868E96', fontSize: '14px', minWidth: '70px' }}>Office:</Typography>
            <Typography sx={{ fontWeight: 400, color: '#212529', fontSize: '14px' }}>{dataOffice?.office_name}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ fontWeight: 400, color: '#868E96', fontSize: '14px', minWidth: '70px' }}>Address:</Typography>
            {dataOffice?.id < 0 ?
              <Typography sx={{ fontWeight: 400, color: '#212529', fontSize: '14px' }}>{`${dataOffice?.office_address ?? ''}`}</Typography>
              :
              <Typography sx={{ fontWeight: 400, color: '#212529', fontSize: '14px' }}>{isMainOffice ? dataCustomer?.address : dataOffice?.office_address}, {isMainOffice ? dataCustomer?.city : dataOffice?.office_city}, {isMainOffice ? dataCustomer?.state : dataOffice?.office_state} {isMainOffice ? dataCustomer?.zip_code : dataOffice?.office_zip_code}</Typography>
            }
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', padding: 2, background: '#ffffff', borderRadius: '8px', width: '100%', flexDirection: 'column', maxHeight: 'calc(100vh - 300px)', minHeight: 'calc(100vh - 300px)' }}>
      <Box sx={{ position: 'sticky', top: '50px', zIndex: '1000' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#343A40' }} >Customer Information</Typography>
            {openCollapse ? <ArrowDropUpIcon sx={{ fontSize: '28px' }} onClick={handleCollapse} /> : <ArrowDropDownIcon sx={{ fontSize: '28px' }} onClick={handleCollapse} />}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#495057', mr: 2 }} >Total Activities</Typography>
            <Box sx={{ width: '135px', backgroundColor: '#FAE1B2', color: '#000', p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', fontWeight: 600, fontSize: '18px' }}>{`${totalIsometricTestCount + totalDynamicExerciseCount}`}</Box>
          </Box>
        </Box>
        {
          dataCustomerInfoActivityTracking &&
          <Collapse sx={{ mb: 3 }} in={openCollapse}>
            {renderInfoOfficeAddress()}
          </Collapse>
        }
      </Box>
      <TableViewActivityTracking
        headers={headers}
        data={data}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        dataRowTotal={dataRowTotal}
      />
    </Box>
  );
}

InfoDetailCustomer.propTypes = {
};


