import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';

export default function TableViewActivityTrackingPDF({
  headers,
  data,
  onRequestSort,
  order,
  orderBy,
  dataRowTotal
}) {
  const { t } = useTranslation();

  const isLoadingActivity = useSelector(state => state.activityTracking.isLoadingActivity);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getHeaders = () => {
    return headers?.map((header) => (
      <TableCell
        sx={{
          width: header.width,
          border: '1px solid #CED4DA',
          fontWeight: 600,
          fontSize: '12px'
        }}
        padding={header.disablePadding ? 'none' : 'normal'}
        align={header.align}
        key={header.title}
        sortDirection={orderBy === header.id ? order : false}
      >
        {header.sortable ? (
          <TableSortLabel
            active={orderBy === header.id}
            direction={orderBy === header.id ? order : 'asc'}
            onClick={createSortHandler(header.id)}
          >
            {orderBy === header.id ? (
              <Box component='span' sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
            {header.title}
          </TableSortLabel>
        ) : (
          header.title
        )}
      </TableCell>
    ));
  };

  const getRows = () => {
    if (isLoadingActivity) {
      return <TableRow>
        <TableCell
          colSpan={getHeaders().length}
          align='center'
        >
          <CircularProgress size={24} />
        </TableCell>
      </TableRow>
    }

    return data?.map((row, i) => {
      return (
        <TableRow key={row.name + i}>
          {Object.keys(row)?.map((key) => {
            return headers?.map((header) => {
              if (header.dataField === key) {
                return (
                  <TableCell sx={{ border: '1px solid #CED4DA', background: '#fff', fontSize: '12px', fontWeight: 400 }} align={header.align} key={key}>
                    {header.format ? header.format(row[key]) : row[key]}
                  </TableCell>
                );
              }
            });
          })}
        </TableRow>
      )
    });
  };

  const getRowTotal = () => {
    return <TableRow sx={{ background: '#FAE1B2' }}>
      {Object.keys(dataRowTotal)?.map((key) => {
        return headers?.map((header) => {
          if (header.dataField === key) {
            return (
              <TableCell sx={{ border: '1px solid #CED4DA', fontSize: '12px', fontWeight: 600 }} align={header.align} key={key}>
                {header.format ? header.format(dataRowTotal[key]) : dataRowTotal[key]}
              </TableCell>
            );
          }
        });
      })}
    </TableRow>
  };

  const renderRowBody = () => {

    if (isLoadingActivity) {
      return <TableRow>
        <TableCell
          colSpan={getHeaders().length}
          align='center'
        >
          <CircularProgress size={24} />
        </TableCell>
      </TableRow>
    }

    return [
      getRowTotal(),
      ...getRows()
    ]
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ minHeight: isEmpty(data) ? 'calc(52vh)' : '' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {getHeaders()}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isEmpty(data) ? <TableRow>
                <TableCell
                  sx={{
                    padding: 0, // Remove padding to allow the Box to fill the cell completely
                    borderBottom: 'none', // Optionally remove the cell's bottom border for a cleaner look
                    height: 'calc(500px)',
                    fontSize: '16px',
                    color: '#868E96',
                    fontWeight: 400,
                    background: '#fff',
                    border: '0.5px solid #CED4DA'
                  }}
                  colSpan={getHeaders().length}
                  align='center'
                >
                  No data for selected customer and office within specified dates.
                </TableCell>
              </TableRow>
                :
                renderRowBody()
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper >
  );
}

TableViewActivityTrackingPDF.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  dataRowTotal: PropTypes.object
};