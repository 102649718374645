import PropTypes from 'prop-types';
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import Skeleton from 'components/Skeleton/InviteCustomerDialog';
import AddOffice from './AddOffice';
import ENV_VARIABLES from 'config/variables';
import SuccessCreateCustomer from './SuccessCreateCustomer';
import CustomerCpn from './CustomerCpn';
import BusinessCpn from './BusinessCpn';
import MainOfficeCpn from './MainOfficeCpn';
import ListOffice from './ListOffice';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from '@mui/material';
import isNil from 'lodash/isNil';

const drawerWidth = '50%';
const mainRoute = '/manage/customers';
export default function InviteCustomerDialog({
  states,
  customer,
  createdCustomer,
  isEditCustomer,
  anchor,
  error,
  onAddMoreCustomer,
  onSubmitInviteCustomerDialog,
  openInviteCustomerDialog,
  handleCloseInviteCustomerDialog,
}) {
  const location = useLocation();
  const { t } = useTranslation();

  const [defaultValues, setDefaultValues] = useState({
    hcpName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    state: '',
    city: '',
    zipCode: '',
    subDomain: '',
    offices: [],
  })

  const resetValues = {
    hcpName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    state: '',
    city: '',
    zipCode: '',
    subDomain: '',
    offices: [],
  }

  const methods = useForm({
    mode: 'all',
    defaultValues
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccessState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenedOutside, setIsOpenedOutside] = useState(false);
  // const [offices, setOffices] = useState([]);
  const [isOfficeChanged, setIsOfficeChanged] = useState(false);


  const { fields, remove, append } = useFieldArray({
    name: 'offices',
    keyName: 'uuid',
    control: methods.control,
    rules: {
      validate: (value) => {
        const exist = new Set();

        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          if (element.office_name !== '' && !isNil(element.office_name) && exist.has(element.office_name.trim().toLowerCase())) {
            return `${index}`
          }
          exist.add(element.office_name.trim().toLowerCase());
        }
        return true;
      }
    }
  });


  // useEffect(() => {
  //   if (openInviteCustomerDialog && location && location.pathname !== mainRoute) {
  //     setIsOpenedOutside(true);
  //   }

  //   // if (isEditCustomer && customer && customer.offices) {
  //   //   const offices = customer.offices.map((d) => ({
  //   //     id: d.id,
  //   //     officeAddress: d.office_address,
  //   //     officeCode: d.office_zip_code,
  //   //     name: d.office_name,
  //   //   }));
  //   //   setOffices(offices);
  //   // }

  //   if (openInviteCustomerDialog) {
  //     methods.reset(defaultValues);
  //     // Reset form to initial values
  //     // methods.reset({
  //     //   mainOfficeName: 'Main Office (Default)'
  //     // });
  //   } else {
  //     // setOffices([]);
  //     setIsOpenedOutside(false);
  //   }
  // }, [openInviteCustomerDialog]);

  useEffect(() => {
    if (error && error.code === 400 && error.error.subcode === 100) {
      methods.setError('email', {
        type: 'email_exists',
      });
    }
    if (error && !isEditCustomer && error.error === 'User existing.') {
      methods.setError('email', {
        type: 'email_exists',
      });
    }
    if (error && !isEditCustomer && error.error === 'Subdomain already taken.') {
      methods.setError('subDomain', {
        type: 'domain_exists',
      });
    }
  }, [error]);

  // useEffect(() => {
  //   function checkIds(arr1, arr2) {
  //     if (arr1.length !== arr2.length) {
  //       // The arrays have different lengths, so they cannot have the same IDs
  //       return false;
  //     }

  //     // Use the `every` method to check that every object in each array has the same ID as the corresponding object in the other array
  //     return arr1.every((obj, index) => obj.id === arr2[index].id);
  //   }

  //   if (openInviteCustomerDialog && isEditCustomer) {
  //     setIsOfficeChanged(!checkIds(offices, customer.offices));
  //   }
  // }, [offices]);

  //when create new customer success
  useEffect(() => {
    if (createdCustomer && createdCustomer.id) {
      setSuccessState(true);
    }
  }, [createdCustomer]);

  useEffect(() => {
    if (customer && isEditCustomer) {

      const fieldsFilterMain = (customer?.offices || [])?.filter((field) => !field.is_main_office);

      const newDefaultValues = {
        hcpName: customer?.hcp_name,
        firstName: customer?.owner_info?.first_name,
        lastName: customer?.owner_info?.last_name,
        phoneNumber: customer?.owner_info?.phone_number,
        email: customer?.owner_info?.email,
        address: customer?.address,
        state: customer.state,
        city: customer.city,
        zipCode: customer?.zip_code,
        subDomain: customer?.sub_domain,
        offices: fieldsFilterMain,
      };

      setDefaultValues(newDefaultValues);
      methods.reset(newDefaultValues);
    }

    return () => {

    }
  }, [isEditCustomer]);

  const resetForm = () => {
    methods.reset(resetValues);
  };

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  const closeModal = () => {
    if (handleCloseInviteCustomerDialog) {
      handleCloseInviteCustomerDialog();
    }
    setSuccessState(false);
    methods.reset(resetValues);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    const officesAfterMap = [];

    data.offices.forEach((office) => {
      let officeFilter = office;
      if (office?.office_state?.name) {
        officeFilter = { ...officeFilter, office_state: office?.office_state?.name, office_address: office?.office_address?.trim(), office_name: office?.office_name.trim(), office_zip_code: office?.office_zip_code.trim() };
      }
      if (office?.office_city?.name) {
        officeFilter = { ...officeFilter, office_city: office?.office_city?.name, office_address: office?.office_address?.trim(), office_name: office?.office_name.trim(), office_zip_code: office?.office_zip_code.trim() };
      }

      officesAfterMap.push(officeFilter);
      return;

    })

    data.offices = officesAfterMap?.map((office) => {
      return { ...office, office_address: office?.office_address?.trim(), office_name: office?.office_name.trim(), office_zip_code: office?.office_zip_code.trim() }
    });
    data.address = data?.address.trim();
    data.firstName = data?.firstName.trim();
    data.lastName = data?.lastName.trim();
    data.hcpName = data?.hcpName.trim();
    data.zipCode = data?.zipCode.trim();

    if (isEditCustomer && customer) {
      data.city = data.city.name ?? customer?.city;
      data.state = data.state.name ?? customer?.state;
      // put id in for edit api
      data.id = customer.id;
      // put status back in to make sure status doesn't change
      data.status = customer.status;
      // when email is changed, the popup stays open
      // data from server is structured differently from data in the form
      // TODO: make the structure consistent
      if (
        (customer.owner_info && customer.owner_info.email !== data.email) ||
        (customer.email && customer.email !== data.email)
      ) {
        data.isNewInvite = true;
      }
    }
    if (onSubmitInviteCustomerDialog) {
      onSubmitInviteCustomerDialog(data);
    }
    setTimeout(() => {
      setSubmitting(false);
      methods.reset(resetValues);
    }, 1000);
  };

  const handleAddMoreCustomer = () => {
    resetForm();
    setSuccessState(false);
    if (onAddMoreCustomer) {
      onAddMoreCustomer();
    }
  };

  const handleAddOffice = () => {
    const newOfficeArr = methods.getValues('offices') ?? [];
    append({ id: null, office_name: '', office_address: '', office_state: '', office_city: '', office_zip_code: '', office_assign_machine: [], office_code: null, is_main_office: null });
  };

  const dataMainOffice = customer?.offices?.find((office) => office.is_main_office === true);

  const renderForm = () => {
    if (isLoading) {
      return <Skeleton />;
    }
    return (
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <Typography sx={{ fontSize: '1.5rem', pt: 2, fontWeight: 500 }} variant='h2'>
            {t('customer:inviteDialog.title')}
          </Typography>
          {!isEditCustomer && (
            <Typography sx={{ mb: 1 }} variant='body1'>
              {t('customer:inviteDialog.subtitle')}
            </Typography>
          )}
          <Box sx={{ mt: 4 }}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>About Customer</Typography>
              <CustomerCpn isEditCustomer={isEditCustomer} customer={customer} />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>About Business</Typography>
              <BusinessCpn isEditCustomer={isEditCustomer} />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>About Office</Typography>
              {
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {<MainOfficeCpn dataMainOffice={dataMainOffice} isEditCustomer={isEditCustomer} customer={customer} />}
                  <ListOffice remove={remove} fields={fields} />
                </Box>
              }
            </Box>
            <Box sx={{ mb: 2 }}>
            </Box>
            <Divider sx={{ mt: 2, mb: 2, borderBottomWidth: '2px' }} />
            <Box>
              <Button onClick={handleAddOffice} variant='outlined' sx={{ color: '#000000', borderColor: '#000000' }} startIcon={<AddIcon />} >Add Office</Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
          <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={methods.handleSubmit(onSubmit)}
            disabled={isEditCustomer && !(methods.formState.isDirty) && !isOfficeChanged}
            variant='contained'
          >
            {isEditCustomer ? t('common:save') : t('common:add')}
          </LoadingButton>
        </Box>
      </form>
    );
  };

  const renderContent = () => (
    <Box sx={{ width: '65%', position: 'relative' }} role='presentation'>
      {success && createdCustomer ? (
        <SuccessCreateCustomer closeModal={closeModal} createdCustomer={createdCustomer} isOpenedOutside={isOpenedOutside} handleAddMoreCustomer={handleAddMoreCustomer} />
      ) : (
        renderForm()
      )}
    </Box>
  );

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      anchor={anchor}
      open={openInviteCustomerDialog}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        {renderContent()}
      </FormProvider>

    </Drawer>
  );
}

InviteCustomerDialog.propTypes = {
  states: PropTypes.array,
  customer: PropTypes.object,
  createdCustomer: PropTypes.object,
  isEditCustomer: PropTypes.bool,
  anchor: PropTypes.string,
  error: PropTypes.object || PropTypes.number,
  onAddMoreCustomer: PropTypes.func,
  openInviteCustomerDialog: PropTypes.bool,
  handleCloseInviteCustomerDialog: PropTypes.func,
  onSubmitInviteCustomerDialog: PropTypes.func,
};

// useEffect(() => {
//   // customer exist means editing
//   if (customer && isEditCustomer) {
//     setIsLoading(true);
//     // auto fill the form
//     const state = states.find((state) => state.name === customer.state);
//     setLoadingCities(true);
//     axios
//       .get(`${ENV_VARIABLES.API_SERVER}location/countries/US/regions/${state?.iso2}/cities?limit=9999`)
//       .then((resp) => {
//         setIsLoading(false);
//         const cities = resp.data;
//         setCitiesOptions(cities);
//         setSelectedState(state);
//         methods.reset({
//           hcpName: customer.hcp_name,
//           firstName: customer.owner_info.first_name,
//           lastName: customer.owner_info.last_name,
//           phoneNumber: customer.owner_info.phone_number,
//           email: customer.owner_info.email,
//           address: customer.address,
//           state: state,
//           city: cities.find((city) => city.name === customer.city),
//           zipCode: customer.zip_code,
//           subDomain: customer.sub_domain,
//           offices: customer.offices
//         });
//         setLoadingCities(false);
//       });
//   }
// }, [isEditCustomer]);

{/* <Box>
<Box
  sx={{
    display: 'flex',
    color: '#2e7d32',
    pt: 5,
    pb: 3,
    alignItems: 'center',
  }}
>
  <CheckCircleOutlineRoundedIcon
    sx={{
      mr: 1,
      fontSize: '2.3rem',
    }}
  />
  <Typography
    sx={{
      fontSize: '1.1rem',
      fontWeight: 'bold',
    }}
  >
    {createdCustomer.isNewInvite
      ? t('customer:confirmInviteDialog.ownerChanged')
      : t('customer:confirmInviteDialog.newCustomer')}
  </Typography>
</Box>
<Typography variant='body2'>
  {t('customer:confirmInviteDialog.message')}
  <strong>
    {' '}
    &quot;{createdCustomer.owner_info.first_name} {createdCustomer.owner_info.last_name}&quot;
  </strong>{' '}
  at <strong>{createdCustomer.owner_info.email}</strong>.
</Typography>
{isOpenedOutside && (
  <Typography sx={{ mt: 1 }} variant='body2'>
    {t('customer:inviteDialog.toView')} <Link to={mainRoute}>{t('common:clickHere')}</Link>.
  </Typography>
)}
<Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
  <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
    {t('common:close')}
  </Button>
  {!createdCustomer.isNewInvite && (
    <Button onClick={handleAddMoreCustomer} variant='contained'>
      {t('customer:confirmInviteDialog.addAnotherHCP')}
    </Button>
  )}
</Box>
</Box> */}

