import Box from '@mui/material/Box';

export default function PasswordInstructions(t) {
  return (
    <Box>
      <br />
      {t('user:password.editDialog.subtitle')}
      <br />
      &#8226; {t('user:password.editDialog.passwordRules:0')}
      <br />
      &#8226; {t('user:password.editDialog.passwordRules:1')}
      <br />
      &#8226; {t('user:password.editDialog.passwordRules:2')}
      <br />
      &#8226; {t('user:password.editDialog.passwordRules:3')}
      <br />
      &#8226; {t('user:password.editDialog.passwordRules:4')}
    </Box>
  );
}
