import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { Drawer, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';

export default function UnassignMachineDialog({
  keep,
  title,
  confirmBtn,
  openUnassignDialog,
  handleCloseUnassignDialog,
  handleSubmitUnassignDialog,
  noCancel,
  icon,
  warningText,
  considerText,
  selectedRow
}) {
  const { t } = useTranslation();

  const closeModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    if (handleCloseUnassignDialog) {
      handleCloseUnassignDialog();
    }
  };

  const onSubmit = (e) => {
    e.currentTarget.disabled = true;
    if (handleSubmitUnassignDialog) {
      handleSubmitUnassignDialog();
    }
  };

  const renderContent = () => {
    return (
      <Dialog maxWidth='sm' onClose={!keep ? closeModal : undefined} open={openUnassignDialog}>
        <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
        <DialogContent>
          <Typography sx={{ color: '#000', mb: 2 }} variant='p' component='div'>
            {considerText}
          </Typography>
          <Typography sx={{ color: '#E03131', display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} variant='p' component='div'>
            {icon} {warningText}
          </Typography>
          <Typography sx={{ color: '#000' }} variant='p' component='div'>
            {`${t('machine:unassignMachineDialog.confirmText', {
              serialNo: selectedRow?.serial
            })}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          {noCancel ? null : (
            <Button variant='outlined' sx={{ border: '1px solid #e2e4e7' }} onClick={closeModal}>
              {t('common:cancel')}
            </Button>
          )}
          <Button variant='contained' sx={{ color: 'white' }} onClick={onSubmit} autoFocus>
            {confirmBtn || 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return renderContent();
}

UnassignMachineDialog.propTypes = {
  keep: PropTypes.bool,
  title: PropTypes.string,
  confirmBtn: PropTypes.string,
  openUnassignDialog: PropTypes.bool,
  handleCloseUnassignDialog: PropTypes.func,
  handleSubmitUnassignDialog: PropTypes.func,
  noCancel: PropTypes.bool,
  icon: PropTypes.element,
  warningText: PropTypes.string,
  considerText: PropTypes.string,
  selectedRow: PropTypes.object
}
