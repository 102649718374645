import authRoutes from './modules/auth/routes';
import bionexUsersRoutes from 'modules/bionexUsers/routes';
import customersRoutes from 'modules/customers/routes';
import machinesRoutes from 'modules/machines/routes';
import manageSoftware from 'modules/softwareUpdate/routes';
import manageAuditLog from 'modules/auditLogs/routes';
import manageActivityTracking from 'modules/activityTrackings/routes'

const router = [...authRoutes, ...bionexUsersRoutes, ...customersRoutes, ...machinesRoutes, ...manageSoftware, ...manageAuditLog, ...manageActivityTracking];
export default router;
