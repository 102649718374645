import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  requestLoginSuccess,
  requestLoginFailure,
  requestLogin,
  fetchUserInfo,
  fetchUserInfoSuccess,
  requestLogout,
  forceLogout,
  forceLogoutSuccess,
  requestLogoutSuccess,
  fetchUserInfoFailure,
  // for bionex users
  requestAcceptInvite,
  requestAcceptInviteSuccess,
  requestAcceptInviteFailure,
  requestCheckInviteToken,
  requestCheckInviteTokenSuccess,
  requestCheckInviteTokenFailure,
  requestForgotPassword,
  requestForgotPasswordSuccess,
  requestForgotPasswordFailure,
  requestCheckForgotPasswordToken,
  requestCheckForgotPasswordTokenSuccess,
  requestCheckForgotPasswordTokenFailure,
  requestResetUserPassword,
  requestResetUserPasswordSuccess,
  requestResetUserPasswordFailure,
  fetchRequireTwoFaSuccess,
  requestVerifyOtpSuccess,
  requestSetOpenModalReachedFirstLimit,
  requestSetOpenModalReachedLastLimit,
  requestVerifyOtpFailure,
  requestResendOtpSuccess,
  requestResendOtpFailure,
  requestVerifyOtp,
  requestResendOtp,
} from './slice';
import { END_POINT, NUMBER_ATTEMPT } from './constants';
import auth from './authenticator';

import { endLoading, requestToast } from 'modules/ui/slice';
import { StatusCodes } from 'http-status-codes';
import { TOAST_TYPE } from 'modules/ui/constants';
import isNil from 'lodash/isNil';

function* login(action) {
  try {
    const { data } = yield axios.post(END_POINT.login.url, {
      ...action.payload,
    });
    if (data.token) {
      auth.setAuth(data.token);
      yield put(fetchUserInfo({ token: data.token }));
      yield put(requestLoginSuccess(data.token));
    }
    if (isNil(data.token) && data.device_uuid) {
      auth.setHcpDeviceUuid(data.device_uuid);
      yield put(fetchRequireTwoFaSuccess(data.required_next_step));
    } else {
      yield put(requestLoginFailure(data));
    }
  } catch (e) {
    yield put(requestLoginFailure(e.message));
  }
}

function* verifyOtp(action) {
  try {
    const { data } = yield axios.post(END_POINT.verifyOpt.url, {
      ...action.payload,
    });
    if (data.token) {
      auth.setAuth(data.token);

      yield put(fetchUserInfo({ token: data.token }));
      yield put(requestLoginSuccess(data.token));
      yield put(requestVerifyOtpSuccess(data.is_success));
    } else {
      switch (data.remaining_attempts) {
        case NUMBER_ATTEMPT.REACHED_FIRST_LIMIT:
          yield put(requestSetOpenModalReachedFirstLimit(true));
          break;

        case NUMBER_ATTEMPT.REACHED_LAST_LIMIT:
          yield put(requestSetOpenModalReachedLastLimit(true));
          break;

        default:
          break;
      }
      yield put(requestVerifyOtpFailure(data));
    }
  } catch (e) {
    console.warn(e);
    //yield put(requestVerifyOtpFailure(e.message));
  }
}

function* resendOtp(action) {
  try {
    const { data } = yield axios.post(END_POINT.resendOpt.url, {
      ...action.payload,
    });
    if (data.is_sent) {
      yield put(requestResendOtpSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'A new code has been sent to your login email address.',
        })
      );
    } else {
      yield put(requestResendOtpFailure(data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* logout(action) {
  try {
    yield axios.delete(END_POINT.logout.url, {
      headers: {
        Authorization: `Bearer ${action.payload.token}`
      }
    });
    auth.logOut();
    yield put(requestLogoutSuccess());
  } catch (e) {
    yield put(requestLogoutSuccess());
  }
  yield put(endLoading());
}

function* forceSignOut() {
  yield put(forceLogoutSuccess());
  yield put(endLoading());
}

function* fetchUser() {
  if (auth.loggedIn) {
    try {
      let res = {};
      res = yield axios.get(END_POINT.profile.url);

      const { data } = res;
      if (data.data) {
        auth.setRole(data.data);
        yield put(fetchUserInfoSuccess(data.data));
      } else {
        yield put(fetchUserInfoFailure({ code: data.code }));
        auth.logOut();
      }
    } catch (error) {
      yield put(fetchUserInfoFailure({ code: error?.response && error.response?.status }));
      yield put(forceLogout());
    }
  } else {
    yield put(fetchUserInfoFailure({ code: StatusCodes.UNAUTHORIZED }));
  }
}

function* acceptInvite(action) {
  try {
    const { data } = yield axios.put(END_POINT.acceptInvite.url, {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(requestAcceptInviteSuccess(data.data));
    } else {
      yield put(requestAcceptInviteFailure(data));
    }
  } catch (e) {
    yield put(requestAcceptInviteFailure(e.message));
  }
}

function* checkInviteToken(action) {
  try {
    const { data } = yield axios.get(END_POINT.checkInviteToken.url(action.payload));
    if (data.code === 200) {
      yield put(requestCheckInviteTokenSuccess(data.data));
    } else {
      yield put(requestCheckInviteTokenFailure(data));
    }
  } catch (e) {
    yield put(requestCheckInviteTokenFailure(e.message));
  }
}

function* forgotPassword(action) {
  try {
    const { data } = yield axios.post(END_POINT.forgot.url, {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(requestForgotPasswordSuccess(data.data));
    } else {
      yield put(requestForgotPasswordFailure(data));
    }
  } catch (e) {
    yield put(requestForgotPasswordFailure(e.message));
  }
}

function* checkForgotPasswordToken(action) {
  try {
    const { data } = yield axios.get(END_POINT.checkForgotPasswordToken.url(action.payload));
    if (data.code === 200) {
      yield put(requestCheckForgotPasswordTokenSuccess(data.email));
    } else {
      // BE returns 401 if token is invalid
      // BUT FE use 401 to kick to login so we need to switch to 404
      // 404 is also consistent with check token for all accept invite tasks
      yield put(requestCheckForgotPasswordTokenFailure({ code: 404 }));
    }
  } catch (e) {
    yield put(requestCheckForgotPasswordTokenFailure(e.message));
  }
}

function* resetUserPassword(action) {
  try {
    const { data } = yield axios.put(END_POINT.resetUserPassword.url(action.payload.token), {
      ...action.payload,
    });
    if (data.code === 200) {
      yield put(
        // passing the email so we can auto fill on login page
        requestResetUserPasswordSuccess({ email: action.payload.email })
      );
    } else {
      yield put(requestResetUserPasswordFailure(data));
    }
  } catch (e) {
    yield put(requestResetUserPasswordFailure(e.message));
  }
}

function* authSaga() {
  yield takeLatest(requestLogin, login);
  yield takeLatest(requestVerifyOtp, verifyOtp);
  yield takeLatest(requestResendOtp, resendOtp);
  yield takeLatest(forceLogout, forceSignOut);
  yield takeLatest(requestLogout, logout);
  yield takeLatest(fetchUserInfo, fetchUser);
  yield takeLatest(requestAcceptInvite, acceptInvite);
  yield takeLatest(requestCheckInviteToken, checkInviteToken);
  yield takeLatest(requestForgotPassword, forgotPassword);
  yield takeLatest(requestCheckForgotPasswordToken, checkForgotPasswordToken);
  yield takeLatest(requestResetUserPassword, resetUserPassword);
}

export default authSaga;
