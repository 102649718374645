import PropTypes from 'prop-types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { Modal as MuiModal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '4px',
};
export function Modal({ showModal, onClose, content, extra = [], isClose, keep, key, title }) {
  return (
    <MuiModal
      key={key}
      open={showModal}
      onClose={!keep ? onClose : undefined}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...style }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            {title ? <Box sx={{ fontSize: '18px', fontWeight: 700, width: '100%' }}>{title}</Box> : <Box></Box>}
            {isClose ? (
              // <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
              <IconButton sx={{ padding: 0 }} onClick={onClose}>
                <CloseOutlinedIcon />
              </IconButton>
              // </Box>
            ) : (
              <Box></Box>
            )}
          </Box>

          <Box>
            <p id='child-modal-description'>{content()}</p>
          </Box>

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', paddingTop: 5, gap: 2 }}>
            {extra.map((e) => e)}
          </Box>
        </Box>
      </Box>
    </MuiModal>
  );
}

Modal.propTypes = {
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  content: PropTypes.any,
  extra: PropTypes.array,
  isClose: PropTypes.bool,
  keep: PropTypes.bool,
  key: PropTypes.string,
  title: PropTypes.string,
};
