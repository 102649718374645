const STG_VARIABLES = {
  WS_SERVER: 'wss://ws.bionexusa.xyz/ws-ui',
  API_SERVER: 'https://api.bionexusa.xyz/v1/',
  HCP_SSO: 'bionexusa.xyz',
  STAGE: 'stg',
  ORG_ID: 1,
  NAME_DASHBOARD: 'audit-log-admin',
  PANEL_ID: 2,
  UUID_DASHBOARD: 'b25aa220-c107-49f0-b70d-4e0a42e93fad',
  GRAFANA_URL: 'https://grafana.bionexusa.xyz/d-solo'
};

export default STG_VARIABLES;
