import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { AddMachineDialog } from 'modules/machines/components/Dialogs';

import { DIALOGS } from 'modules/dialogs/constants';
import { closeDialog } from 'lib/dialog.js';
import {
  requestAddMachine,
  requestEditMachine,
  clearSuccessAndError,
} from 'modules/machines/slice';

export default function AddMachine({
  selectedRow,
  isEditMachine,
  setSelectedRow,
  sortData,
}) {
  const dispatch = useDispatch();

  const openAddMachineDialog = useSelector(
    (state) => state.dialogs[DIALOGS.ADD_MACHINE_DIALOG]
  );
  const createdMachine = useSelector((state) => state.machines.createdMachine);
  const error = useSelector((state) => state.machines.error);

  const handleCloseAddMachineDialog = () => {
    closeDialog(dispatch, DIALOGS.ADD_MACHINE_DIALOG);
  };

  const onSubmitAddMachineDialog = (data) => {
    // keep the current sort
    data.sortData = sortData;
    if (isEditMachine) {
      dispatch(requestEditMachine(data));
    } else {
      dispatch(requestAddMachine(data));
    }
    // set this data for confirm dialog
    setSelectedRow(data);
  };

  // functions for confirm add machine dialog
  const onAddMoreMachine = () => {
    dispatch(clearSuccessAndError());
  };

  return (
    <AddMachineDialog
      anchor="right"
      machine={selectedRow}
      createdMachine={createdMachine}
      isEditMachine={isEditMachine}
      onAddMoreMachine={onAddMoreMachine}
      onSubmitAddMachineDialog={onSubmitAddMachineDialog}
      error={error}
      openAddMachineDialog={openAddMachineDialog}
      handleCloseAddMachineDialog={handleCloseAddMachineDialog}
    />
  );
}

AddMachine.propTypes = {
  selectedRow: PropTypes.object,
  isEditMachine: PropTypes.bool,
  setSelectedRow: PropTypes.func,
  sortData: PropTypes.object,
};
