import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { configuredStatus } from 'lib/getStatus';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ENV_VARIABLES from 'config/variables';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchHcpOffices } from 'modules/machines/slice';
import isNil from 'lodash/isNil';

const drawerWidth = '50%';

export default function AssignMachineDialog({
  machine,
  createdMachine,
  customers,
  anchor,
  error,
  onSubmitAssignMachineDialog,
  openAssignMachineDialog,
  handleCloseAssignMachineDialog,
}) {
  const defaultValues = {
    hcp: null,
    office: null,
    canConfigure: '',
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccessState] = useState(false);
  const [hcpSingle, setHcpSingle] = useState(false);
  const [officeOptions, setOfficeOptions] = useState([]);

  const token = localStorage.getItem('access_token');

  const {
    getValues,
    reset,
    handleSubmit,
    control,
    setValue,
    // setError,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all', defaultValues });

  // auto fill the form
  useEffect(() => {
    //disabled Yes if the machine is already configured
    if (machine && machine.machine.config_status === configuredStatus.YES) {
      reset({
        hcp: null,
        canConfigure: '-1',
      });
    } else if (openAssignMachineDialog) {
      reset(defaultValues);
    }
  }, [openAssignMachineDialog]);

  //when create new machine success
  useEffect(() => {
    if (createdMachine && createdMachine.id) {
      setSuccessState(true);
    }
  }, [createdMachine]);

  const hcpValue = getValues('hcp');

  useEffect(() => {

    if (!isNil(hcpValue)) {
      setHcpSingle(hcpValue);
    }

    return () => {

    }
  }, [hcpValue]);

  useEffect(() => {
    if (hcpSingle) {
      setOfficeOptions([]);
      axios
        .get(`${ENV_VARIABLES.API_SERVER}hcps/${hcpSingle.id}/offices`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((resp) => {
          setOfficeOptions(resp.data.data);
        }).catch((error) => {
          console.error('Error fetching offices:', error);
        });;
      return;

    }

    return () => {

    }
  }, [hcpSingle]);


  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  const closeModal = () => {
    if (handleCloseAssignMachineDialog) {
      handleCloseAssignMachineDialog();
    }
    setSuccessState(false);
    reset(defaultValues);
    setOfficeOptions([]);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    const submitData = { ...data, machine: machine.machine };
    submitData.id = machine.machine.id;
    if (onSubmitAssignMachineDialog) {
      onSubmitAssignMachineDialog(submitData);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const renderContent = () => {
    return (
      <Box sx={{ width: '65%', position: 'relative' }} role='presentation'>
        {success && createdMachine ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                color: '#2e7d32',
                pt: 5,
                pb: 3,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  mr: 1,
                  fontSize: '2.3rem',
                }}
              />
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                A <strong>{createdMachine.machine.machine_type}</strong> {t('machine:assignDialog.withSerial')}{' '}
                <strong>{createdMachine.machine.serial_no}</strong> {t('machine:assignDialog.beenAssigned')}{' '}
                <strong>{createdMachine.hcp_name}</strong>.
              </Typography>
            </Box>
            <Box>
              {t('machine:assignDialog.notiSent')} at <strong>{machine.hcp.owner_info.email}</strong>.
            </Box>
            <Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:close')}
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography sx={{ fontSize: '1.5rem', pt: 2, fontWeight: 500 }} variant='h2'>
                {t('machine:assignDialog.title')}
              </Typography>

              {error && error.code !== 400 ? <Alert severity='error'>{error.message}</Alert> : null}
              <Box sx={{ mt: 1, display: 'flex' }}>
                <Typography variant='body1' sx={{ mr: 5 }}>
                  {t('machine:assignDialog.machineType')}:{' '}
                  <Box sx={{ opacity: 0.6 }} component='span'>
                    {machine.machine.machine_type}
                  </Box>
                </Typography>
                <Typography variant='body1'>
                  {t('machine:assignDialog.serialNo')}:{' '}
                  <Box sx={{ opacity: 0.6 }} component='span'>
                    {machine.machine.serial_no}
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Controller
                  name='hcp'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      getOptionLabel={(option) => (option ? `${option.hcp_name}` : '')}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderOption={(props, option) => (
                        <Box component='li' {...props} key={option.id}>
                          {option.hcp_name}
                        </Box>
                      )}
                      noOptionsText={t('common:noOptions')}
                      options={customers}
                      value={value}
                      onChange={(event, item) => {
                        onChange(item);
                        setHcpSingle(item);
                        setValue('office', null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='hcp'
                          error={!!errors.hcp}
                          margin='normal'
                          helperText={!!errors.hcp && t('common:errors.required')}
                          label={t('customer:hcp.hcpName')}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name='office'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      getOptionLabel={(option) => (option ? `${option.office_name}` : '')}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderOption={(props, option) => (
                        <Box component='li' {...props} key={option.id}>
                          {option.office_name}
                        </Box>
                      )}
                      noOptionsText={t('common:noOptions')}
                      options={officeOptions}
                      value={value}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete='office'
                          error={!!errors.office}
                          margin='normal'
                          helperText={!!errors.office && t('common:errors.required')}
                          label={t('customer:hcp.office')}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Controller
                  name='canConfigure'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl error={!!errors.canConfigure} fullWidth>
                      <InputLabel>{t('machine:assignDialog.canUConfigure')}</InputLabel>
                      <Select
                        disabled={machine && machine.machine.config_status === configuredStatus.YES}
                        value={value}
                        label={t('machine:assignDialog.canUConfigure')}
                        onChange={onChange}
                      >
                        <MenuItem key={1} value={1}>
                          Yes
                        </MenuItem>
                        <MenuItem key={-1} value={-1}>
                          No
                        </MenuItem>
                      </Select>
                      <FormHelperText error={!!errors.canConfigure}>
                        {errors.canConfigure && t('common:errors.required')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:cancel')}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
                variant='contained'
              >
                {t('common:assign')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      anchor={anchor}
      open={openAssignMachineDialog}
      onClose={onClose}
    >
      {machine && machine.machine ? renderContent() : null}
    </Drawer>
  );
}

AssignMachineDialog.propTypes = {
  machine: PropTypes.object,
  customers: PropTypes.array,
  createdMachine: PropTypes.object,
  anchor: PropTypes.string,
  error: PropTypes.object,
  openAssignMachineDialog: PropTypes.bool,
  handleCloseAssignMachineDialog: PropTypes.func,
  onSubmitAssignMachineDialog: PropTypes.func,
};
