import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { configuredStatus } from 'lib/getStatus';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import { machineTypes } from 'modules/machines/components/Dialogs';

const drawerWidth = '50%';

export default function AddMachineDialog({
  machine,
  createdMachine,
  isEditMachine,
  anchor,
  error,
  onAddMoreMachine,
  onSubmitAddMachineDialog,
  openAddMachineDialog,
  handleCloseAddMachineDialog,
}) {
  const defaultValues = {
    name: '',
    number: '',
    type: '',
    converterId: '',
    macAddress: '',
  };

  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const [success, setSuccessState] = useState(false);
  const [shouldDisabledEdit, setShouldDisabledEdit] = useState(false);
  const [disableMachineType, setDisableMachineType] = useState(false);

  const {
    reset,
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty },
  } = useForm({ mode: 'all', defaultValues });

  useEffect(() => {
    if (isEditMachine && machine && machine.machine) {
      setShouldDisabledEdit(
        machine.machine.config_status === configuredStatus.YES ||
          machine.machine.config_status === configuredStatus.READY
      );
      setDisableMachineType(machine.machine.config_status === configuredStatus.YES);
      return;
    } else {
      setShouldDisabledEdit(false);
      setDisableMachineType(false);
    }
    setDisableMachineType(false);
    setShouldDisabledEdit(false);
  }, [machine, openAddMachineDialog]);

  useEffect(() => {
    if (openAddMachineDialog) {
      reset(defaultValues);
    }
  }, [openAddMachineDialog]);

  useEffect(() => {
    if (error && error.code === 400) {
      if (
        error.error === 'This converter ID already exist' ||
        error.error === 'Another machine already has this converter ID'
      ) {
        setError('converterId', {
          type: 'converter_exists',
        });
      } else if (error.error === 'Unable to modify U12 ID of online or configured machines') {
        setError('converterId', {
          type: 'converter_disallowed',
        });
      } else if (
        error.error === 'This MAC address already exist' ||
        error.error === 'Another machine already has this Raspberry PI ID'
      ) {
        setError('macAddress', {
          type: 'mac_exists',
        });
      } else if (error.error === 'Unable to modify Raspberry PI ID of online or configured machines') {
        setError('macAddress', {
          type: 'ras_disallowed',
        });
      } else {
        setError('number', {
          type: 'serial_exists',
        });
      }
    }
  }, [error]);

  //when create new machine success
  useEffect(() => {
    if (createdMachine && createdMachine.id) {
      setSuccessState(true);
    }
  }, [createdMachine]);

  useEffect(() => {
    // machine exist means editing
    if (machine && isEditMachine) {
      // auto fill the form
      reset({
        name: machine.machine.name,
        number: machine.machine.serial_no,
        type: machine.machine.machine_type,
        converterId: machine.machine.u12_id,
        macAddress: machine.machine.mac_addr,
      });
    }
  }, [isEditMachine]);

  const resetForm = () => {
    reset({
      ...defaultValues,
    });
  };

  const onClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
  };

  const closeModal = () => {
    if (handleCloseAddMachineDialog) {
      handleCloseAddMachineDialog();
    }
    setShouldDisabledEdit(false);
    setDisableMachineType(false);
    setSuccessState(false);
    reset(defaultValues);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    const submitData = { ...data };
    if (isEditMachine && machine) {
      // put id in for edit api
      submitData.id = machine.machine ? machine.machine.id : machine.id;
      // put status back in to make sure status doesn't change
      submitData.status = machine.machine ? machine.machine.status : machine.status;
    }
    if (onSubmitAddMachineDialog) {
      onSubmitAddMachineDialog(submitData);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const handleAddMoreMachine = () => {
    resetForm();
    setSuccessState(false);
    if (onAddMoreMachine) {
      onAddMoreMachine();
    }
  };

  const allowAlphaNumericSpace = (e) => {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidNamePattern');
  };

  const getSerialErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'serial_exists') return t('machine:addDialog.errors.serialExists');
  };

  const getConverterErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'converter_exists') return t('machine:addDialog.errors.converterExists');
    if (error?.type === 'converter_disallowed') return t('machine:addDialog.errors.converterDisallowed');
  };

  const getMacAddressErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'mac_exists') return t('machine:addDialog.errors.rasSerialExists');
    if (error?.type === 'ras_disallowed') return t('machine:addDialog.errors.rasSerialDisallowed');
  };

  const renderContent = () => {
    return (
      <Box sx={{ width: '65%', position: 'relative' }} role='presentation'>
        {success && createdMachine ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                color: '#2e7d32',
                pt: 5,
                pb: 3,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  mr: 1,
                  fontSize: '2.3rem',
                }}
              />
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                {t('machine:confirmAddMachine.newMachine')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:close')}
              </Button>
              <Button onClick={handleAddMoreMachine} variant='contained'>
                {t('machine:confirmAddMachine.addAnotherMachine')}
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography sx={{ fontSize: '1.5rem', pt: 2, fontWeight: 500 }} variant='h2'>
                {t('machine:addDialog.title')}
              </Typography>
              {!isEditMachine && (
                <Typography sx={{ mb: 1 }} variant='body1'>
                  {t('machine:addDialog.subtitle')}
                </Typography>
              )}

              {error && error.code !== 400 ? <Alert severity='error'>{error.message}</Alert> : null}

              <Controller
                name='number'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowAlphaNumericSpace(e);
                    }}
                    error={!!errors.number}
                    helperText={getSerialErrorMessage(errors.number)}
                    margin='normal'
                    fullWidth
                    id='number'
                    value={value}
                    label={t('machine:addDialog.serialNo')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 13,
                    }}
                  />
                )}
              />
              <Controller
                name='name'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.name}
                    helperText={getNameErrorMessage(errors.name)}
                    margin='normal'
                    fullWidth
                    id='name'
                    value={value}
                    label={t('machine:addDialog.name')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                )}
              />
              <Box sx={{ mt: 2, mb: 1 }}>
                <Controller
                  name='type'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl error={!!errors.type} fullWidth>
                      <InputLabel>{t('machine:addDialog.machineType')}</InputLabel>
                      <Select
                        value={value}
                        label={t('machine:addDialog.machineType')}
                        disabled={disableMachineType}
                        onChange={onChange}
                      >
                        {machineTypes.map((type) => (
                          <MenuItem key={type.id} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.type}>
                        {errors.type && t('common:errors.required')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
              <Controller
                name='converterId'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowAlphaNumericSpace(e);
                    }}
                    error={!!errors.converterId}
                    helperText={getConverterErrorMessage(errors.converterId)}
                    margin='normal'
                    disabled={shouldDisabledEdit}
                    fullWidth
                    id='converterId'
                    value={value}
                    label={t('machine:addDialog.converterId')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 16,
                    }}
                  />
                )}
              />
              <Controller
                name='macAddress'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowAlphaNumericSpace(e);
                    }}
                    error={!!errors.macAddress}
                    helperText={getMacAddressErrorMessage(errors.macAddress)}
                    margin='normal'
                    disabled={shouldDisabledEdit}
                    fullWidth
                    id='macAddress'
                    value={value}
                    label={t('machine:addDialog.macAddress')}
                    onChange={onChange}
                    InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:cancel')}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty && isEditMachine}
                variant='contained'
              >
                {isEditMachine ? t('common:save') : t('common:add')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      anchor={anchor}
      open={openAddMachineDialog}
      onClose={onClose}
    >
      {renderContent()}
    </Drawer>
  );
}

AddMachineDialog.propTypes = {
  machine: PropTypes.object,
  createdMachine: PropTypes.object,
  isEditMachine: PropTypes.bool,
  anchor: PropTypes.string,
  error: PropTypes.object,
  onAddMoreMachine: PropTypes.func,
  openAddMachineDialog: PropTypes.bool,
  handleCloseAddMachineDialog: PropTypes.func,
  onSubmitAddMachineDialog: PropTypes.func,
};
