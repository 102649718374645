import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Version({ version }) {
  return (
    <Typography
      sx={{
        color: '#ED9A00',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
      }}
    >
      {version}
    </Typography>
  );
}

Version.propTypes = {
  version: PropTypes.string,
};
