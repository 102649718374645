import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import {
  requestUpdateSoftwareVersion,
  requestUpdateSoftwareVersionFailure,
  requestUpdateSoftwareVersionSuccess,
  listVersion,
  listVersionFailure,
  listVersionSuccess,
  requestPushToHcp,
  getLatestVersion,
  getLatestVersionFailure,
  getLatestVersionSuccess,
} from './slice';

import { END_POINT } from './constants.js';
import { requestToast } from '../ui/slice';
import { TOAST_TYPE } from '../ui/constants';

function* uploadVersionFileHandler(action) {
  try {
    yield axios.post(
      `${END_POINT.uploadVersionFile.url(action.payload.version, action.payload.name)}`,
      action.payload.file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    yield put(
      requestToast({
        type: TOAST_TYPE.SUCCESS,
        message: 'New software file has been successfully uploaded.',
      })
    );
    yield put(listVersion());
    yield put(requestUpdateSoftwareVersionSuccess());
  } catch (e) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: 'Upload failure.',
      })
    );
    yield put(requestUpdateSoftwareVersionFailure());
  }
}

function* listVersionHandler() {
  try {
    const { data } = yield axios.get(`${END_POINT.listVersion.url}`);

    data.data.sort((a, b) => b.id - a.id);
    yield put(listVersionSuccess(data.data));
  } catch (e) {
    yield put(listVersionFailure());
  }
}

function* pushVersionToHcpHandler(action) {
  try {
    yield axios.post(`${END_POINT.pushVersionToHcp.url(action.payload)}`);

    yield put(
      requestToast({
        type: TOAST_TYPE.SUCCESS,
        message: 'New software update has been published.',
      })
    );
  } catch (e) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: 'Publish New software to customer failure.”',
      })
    );
  }
}

function* getLatestVersionHandler() {
  try {
    const { data } = yield axios.get(`${END_POINT.getLatestVersion.url}`);

    yield put(getLatestVersionSuccess(data.data));
  } catch (e) {
    yield put(
      requestToast({
        type: TOAST_TYPE.ERROR,
        message: 'Cannot get latest version try again.”',
      })
    );
    yield put(getLatestVersionFailure());
  }
}
function* softwareUploadSaga() {
  yield takeLatest(requestUpdateSoftwareVersion, uploadVersionFileHandler);
  yield takeLatest(listVersion, listVersionHandler);
  yield takeLatest(requestPushToHcp, pushVersionToHcpHandler);
  yield takeLatest(getLatestVersion, getLatestVersionHandler);
}

export default softwareUploadSaga;
