export const dateParserWithTimezone = (d) => {
  if (!d) {
    return;
  }
  const date = new Date(d);
  const utcDate = new Date(date);
  const offset = new Date().getTimezoneOffset();
  return new Date(utcDate.getTime() - offset * 60000);
};

export const getTimeFromSeconds = (secs) => {
  const totalSeconds = Math.ceil(secs);
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return {
    seconds: seconds > 9 ? seconds : `0${seconds}`,
    minutes: minutes > 9 ? minutes : `0${minutes}`
  }
};

export const getSecondsFromPrevTime = (prevTime, shouldRound) => {
  const now = new Date().getTime();
  const milliSecondsDistance = now - prevTime;
  if (milliSecondsDistance > 0) {
    const val = milliSecondsDistance / 1000;
    return shouldRound ? Math.round(val) : val;
  }
  return 0;
};

export const getSecondsFromExpiry = (expire, shouldRound, currentTime) => {
  const now = new Date().getTime();
  const milliSecondDistance = expire - now;
  if (milliSecondDistance > 0) {
    const val = milliSecondDistance / 1000;
    return shouldRound ? Math.round(val) : val;
  }
  return currentTime || 0;
};

export const getCookie = (cname) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const allowAlphaNumericSpace = (e) => {
  var code = 'charCode' in e ? e.charCode : e.keyCode;
  if (
    !(code === 45) && // minus
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123) // lower alpha (a-z)
  ) {
    e.preventDefault();
  }
};

export const getEmailErrorMessage = (error, t) => {
  if (error?.type === 'required') return t('common:errors.required');
  if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidEmailPattern');
  if (error?.type === 'email_exists') return t('customer:inviteDialog.errors.emailExists');
};

export const getNameErrorMessage = (error, t) => {
  if (error?.type === 'required') return t('common:errors.required');
  if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidNamePattern');
};

export const getDomainErrorMessage = (error, t) => {
  if (error?.type === 'required') return t('common:errors.required');
  if (error?.type === 'domain_exists') return 'Someone already uses this domain.';
  if (error?.type === 'pattern') return t('customer:inviteDialog.errors.domainPattern');
};

export const dateParseMonthDayYear = (date) => {

  if (!date) {
    return;
  }

  const currentDate = new Date(date);

  const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  // Get the month, day, and year components
  const month = monthNames[currentDate.getMonth()];
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  // Format the date string
  const formattedDate = `${month} ${day < 10 ? '0' : ''}${day}, ${year}`;

  return formattedDate
}
