import Container from '@mui/material/Container';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PeopleIcon from '@mui/icons-material/People';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import HistoryIcon from '@mui/icons-material/History';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import DashboardLinks from './DashboardLinks';

const ADMIN_LINKS = [
  {
    name: 'Manage BioneX Users',
    url: '/manage/users',
    icon: <PeopleIcon />,
  },
  {
    name: 'Manage Customers',
    url: '/manage/customers',
    icon: <ApartmentIcon />,
  },
  {
    name: 'Manage Machines',
    url: '/manage/machines',
    icon: <PrecisionManufacturingIcon />,
  },
  {
    name: 'Manage Software Update',
    url: '/manage/software',
    icon: <BrowserUpdatedIcon />,
  },
  {
    name: 'Manage Audit Log',
    url: '/manage/audit-log',
    icon: <HistoryIcon />,
  },
  {
    name: 'Activity Tracking',
    url: '/manage/activity-tracking',
    icon: <QueryStatsIcon />,
  },
];

export default function AdminDashboard() {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 'calc(100vh - 70px)',
        backgroundColor: 'grey1',
      }}
      maxWidth={false}
    >
      <DashboardLinks links={ADMIN_LINKS} />
    </Container>
  );
}
