import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/dialogs/actions';

export const UsersSlice = createSlice({
  name: 'usersSlice',
  initialState: {
    errorCode: 0,
    error: null,
    users: [],
    itemPerPage: 10,
    totalUsers: 0,
    createdUser: null,
  },
  reducers: {
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdUser = null;
    },
    fetchUsers: (state) => {
      state.errorCode = 0;
    },
    fetchUsersSuccess: (state, action) => {
      state.users = action.payload.users;
      state.itemPerPage = action.payload.item_per_page;
      state.totalUsers = action.payload.total_users;
    },
    fetchUsersFailure: (state, action) => {
      state.users = [];
      state.errorCode = action.payload.code;
    },
    fetchRoles: (state) => {
      state.errorCode = 0;
    },
    fetchRolesSuccess: (state, action) => {
      state.roles = action.payload;
    },
    fetchRolesFailure: (state, action) => {
      state.roles = [];
      state.errorCode = action.payload.code;
    },
    requestInviteUser: (state) => {
      state.error = null;
    },
    requestInviteUserSuccess: (state, action) => {
      state.createdUser = action.payload.data;
    },
    requestInviteUserFailure: (state, action) => {
      state.error = action.payload;
    },
    requestDeleteUser: (state) => {
      state.errorCode = 0;
    },
    requestDeleteUserSuccess: () => {},
    requestDeleteUserFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestSuspendUser: (state) => {
      state.errorCode = 0;
    },
    requestSuspendUserSuccess: () => {},
    requestSuspendUserFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestRestoreUser: (state) => {
      state.errorCode = 0;
    },
    requestRestoreUserSuccess: () => {},
    requestRestoreUserFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.errorCode = 0;
      state.error = null;
      state.createdUser = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  requestInviteUser,
  requestInviteUserSuccess,
  requestInviteUserFailure,
  requestDeleteUser,
  requestDeleteUserSuccess,
  requestDeleteUserFailure,
  requestSuspendUser,
  requestSuspendUserSuccess,
  requestSuspendUserFailure,
  requestRestoreUser,
  requestRestoreUserSuccess,
  requestRestoreUserFailure,
  fetchRoles,
  fetchRolesSuccess,
  fetchRolesFailure,
} = UsersSlice.actions;

export default UsersSlice.reducer;
