import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export default function GlobalLoading({ isLoading }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    isLoading && (
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          zIndex: 9999,
        }}
      >
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    )
  );
}

GlobalLoading.propTypes = {
  isLoading: PropTypes.bool,
};
