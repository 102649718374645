import ENV_VARIABLES from 'config/variables';

export const DETECTED_MACHINE_STATUS = {
  FAILED: -1,
  READY: 0,
  CONFIGURING: 1,
  COMPLETED: 2,
};

export const CALIBRATE_ACTION_TYPE = {
  POTENTIOMETER: 'machine_calibration_potentiometer',
  STRAIN_GAUGE: 'machine_calibration_strain_gauge',
  DEBUG: 'machine_calibration_debug',
  SPECIFICATION_SETTING: 'machine_calibration_specification_setting',
};

export const END_POINT = {
  getMachines: {
    url: `${ENV_VARIABLES.API_SERVER}machines/`,
  },
  updateMachines: {
    url: `${ENV_VARIABLES.API_SERVER}machines/`,
  },
  getDetectedMachines: {
    url: `${ENV_VARIABLES.API_SERVER}machines/detected_machines`,
  },
  addMachine: {
    url: `${ENV_VARIABLES.API_SERVER}machines/`,
  },
  editMachine: {
    url: (id) => `${ENV_VARIABLES.API_SERVER}machines/?id=${id}`,
  },
  assignMachine: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/assignments`,
  },
  removeMachine: {
    url: (id) => `${ENV_VARIABLES.API_SERVER}machines/?id=${id}`,
  },
  configureMachine: {
    url: `${ENV_VARIABLES.API_SERVER}machines/configure`,
  },
  configurePreAddedMachine: {
    url: (id) => `${ENV_VARIABLES.API_SERVER}machines/${id}/configure`,
  },
  getHcpMachines: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}machines/hcps/${hcpId}`,
  },
  getHcpConfiguredMachines: {
    url: `${ENV_VARIABLES.API_SERVER}machines/configured`,
  },
  requestLoggingRecordAction: {
    url: `${ENV_VARIABLES.API_SERVER}logging/record`,
    method: 'POST',
  },
  fetchHcpOfficeAction: {
    url: (hcpId) => `${ENV_VARIABLES.API_SERVER}hcps/${hcpId}/offices`,
    method: 'GET',
  },
  unassignMachine: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/assignments`,
  },
  unassignComponent: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance`,
  },
  getListCpnAvailableReplace: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance/available`,
  },
  replaceMachineLater: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance`,
  },
  getCurrentProcessReplace: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance/process`,
  },
  confirmReplaceMachine: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance`,
  },
  finishReplaceMachine: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance/finish`,
  },
  retryReplaceMachine: {
    url: (machineId) => `${ENV_VARIABLES.API_SERVER}machines/${machineId}/maintenance/retry`,
  },
};
