import { Status } from 'components/Table';
import Typography from '@mui/material/Typography';

export const configuredStatus = {
  NO: 0,
  READY: 1,
  YES: 3,
  CONFIGURING: 2,
};

export default function getStatus(status, t) {
  switch (status) {
    case 2:
      return <Status text={t('common:status.locked')} color={'error'} />;
    case 1:
      return <Status text={t('common:status.active')} color={'success'} />;
    case 0:
      return <Status text={t('common:status.pending')} color={'info'} />;
    default:
      return <Status text={t('common:status.suspended')} color={'warning'} />;
  }
}

export function getMachineStatus(status, t) {
  switch (status) {
    case 3:
      return <Status text={t('common:status.maintenance')} color={'warning'} />;
    case 2:
      return <Status text={t('common:status.inactive')} color={'warning'} />;
    case 1:
      return <Status text={t('common:status.assigned')} color={'success'} />;
    case 0:
      return <Status text={t('common:status.unassigned')} color={'info'} />;
    default:
      return <Status text={t('common:status.suspended')} color={'warning'} />;
  }
}

export function getMachineConfigureStatus(status, t) {
  switch (status) {
    case -1:
      return <Typography color={'error'}>{t('common:status.failed')}</Typography>;
    case 1:
      return <Typography color={'primary.main'}>{t('common:status.configuring')}</Typography>;
    case 2:
      return <Typography sx={{ color: 'rgb(0 142 60)' }}>{t('common:status.completed')}</Typography>;
    default:
      return <Typography color={'info'}>{t('common:status.readyForConfiguration')}</Typography>;
  }
}

export function getConfiguredStatusGeneralMachine(status, t) {
  switch (status) {
    case configuredStatus.READY:
      return t('common:status.readyForConfiguration');
    case configuredStatus.YES:
      return t('common:status.yes');
    case configuredStatus.CONFIGURING:
      return t('common:status.configuring');

    default:
      return t('common:status.no');
  }
}
