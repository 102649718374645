import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { isEmpty, kebabCase } from 'lodash';
import { StepReplaceContext } from '../Dialogs/ReplaceMachineDialog';
import { initReplaceStepRepairMachine, requestUnassignComponent } from 'modules/machines/slice';
import { useDispatch, useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ['Orange Pi', 'U12'];

const components = [{ 'Orange Pi': 'mac_address' }, { U12: 'u12_id' }];

export default function ContentStepUnassign({}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [componentSelected, setComponentSelected] = useState([]);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(true);

  const stepReplaceData = useContext(StepReplaceContext);
  const { closeModal, handleNext, selectedRow } = stepReplaceData;

  const isUnassignCpnSuccess = useSelector((state) => state.machines.isUnassignCpnSuccess);

  const handleChangeTest = (event) => {
    const {
      target: { value },
    } = event;

    setComponentSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    if (!isEmpty(value)) {
      setDisableConfirmBtn(false);
    } else {
      setDisableConfirmBtn(true);
    }
  };

  useEffect(() => {
    if (isUnassignCpnSuccess) {
      handleNext();
    }

    // return () => {
    //   dispatch(initReplaceStepRepairMachine());
    // };
  }, [isUnassignCpnSuccess]);

  const onConfirmUnassignComponent = () => {
    const values =
      !isEmpty(componentSelected) &&
      componentSelected.map((key) => {
        const foundObject = components.find((ele) => ele.hasOwnProperty(key));

        return foundObject ? foundObject[key] : undefined;
      });

    dispatch(
      requestUnassignComponent({
        id: selectedRow.id,
        parts: values,
      })
    );
  };

  const renderContent = () => {
    return (
      <Box>
        <Typography sx={{ color: '#000', display: 'flex', alignItems: 'center', gap: 1, mb: '80px' }} component='div'>
          {`${t('machine:contentStepUnassign.contentText')}`}
          <FormControl sx={{ m: 1, minWidth: 240 }} size='small'>
            <InputLabel id='demo-multiple-checkbox-label'>Component</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={componentSelected}
              onChange={handleChangeTest}
              input={<OutlinedInput label='Component' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={componentSelected.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant='outlined' sx={{ border: '1px solid #e2e4e7' }} onClick={closeModal}>
            {t('machine:contentStepUnassign.textBtnCancel')}
          </Button>
          <Button
            disabled={disableConfirmBtn}
            variant='contained'
            sx={{ color: 'white' }}
            onClick={onConfirmUnassignComponent}
            autoFocus
          >
            {t('machine:contentStepUnassign.textBtnConfirm')}
          </Button>
        </Box>
      </Box>
    );
  };

  return renderContent();
}

ContentStepUnassign.propTypes = {};
