export const DIALOGS = {
  CHANGE_PASSWORD: 'change_password_dialog',
  CHANGE_PROFILE: 'change_profile_dialog',
  CHANGE_HCP_USER_PROFILE: 'change_hcp_user_profile_dialog',
  INVITE_USER: 'invite_user_dialog',
  CONFIRM_INVITE_USER: 'confirm_invite_user_dialog',
  CONFIRM_DELETE_DIALOG: 'confirm_delete_user_dialog',
  CONFIRM_SUSPEND_DIALOG: 'confirm_suspend_user_dialog',
  CONFIRM_RESTORE_DIALOG: 'confirm_restore_user_dialog',
  INVITE_CUSTOMER: 'invite_customer_dialog',
  CONFIRM_DELETE_CUSTOMER_DIALOG: 'confirm_delete_customer_dialog',
  CONFIRM_SUSPEND_CUSTOMER_DIALOG: 'confirm_suspend_customer_dialog',
  CONFIRM_RESTORE_CUSTOMER_DIALOG: 'confirm_restore_customer_dialog',
  CONFIRM_DELETE_HCP_USER_DIALOG: 'confirm_delete_hcp_users_dialog',
  ADD_MACHINE_DIALOG: 'add_machine_dialog',
  CONFIGURE_MACHINE_DIALOG: 'configure_machine_dialog',
  ASSIGN_MACHINE_DIALOG: 'assign_machine_dialog',
  CONFIRM_REMOVE_MACHINE_DIALOG: 'confirm_remove_machine_dialog',
  UNASSIGN_MACHINE_DIALOG: 'unassign_machine_dialog',
  REASSIGN_MACHINE_DIALOG: 'reassign_machine_dialog',
  REPLACE_MACHINE_DIALOG: 'replace_machine_dialog',
  DEBUG_MACHINE_DIALOG: 'debug_machine_dialog',
};
