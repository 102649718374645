import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/dialogs/actions';
import { REMAINING_ATTEMPT_DEFAULT } from './constants';

export const AuthSlice = createSlice({
  name: 'authSlice',
  initialState: {
    token: '',
    error: null,
    errorCode: 0,
    forcedLogout: false,
    invitedUser: null,
    isRequireTwoFa: false,
    isLogoutSuccess: false,
    remaining_attempts: REMAINING_ATTEMPT_DEFAULT,
    openModalReachedFirstLimit: false,
    openModalReachedLastLimit: false
  },
  reducers: {
    requestLogin: (state) => {
      state.invitedUser = null;
      state.errorCode = 0;
      state.error = null;
      state.status = 0;
    },
    requestLoginSuccess: (state, action) => {
      state.token = action.payload;
    },
    requestLoginFailure: (state, action) => {
      state.errorCode = action.payload.code;
      state.status = action.payload?.error?.status;
      state.error = action.payload?.error;
    },
    fetchUserInfo: (state) => {
      state.errorCode = 0;
    },
    fetchUserInfoSuccess: (state, action) => {
      state.currentUser = action.payload;
    },
    fetchUserInfoFailure: (state, action) => {
      state.currentUser = false;
      state.token = '';
      state.errorCode = action.payload.code;
    },
    requestLogout: (state) => {
      state.forcedLogout = false;
    },
    requestLogoutSuccess: (state) => {
      state.currentUser = null;
      state.token = '';
      state.isLogoutSuccess = true
    },
    forceLogout: () => { },
    forceLogoutSuccess: (state) => {
      state.forcedLogout = true;
    },
    // for two fa
    fetchRequireTwoFaSuccess: (state, action) => {
      state.isRequireTwoFa = action.payload;
    },
    resetRequireTwoFa: (state, action) => {
      state.isRequireTwoFa = false;
    },
    resetReasonLogin: (state, action) => {
      state.reason = null;
    },
    resetRemainingAttempt: (state, action) => {
      state.remaining_attempts = REMAINING_ATTEMPT_DEFAULT;
    },
    requestVerifyOtp: (state) => {
    },
    requestVerifyOtpSuccess: (state, action) => {
      state.is_success = action.payload;
    },
    requestVerifyOtpFailure: (state, action) => {
      state.reason = action.payload.reason;
      state.is_success = action.payload.is_success;
      state.remaining_attempts = action.payload.remaining_attempts
    },
    requestSetOpenModalReachedFirstLimit: (state, action) => {
      state.openModalReachedFirstLimit = action.payload;
    },
    requestSetOpenModalReachedLastLimit: (state, action) => {
      state.openModalReachedLastLimit = action.payload
    },
    requestResendOtp: (state) => {
    },
    requestResendOtpSuccess: (state, action) => {
      state.is_sent = action.payload.is_sent;
      state.resend_attempts = action.payload.remaining_attempts
    },
    requestResendOtpFailure: (state, action) => {
    },

    // for bionex users
    requestAcceptInvite: (state) => {
      state.errorCode = 0;
    },
    requestAcceptInviteSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestAcceptInviteFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestCheckInviteToken: (state) => {
      state.invitedUser = null;
      state.errorCode = 0;
    },
    requestCheckInviteTokenSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestCheckInviteTokenFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestForgotPassword: (state) => {
      state.errorCode = 0;
      state.status = 0;
    },
    requestForgotPasswordSuccess: () => { },
    requestForgotPasswordFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestCheckForgotPasswordToken: (state) => {
      state.errorCode = 0;
      state.status = 0;
    },
    requestCheckForgotPasswordTokenSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestCheckForgotPasswordTokenFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    requestResetUserPassword: (state) => {
      state.errorCode = 0;
    },
    requestResetUserPasswordSuccess: (state, action) => {
      state.invitedUser = action.payload;
    },
    requestResetUserPasswordFailure: (state, action) => {
      state.errorCode = action.payload.code;
    },
    clearError: (state) => {
      state.errorCode = 0;
    },
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.errorCode = 0;
      state.forcedLogout = false;
      state.status = 0;
    },
  },
});
export const {
  clearError,
  requestLogin,
  requestLoginSuccess,
  requestLoginFailure,
  fetchUserInfo,
  fetchUserInfoSuccess,
  fetchUserInfoFailure,
  requestLogout,
  requestLogoutSuccess,
  forceLogout,
  forceLogoutSuccess,
  requestAcceptInvite,
  requestAcceptInviteSuccess,
  requestAcceptInviteFailure,
  requestCheckInviteToken,
  requestCheckInviteTokenSuccess,
  requestCheckInviteTokenFailure,
  requestForgotPassword,
  requestForgotPasswordSuccess,
  requestForgotPasswordFailure,
  requestCheckForgotPasswordToken,
  requestCheckForgotPasswordTokenSuccess,
  requestCheckForgotPasswordTokenFailure,
  requestResetUserPassword,
  requestResetUserPasswordSuccess,
  requestResetUserPasswordFailure,

  fetchRequireTwoFaSuccess,
  resetRequireTwoFa,
  resetReasonLogin,
  resetRemainingAttempt,
  requestVerifyOtp,
  requestVerifyOtpSuccess,
  requestVerifyOtpFailure,
  requestSetOpenModalReachedFirstLimit,
  requestSetOpenModalReachedLastLimit,
  requestResendOtp,
  requestResendOtpSuccess,
  requestResendOtpFailure
} = AuthSlice.actions;

export default AuthSlice.reducer;
