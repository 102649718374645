import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchCustomers,
  fetchCustomersSuccess,
  fetchCustomersFailure,
  filterCustomers,
  filterCustomersSuccess,
  filterCustomersFailure,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailure,
  fetchStates,
  fetchStatesSuccess,
  fetchStatesFailure,
  requestInviteCustomer,
  requestInviteCustomerSuccess,
  requestInviteCustomerFailure,
  requestEditCustomer,
  requestEditCustomerSuccess,
  requestEditCustomerFailure,
  requestDeleteCustomer,
  requestDeleteCustomerSuccess,
  requestDeleteCustomerFailure,
  requestSuspendCustomer,
  requestSuspendCustomerSuccess,
  requestSuspendCustomerFailure,
  requestRestoreCustomer,
  requestRestoreCustomerSuccess,
  requestRestoreCustomerFailure,
  requestUpdateCustomerFeature,
  requestUpdateCustomerFeatureSuccess,
  requestUpdateCustomerFeatureFailure
} from './slice';

import { requestDialog } from 'modules/dialogs/slice';

import { END_POINT } from './constants';
import { requestToast } from 'modules/ui/slice';
import { TOAST_TYPE } from 'modules/ui/constants';
import { DIALOGS } from 'modules/dialogs/constants';

function* getCustomers(action) {
  const query = action.payload || {};
  const filterStatus = query.filterStatus >= -1 ? query.filterStatus : '';
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`order_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatus >= -1) {
    queryString.push(`filter_by=status&filter_value=${filterStatus}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getCustomers.url}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      yield put(fetchCustomersSuccess(data.data));
    } else {
      yield put(fetchCustomersFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchCustomersFailure(e.message));
  }
}

function* getFilteredCustomers(action) {
  const query = action.payload || {};
  const filterStatuses = query.filterStatuses;
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const mergedFilters = [];
  let mergedValues = [];

  let queryString = [];
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatuses.length) {
    for (let i = 0; i < filterStatuses.length; i++) {
      mergedFilters.push('status');
    }
    mergedValues = [...mergedValues, ...filterStatuses];
  }

  if (mergedFilters.length > 0) {
    queryString.push(`filter_by=${mergedFilters.join(',')}&filter_value=${mergedValues.join(',')}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getFilteredCustomers.url}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      yield put(filterCustomersSuccess(data.data));
    } else {
      yield put(filterCustomersFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(filterCustomersFailure(e.message));
  }
}

function* getCities() {
  try {
    const { data } = yield axios.get(END_POINT.getCities.url);
    if (data) {
      yield put(fetchCitiesSuccess(data));
    } else {
      yield put(fetchCitiesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchCitiesFailure(e.message));
  }
}

function* getStates() {
  try {
    const { data } = yield axios.get(END_POINT.getStates.url);
    yield put(fetchStatesSuccess(data));
  } catch (e) {
    yield put(fetchStatesFailure(e.message));
  }
}

function* inviteCustomer(action) {
  try {
    const { data } = yield axios.post(END_POINT.inviteCustomer.url, {
      hcp_name: action.payload.hcpName.trim(),
      owner_info: {
        email: action.payload.email,
        first_name: action.payload.firstName.trim(),
        last_name: action.payload.lastName.trim(),
        phone_number: action.payload.phoneNumber,
      },
      state: action.payload.state.name,
      city: action.payload.city.name,
      address: action.payload.address.trim(),
      zip_code: action.payload.zipCode.trim(),
      status: 0,
      sub_domain: action.payload.subDomain,
      offices: action.payload.offices,
    });
    if (data.code === 201) {
      yield put(requestInviteCustomerSuccess(data));
      yield put(fetchCustomers(action.payload.sortData));
    } else {
      yield put(requestInviteCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestInviteCustomerFailure(e.message));
  }
}

function* editCustomer(action) {
  try {
    const customer = {
      hcp_name: action.payload.hcpName.trim(),
      owner_info: {
        email: action.payload.email,
        first_name: action.payload.firstName.trim(),
        last_name: action.payload.lastName.trim(),
        phone_number: action.payload.phoneNumber,
      },
      state: action.payload.state.trim() || action.payload.state.name.trim(),
      city: action.payload.city.trim() || action.payload.city.name.trim(),
      address: action.payload.address.trim(),
      zip_code: action.payload.zipCode.trim(),
      status: action.payload.status,
      sub_domain: action.payload.subDomain,
      offices: action.payload.offices,
    };
    const { data } = yield axios.put(END_POINT.editCustomer.url(action.payload.id), customer);

    const isNewInvite = action.payload.isNewInvite;
    if (data.code === 200) {
      yield put(fetchCustomers(action.payload.sortData));
      if (!isNewInvite) {
        yield put(
          requestToast({
            type: TOAST_TYPE.SUCCESS,
            message: 'Customer information updated successfully.',
          })
        );
        yield put(requestDialog({ type: DIALOGS.INVITE_CUSTOMER, open: false }));
      } else {
        customer.isNewInvite = true;
      }
      customer.id = action.payload.id;
      yield put(requestEditCustomerSuccess(customer));
    } else {
      yield put(requestEditCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestEditCustomerFailure(e.message));
  }
}

function* deleteCustomer(action) {
  try {
    const { data } = yield axios.put(END_POINT.manageCustomer.url(action.payload.customerId), {
      action: 'delete',
    });
    if (data.code === 200) {
      yield put(requestDeleteCustomerSuccess(data));
      yield put(fetchCustomers());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.firstName} ${action.payload.lastName}" deleted.`,
        })
      );
    } else {
      yield put(requestDeleteCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestDeleteCustomerFailure(e.message));
  }
}

function* suspendCustomer(action) {
  try {
    const { data } = yield axios.put(END_POINT.manageCustomer.url(action.payload.customerId), action.payload);
    if (data.code === 200 || data.code === 202) {
      yield put(requestSuspendCustomerSuccess(data));
      yield put(fetchCustomers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.hcp_name}" suspended.`,
        })
      );
    } else {
      yield put(requestSuspendCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestSuspendCustomerFailure(e.message));
  }
}

function* restoreCustomer(action) {
  try {
    const { data } = yield axios.put(END_POINT.manageCustomer.url(action.payload.customerId), action.payload);
    if (data.code === 200) {
      yield put(requestRestoreCustomerSuccess(data));
      yield put(fetchCustomers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.hcp_name}" restored.`,
        })
      );
    } else {
      yield put(requestRestoreCustomerFailure(data));
    }
  } catch (e) {
    yield put(requestRestoreCustomerFailure(e.message));
  }
}

function* updateCustomerFeature(action) {
  try {
    const { data } = yield axios.put(END_POINT.editFeature.url(action.payload.customerId), action.payload.payload);
    if (data.code === 200) {
      yield put(requestUpdateCustomerFeatureSuccess(data));
      yield put(fetchCustomers(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `Update the customer feature success.`,
        })
      );
    } else {
      yield put(requestUpdateCustomerFeatureFailure(data));
    }
  } catch (e) {
    yield put(requestUpdateCustomerFeatureFailure(e.message));
  }
}

function* customersSaga() {
  yield takeLatest(fetchCustomers, getCustomers);
  yield takeLatest(filterCustomers, getFilteredCustomers);
  yield takeLatest(fetchCities, getCities);
  yield takeLatest(fetchStates, getStates);
  yield takeLatest(requestInviteCustomer, inviteCustomer);
  yield takeLatest(requestEditCustomer, editCustomer);
  yield takeLatest(requestDeleteCustomer, deleteCustomer);
  yield takeLatest(requestSuspendCustomer, suspendCustomer);
  yield takeLatest(requestRestoreCustomer, restoreCustomer);
  yield takeLatest(requestUpdateCustomerFeature, updateCustomerFeature);
}

export default customersSaga;
