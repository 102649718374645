import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export default function ManageActivityTrackingSkeleton() {
  return (
    <Stack spacing={1}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={'15%'} height={56} />
        <Skeleton variant="text" width={'15%'} height={56} />
        <Skeleton variant="text" width={'15%'} height={56} />
        <Skeleton variant="text" width={'15%'} height={56} />
        <Skeleton variant="text" width={'15%'} height={56} />
      </Box>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={'20%'} height={56} />
        <Skeleton variant="text" width={'20%'} height={56} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
        <Box sx={{ display: 'flex' }}>
          <Skeleton sx={{ mr: 2 }} variant="text" width={80} height={56} />
          <Skeleton variant="text" width={80} height={56} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Skeleton sx={{ mr: 2 }} variant="text" width={80} height={56} />
          <Skeleton variant="text" width={80} height={56} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
        <Box sx={{ display: 'flex' }}>
          <Skeleton sx={{ mr: 2 }} variant="text" width={80} height={56} />
          <Skeleton variant="text" width={80} height={56} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Skeleton sx={{ mr: 2 }} variant="text" width={80} height={56} />
          <Skeleton variant="text" width={80} height={56} />
        </Box>
      </Box>
      <br />
      <Skeleton variant="text" height={56} />
      <Skeleton variant="text" height={56} />
      <Skeleton variant="text" height={56} />
      <Skeleton variant="text" height={56} />
      <Skeleton variant="text" height={56} />
    </Stack>
  );
}
