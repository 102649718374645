import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import CustomersList from 'modules/customers/components/CustomersList';
import ConfirmDialog from 'components/ConfirmDialog';
import SearchBar from 'modules/customers/components/Search';
import Filter from 'modules/customers/components/Filter';
import InviteCustomer from 'modules/customers/containers/InviteCustomer';

import { openDialog, closeDialog } from 'lib/dialog.js';
import { getDateTime } from 'lib/datetime';
import getStatus from 'lib/getStatus';
import { DIALOGS } from 'modules/dialogs/constants';
import { fetchCustomers, requestSuspendCustomer, requestRestoreCustomer, requestUpdateCustomerFeature } from 'modules/customers/slice';
import { END_POINT } from '../constants.js';
import ENV_VARIABLES from '../../../config/variables';
import { getLatestVersion, requestPushToHcp } from '../../softwareUpdate/slice';
import { Modal } from '../../../components/Modal';

export default function ManageCustomers() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isEditCustomer, setIsEditCustomer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const openInviteCustomerDialog = useSelector((state) => state.dialogs[DIALOGS.INVITE_CUSTOMER]);
  // functions for customer table
  const customers = useSelector((state) => state.customers.customers);
  const latestVersion = useSelector((state) => state.software.latestVersion);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('hcp_name');
  const [sortData, setSortData] = useState();
  const [keyword, setSearchKeyword] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCustomerFeatureOpen, setIsCustomerFeatureOpen] = useState(false);
  const [enabledFeature, setEnableFeature] = useState({
    demographic: false,
    emr: {
      caspio: false
    }
  });

  const getAddress = (city, state) => {
    return (
      <Typography sx={{ wordBreak: 'break-all' }} variant='body1' component='div'>
        {city}, {state}
      </Typography>
    );
  };

  const getCustomerLink = (customer) => {
    return (
      <Box
        sx={{
          a: {
            color: 'primary.main',
            textDecoration: 'none',
          },
        }}
      >
        {customer.total_users}
      </Box>
    );
  };

  const createData = (originalData, id, name, address, createdAt, totalUsers, noOfMachines, status) => {
    return {
      originalData,
      id,
      name,
      address,
      createdAt,
      totalUsers,
      noOfMachines,
      status,
    };
  };

  // transform customers data to table data
  const data = customers.map((customer) => {
    return createData(
      customer, // original data, use this for actions on the table such as delete, restore, suspend
      customer.id,
      customer.hcp_name,
      getAddress(customer.city, customer.state),
      customer.owner_info.created_at,
      getCustomerLink(customer),
      customer.assigned_machines,
      customer.status,
      customer.owner_info.first_name,
      customer.owner_info.last_name
    );
  });

  // dataField has to match fields in createData function
  const headers = [
    {
      id: 'hcp_name',
      title: t('admin:customersTable.customerName'),
      dataField: 'name',
      align: 'left',
      disablePadding: false,
      sortable: true,
      width: '20%',
    },
    {
      id: 'address',
      title: t('admin:customersTable.address'),
      dataField: 'address',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '20%',
    },
    {
      id: 'created_at',
      title: t('admin:customersTable.createdOn'),
      dataField: 'createdAt',
      align: 'left',
      disablePadding: false,
      sortable: true,
      width: '15%',
      format: (value) => getDateTime(value),
    },
    {
      id: 'total_users',
      title: t('admin:customersTable.noOfUsers'),
      dataField: 'totalUsers',
      align: 'center',
      sortable: false,
      disablePadding: false,
      width: '10%',
    },
    {
      id: 'noOfMachines',
      title: t('admin:customersTable.noOfMachines'),
      dataField: 'noOfMachines',
      align: 'center',
      sortable: false,
      disablePadding: false,
      width: '15%',
    },
    {
      id: 'status',
      title: t('admin:customersTable.status'),
      dataField: 'status',
      align: 'left',
      sortable: false,
      disablePadding: false,
      width: '10%',
      format: (value) => getStatus(value, t),
    },
  ];

  // functions for suspend customers
  const openConfirmSuspendDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_SUSPEND_DIALOG]);

  const onSuspendCustomer = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.CONFIRM_SUSPEND_DIALOG);
  };

  const handleCloseConfirmSuspendDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_SUSPEND_DIALOG);
  };

  const handleSubmitSuspendCustomer = () => {
    dispatch(
      requestSuspendCustomer({
        ...selectedRow.originalData,
        customerId: selectedRow.id,
        status: -1,
        sortData: sortData, // keep the current sort
      })
    );
    handleCloseConfirmSuspendDialog();
  };

  // functions for restore customers
  const openConfirmRestoreDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_RESTORE_DIALOG]);

  const onRestorCustomer = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.CONFIRM_RESTORE_DIALOG);
  };

  const handleCloseConfirmRestoreDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_RESTORE_DIALOG);
  };

  const handleSubmitRestoreCustomer = () => {
    dispatch(
      requestRestoreCustomer({
        ...selectedRow.originalData,
        customerId: selectedRow.id,
        status: 1,
        sortData: sortData, // keep the current sort
      })
    );
    handleCloseConfirmRestoreDialog();
  };

  // functions for edit customer
  const onEditCustomer = (row) => {
    const customer = customers.find((customer) => customer.id === row.id);
    setSelectedRow(customer);
    setIsEditCustomer(true);
    handleOpenInviteCustomerDialog();
  };

  // functions for access customer
  const getDomain = () => {
    return ENV_VARIABLES.HCP_SSO;
  };

  const onAccessCustomer = (row) => {
    const customer = customers.find((customer) => customer.id === row.id);
    setSelectedRow(customer);
    axios
      .post(`${END_POINT.signInHcp.url(customer.id)}`, {
        hcp_id: customer.id,
      })
      .then((resp) => {
        if (resp && resp.data && resp.data.token) {
          window.open(
            `https://${customer.sub_domain}.${getDomain()}/sso?hcpId=${customer.id}&token=${resp.data.token}`,
            '_blank'
          );
        }
      });
  };

  const pushLatestSoftwareHandler = (row) => {
    const customer = customers.find((customer) => customer.id === row.id);
    setSelectedRow(customer);
    dispatch(getLatestVersion());
    setIsConfirmModalOpen(true);
  };

  const pushLatestSoftwareContentRender = () => {
    if (!selectedRow || Object.keys(latestVersion).length === 0) {
      return;
    }
    return `The latest software version is "${latestVersion?.name} ${latestVersion?.version}". 
    Are you sure you want to publish the latest software version to all machines belong to "${selectedRow.hcp_name}" ?`;
  };

  const pushLatestSoftwareExtraRender = () => {
    const updateSoftware = () => {
      dispatch(requestPushToHcp(selectedRow.id));
      setIsConfirmModalOpen(false);
    };

    return [
      <Button key={'1'} variant={'outlined'} onClick={() => setIsConfirmModalOpen(false)}>
        Cancel
      </Button>,
      <Button
        variant={'contained'}
        key={'1'}
        disabled={!selectedRow || Object.keys(latestVersion).length === 0}
        onClick={updateSoftware}
      >
        Publish
      </Button>,
    ];
  };

  const setCustomerFeature = (row) => {
    const customer = customers.find((customer) => customer.id === row.id);
    setSelectedRow(customer);
    setEnableFeature(customer.enabled_feature);
    setIsCustomerFeatureOpen(true);
  }

  const onChangeFeature = (field, value) => {
    const newEnableFeature = cloneDeep(enabledFeature);
    setEnableFeature(set(newEnableFeature, field, value));
  }

  const toggleAllEMR = () => {
    const newEnableFeature = cloneDeep(enabledFeature);
    const status = !Object.entries(newEnableFeature.emr).filter(([key, value]) => value).length;
    Object.entries(newEnableFeature.emr).map(([key, value]) => {
      set(newEnableFeature, ['emr', key], status);
    });
    setEnableFeature(newEnableFeature);
  }

  const customerFeatureContent = () => {
    if (!selectedRow) {
      return;
    }
    return <>
      <Typography>Features this customer could access</Typography>
      <label><Checkbox checked={enabledFeature.demographic} onClick={() => onChangeFeature('demographic', !enabledFeature.demographic)} />Demographic questions</label><br />
      <label><Checkbox onClick={() => toggleAllEMR()} checked={Object.entries(enabledFeature.emr).filter(([key, value]) => value).length} />EMR Integrate</label>
      <Box sx={{ paddingLeft: '32px' }}>
        {Object.entries(enabledFeature.emr).map(([key, value]) => {
          return <label key={key} onClick={() => onChangeFeature(`emr.${key}`, !enabledFeature.emr[key])}><Checkbox checked={value} />Caspio</label>
        })
        }
      </Box>

    </>
  }

  const customerFeatureExtra = () => {
    const updateCustomerFeature = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(requestUpdateCustomerFeature({ customerId: selectedRow.id, payload: enabledFeature, sortData }));
      setIsCustomerFeatureOpen(false);
    }
    return [
      <Button key={'0'} variant={'outlined'} onClick={() => setIsCustomerFeatureOpen(false)}>
        Cancel
      </Button>,
      <Button
        variant={'contained'}
        key={'1'}
        disabled={!selectedRow}
        onClick={updateCustomerFeature}
      >
        Apply
      </Button>,
    ];
  };



  // functions for view customers table

  const actions = [
    {
      title: t('admin:customerActions.suspendCustomer'),
      callback: onSuspendCustomer,
    },
    {
      title: t('admin:customerActions.restoreCustomer'),
      callback: onRestorCustomer,
    },
    {
      title: t('admin:customerActions.view/editCustomer'),
      callback: onEditCustomer,
    },
    {
      title: t('admin:customerActions.accessCustomer'),
      callback: onAccessCustomer,
    },
    {
      title: t('admin:customerActions.updateSoftware'),
      callback: pushLatestSoftwareHandler,
    },
    {
      title: t('admin:customerActions.manageFeatureOptions'),
      callback: setCustomerFeature,
    },
  ];

  const getActions = (row, handleClose) => {
    let newActions = actions;
    if (row?.status === 0) {
      newActions = [actions[2]];
    }
    if (row?.status === 1) {
      newActions = [actions[3], actions[2], actions[0], actions[4], actions[5]];
    }
    if (row?.status === -1) {
      newActions = [actions[2], actions[1]];
    }

    return newActions.map((action) => {
      return (
        <MenuItem
          key={action.title}
          onClick={() => {
            action.callback(row);
            handleClose();
          }}
        >
          {action.title}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    getCustomers();

    if ((keyword && keyword !== '') || (filterStatus && filterStatus >= -1)) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  }, [orderBy, order, keyword, filterStatus]);

  const getCustomers = () => {
    let data = { itemPerPage: 999999 };
    data = { ...data, orderBy, order, keyword, filterStatus };
    setSortData(data);

    dispatch(fetchCustomers(data));
  };

  // search bar
  const searchCustomers = (keyword) => {
    setSearchKeyword(keyword);
  };
  // filter
  const filterCustomers = (status) => {
    setFilterStatus(status);
  };

  //everytime the invite dialog is closed, reset the edit state
  useEffect(() => {
    if (!openInviteCustomerDialog) {
      setIsEditCustomer(false);
    }
  }, [openInviteCustomerDialog]);

  const handleOpenInviteCustomerDialog = () => {
    openDialog(dispatch, DIALOGS.INVITE_CUSTOMER);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 70px)',
        backgroundColor: 'grey1',
      }}
      maxWidth={false}
    >
      <Box>
        <Toolbar
          sx={{
            pl: { xs: 2, sm: 1, md: 0 },
            pr: { xs: 1, sm: 1, md: 0 },
            py: { xs: 2, sm: 2, md: 2 },
          }}
        >
          <Typography sx={{ display: 'flex', fontSize: '1.5rem' }} variant='h6' component='div'>
            Customers
          </Typography>
          <Button
            startIcon={<AddIcon />}
            sx={{ ml: 'auto' }}
            variant='contained'
            size='large'
            onClick={handleOpenInviteCustomerDialog}
          >
            {t('admin:customerActions.addCustomer')}
          </Button>
        </Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <SearchBar searchFunction={searchCustomers} />
          <Filter filterFunction={filterCustomers} />
        </Box>
        <CustomersList
          isSearching={isSearching}
          headers={headers}
          data={data}
          actions={actions}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          overrideGetActions={getActions}
        />
      </Box>
      <InviteCustomer
        sortData={sortData}
        selectedRow={selectedRow}
        isEditCustomer={isEditCustomer}
        setSelectedRow={setSelectedRow}
      />
      <ConfirmDialog
        title={`${t('user:restoreDialog.confirm')} "${selectedRow?.name}"?`}
        confirmText='Restore'
        openConfirmDialog={openConfirmRestoreDialog}
        handleCloseConfirmDialog={handleCloseConfirmRestoreDialog}
        handleSubmitConfirmDialog={handleSubmitRestoreCustomer}
      />
      <ConfirmDialog
        title={`${t('user:suspendDialog.confirm')} "${selectedRow?.name}"?`}
        confirmText='Suspend'
        openConfirmDialog={openConfirmSuspendDialog}
        handleCloseConfirmDialog={handleCloseConfirmSuspendDialog}
        handleSubmitConfirmDialog={handleSubmitSuspendCustomer}
      />
      <Modal
        title={'Update New Software Version'}
        keep={true}
        showModal={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        content={pushLatestSoftwareContentRender}
        extra={pushLatestSoftwareExtraRender()}
      />
      <Modal
        title={'Customer\'s Feature Management'}
        keep={true}
        showModal={isCustomerFeatureOpen}
        onClose={() => setIsCustomerFeatureOpen(false)}
        content={customerFeatureContent}
        extra={customerFeatureExtra()}
      />
    </Container>
  );
}
