import React, { useState, useEffect, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';

import SearchBar from 'modules/auditLogs/components/Search';
import Filter from 'modules/auditLogs/components/Filter';
import { convertObjectFilter } from 'modules/auditLogs/helper';
import { ACCESS_TOKEN } from 'config/constants';
import { Button, Typography } from '@mui/material';
import { requestCountRecordAuditLog, requestExportAuditLogAction } from 'modules/auditLogs/slice';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import { format, toDate } from 'date-fns';
import ENV_VARIABLES from 'config/variables';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal';

export default function GeneralAuditLog() {
  const orgId = ENV_VARIABLES.ORG_ID
  const dashBoardName = ENV_VARIABLES.NAME_DASHBOARD;
  const panelId = ENV_VARIABLES.PANEL_ID;
  const uuidDashboard = ENV_VARIABLES.UUID_DASHBOARD;
  const grafanaUrl = ENV_VARIABLES.GRAFANA_URL;
  const baseURL = `${grafanaUrl}/${uuidDashboard}/${dashBoardName}?theme=light`;
  const defaultParams = {
    actor: null,
    mode: null,
    module: null,
    actionEvent: null,
    user_id: null,
    from: null,
    to: null,
  };
  const token = localStorage.getItem(ACCESS_TOKEN);

  const [keyword, setSearchKeyword] = useState();
  const [objectFilter, setObjectFilter] = useState(defaultParams);
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModalDownload, setOpenModalDownload] = useState(false);
  const numberRecord = useSelector((state) => state.auditLogs.numberRecord);
  const isDownloadSuccess = useSelector((state) => state.auditLogs.isDownloadSuccess);
  const convertObject = useRef({});

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [auditUrl, setAuditUrl] = useState(baseURL);

  const handleOpenDateRangePicker = useCallback(() => {
    setOpenDateRangePicker(true);
  }, []);

  const handleCloseDateRangePicker = useCallback(() => {
    setOpenDateRangePicker(false);
  }, []);

  const handleTogglePopover = useCallback(() => {
    setOpen((previousOpen) => !previousOpen);
  }, []);

  const handleSetObjectFilter = (data) => {
    setObjectFilter((prev) => {
      return { ...prev, ...data };
    });
  };

  useEffect(() => {
    if (isDownloadSuccess && (Number(numberRecord) >= 2000)) {
      setOpenModalDownload(true);
    }
  }, [numberRecord, isDownloadSuccess]);

  const handleResetAllFilter = () => {
    setObjectFilter(defaultParams);
    // convertObject.current = convertObjectFilter(defaultParams);
    // const parameters = {
    //   ...objectFilter,
    //   orgId: orgId,
    //   panelId: panelId,
    //   'var-search': keyword ? keyword : '',
    //   theme: 'light',
    //   // Add more parameters as needed
    //   token: token
    // };
    // setAuditUrl(createURL(baseURL, parameters));
  };

  const handleHideClearFilter = () => {
    setObjectFilter(defaultParams);
    convertObject.current = convertObjectFilter(defaultParams);

    const parameters = {
      ...convertObject.current,
      orgId: orgId,
      panelId: panelId,
      'var-search': !isNil(keyword) ? keyword : '',
      theme: 'light',
      // Add more parameters as needed
      token: token
    };
    setAuditUrl(createURL(baseURL, parameters));
  }

  const createURL = (baseURL, params) => {
    const url = new URL(baseURL);
    // Iterate over the params object and append each parameter
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        if (url.searchParams.has(key)) {
          url.searchParams.set(key, params[key] ? params[key] : '');
        } else {
          url.searchParams.append(key, params[key] ? params[key] : '');
        }
      }
    }

    return url.toString();
  };

  function applyFilter(newFilter = null) {
    convertObject.current = convertObjectFilter(objectFilter || newFilter);

    const parameters = {
      ...convertObject.current,
      'var-search': objectFilter.search || (!isNil(keyword) ? keyword : ''),
      panelId: panelId,
      orgId: orgId,
      token: token
    };

    setAuditUrl(createURL(baseURL, parameters));
  }


  const onApplyFilter = () => {
    applyFilter();
    handleTogglePopover();
  };

  const onApplySearch = (item) => {
    const parameters = {
      ...convertObject.current,
      'var-search': !isNil(item) ? item : '',
      panelId: panelId,
      orgId: orgId,
      token: token

    };
    handleSetObjectFilter({ search: item });
    setAuditUrl(createURL(baseURL, parameters));
  };

  useEffect(() => {
    convertObject.current = convertObjectFilter(objectFilter);
    const parameters = {
      ...convertObject.current,
      orgId: orgId,
      panelId: panelId,
      'var-search': !isNil(keyword) ? keyword : '',
      theme: 'light',
      // Add more parameters as needed
      token: token
    };

    setAuditUrl(createURL(baseURL, parameters));
  }, [localStorage]);

  //search bar
  const searchAuditLog = (keyword) => {
    setSearchKeyword(keyword);
  };

  const formattedDate = (timestamp) => {
    if (!isNil(timestamp)) {
      return format(toDate(timestamp), 'yyyy-MM-dd');
    }
  }

  const onExportAuditLogAction = () => {
    const parameter = convertObjectFilter(objectFilter, '');

    const data = {
      ...parameter,
      'search': !isNil(keyword) ? keyword : '',
      'time_from': !isNil(objectFilter?.from) ? formattedDate(objectFilter?.from) : '',
      'time_to': !isNil(objectFilter?.to) ? formattedDate(objectFilter?.to) : ''
    };


    const dataContainType = {
      ...parameter,
      'search': !isNil(keyword) ? keyword : '',
      'time_from': !isNil(objectFilter?.from) ? formattedDate(objectFilter?.from) : '',
      'time_to': !isNil(objectFilter?.to) ? formattedDate(objectFilter?.to) : '',
      'data_type': 'JSON'
    }

    dispatch(requestExportAuditLogAction({ ...data, token }));
    dispatch(requestCountRecordAuditLog({ ...dataContainType, token }));
  }

  const renderContentDownloadLargeLogs = () => {
    return <Box>
      <Typography sx={{ color: '#000', fontSize: '16px' }}>{t('user:errorDownloadLargeLogs.content')}</Typography>
    </Box>
  };

  const handleCloseModalDownload = () => {
    setOpenModalDownload(false);
  }

  const modalDownloadLargeLogsFile = () => {
    return <Modal
      showModal={openModalDownload}
      onClose={handleCloseModalDownload}
      isClose={true}
      keep={true}
      content={renderContentDownloadLargeLogs}
      extra={[
        <Button onClick={handleCloseModalDownload} variant='outlined' key={'btn-1'}>
          {`OK`}
        </Button>
      ]}
    />
  }

  return (
    <Box>
      <Box sx={{ width: '50%' }}>
        {modalDownloadLargeLogsFile()}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 3 }}>
          <SearchBar onApplySearch={onApplySearch} searchFunction={searchAuditLog} />
          <Box sx={{ position: 'absolute', right: '25px' }}>
            <Button
              onClick={() => {
                setTimeout(() => {
                  onExportAuditLogAction();
                }, 100);
              }}
              sx={{ alignSelf: 'baseline', color: '#FFF' }}
              variant='contained'
            >
              Export
            </Button>
          </Box>
          <Filter
            handleHideClearFilter={handleHideClearFilter}
            handleResetAllFilter={handleResetAllFilter}
            objectFilter={objectFilter}
            handleSetObjectFilter={handleSetObjectFilter}
            openDateRangePicker={openDateRangePicker}
            handleOpenDateRangePicker={handleOpenDateRangePicker}
            handleCloseDateRangePicker={handleCloseDateRangePicker}
            onApplyFilter={onApplyFilter}
            handleTogglePopover={handleTogglePopover}
            open={open}
          />
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: '65vh' }}>
        <iframe src={auditUrl} width='100%' height='100%' style={{ border: 0 }}></iframe>
      </Box>
    </Box>
  );
}
