import { formatInTimeZone } from 'date-fns-tz';

export function getDateTime(string) {
  if (!string) return '';
  const date = new Date(string);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = formatInTimeZone(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
    timezone,
    `MM/dd/yyyy 'at' h:mmaaa`
  );

  return localDate;
}

export function getTimeDate(string) {
  if (!string) return '';
  const date = new Date(string);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = formatInTimeZone(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
    timezone,
    'h:mm aa, LLL d yyyy'
  );

  return localDate;
}
