import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './sagas';

import authReducer from 'modules/auth/slice';
import profileReducer from 'modules/profile/slice';
import bionexUsersReducer from 'modules/bionexUsers/slice';
import customersReducer from 'modules/customers/slice';
import machinesReducer from 'modules/machines/slice';
import dialogsReducer from 'modules/dialogs/slice';
import uiReducer from 'modules/ui/slice';
import softwareReducer from 'modules/softwareUpdate/slice';
import auditLogsReducer from 'modules/auditLogs/slice';
import activityTrackingReducer from 'modules/activityTrackings/slice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    users: bionexUsersReducer,
    dialogs: dialogsReducer,
    ui: uiReducer,
    customers: customersReducer,
    machines: machinesReducer,
    software: softwareReducer,
    auditLogs: auditLogsReducer,
    activityTracking: activityTrackingReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export default store;
