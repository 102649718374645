import React, { useEffect, useRef, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import './style.css';
export default function CustomDateRangePicker({ handleRangeSelect, selectedRange, openDateRangePicker, handleCloseDateRangePicker }) {
  const dateRangePickerRef = useRef();
  const numberOfMonths = 2;

  const handleDayClick = (day) => {

    // const range = DateUtils.addDayToRange(day, selectedRange);
    // handleRangeSelect(range);

    let newRange;

    if (!selectedRange.from && !selectedRange.to) {
      // If both "from" and "to" are null, set the selected day as "from"
      newRange = { from: day, to: null };
    } else {
      // Normal behavior: add the day to the range
      newRange = DateUtils.addDayToRange(day, selectedRange);
    }

    // if (!selectedRange.from && !selectedRange.to) {
    //   // If both "from" and "to" are null, set the selected day as "to"
    //   if (focusFrom) {
    //     // newRange = { from: day, to: null };
    //     newRange = { ...selectedRange, to: null, from: day };
    //   } else if (focusTo) {
    //     // newRange = { from: null, to: day };
    //     newRange = { ...selectedRange, to: day, from: null };

    //   }
    //   if (focusTo) {
    //   }
    // } else {
    //   // Normal behavior: add the day to the range
    //   newRange = DateUtils.addDayToRange(day, selectedRange);
    // }

    handleRangeSelect(newRange);
  };

  const handleOutsideClick = (event) => {
    if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
      handleCloseDateRangePicker();
      return
    }
  };

  useEffect(() => {
    if (openDateRangePicker) {
      // Add click event listener when the modal is open
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      // Remove click event listener when the modal is closed
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [openDateRangePicker]);

  const { from, to } = selectedRange;
  const modifiers = { start: from, end: to };
  return (
    <Box ref={dateRangePickerRef} sx={{ position: 'absolute', zIndex: '1000' }} className='dateRangePicker'>
      <DayPicker
        className="selectable"
        numberOfMonths={numberOfMonths}
        selectedDays={[from, to, { from, to }]}
        modifiers={modifiers}
        onDayClick={handleDayClick}
      />
      <style>
        {/* {
          `
          .dateRangePicker {
            width: 100%;
            height: 100%;
            width: 550px;
            display: 'flex';
            flex-direction: row
          }
          .dateRangePicker .selectable {
            background-color: #fff !important;
          }
          .dateRangePicker .selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            height: 40px !important; 
            background-color: #f9f3e8 !important;
            color: #ed9a00;
          }
          .dateRangePicker .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background-color: #ed9a00 !important;
            color: #fff;
          }
          .dateRangePicker .dateRangePicker .selectable .DayPicker-Day--today {
            color: #000;
          }
          .dateRangePicker .selectable .DayPicker-Day {
            border-radius: 0 !important;
          }
          .dateRangePicker .selectable .DayPicker-Day--start {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
          }
          .dateRangePicker .selectable .DayPicker-Day--end {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
          }     
          `
        } */}
      </style>
    </Box>
  )
}

CustomDateRangePicker.propTypes = {
  handleRangeSelect: PropTypes.func,
  handleCloseDateRangePicker: PropTypes.func,
  selectedRange: PropTypes.object,
  objectFilter: PropTypes.object,
  openDateRangePicker: PropTypes.bool
};