import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default function skeleton() {
  return (
    <Stack spacing={1}>
      <Skeleton sx={{ mt: 10 }} variant="text" height={56} />
      <Skeleton variant="text" height={56} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={'48%'} height={56} />
        <Skeleton variant="text" width={'48%'} height={56} />
      </Box>
      <Skeleton variant="text" height={56} />
      <Skeleton variant="text" height={56} />
      <Divider orientation="horizontal" />
      <Skeleton variant="text" height={56} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="text" width={'35%'} height={56} />
        <Skeleton variant="text" width={'35%'} height={56} />
        <Skeleton variant="text" width={'20%'} height={56} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Skeleton sx={{ mr: 2 }} variant="text" width={80} height={56} />
        <Skeleton variant="text" width={80} height={56} />
      </Box>
    </Stack>
  );
}
