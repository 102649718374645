import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicTable } from 'components/Table';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import ConfirmDialog from 'components/ConfirmDialog';

import { getDateTime } from 'lib/datetime';
import getStatus from 'lib/getStatus';
import { openDialog, closeDialog } from 'lib/dialog.js';
import { DIALOGS } from 'modules/dialogs/constants';
import { fetchUsers, requestDeleteUser, requestSuspendUser, requestRestoreUser } from 'modules/bionexUsers/slice';

export default function ManageUsers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // functions for user table
  const users = useSelector((state) => state.users.users);

  const getName = (first, last, email) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar sx={{ width: 40, height: 40 }}>
          {first.split('')[0]}
          {last.split('')[0]}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
          <Typography sx={{ wordBreak: 'break-all' }} variant='body1' component='div'>
            {first} {last}
          </Typography>
          <Typography
            sx={{ wordBreak: 'break-all', fontSize: '0.8rem', color: 'gray' }}
            variant='body1'
            component='div'
          >
            {email}
          </Typography>
        </Box>
      </Box>
    );
  };

  const createData = (id, name, createdAt, lastActivity, status, firstName, lastName) => {
    return { id, name, createdAt, lastActivity, status, firstName, lastName };
  };

  const data = users.map((user) => {
    return createData(
      user.id,
      getName(user.first_name, user.last_name, user.email),
      user.created_at,
      user.last_activity,
      user.status,
      user.first_name,
      user.last_name
    );
  });

  //sort by created_at
  data.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  // dataField has to match fields in createData function
  const headers = [
    {
      title: t('admin:usersTable.userName'),
      dataField: 'name',
      align: 'left',
      disablePadding: false,
      width: '40%',
    },
    {
      title: t('admin:usersTable.createdOn'),
      dataField: 'createdAt',
      align: 'left',
      disablePadding: false,
      width: '20%',
      format: (value) => getDateTime(value),
    },
    {
      title: t('admin:usersTable.lastActivity'),
      dataField: 'lastActivity',
      align: 'left',
      disablePadding: false,
      width: '20%',
      format: (value) => getDateTime(value),
    },
    {
      title: t('admin:usersTable.status'),
      dataField: 'status',
      align: 'left',
      disablePadding: false,
      width: '10%',
      format: (value) => getStatus(value, t),
    },
  ];

  // functions for suspend users
  const openConfirmSuspendDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_SUSPEND_DIALOG]);

  const onSuspendUser = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.CONFIRM_SUSPEND_DIALOG);
  };

  const handleCloseConfirmSuspendDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_SUSPEND_DIALOG);
  };

  const handleSubmitSuspendUser = () => {
    dispatch(
      requestSuspendUser({
        userId: selectedRow.id,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
      })
    );
    handleCloseConfirmSuspendDialog();
  };

  // functions for restore users
  const openConfirmRestoreDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_RESTORE_DIALOG]);

  const onRestorUser = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.CONFIRM_RESTORE_DIALOG);
  };

  const handleCloseConfirmRestoreDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_RESTORE_DIALOG);
  };

  const handleSubmitRestoreUser = () => {
    dispatch(
      requestRestoreUser({
        userId: selectedRow.id,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
      })
    );
    handleCloseConfirmRestoreDialog();
  };

  // functions for delete users
  const openConfirmDeleteDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_DELETE_DIALOG]);

  const onDeleteUser = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.CONFIRM_DELETE_DIALOG);
  };

  const handleCloseConfirmDeleteDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_DELETE_DIALOG);
  };

  const handleSubmitDeleteUser = () => {
    dispatch(
      requestDeleteUser({
        userId: selectedRow.id,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
      })
    );
    handleCloseConfirmDeleteDialog();
  };

  // functions for view users table

  const actions = [
    {
      title: t('admin:userActions.suspendUser'),
      callback: onSuspendUser,
    },
    {
      title: t('admin:userActions.restoreUser'),
      callback: onRestorUser,
    },
    {
      title: t('admin:userActions.deleteUser'),
      callback: onDeleteUser,
    },
  ];

  const getActions = (row, handleClose) => {
    let newActions = actions;
    if (row?.status === 0) {
      newActions = [actions[2]];
    }
    if (row?.status === 1) {
      newActions = [actions[0]];
    }
    if (row?.status === -1) {
      newActions = [actions[1]];
    }
    if (row?.status === 2) {
      newActions = [actions[1]];
    }

    return newActions.map((action) => {
      return (
        <MenuItem
          key={action.title}
          onClick={() => {
            action.callback(row);
            handleClose();
          }}
        >
          {action.title}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  // functions for invite user dialog
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenInviteUserDialog = () => {
    openDialog(dispatch, DIALOGS.INVITE_USER);
  };

  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 70px)',
        backgroundColor: 'grey1',
      }}
      maxWidth={false}
    >
      <Box>
        <Toolbar
          sx={{
            pl: { xs: 2, sm: 1, md: 0 },
            pr: { xs: 1, sm: 1, md: 0 },
            py: { xs: 2, sm: 2, md: 3 },
          }}
        >
          <Typography sx={{ display: 'flex', fontSize: '1.5rem' }} variant='h6' component='div'>
            Users
          </Typography>
          <Button
            startIcon={<AddIcon />}
            sx={{ ml: 'auto' }}
            variant='contained'
            size='large'
            onClick={handleOpenInviteUserDialog}
          >
            {t('admin:userActions.addUser')}
          </Button>
        </Toolbar>
        <BasicTable headers={headers} data={data} actions={actions} overrideGetActions={getActions} />
      </Box>
      <ConfirmDialog
        title={`${t('user:restoreDialog.confirm')} "${selectedRow?.firstName} ${selectedRow?.lastName}"?`}
        confirmText='Restore'
        openConfirmDialog={openConfirmRestoreDialog}
        handleCloseConfirmDialog={handleCloseConfirmRestoreDialog}
        handleSubmitConfirmDialog={handleSubmitRestoreUser}
      />
      <ConfirmDialog
        title={`${t('user:suspendDialog.confirm')} "${selectedRow?.firstName} ${selectedRow?.lastName}"?`}
        confirmText='Suspend'
        openConfirmDialog={openConfirmSuspendDialog}
        handleCloseConfirmDialog={handleCloseConfirmSuspendDialog}
        handleSubmitConfirmDialog={handleSubmitSuspendUser}
      />
      <ConfirmDialog
        title={`${t('user:deleteDialog.confirm')} "${selectedRow?.firstName} ${selectedRow?.lastName}"?`}
        confirmText='Delete'
        openConfirmDialog={openConfirmDeleteDialog}
        handleCloseConfirmDialog={handleCloseConfirmDeleteDialog}
        handleSubmitConfirmDialog={handleSubmitDeleteUser}
      />
    </Container>
  );
}
