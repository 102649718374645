import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getCustomers: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/`,
  },
  getFilteredCustomers: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/list`,
  },
  getStates: {
    url: `${ENV_VARIABLES.API_SERVER}location/countries/US/regions?limit=500`,
  },
  getCities: {
    url: `${ENV_VARIABLES.API_SERVER}location/cities`,
  },
  inviteCustomer: {
    url: `${ENV_VARIABLES.API_SERVER}hcps/`,
  },
  editCustomer: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/admin/${customerId}`,
  },
  manageCustomer: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/admin/${customerId}`,
  },
  signInHcp: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/sso`,
  },
  editFeature: {
    url: (customerId) => `${ENV_VARIABLES.API_SERVER}hcps/${customerId}/features`,
  }
};
