import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { ConfigureMachineDialog } from 'modules/machines/components/Dialogs';

import { DIALOGS } from 'modules/dialogs/constants';
import { closeDialog } from 'lib/dialog.js';
import { requestConfigureMachine, requestConfigurePreAddedMachine, clearSuccessAndError } from 'modules/machines/slice';

export default function ConfigureMachine({ selectedRow, setSelectedRow, sortData }) {
  const dispatch = useDispatch();

  const openConfigureMachineDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIGURE_MACHINE_DIALOG]);
  const createdMachine = useSelector((state) => state.machines.createdMachine);
  const error = useSelector((state) => state.machines.error);

  const handleCloseConfigureMachineDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIGURE_MACHINE_DIALOG);
  };

  const onSubmitConfigureMachineDialog = (data) => {
    if (sortData) {
      data.sortData = sortData;
    }

    // machine from general list will have ID
    if (data.id) {
      dispatch(requestConfigurePreAddedMachine(data));
    } else {
      dispatch(requestConfigureMachine(data));
    }
    // set this data for confirm dialog
    setSelectedRow(data);
  };

  // functions for confirm add machine dialog
  const onAddMoreMachine = () => {
    dispatch(clearSuccessAndError());
  };

  return (
    <ConfigureMachineDialog
      anchor='right'
      machine={selectedRow}
      createdMachine={createdMachine}
      onAddMoreMachine={onAddMoreMachine}
      onSubmitConfigureMachineDialog={onSubmitConfigureMachineDialog}
      error={error}
      openConfigureMachineDialog={openConfigureMachineDialog}
      handleCloseConfigureMachineDialog={handleCloseConfigureMachineDialog}
    />
  );
}

ConfigureMachine.propTypes = {
  selectedRow: PropTypes.object,
  setSelectedRow: PropTypes.func,
  sortData: PropTypes.object,
};
