import ManageSoftware from './containers/ManageSoftware';

const route = [
  {
    path: '/manage/software',
    element: <ManageSoftware />,
    private: true,
  },
];
export default route;
