import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
export default function CustomizedInputBase({ handleOpenDateRangePicker, handleFromChange, handleToChange, handleFromBlur, handleToBlur, fromValue, toValue, handleSetFocusFrom, handleSetFocusTo, focusFrom, focusTo }) {

  const onFocusFrom = () => {
    handleOpenDateRangePicker();
    handleSetFocusFrom();
  }

  const onFocusTo = () => {
    handleOpenDateRangePicker();
    handleSetFocusTo();
  }

  return (
    <Paper
      component="form"
      sx={{ p: '5px 4px', display: 'flex', alignItems: 'center', border: '1px solid #b3afaf', boxShadow: 'none', width: '280px' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, borderBottom: focusFrom ? '2px solid #ed9a00' : 'none' }}
        id='from-to'
        components={<TextField label={'Action/Event'} />}
        placeholder="From"
        value={fromValue}
        onChange={handleFromChange}
        onBlur={handleFromBlur}
        onFocus={onFocusFrom}
      />
      <ArrowRightAltRoundedIcon />

      <InputBase
        sx={{ ml: 1, flex: 1, borderBottom: focusTo ? '2px solid #ed9a00' : 'none' }}
        placeholder="To"
        value={toValue}
        onChange={handleToChange}
        onBlur={handleToBlur}
        onFocus={onFocusTo}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={() => handleOpenDateRangePicker()} color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DateRangeRoundedIcon />
      </IconButton>
    </Paper>
  );
}

CustomizedInputBase.propTypes = {
  handleOpenDateRangePicker: PropTypes.func,
  handleFromChange: PropTypes.func,
  handleToChange: PropTypes.func,
  handleFromBlur: PropTypes.func,
  handleToBlur: PropTypes.func,
  fromValue: PropTypes.string,
  toValue: PropTypes.string,
  handleSetFocusFrom: PropTypes.func,
  handleSetFocusTo: PropTypes.func,
  focusFrom: PropTypes.string,
  focusTo: PropTypes.string
};