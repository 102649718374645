import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { StepReplaceContext } from '../Dialogs/ReplaceMachineDialog';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetCurrentProcessReplace, requestGetListCpnAvailableReplace } from 'modules/machines/slice';
import { useSocketDataContext } from 'components/WebSocketProvider';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import moment from 'moment/moment';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFCC80' : '#308fe8',
  },
}));

export default function ContentStepCompleted({}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const stepReplaceData = useContext(StepReplaceContext);
  const { activeStep, handleDone, selectedRow, handleRetry } = stepReplaceData;
  const currentProcessReplace = useSelector((state) => state.machines.currentProcessReplace);

  const [disableDoneBtn, setDisableDoneBtn] = useState(true);
  const [canRetry, setCanRetry] = useState(false);
  const [textProcess, setTextProcess] = useState(null);

  const socketContext = useSocketDataContext();
  useEffect(() => {
    setTextProcess(t('machine:contentStepCompleted.inProgress'));
    return () => {
      // clearInterval()
    };
  }, []);

  useEffect(() => {
    if (activeStep) {
      switch (+activeStep) {
        case 0:
          setValue(0);
          setTextProcess(t('machine:contentStepCompleted.inProgress'));
          break;
        case 1:
          setValue(0);
          setTextProcess(t('machine:contentStepCompleted.inProgress'));
          break;
        case 2:
          setValue(0);
          setTextProcess(t('machine:contentStepCompleted.inProgress'));
          break;
        default:
          const currentStep = currentProcessReplace?.data?.current_step || 0;
          const maxStep = currentProcessReplace?.data?.max_step || 0;
          const _canRetry = currentProcessReplace?.data?.canRetry || false;

          if (currentStep && maxStep) {
            const process = (currentStep / maxStep) * 100;
            setValue(process);
            setDisableDoneBtn(process != 100);
            if (currentStep == maxStep) {
              setTextProcess(t('machine:contentStepCompleted.replaceSuccess'));
            }
            if (currentStep == maxStep) {
              setCanRetry(false);
              break;
            }
          } else {
            setValue(0);
            setDisableDoneBtn(true);
          }

          if (_canRetry) {
            setCanRetry(true);
            setDisableDoneBtn(true);
            setTextProcess(t('machine:contentStepCompleted.retry'));
          } else {
            setCanRetry(false);
          }
          break;
      }
    }

    return () => {};
  }, [activeStep, currentProcessReplace]);

  useEffect(() => {
    if (!selectedRow) {
      return;
    }

    const interval = setInterval(() => {
      dispatch(
        requestGetCurrentProcessReplace({
          id: selectedRow.id,
        })
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [socketContext.shouldRefreshMachines]);

  const renderContent = () => {
    return (
      <Box>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' component='div'>
          <BorderLinearProgress variant='determinate' value={value} />
        </Typography>
        <Typography sx={{ color: '#000', mb: 2 }} variant='p' component='div'>
          {textProcess}{' '}
          {canRetry && (
            <Button variant='text' disableRipple startIcon={<AutorenewIcon />} onClick={handleRetry}>
              Retry
            </Button>
          )}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button disabled={disableDoneBtn} variant='contained' sx={{ color: 'white' }} onClick={handleDone} autoFocus>
            {t('machine:contentStepCompleted.textBtnConfirm')}
          </Button>
        </Box>
      </Box>
    );
  };

  return renderContent();
}

ContentStepCompleted.propTypes = {};
