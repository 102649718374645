import { useEffect, useState } from 'react';
import isNil from 'lodash/lodash';
import { getSecondsFromExpiry, getSecondsFromPrevTime, getTimeFromSeconds } from 'helpers';

export default function useCustomWatchHook({ autoStart, offsetTimestamp, currentTime, isCountDown }) {
  const [passedSeconds, setPassedSeconds] = useState(
    getSecondsFromExpiry(offsetTimestamp, true, currentTime) || 0
  );

  const [prevTime, setPrevTime] = useState(new Date().getTime());
  const [seconds, setSeconds] = useState(passedSeconds + getSecondsFromPrevTime(prevTime || 0, true) || 0);
  const [isRunning, setIsRunning] = useState(autoStart);

  useEffect(() => {
    if (seconds !== currentTime) {
      setSeconds(currentTime);
      setPassedSeconds(getSecondsFromExpiry(prevTime, true, currentTime) || 0);
    }
  }, []);

  useEffect(() => {
    if (!isRunning) {
      return () => { };
    }
    const interval = setInterval(() => {
      if (isCountDown) {
        if (seconds <= 0 && isNil(seconds)) {
          setIsRunning(false);
          clearInterval(interval);
        } else {
          setSeconds(passedSeconds - getSecondsFromPrevTime(prevTime, true));
        }
      } else {
        setSeconds(passedSeconds + getSecondsFromPrevTime(prevTime, true));
      }
    }, isRunning ? 1000 : null);
    return () => clearInterval(interval);
  }, [seconds, isRunning, isCountDown]);


  const reset = (offset = 0, newAutoStart = true, isCountDown = false) => {
    const newPassedSeconds = isCountDown
      ? getSecondsFromExpiry(null, true, offset) || 0
      : getSecondsFromExpiry(offset, true) || 0;

    const newPrevTime = new Date().getTime();
    setPrevTime(newPrevTime);
    setPassedSeconds(newPassedSeconds);
    setIsRunning(newAutoStart);
    if (isCountDown) {
      setSeconds(newPassedSeconds - getSecondsFromPrevTime(newPrevTime, true));
      return;
    }
    setSeconds(newPassedSeconds + getSecondsFromPrevTime(newPrevTime, true));
    return
    // isCountDown
    //   ? setSeconds(newPassedSeconds - getSecondsFromPrevTime(newPrevTime, true))
    //   : setSeconds(newPassedSeconds + getSecondsFromPrevTime(newPrevTime, true));
  }

  return {
    ...getTimeFromSeconds(seconds),
    reset,
    isRunning,
  };
}
