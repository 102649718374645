import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCustomWatchHook from 'hooks/useCustomWatchHook';
import useNetwork from 'hooks/useNetwork';

const CountDownResend = (props) => {

  const { countdownTime, handleResendCode } = props;
  const { t } = useTranslation();
  const isOnline = useNetwork();
  const [enableResend, setEnableResend] = useState(false);
  const { seconds, minutes, isRunning, reset } = useCustomWatchHook({
    autoStart: true,
    currentTime: Number(countdownTime), //initial time in second
    isCountDown: true,
  });

  useEffect(() => {
    if (!isRunning) {
      setEnableResend(true);
    }

  }, [isRunning]);

  const resendCode = useCallback(
    () => {
      setEnableResend(false);
      reset(Number(countdownTime), true, true);
      handleResendCode();
    },
    [countdownTime],
  )

  return (
    <Box sx={{ width: enableResend ? '100px' : '200px' }}>
      {enableResend ? (
        <Box sx={{ cursor: isOnline ? 'pointer' : 'default', mt: 1 }} onClick={() => isOnline && resendCode()}>
          <Typography sx={{ fontWeight: '600', fontSize: '16px', color: isOnline ? '#0e0326' : '#9CA3AF' }}>
            {t('auth:twoFaDialog.resendCode')}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography sx={{ color: '#9CA3AF', mt: 1 }}>
            {t('auth:twoFaDialog.codeExpireIn', { minute: minutes, second: seconds })}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

CountDownResend.propTypes = {
  countdownTime: PropTypes.number,
  handleResendCode: PropTypes.func
}

export default CountDownResend