import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import { machineTypes } from 'modules/machines/components/Dialogs';

const drawerWidth = '50%';

export default function ConfigureMachineDialog({
  machine,
  createdMachine,
  anchor,
  error,
  onSubmitConfigureMachineDialog,
  openConfigureMachineDialog,
  handleCloseConfigureMachineDialog,
}) {
  const defaultValues = {
    name: '',
    serialNo: '',
    type: '',
    converterId: '',
    macAddress: '',
  };

  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const [success, setSuccessState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    reset,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues });

  // prefill form with machine data
  useEffect(() => {
    if (openConfigureMachineDialog) {
      reset(defaultValues);
      setErrorMessage('');
    }
    // data coming from NewList Machines
    if (machine && machine.originalData) {
      setValue('converterId', machine.originalData.u12_id.trim());
      setValue('macAddress', machine.originalData.mac_address.trim());
    }

    // data coming from GeneralList Machines
    if (machine && machine.machine) {
      setValue('name', machine.machine.name);
      setValue('serialNo', machine.machine.serial_no);
      setValue('type', machine.machine.machine_type);
      setValue('converterId', machine.machine.u12_id.trim());
      setValue('macAddress', machine.machine.mac_addr.trim());
    }
  }, [openConfigureMachineDialog]);

  useEffect(() => {
    if (error && error.code === 400) {
      if (
        error.error === 'This converter ID already exist' ||
        error.error === 'Another machine already has this converter ID'
      ) {
        setError('converterId', {
          type: 'converter_exists',
        });
      } else if (
        error.error === 'This MAC address already exist' ||
        error.error === 'Another machine already has this MAC address'
      ) {
        setError('macAddress', {
          type: 'mac_exists',
        });
      } else if (error.error === 'This serial number already exist') {
        setError('serialNo', {
          type: 'serial_exists',
        });
      } else {
        setErrorMessage(error.error);
      }
    }
  }, [error]);

  //when create new machine success
  useEffect(() => {
    if (createdMachine && createdMachine.id) {
      setSuccessState(true);
    }
  }, [createdMachine]);

  const onClose = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
  };

  const closeModal = () => {
    if (handleCloseConfigureMachineDialog) {
      handleCloseConfigureMachineDialog();
    }
    setSuccessState(false);
    reset(defaultValues);
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    let submitData = { ...data };

    //only machine from general list has id
    if (machine && machine.machine) {
      submitData = { ...data, id: machine.machine.id };
    }

    if (onSubmitConfigureMachineDialog) {
      onSubmitConfigureMachineDialog(submitData);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  const allowAlphaNumericSpace = (e) => {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code == 32) && // space
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  };

  const getNameErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'pattern') return t('customer:inviteDialog.errors.invalidNamePattern');
  };

  const getSerialErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'serial_exists') return t('machine:configureDialog.errors.serialExists');
  };

  const getConverterErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'converter_exists') return t('machine:configureDialog.errors.converterExists');
  };

  const getMacAddressErrorMessage = (error) => {
    if (error?.type === 'required') return t('common:errors.required');
    if (error?.type === 'mac_exists') return t('machine:configureDialog.errors.rasSerialExists');
  };

  const renderContent = () => {
    return (
      <Box sx={{ width: '65%', position: 'relative' }} role='presentation'>
        {success && createdMachine ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                color: '#2e7d32',
                pt: 5,
                pb: 3,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  mr: 1,
                  fontSize: '2.3rem',
                }}
              />
              <Typography
                sx={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                }}
              >
                {t('machine:confirmAddMachine.newMachine')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', py: 3, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:close')}
              </Button>
            </Box>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography sx={{ fontSize: '1.5rem', pt: 2, fontWeight: 500 }} variant='h2'>
                {t('machine:configureDialog.title')}
              </Typography>
              <Typography sx={{ mb: 1 }} variant='body1'>
                {t('machine:configureDialog.subtitle')}
              </Typography>
              <Typography sx={{ mb: 1, color: 'red' }} variant='body1'>
                {t('machine:configureDialog.attention')}
              </Typography>

              {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
              <Controller
                name='serialNo'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowAlphaNumericSpace(e);
                    }}
                    error={!!errors.serialNo}
                    helperText={getSerialErrorMessage(errors.serialNo)}
                    margin='normal'
                    fullWidth
                    id='serialNo'
                    value={value}
                    label={t('machine:configureDialog.serialNo')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 13,
                    }}
                  />
                )}
              />
              <Controller
                name='name'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.name}
                    helperText={getNameErrorMessage(errors.name)}
                    margin='normal'
                    fullWidth
                    id='name'
                    value={value}
                    label={t('machine:configureDialog.name')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                )}
              />
              <Box sx={{ mt: 2, mb: 1 }}>
                <Controller
                  name='type'
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl error={!!errors.type} fullWidth>
                      <InputLabel>{t('machine:configureDialog.machineType')}</InputLabel>
                      <Select value={value} label={t('machine:configureDialog.machineType')} onChange={onChange}>
                        {machineTypes.map((type) => (
                          <MenuItem key={type.id} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.type}>
                        {errors.type && t('common:errors.required')}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Box>
              <Controller
                name='converterId'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onKeyPress={(e) => {
                      allowAlphaNumericSpace(e);
                    }}
                    error={!!errors.converterId}
                    helperText={getConverterErrorMessage(errors.converterId)}
                    margin='normal'
                    fullWidth
                    id='converterId'
                    value={value}
                    disabled
                    label={t('machine:configureDialog.converterId')}
                    onChange={onChange}
                    inputProps={{
                      maxLength: 16,
                    }}
                  />
                )}
              />
              <Controller
                name='macAddress'
                control={control}
                rules={{
                  required: true,
                  validate: {
                    required: (v) => !!v.trim(),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={!!errors.macAddress}
                    helperText={getMacAddressErrorMessage(errors.macAddress)}
                    margin='normal'
                    fullWidth
                    id='macAddress'
                    disabled
                    value={value}
                    label={t('machine:configureDialog.macAddress')}
                    onChange={onChange}
                    InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', my: 2, justifyContent: 'flex-end' }}>
              <Button sx={{ mr: 2 }} variant='outlined' onClick={closeModal}>
                {t('common:cancel')}
              </Button>
              <LoadingButton loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant='contained'>
                {t('common:configure')}
              </LoadingButton>
            </Box>
          </form>
        )}
      </Box>
    );
  };

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      anchor={anchor}
      open={openConfigureMachineDialog}
      onClose={onClose}
    >
      {renderContent()}
    </Drawer>
  );
}

ConfigureMachineDialog.propTypes = {
  machine: PropTypes.object,
  createdMachine: PropTypes.object,
  anchor: PropTypes.string,
  error: PropTypes.object,
  openConfigureMachineDialog: PropTypes.bool,
  handleCloseConfigureMachineDialog: PropTypes.func,
  onSubmitConfigureMachineDialog: PropTypes.func,
};
