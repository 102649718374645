import { Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { getEmailErrorMessage, getNameErrorMessage } from 'helpers';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

export default function CustomerCpn({ customer, isEditCustomer }) {

  const { t } = useTranslation();
  const { control, formState, getValues } = useFormContext();

  const { errors } = formState;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ width: '48%' }}>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: true,
              pattern: /^[a-zA-Z0-9 ]*$/,
              validate: {
                required: (v) => !!v.trim(),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!errors.firstName}
                helperText={getNameErrorMessage(errors.firstName, t)}
                margin='normal'
                fullWidth
                id='firstName'
                value={value}
                label={t('customer:hcp.ownerFirstName')}
                onChange={onChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ width: '48%' }}>
          <Controller
            name='lastName'
            control={control}
            rules={{
              required: true,
              pattern: /^[a-zA-Z0-9 ]*$/,
              validate: {
                required: (v) => !!v.trim(),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!errors.lastName}
                helperText={getNameErrorMessage(errors.lastName, t)}
                margin='normal'
                fullWidth
                value={value}
                id='lastName'
                label={t('customer:hcp.ownerLastName')}
                onChange={onChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
            )}
          />
        </Box>
      </Box>
      <Controller
        name='email'
        rules={{
          required: true,
          pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            error={!!errors.email}
            helperText={getEmailErrorMessage(errors.email, t)}
            margin='normal'
            disabled={isEditCustomer && customer.status !== 0}
            fullWidth
            id='email'
            value={value}
            label={t('common:emailAddress')}
            inputProps={{
              maxLength: 255,
            }}
            onChange={onChange}
          />
        )}
      />
      <FormControl
        error={!!errors.phoneNumber}
        fullWidth
        sx={{
          my: 2,

          '&:focus-within': {
            '.special-label': {
              color: '#ED9A00',
            },

            '& .error': {
              input: {
                boxShadow: '0 0 0 1px #d32f2f !important',
              },
            },
          },

          '.form-control:focus': {
            boxShadow: '0 0 0 1px #ED9A00 !important',
            borderColor: '#ED9A00 !important',
          },

          '& .error': {
            input: {
              // boxShadow: '0 0 0 1px #d32f2f !important',
              boxShadow: 'none !important',
              border: '1px solid #d32f2f !important',
            },
            '.special-label': {
              color: '#d32f2f',
            },
          },

          '.search-box': {
            height: '40px',
            width: '250px',
          },
        }}
      >
        <Controller
          name='phoneNumber'
          rules={{
            required: true,
          }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              inputStyle={{
                width: '100%',
                height: '56px',
                fontSize: '15px',
                paddingLeft: '60px',
                borderRadius: '4px',
              }}
              id='phoneNumber'
              country={'us'}
              value={value}
              containerClass={errors.phoneNumber ? 'error' : ''}
              enableSearch
              onChange={onChange}
              searchNotFound={'No data found.'}
              specialLabel={t('customer:hcp.ownerPhoneNumber')}
              placeholder={''}
            />
          )}
        />
        <FormHelperText error={!!errors.phoneNumber}>
          {errors.phoneNumber && t('common:errors.required')}
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

CustomerCpn.propTypes = {
  customer: PropTypes.any,
  isEditCustomer: PropTypes.bool,
};
