import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { requestCloseAllDialogs } from 'modules/dialogs/slice';
import { useDispatch } from 'react-redux';

import InviteUser from 'modules/bionexUsers/containers/InviteUser';
import InviteCustomer from 'modules/customers/containers/InviteCustomer';

// import SearchPatient from 'modules/patients/containers/SearchPatient';

export default function Dialogs() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    //if location change clear dialogs state
    if (location) {
      dispatch(requestCloseAllDialogs());
    }
  }, [location]);
  return (
    <>
      <InviteUser />
      <InviteCustomer />
    </>
  );
}
