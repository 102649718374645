import { requestDialog } from 'modules/dialogs/slice';

export function openDialog(dispatch, dialog, fromNavbar = false) {
  dispatch(requestDialog({ type: dialog, open: true, fromNavbar: fromNavbar }));
}

export function closeDialog(dispatch, dialog, fromNavbar = false) {
  dispatch(
    requestDialog({ type: dialog, open: false, fromNavbar: fromNavbar })
  );
}
