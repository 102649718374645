import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  fetchMachines,
  fetchMachinesSuccess,
  fetchMachinesFailure,
  fetchDetectedMachines,
  fetchDetectedMachinesSuccess,
  fetchDetectedMachinesFailure,
  requestAddMachine,
  requestAddMachineSuccess,
  requestAddMachineFailure,
  requestEditMachine,
  requestEditMachineSuccess,
  requestEditMachineFailure,
  requestAssignMachine,
  requestAssignMachineSuccess,
  requestAssignMachineFailure,
  requestRemoveMachine,
  requestRemoveMachineSuccess,
  requestRemoveMachineFailure,
  requestConfigureMachine,
  requestConfigureMachineSuccess,
  requestConfigureMachineFailure,
  requestConfigurePreAddedMachine,
  requestConfigurePreAddedMachineSuccess,
  requestConfigurePreAddedMachineFailure,
  fetchHcpConfiguredMachines,
  fetchHcpConfiguredMachinesSuccess,
  fetchHcpConfiguredMachinesFailure,
  fetchHcpMachines,
  fetchHcpMachinesSuccess,
  fetchHcpMachinesFailure,
  requestLoggingRecordActionSuccess,
  requestLoggingRecordActionFailed,
  requestLoggingRecordAction,
  fetchHcpOfficeSuccess,
  fetchHcpOfficesFailure,
  fetchHcpOffices,
  requestUnassignMachineSuccess,
  requestUnassignMachineFailure,
  requestUnassignMachine,
  requestUnassignComponent,
  requestUnassignComponentSuccess,
  requestUnassignComponentFailure,
  requestGetListCpnAvailableReplace,
  requestGetListCpnAvailableReplaceSuccess,
  requestGetListCpnAvailableReplaceFailure,
  requestConfirmReplaceMachine,
  requestConfirmReplaceMachineFailure,
  requestReplaceMachineLater,
  requestReplaceMachineLaterSuccess,
  requestReplaceMachineLaterFailure,
  requestGetCurrentProcessReplace,
  requestGetCurrentProcessReplaceSuccess,
  requestGetCurrentProcessReplaceFailure,
  requestFinishReplaceMachineSuccess,
  requestFinishReplaceMachineFailure,
  requestFinishReplaceMachine,
  requestConfirmReplaceMachineSuccess,
  requestRetryRepairMachine,
} from './slice';

import { requestDialog } from 'modules/dialogs/slice';
import { END_POINT } from './constants';
import { requestToast } from 'modules/ui/slice';
import { TOAST_TYPE } from 'modules/ui/constants';
import { DIALOGS } from 'modules/dialogs/constants';
import isNil from 'lodash/isNil';

function* getMachines(action) {
  const query = action.payload || {};
  const filterStatus = query.filterStatus >= -1 ? query.filterStatus : '';
  const filterType = query.filterType ? query.filterType : '';
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';
  const mergedFilters = [];
  const mergedValues = [];

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatus !== '' && filterStatus >= -1) {
    mergedFilters.push('status');
    mergedValues.push(filterStatus);
  }
  if (filterType) {
    mergedFilters.push('machine_type');
    mergedValues.push(filterType);
  }

  if (mergedFilters.length > 0) {
    queryString.push(`filter_by=${mergedFilters.join(',')}&filter_value=${mergedValues.join(',')}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getMachines.url}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      yield put(fetchMachinesSuccess(data.data));
    } else {
      yield put(fetchMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchMachinesFailure(e.message));
  }
}

function* getDetectedMachines(action) {
  const query = action.payload || {};
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const itemPerPage = query.itemPerPage || '';

  let queryString = [];
  if (itemPerPage) {
    queryString.push(`item_per_page=${itemPerPage}`);
  }
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(`${END_POINT.getDetectedMachines.url}${queryString ? `?${queryString}` : ''}`);
    if (data.data) {
      yield put(fetchDetectedMachinesSuccess(data.data));
    } else {
      yield put(fetchDetectedMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchDetectedMachinesFailure(e.message));
  }
}

function* addMachine(action) {
  try {
    const { data } = yield axios.post(END_POINT.addMachine.url, {
      name: action.payload.name.trim(),
      serial_no: action.payload.number.trim(),
      machine_type: action.payload.type,
      u12_id: action.payload.converterId,
      mac_addr: action.payload.macAddress,
      notes: '',
    });
    if (data.code === 201) {
      yield put(requestAddMachineSuccess(data));
      yield put(fetchMachines(action.payload.sortData));
    } else {
      yield put(requestAddMachineFailure(data));
    }
  } catch (e) {
    yield put(requestAddMachineFailure(e.message));
  }
}

function* editMachine(action) {
  try {
    const machine = {
      name: action.payload.name ? action.payload.name.trim() : undefined,
      serial_no: action.payload.number ? action.payload.number.trim() : undefined,
      machine_type: action.payload.type || undefined,
      u12_id: action.payload.converterId,
      mac_addr: action.payload.macAddress,
      lode_cell_calibrated_at: action.payload.lodeCellCalibratedAt || undefined,
      pot_calibrated_at: action.payload.potCalibratedAt || undefined,
    };
    Object.keys(machine).map((key) => {
      if (machine[key] === undefined) {
        delete machine[key];
      }
    });

    const { data } = yield axios.put(END_POINT.editMachine.url(action.payload.id), machine);

    if (data.code === 200) {
      yield put(fetchMachines(action.payload.sortData));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'Machines information updated successfully.',
        })
      );
      yield put(requestDialog({ type: DIALOGS.ADD_MACHINE_DIALOG, open: false }));
      yield put(requestEditMachineSuccess(data));
    } else {
      yield put(requestEditMachineFailure(data));
    }
  } catch (e) {
    yield put(requestEditMachineFailure(e.message));
  }
}

function* assignMachine(action) {
  try {
    const { data } = yield axios.put(END_POINT.assignMachine.url(action.payload.id), {
      hcp_id: action.payload.hcp.id,
      hcp_allow_config: action.payload.canConfigure === 1 ? true : false,
      office_id: action.payload.office.id,
    });
    if (data.code === 201) {
      yield put(requestAssignMachineSuccess(data));
      yield put(fetchMachines(action.payload.sortData));
    } else {
      yield put(requestAssignMachineFailure(data));
    }
  } catch (e) {
    yield put(requestAssignMachineFailure(e.message));
  }
}

function* removeMachine(action) {
  try {
    const { data } = yield axios.delete(END_POINT.removeMachine.url(action.payload.id));
    if (data.code === 200) {
      yield put(requestRemoveMachineSuccess(data));
      yield put(fetchMachines());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `"${action.payload.serial}" removed.`,
        })
      );
    } else {
      yield put(requestRemoveMachineFailure(data));
    }
  } catch (e) {
    yield put(requestRemoveMachineFailure(e.message));
  }
}

function* configureMachine(action) {
  try {
    const { data } = yield axios.post(END_POINT.configureMachine.url, {
      name: action.payload.name.trim(),
      serial_no: action.payload.serialNo.trim(),
      machine_type: action.payload.type,
      u12_id: action.payload.converterId,
      mac_address: action.payload.macAddress,
    });
    if (data.code === 200) {
      yield put(requestConfigureMachineSuccess());
      //close dialog
      yield put(requestDialog({ type: DIALOGS.CONFIGURE_MACHINE_DIALOG, open: false }));
      // get detected machines
      yield put(fetchDetectedMachines());
    } else {
      yield put(requestConfigureMachineFailure(data));
    }
  } catch (e) {
    yield put(requestConfigureMachineFailure(e.message));
  }
}

//updateMachines

function* configurePreAddedMachine(action) {
  try {
    const { data } = yield axios.post(END_POINT.configurePreAddedMachine.url(action.payload.id), {
      name: action.payload.name.trim(),
      serial_no: action.payload.serialNo.trim(),
      machine_type: action.payload.type,
      u12_id: action.payload.converterId,
      mac_address: action.payload.macAddress,
    });
    if (data.code === 200) {
      yield put(requestConfigurePreAddedMachineSuccess());
      //close dialog
      yield put(requestDialog({ type: DIALOGS.CONFIGURE_MACHINE_DIALOG, open: false }));
      //get machines
      yield put(fetchMachines(action.payload.sortData));
    } else {
      yield put(requestConfigurePreAddedMachineFailure(data));
    }
  } catch (e) {
    yield put(requestConfigurePreAddedMachineFailure(e.message));
  }
}

function* getHcpConfiguredMachines(action) {
  const hcpId = action.payload.hcpId;
  const machineType = action.payload.machineType;

  let queryString = [];
  if (hcpId) {
    queryString.push(`hcp_id=${hcpId}`);
  }
  if (machineType) {
    queryString.push(`machine_type=${machineType}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.getHcpConfiguredMachines.url}${queryString ? `?${queryString}` : ''}`
    );
    if (data.code === 200) {
      yield put(fetchHcpConfiguredMachinesSuccess(data.data));
    } else {
      yield put(fetchHcpConfiguredMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpConfiguredMachinesFailure(e.message));
  }
}

function* getHcpMachines(action) {
  const query = action.payload || {};
  const filterStatuses = query.filterStatuses || [];
  const keyword = query.keyword || '';
  const orderBy = query.orderBy || '';
  const order = query.order || '';
  const mergedFilters = [];
  let mergedValues = [];

  let queryString = [];
  if (orderBy) {
    queryString.push(`sort_by=${orderBy}`);
  }
  if (order) {
    queryString.push(`sort_direction=${order}`);
  }
  if (keyword) {
    queryString.push(`keyword=${encodeURIComponent(keyword.trim())}`);
  }
  if (filterStatuses.length) {
    for (let i = 0; i < filterStatuses.length; i++) {
      mergedFilters.push('status');
    }
    mergedValues = [...mergedValues, ...filterStatuses];
  }

  if (mergedFilters.length > 0) {
    queryString.push(`filter_by=${mergedFilters.join(',')}&filter_value=${mergedValues.join(',')}`);
  }

  if (queryString.length) {
    queryString = queryString.join('&');
  } else {
    queryString = '';
  }
  try {
    const { data } = yield axios.get(
      `${END_POINT.getHcpMachines.url(action.payload.hcpId)}${queryString ? `?${queryString}` : ''}`
    );
    if (data.code === 200) {
      yield put(fetchHcpMachinesSuccess(data.data));
    } else {
      yield put(fetchHcpMachinesFailure({ code: data.code }));
    }
  } catch (e) {
    yield put(fetchHcpMachinesFailure(e.message));
  }
}

function* requestLoggingRecordActionHandler(action) {
  try {
    yield axios.post(
      END_POINT.requestLoggingRecordAction.url,
      { ...action.payload },
      {
        headers: {
          Authorization: `Bearer ${action.payload.token}`,
        },
      }
    );
    yield put(requestLoggingRecordActionSuccess());
  } catch (e) {
    yield put(requestLoggingRecordActionFailed(e));
  }
}

function* fetchHcpOfficeHandler(action) {
  try {
    const { data } = yield axios.get(END_POINT.fetchHcpOfficeAction.url(action.payload.hcpId), {
      headers: {
        Authorization: `Bearer ${action.payload.token}`,
      },
    });
    yield put(fetchHcpOfficeSuccess(data));
  } catch (e) {
    yield put(fetchHcpOfficesFailure(e));
  }
}

function* unassignMachine(action) {
  try {
    const { data } = yield axios.delete(END_POINT.unassignMachine.url(action.payload.id));
    if (data.code === 200) {
      yield put(requestUnassignMachineSuccess(data));
      yield put(fetchMachines());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: `Machine is unassigned successfully.`,
        })
      );
    } else {
      yield put(requestUnassignMachineFailure(data));
    }
  } catch (e) {
    yield put(requestUnassignMachineFailure(e.message));
  }
}

function* unassignComponent(action) {
  try {
    const { data } = yield axios.post(END_POINT.unassignComponent.url(action.payload.id), {
      parts: action.payload.parts,
    });

    if (data.code === 200) {
      yield put(requestUnassignComponentSuccess({ isUnassignCpnSuccess: true }));
      yield put(fetchMachines());
      // yield put(
      //   requestToast({
      //     type: TOAST_TYPE.SUCCESS,
      //     message: `Component is unassigned successfully.`,
      //   })
      // );
    } else {
      yield put(requestUnassignComponentFailure(data));
    }
  } catch (e) {
    yield put(requestUnassignComponentFailure(e.message));
  }
}

function* retryRepairComponent(action) {
  try {
    const { data } = yield axios.put(END_POINT.retryReplaceMachine.url(action.payload.id));

    if (data.code === 200) {
      // yield put(requestUnassignComponentSuccess({ isUnassignCpnSuccess: true }));
      yield put(requestGetCurrentProcessReplace());
    }
  } catch (e) {
    // yield put(requestUnassignComponentFailure(e.message));
  }
}

function* getListCpnAvailableReplace(action) {
  try {
    const { data } = yield axios.get(END_POINT.getListCpnAvailableReplace.url(action.payload.id));
    if (data.code === 200) {
      yield put(requestGetListCpnAvailableReplaceSuccess(data?.data?.detected_parts));
      // yield put(
      //   requestToast({
      //     type: TOAST_TYPE.SUCCESS,
      //     message: `Component is unassigned successfully.`,
      //   })
      // );
    } else {
      yield put(requestGetListCpnAvailableReplaceFailure(data));
    }
  } catch (e) {
    yield put(requestGetListCpnAvailableReplaceFailure(e.message));
  }
}

function* confirmReplaceMachine(action) {
  try {
    const { data } = yield axios.put(END_POINT.confirmReplaceMachine.url(action.payload.id), {
      parts: action.payload.parts,
    });
    if (data.code === 200) {
      yield put(requestConfirmReplaceMachineSuccess({ isReplaceMachineSuccess: true }));
      // yield put(
      //   requestToast({
      //     type: TOAST_TYPE.SUCCESS,
      //     message: `Component is unassigned successfully.`,
      //   })
      // );
    } else {
      yield put(requestConfirmReplaceMachineFailure(data));
    }
  } catch (e) {
    yield put(requestConfirmReplaceMachineFailure(e.message));
  }
}

function* replaceMachineLater(action) {
  try {
    const { data } = yield axios.delete(END_POINT.replaceMachineLater.url(action.payload.id));

    if (data.code === 200) {
      yield put(requestReplaceMachineLaterSuccess({ isReplaceLaterSuccess: true }));
      // yield put(
      //   requestToast({
      //     type: TOAST_TYPE.SUCCESS,
      //     message: `Component is unassigned successfully.`,
      //   })
      // );
    } else {
      yield put(requestReplaceMachineLaterFailure(data));
    }
  } catch (e) {
    yield put(requestReplaceMachineLaterFailure(e.message));
  }
}

function* getCurrentProcessReplace(action) {
  try {
    const { data } = yield axios.get(END_POINT.getCurrentProcessReplace.url(action.payload.id));

    if (data.code === 200) {
      yield put(requestGetCurrentProcessReplaceSuccess(data));
      // yield put(
      //   requestToast({
      //     type: TOAST_TYPE.SUCCESS,
      //     message: `Component is unassigned successfully.`,
      //   })
      // );
    } else if (data.code === 404 && isNil(data.data)) {
      yield put(requestGetCurrentProcessReplaceSuccess(data));
    } else {
      yield put(requestGetCurrentProcessReplaceFailure(data));
    }
  } catch (e) {
    yield put(requestGetCurrentProcessReplaceFailure(e.message));
  }
}

function* finishReplaceMachine(action) {
  try {
    const { data } = yield axios.get(END_POINT.finishReplaceMachine.url(action.payload.id));

    if (data.code === 200) {
      yield put(requestFinishReplaceMachineSuccess({ isFinishReplaceSuccess: true }));
      yield put(fetchMachines());
      // yield put(
      //   requestToast({
      //     type: TOAST_TYPE.SUCCESS,
      //     message: `Component is unassigned successfully.`,
      //   })
      // );
    } else {
      yield put(requestFinishReplaceMachineFailure(data));
    }
  } catch (e) {
    yield put(requestFinishReplaceMachineFailure(e.message));
  }
}

function* machineSaga() {
  yield takeLatest(fetchMachines, getMachines);
  yield takeLatest(fetchDetectedMachines, getDetectedMachines);
  yield takeLatest(requestAddMachine, addMachine);
  yield takeLatest(requestAssignMachine, assignMachine);
  yield takeLatest(requestEditMachine, editMachine);
  yield takeLatest(requestRemoveMachine, removeMachine);
  yield takeLatest(requestConfigureMachine, configureMachine);
  yield takeLatest(requestConfigurePreAddedMachine, configurePreAddedMachine);
  yield takeLatest(fetchHcpConfiguredMachines, getHcpConfiguredMachines);
  yield takeLatest(fetchHcpMachines, getHcpMachines);
  yield takeLatest(requestLoggingRecordAction, requestLoggingRecordActionHandler);
  yield takeLatest(fetchHcpOffices, fetchHcpOfficeHandler);
  yield takeLatest(requestUnassignMachine, unassignMachine);
  yield takeLatest(requestUnassignComponent, unassignComponent);
  yield takeLatest(requestGetListCpnAvailableReplace, getListCpnAvailableReplace);
  yield takeLatest(requestConfirmReplaceMachine, confirmReplaceMachine);
  yield takeLatest(requestReplaceMachineLater, replaceMachineLater);
  yield takeLatest(requestGetCurrentProcessReplace, getCurrentProcessReplace);
  yield takeLatest(requestFinishReplaceMachine, finishReplaceMachine);
  yield takeLatest(requestRetryRepairMachine, retryRepairComponent);
}

export default machineSaga;
