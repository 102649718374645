import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

export default function DashboardLinks({ links }) {
  const navigator = useNavigate();

  const goToLink = (link) => {
    if (link.disabled) return;
    navigator(link.url);
  };
  return links.map((link) => (
    <Box
      key={link.url}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <IconButton
        disabled={link.disabled}
        onClick={() => goToLink(link)}
        sx={{
          backgroundColor: 'white',
          padding: '1rem',
          border: link.disabled ? '1px solid #cccccc' : '',
        }}
      >
        {link.icon}
      </IconButton>

      <Typography
        onClick={() => goToLink(link)}
        sx={{
          color: link.disabled ? 'gray' : 'black',
          cursor: 'pointer',
          textTransform: 'capitalize',
          fontSize: '14px',
          mt: '1rem',
        }}
      >
        {link.name}
      </Typography>
    </Box>
  ));
}
