import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  getUsers: {
    url: `${ENV_VARIABLES.API_SERVER}users/?item_per_page=100`,
  },
  inviteUser: {
    url: `${ENV_VARIABLES.API_SERVER}users/`,
  },
  manageUser: {
    url: (userId) => `${ENV_VARIABLES.API_SERVER}users/${userId}`,
  },
  getRoles: {
    url: `${ENV_VARIABLES.API_SERVER}users/roles`,
  },
};
