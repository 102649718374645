import { createSlice } from '@reduxjs/toolkit';

export const softwareUploadSlice = createSlice({
  name: 'softwareUploadSlice',
  initialState: {
    isFetching: false,

    listVersionFetching: false,
    versions: [],

    latestVersion: {},
    fetchLatestVersion: false,
  },
  reducers: {
    requestUpdateSoftwareVersion: (state) => {
      state.isFetching = true;
    },
    requestUpdateSoftwareVersionSuccess: (state) => {
      state.isFetching = false;
    },
    requestUpdateSoftwareVersionFailure: (state) => {
      state.isFetching = false;
    },

    listVersion: (state) => {
      state.listVersionFetching = true;
    },
    listVersionSuccess: (state, action) => {
      state.listVersionFetching = false;
      state.versions = action.payload;
    },
    listVersionFailure: (state) => {
      state.listVersionFetching = false;
    },

    getLatestVersion: (state) => {
      state.fetchLatestVersion = true;
    },
    getLatestVersionSuccess: (state, action) => {
      state.fetchLatestVersion = false;
      state.latestVersion = action.payload;
    },
    getLatestVersionFailure: (state) => {
      state.fetchLatestVersion = false;
    },

    requestPushToHcp: () => {},
  },
});

export const {
  listVersion,
  listVersionFailure,
  listVersionSuccess,

  requestUpdateSoftwareVersion,
  requestUpdateSoftwareVersionSuccess,
  requestUpdateSoftwareVersionFailure,

  requestPushToHcp,

  getLatestVersion,
  getLatestVersionFailure,
  getLatestVersionSuccess,
} = softwareUploadSlice.actions;

export default softwareUploadSlice.reducer;
