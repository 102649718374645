import { useSelector, useDispatch } from 'react-redux';

import { useEffect } from 'react';

import InviteUserDialog from 'modules/bionexUsers/components/InviteUserDialog';

import { closeDialog } from 'lib/dialog.js';
import { DIALOGS } from 'modules/dialogs/constants';
import {
  fetchRoles,
  requestInviteUser,
  clearSuccessAndError,
} from 'modules/bionexUsers/slice';

export default function InviteUser() {
  const dispatch = useDispatch();

  const createdUser = useSelector((state) => state.users.createdUser);
  const roles = useSelector((state) => state.users.roles);
  const error = useSelector((state) => state.users.error);
  const openInviteUserDialog = useSelector(
    (state) => state.dialogs[DIALOGS.INVITE_USER]
  );

  useEffect(() => {
    if (openInviteUserDialog) {
      dispatch(fetchRoles());
    }
  }, [openInviteUserDialog]);

  const handleCloseInviteUserDialog = () => {
    closeDialog(dispatch, DIALOGS.INVITE_USER);
  };

  const onSubmitInviteUserDialog = (data) => {
    dispatch(requestInviteUser({ ...data, email: data.email.toLowerCase() }));
  };

  // functions for invite more user
  const onAddMoreUser = () => {
    dispatch(clearSuccessAndError());
  };

  if (!roles) return <></>;

  return (
    <InviteUserDialog
      anchor="right"
      onSubmitInviteUserDialog={onSubmitInviteUserDialog}
      createdUser={createdUser}
      onAddMoreUser={onAddMoreUser}
      error={error}
      roles={roles}
      openInviteUserDialog={openInviteUserDialog}
      handleCloseInviteUserDialog={handleCloseInviteUserDialog}
    />
  );
}
