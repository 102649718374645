import { Autocomplete, Box, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import axios from 'axios';
import ENV_VARIABLES from 'config/variables';
import { getNameErrorMessage } from 'helpers';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import InfoListAssignMachine from './InfoListAssignMachine';
import isEmpty from 'lodash/isEmpty';

export default function OfficeCpn({ office, index, remove }) {

  const { t } = useTranslation();

  const { control, formState, watch, getValues, setValue } = useFormContext();
  const states = useSelector((state) => state.customers.states);
  const [stateSingle, setStateSingle] = useState(null);
  const [citySingle, setCitySingle] = useState(null);
  const [address, setAddress] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [name, setName] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [matchRemove, setMatchRemove] = useState(null);
  const [statesOptions, setStatesOptions] = useState([]);

  const valueOffice = getValues('offices');

  const { errors } = formState;
  let isErrorExist = null;

  if (!isNil(errors?.offices)) {
    isErrorExist = +errors?.offices?.root?.message;
  }

  useEffect(() => {
    setAddress(valueOffice?.[index]?.office_address);
    setZipCode(valueOffice?.[index]?.office_zip_code);
    setName(valueOffice?.[index]?.office_name)
  }, []);

  useEffect(() => {
    return () => {
      setMatchRemove(null);
    }
  }, []);

  useEffect(() => {
    let statesDefaults = [{ name: 'None', iso2: -1 }];
    if (states) {
      statesDefaults = [...statesDefaults, ...states];
    }

    setStatesOptions(statesDefaults);
    return () => {

    }
  }, [states]);

  useEffect(() => {
    const stateValue = valueOffice?.[index]?.office_state ?? null;
    if (stateValue) {
      const state = statesOptions.find((state) => {
        return state.name === stateValue
      });
      setStateSingle(state);
    }
  }, [statesOptions]);

  useEffect(() => {
    if (citiesOptions) {
      const cityValue = valueOffice?.[index]?.office_city || null;
      if (cityValue) {
        const city = citiesOptions.find((city) => {
          return city.name === cityValue
        });
        setCitySingle(city);
      }
    }
  }, [citiesOptions]);


  useEffect(() => {
    if (stateSingle) {
      setCitiesOptions([]);
      setLoadingCities(true);
      axios
        .get(`${ENV_VARIABLES.API_SERVER}location/countries/US/regions/${stateSingle.iso2}/cities?limit=9999`)
        .then((resp) => {
          let cities = [{ name: 'None', id: -1 }];
          if (resp.data) {
            cities = [...cities, ...resp.data];
          }
          setCitiesOptions(cities);
          setLoadingCities(false);
        });
      return;
    }
  }, [stateSingle]);

  useEffect(() => {
    setTimeout(() => {

    }, 1000);
  }, []);

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  }

  const handleRemoveOffice = () => {
    setMatchRemove(null);
    if (office && isEmpty(office.office_assign_machine)) {
      remove(index);
      return;
    }
    setMatchRemove(index);
    return;
  }

  return (
    <Box>
      <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: '2px', }} />
      <Box sx={{ mb: 3 }}>
        <Controller
          name={`offices.${index}.office_name`}
          control={control}
          rules={{
            required: true,
            pattern: /^[a-zA-Z0-9 ]*$/,
            validate: {
              required: (v) => v && !!v.trim(),
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                error={!!error || (index === isErrorExist)}
                helperText={(error && getNameErrorMessage(error, t)) || (index === isErrorExist && 'The office name already exists.')}
                fullWidth
                value={name}
                id={`officeName${index + 1}`}
                label={'Office Name'}
                onChange={(e) => {
                  setName(e.target.value)
                  onChange(e.target.value);
                }}
                inputProps={{
                  maxLength: 255,
                }}
              />
            )
          }}
        />
      </Box>
      <Box sx={{ mb: 1 }}>
        <Controller
          name={`offices.${index}.office_address`}
          control={control}
          rules={{
            required: true,
            validate: {
              required: (v) => v && !!v.trim(),
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                error={!!error}
                helperText={error && getNameErrorMessage(error, t)}
                fullWidth
                value={address}
                id={`address${index + 1}`}
                label={'Office Address'}
                onChange={(e) => {
                  setAddress(e.target.value)
                  onChange(e.target.value);
                }}
                inputProps={{
                  maxLength: 255,
                }}
              />
            )
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2 }}>
        <Box sx={{ width: '35%' }}>
          <Controller
            name={`offices.${index}.office_state`}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Autocomplete
                  getOptionLabel={(option) => (option ? `${option.name}` : '')}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === '' || option.id === value.id
                  }
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  value={stateSingle}
                  onChange={(event, item) => {
                    onChange(item);
                    setStateSingle(item);
                    setCitySingle(null);
                    setValue(`offices[${index}].office_city`, null);
                  }}
                  options={statesOptions}
                  noOptionsText={t('common:noOptions')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={stateSingle}
                      autoComplete='state'
                      error={!!error}
                      margin='normal'
                      helperText={error && getNameErrorMessage(error, t)}
                      label={t('common:state')}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )
            }}
          />
        </Box>
        <Box
          sx={{
            width: '35%',
            '& .Mui-disabled.Mui-error .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #d32f2f',
            },
          }}
        >
          <Controller
            name={`offices.${index}.office_city`}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Autocomplete
                  getOptionLabel={(option) => (option ? `${option.name}` : '')}
                  isOptionEqualToValue={(option, value) =>
                    value === undefined || value === '' || option.id === value.id
                  }
                  renderOption={(props, option) => (
                    <Box component='li' {...props} key={option.id}>
                      {option.name}
                    </Box>
                  )}
                  disabled={!stateSingle}
                  noOptionsText={t('common:noOptions')}
                  options={citiesOptions}
                  value={citySingle}
                  onChange={(event, item) => {
                    onChange(item);
                    setCitySingle(item);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={citySingle}
                      autoComplete='new-password'
                      error={!!error}
                      margin='normal'
                      helperText={error && getNameErrorMessage(error, t)}
                      label={t('common:city')}
                      inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <>
                            {loadingCities ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )
            }}
          />
        </Box>
        <Box
          sx={{
            width: '20%',
          }}
        >
          <Controller
            name={`offices.${index}.office_zip_code`}
            control={control}
            rules={{
              required: true,
              validate: {
                required: (v) => v && !!v.trim()
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                error={!!error}
                helperText={error && getNameErrorMessage(error, t)}
                margin='normal'
                fullWidth
                id={`zipCode${index + 1}`}
                value={zipCode}
                label={t('common:zipCode')}
                onChange={(e) => {
                  setZipCode(e.target.value)
                  onChange(e.target.value);
                }}
                inputProps={{
                  maxLength: 15,
                }}
              />
            )}
          />
        </Box>
      </Box>
      <InfoListAssignMachine office={office} openCollapse={openCollapse} handleCollapse={handleCollapse} />
      <Box sx={{ pb: 2 }}>
        {!isNil(matchRemove) && <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#C92A2A' }}>Unassign all the machines to remove this office</Typography>}
      </Box>
      <Box>
        <Button onClick={handleRemoveOffice} variant='outlined' sx={{ color: '#C92A2A', borderColor: '#C92A2A' }} startIcon={<CloseIcon />}>Remove</Button>
      </Box>
    </Box>
  )
}

OfficeCpn.propTypes = {
  office: PropTypes.any,
  index: PropTypes.any,
  remove: PropTypes.func,
};