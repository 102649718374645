import ManageAuditLog from './containers/ManageAuditLogs';

const route = [
  {
    path: '/manage/audit-log',
    element: <ManageAuditLog />,
    private: true,
  },
];
export default route;
