import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Box, Button } from '@mui/material';
import ActivityTracking from './ActivityTracking';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilteredCustomers, filterCustomers } from 'modules/customers/slice';
import { forwardRef, useEffect, useRef } from 'react';
import ManageActivityTrackingSkeleton from 'components/Skeleton/ManageActivityTracking';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { requestToast } from 'modules/ui/slice';
import { TOAST_TYPE } from 'modules/ui/constants';
import { ReactToPrint, useReactToPrint } from 'react-to-print'
import axios from 'axios';
import ENV_VARIABLES from 'config/variables';
import './style.css'
import { requestLoggingRecordAction } from '../slice';
import { useAuthDataContext } from 'components/AuthProvider';

export default function ManageActivityTrackings() {

  const { t } = useTranslation();
  const context = useAuthDataContext();
  const printRef = useRef(null);
  const token = localStorage.getItem('access_token');

  const dispatch = useDispatch();
  const isFilteredCustomers = useSelector((state) => state.customers.isFilteredCustomers);

  useEffect(() => {
    dispatch(filterCustomers({ filterStatuses: [1, 0] }));

    return () => {
      dispatch(clearFilteredCustomers());
    }
  }, []);


  const onViewActivityTrackingAction = (eventName) => {

    const data = {
      event: eventName,
      data: {
        email_address: context?.currentUser?.email
      },
      module: 'activity_tracking',
    }

    dispatch(requestLoggingRecordAction({ ...data, token }));
  };

  useEffect(() => {
    if (context.currentUser) {
      onViewActivityTrackingAction('view_activity_tracking_report');
    }
    return () => {
    }
  }, []);

  const addFooters = (doc) => {
    const pageCount = doc.getNumberOfPages();
    const x = doc.getCurrentPageInfo().pageContext.mediaBox.topRightX - 60;
    const y = doc.getCurrentPageInfo().pageContext.mediaBox.topRightY - 15;
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(`Page ${i}/${pageCount}`, 195, 290);
    }
  };

  const handlePrintServer = async (target) => {
    const htmlContent = target;

    const blob = new Blob([htmlContent], { type: 'html' });

    const formData = new FormData();
    formData.append('content', blob)

    axios.post(`${ENV_VARIABLES.API_SERVER}activity_tracking/export-pdf`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob'
    }).then((resp) => {

      const url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Report_activity_tracking.pdf');
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        dispatch(requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: 'Report downloaded successfully.',
        }));
      }, 500);
    });

  }

  const reactToPrintContent = React.useCallback(() => {
    return printRef.current;
  }, [printRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Activity Tracking',
    copyStyles: true,
    print: async (target) => {
      const elementPdf = target.contentWindow.document.getElementsByTagName('html')[0];
      elementPdf.getElementsByClassName('table-activity')[0].classList.add('show-pdf');

      handlePrintServer(elementPdf.innerHTML);

      // const doc = new jsPDF() // create jsPDF object

      // doc.html(elementPdf, {
      //   callback: (pdf) => {
      //     pdf.save('ActivityTracking.pdf')
      //   },
      //   // margin: 32, // optional: page margin
      //   // optional: other HTMLOptions
      // })
    }
  });

  // const handleDownloadPdf = async () => {
  //   const element = printRef.current;
  //   const canvas = await html2canvas(element);

  //   const imgWidth = 204;
  //   const pageHeight = 295;
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;
  //   heightLeft -= pageHeight;
  //   // const doc = new jsPDF('p', 'mm');
  //   const doc = new jsPDF('portrait', 'mm', 'a4');

  //   doc.addImage(canvas, 'PNG', 20, 20, imgWidth, imgHeight + 5, '', 'FAST');
  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight + 20;
  //     doc.addPage();
  //     doc.addImage(canvas, 'PNG', 20, position, imgWidth, imgHeight, '', 'FAST');
  //     heightLeft -= pageHeight;
  //   }
  //   addFooters(doc);
  //   doc.save('Activity Tracking.pdf');

  //   dispatch(requestToast({
  //     type: TOAST_TYPE.SUCCESS,
  //     message: 'Report downloaded successfully.',
  //   }));
  // };

  const CpnActivityTracking = React.forwardRef((props, printRef) => {
    return <ActivityTracking printRef={printRef} {...props} />
  })


  return (
    <Container
      sx={{
        minHeight: 'calc(100vh - 68px)',
        backgroundColor: 'grey1',
        '.tab-container': {
          px: { xs: 2, sm: 1, md: 0 },
        },
      }}
      maxWidth={false}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ display: 'flex', fontSize: '1.5rem', padding: '24px 0' }} variant='h6' component='div'>
          {t('admin:activityTracking')}
        </Typography>
        <Box
          onClick={() => {
            setTimeout(() => {
              handlePrint();
            }, 100);
          }} sx={{ width: '152px', color: '###343A40', cursor: 'pointer', fontSize: '16px', fontWeight: 500, border: '1px solid #343A40', borderRadius: '4px', p: 1, display: 'flex', justifyContent: 'center' }}>Export</Box>
      </Box>
      {
        isFilteredCustomers ?
          <ManageActivityTrackingSkeleton />
          :
          <CpnActivityTracking printRef={printRef} />
      }
    </Container>
  );
}
