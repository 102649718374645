/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
const RangeTag = ({ isLock, values }) => {
  const [textColor, setTextColor] = useState('black');
  const [tagBackground, setTagBackground] = useState('initial');
  useEffect(() => {
    if (isLock) {
      setTextColor('#666666');
      setTagBackground('#e2e4e7');
    } else {
      setTextColor('#59a211');
      setTagBackground('#f1ffe3');
    }
  }, [isLock]);
  return (
    <div
      className={'target-tag'}
      css={css`
        border: 1px solid ${textColor};
        background: ${tagBackground};
      `}
    >
      <span
        css={css`
          color: ${textColor};
        `}
      >
        {values[0]}° to {values[1]}°
      </span>
    </div>
  );
};

RangeTag.propTypes = {
  isLock: PropTypes.bool,
  values: PropTypes.array,
};

export default RangeTag;
