import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import WebSocketProvider from 'components/WebSocketProvider';
import AuthDataProvider from 'components/AuthProvider';
import { AppRoute } from 'components/AppRoute';

import 'overlayscrollbars/css/OverlayScrollbars.css';
import routes from './routes';
import theme from './binonex-theme';
import store from './store';
// import './style/index.css';
// Axios config
import configAxios from './config/axios';
import { AppLayout } from 'components/Layout';
import { fetchUserInfoFailure } from 'modules/auth/slice';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';

function App() {
  configAxios(store.dispatch, fetchUserInfoFailure);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={10}>
              <AuthDataProvider>
                <WebSocketProvider>
                  <AppLayout>
                    <AppRoute routes={routes} />
                  </AppLayout>
                </WebSocketProvider>
              </AuthDataProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
