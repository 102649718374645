export const machineTypes = [
  {
    id: 1,
    name: 'Lumbar',
  },
  {
    id: 2,
    name: 'Cervical',
  },
  {
    id: 3,
    name: 'Knee',
  },
  {
    id: 4,
    name: 'Torso Rotation',
  },
  {
    id: 5,
    name: 'Cervical Rotation',
  },
];
