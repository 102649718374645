import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import { createContext, useEffect, useState } from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ContentStepCompleted, ContentStepReplace, ContentStepSyncData, ContentStepUnassign } from '../ReplaceMachine';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDetectedMachines,
  requestFinishReplaceMachine,
  requestGetCurrentProcessReplace,
  requestGetListCpnAvailableReplace,
  requestRetryRepairMachine,
  requestUnassignComponent,
  resetDetectedMachines,
  resetStateReplaceMachine,
} from 'modules/machines/slice';
import { isNil, isNull } from 'lodash';
import { useSocketDataContext } from 'components/WebSocketProvider';
import ENV_VARIABLES from 'config/variables';
export const StepReplaceContext = createContext(null);
export default function ReplaceMachineDialog({
  keep,
  title,
  openReplaceDialog,
  handleCloseReplaceDialog,
  handleSubmitUnassignDialog,
  selectedRow,
  handleResetSelectedRow,
}) {
  const { t } = useTranslation();
  const socketContext = useSocketDataContext();
  const dispatch = useDispatch();
  const currentProcessReplace = useSelector((state) => state.machines.currentProcessReplace);
  const isGetCurrentProcessSuccess = useSelector((state) => state.machines.isGetCurrentProcessSuccess);

  const [activeStep, setActiveStep] = useState(0);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (selectedRow && openReplaceDialog) {
      dispatch(
        requestGetCurrentProcessReplace({
          id: selectedRow?.id,
        })
      );
      dispatch(
        requestGetListCpnAvailableReplace({
          id: selectedRow.id,
        })
      );
    }

    return () => {};
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow && openReplaceDialog) {
      dispatch(
        requestGetCurrentProcessReplace({
          id: selectedRow?.id,
        })
      );
      if (activeStep == 3) {
        dispatch(
          requestGetListCpnAvailableReplace({
            id: selectedRow.id,
          })
        );
      }
    }
  }, [socketContext.shouldRefreshMachines]);

  useEffect(() => {
    const currentStep = currentProcessReplace?.data?.current_step;
    // const currentStep = 2
    const maxStep = currentProcessReplace?.data?.max_step;

    if (currentProcessReplace?.code === 200) {
      setActiveStep(currentStep + 1);
      if (currentStep == maxStep) {
        setIsDone(true);
      }
      return;
    }
    if (currentProcessReplace?.code === 404) {
      setActiveStep(0);
      setIsDone(false);
      return;
    }
  }, [currentProcessReplace]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleRetry = () => {
    dispatch(
      requestRetryRepairMachine({
        id: selectedRow.id,
      })
    );
  };

  const handleDone = () => {
    dispatch(
      requestFinishReplaceMachine({
        id: selectedRow.id,
      })
    );

    dispatch(resetDetectedMachines());

    if (handleCloseReplaceDialog) {
      handleCloseReplaceDialog();
      setActiveStep(0);
      handleResetSelectedRow();
      dispatch(resetStateReplaceMachine());
    }
  };

  const closeModal = (event, reason) => {
    setActiveStep(0);
    dispatch(resetStateReplaceMachine());
    handleResetSelectedRow();
    if (reason && reason === 'backdropClick') return;
    if (handleCloseReplaceDialog) {
      handleCloseReplaceDialog();
    }
  };

  const steps = [
    {
      title: t('machine:stepsReplaceMachine.syncData'),
      bodyContent: <ContentStepSyncData />,
    },
    {
      title: t('machine:stepsReplaceMachine.unassign'),
      bodyContent: <ContentStepUnassign />,
    },
    {
      title: t('machine:stepsReplaceMachine.replace'),
      bodyContent: <ContentStepReplace />,
    },
    {
      title: t('machine:stepsReplaceMachine.completed'),
      bodyContent: <ContentStepCompleted />,
    },
  ];

  const props = {
    selectedRow,
    handleNext,
    handleDone,
    closeModal,
    handleRetry,
    activeStep,
    openReplaceDialog,
  };

  const getContentStep = () => {
    if (isGetCurrentProcessSuccess && activeStep == 0) {
      return (
        <Box
          sx={{ width: '600px', minHeight: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <CircularProgress size={24} />
        </Box>
      );
    }
    const getContentDialog = () => {
      if (isDone) {
        return steps[steps.length - 1]?.bodyContent;
      }
      if (steps[activeStep]?.bodyContent) {
        return steps[activeStep]?.bodyContent;
      }
      return steps[0].bodyContent;
    };
    return (
      <Box sx={{ width: '600px', minHeight: '250px' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel
                  sx={{
                    '.MuiStepIcon-text.css-ii0vbs-MuiStepIcon-text': {
                      display: 'none',
                    },
                    '.css-ik8ywc-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                      color: '#FFCC80',
                    },
                  }}
                >
                  {step.title}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Typography sx={{ mt: 3, width: '100%' }} component='div'>
          {getContentDialog()}
        </Typography>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Dialog maxWidth='md' onClose={!keep ? closeModal : undefined} open={openReplaceDialog}>
        <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
        <DialogContent>
          <StepReplaceContext.Provider value={props}>{getContentStep()}</StepReplaceContext.Provider>
        </DialogContent>
      </Dialog>
    );
  };

  return renderContent();
}

ReplaceMachineDialog.propTypes = {
  keep: PropTypes.bool,
  title: PropTypes.string,
  openReplaceDialog: PropTypes.bool,
  handleCloseReplaceDialog: PropTypes.func,
  handleSubmitUnassignDialog: PropTypes.func,
  handleResetSelectedRow: PropTypes.func,
  selectedRow: PropTypes.object,
};
