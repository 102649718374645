import GaugeSlider from 'modules/machines/components/GaugeSlider';
import PropTypes from 'prop-types';
import { Modal } from '../../../../../components/Modal';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import socketActions from '../../../../../components/WebSocketProvider/actions';
import { useSocketDataContext } from '../../../../../components/WebSocketProvider';

export default function UpdatePotRangeModal({ showModal, onClose, rowSelected }) {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const socketContext = useSocketDataContext();
  const [minDegrees, setMinDegrees] = useState(0);
  const [maxDegrees, setMaxDegrees] = useState(0);
  const [minVolume, setMinVolume] = useState(0);
  const [maxVolume, setMaxVolume] = useState(0);

  useEffect(() => {
    return () => {
      stopRequestHandler();
    };
  }, []);

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    if (!message || (message && Object.keys(message).length === 0)) {
      return;
    }

    if (message.degree === null || message.degree === undefined) {
      return;
    }

    if (message.volt === null || message.volt === undefined) {
      return;
    }
    switch (step) {
      case 1:
        setMinDegrees(message.degree);
        setMinVolume(message.volt);
        break;
      case 2:
        setMaxDegrees(message.degree);
        setMaxVolume(message.volt);
        break;
      default:
        break;
    }
  }, [socketContext.messageHistory]);

  useEffect(() => {
    setStep(1);
    setMinDegrees(0);
    setMaxDegrees(0);
    setMinVolume(0);
    setMaxVolume(0);
    if (showModal === true) {
      startRequestHandler();
    }

    if (showModal === false) {
      stopRequestHandler();
    }
  }, [showModal]);

  const stopRequestHandler = () => {
    if (!rowSelected) {
      return;
    }

    socketContext.sendJsonMessage({
      request: socketActions.STOP_GET_VALUE_BY_SWITCH,
      u12_id: rowSelected.machine.u12_id,
      mac_address: rowSelected.machine.mac_addr,
    });
    socketContext.clearMessageHistory();
  };

  const startRequestHandler = () => {
    if (rowSelected && showModal) {
      socketContext.sendJsonMessage({
        request: socketActions.GET_DEGREE_BY_SWITCH,
        u12_id: rowSelected.machine.u12_id,
        mac_address: rowSelected.machine.mac_addr,
      });
    }
  };

  const onContinueHandler = () => {
    stopRequestHandler();
    if (step === 1) {
      setTimeout(() => {
        startRequestHandler();
      }, 1000);
    }

    setStep(step + 1);
  };
  const onOkHandler = () => {
    socketContext.sendJsonMessage({
      request: socketActions.POT_RANGE_CALIBRATION,
      u12_id: rowSelected.machine.u12_id,
      mac_address: rowSelected.machine.mac_addr,
      pot_0_volt: minVolume,
      pot_max_volt: maxVolume,
    });

    onClose();
  };

  const getGaugeMaxValue = () => {
    switch (rowSelected.machine.max_angle) {
      case 84:
        return 96;
      case 108:
        return 120;
      default:
        return rowSelected.machine.max_angle;
    }
  };

  const getGaugeMinValue = () => {
    switch (rowSelected.machine.min_angle) {
      case -84:
        return -96;
      case -108:
        return -120;
      default:
        return rowSelected.machine.min_angle;
    }
  };

  const getMinText = () => {
    if (
      (rowSelected && rowSelected.machine.machine_type === 'Knee') ||
      rowSelected.machine.machine_type === 'Torso Rotation'
    ) {
      return 0;
    }
    if (rowSelected && rowSelected.machine.min_angle) {
      return rowSelected.machine.min_angle;
    }

    return 0;
  };

  const getMaxText = () => {
    if (rowSelected && rowSelected.machine.machine_type === 'Knee') {
      return 120;
    }
    if (rowSelected && rowSelected.machine.machine_type === 'Lumbar') {
      return 72;
    }
    if (rowSelected && rowSelected.machine.machine_type === 'Cervical') {
      return 126;
    }
    if (rowSelected && rowSelected.machine.min_angle) {
      return rowSelected.machine.max_angle;
    }

    return 120;
  };

  const setMinAngleRender = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
        <div style={{ paddingBottom: '50px' }}>Set the current angle to {0} degrees, then slip the switch</div>
        <div style={{ maxHeight: 270 }}>
          <GaugeSlider
            value={minDegrees}
            minValue={getGaugeMinValue()}
            maxValue={getGaugeMaxValue()}
            isLock={false}
            middleTitle={'Reported angle'}
          />
        </div>
      </Box>
    );
  };

  const setMaxAngleRender = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
        <div style={{ paddingBottom: '50px' }}>
          Set the current angle to {getMaxText()} degrees, then slip the switch
        </div>
        <div style={{ maxHeight: 270 }}>
          <GaugeSlider
            value={maxDegrees}
            minValue={getGaugeMinValue()}
            maxValue={getGaugeMaxValue()}
            isLock={false}
            middleTitle={'Reported angle'}
          />
        </div>
      </Box>
    );
  };

  const updatePotRangeRender = () => {
    return (
      <Box>
        <div style={{ paddingBottom: '50px' }}>Do you want update the pot range following the reported angle?</div>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: '500' }} variant={'h3'}>
            {minDegrees}°- {maxDegrees}°
          </Typography>
          <Typography sx={{ fontWeight: '500' }} variant={'h6'}>
            Reported angle range
          </Typography>
        </Box>
      </Box>
    );
  };
  const contentRender = () => {
    switch (step) {
      case 1:
        return setMinAngleRender();
      case 2:
        return setMaxAngleRender();
      case 3:
        return updatePotRangeRender();
      default:
        return null;
    }
  };

  const getFooterExtra = () => {
    if (step === 1 || step === 2) {
      return [
        <Button onClick={onClose} key={'calibrate-btn-1'} size={'small'}>
          {t('common:cancel')}
        </Button>,
        <Button onClick={onContinueHandler} key={'calibrate-btn-2'} size={'small'} variant={'contained'}>
          {t('common:next')}
        </Button>,
      ];
    }
    return [
      <Button onClick={onClose} key={'calibrate-btn-1'} size={'small'}>
        {t('common:cancel')}
      </Button>,
      <Button onClick={onOkHandler} key={'calibrate-btn-2'} size={'small'} variant={'contained'}>
        {t('common:ok')}
      </Button>,
    ];
  };

  return (
    <Modal
      keep={true}
      showModal={showModal}
      onClose={onClose}
      content={contentRender}
      isClose={false}
      extra={getFooterExtra()}
    />
  );
}

UpdatePotRangeModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  rowSelected: PropTypes.object,
};
