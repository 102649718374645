import { createSlice } from '@reduxjs/toolkit';
import { clearStoreError } from 'modules/dialogs/actions';

export const CustomersSlice = createSlice({
  name: 'customersSlice',
  initialState: {
    error: null,
    customers: [],
    filteredCustomers: [],
    cities: [],
    states: [],
    itemPerPage: 100,
    totalCustomers: 0,
    createdCustomer: null,
    isLoading: false,
    isFilteredCustomers: false
  },
  reducers: {
    fetchCustomers: (state) => {
      state.error = null;
      state.isLoading = true
    },
    fetchCustomersSuccess: (state, action) => {
      state.customers = action.payload.hcp;
      state.itemPerPage = action.payload.item_per_page;
      state.totalCustomers = action.payload.total_hcp;
      state.isLoading = false
    },
    fetchCustomersFailure: (state, action) => {
      state.customers = [];
      state.error = action.payload.code;
      state.isLoading = false
    },
    filterCustomers: (state) => {
      state.error = null;
      state.filteredCustomers = [];
      state.isFilteredCustomers = true;
    },
    filterCustomersSuccess: (state, action) => {
      state.filteredCustomers = action.payload.hcp;
      state.itemPerPage = action.payload.item_per_page;
      state.totalCustomers = action.payload.total_hcp;
      state.isFilteredCustomers = false;
    },
    filterCustomersFailure: (state, action) => {
      state.filteredCustomers = [];
      state.error = action.payload.code;
      state.isFilteredCustomers = false;
    },
    fetchCities: (state) => {
      state.error = null;
    },
    fetchCitiesSuccess: (state, action) => {
      state.cities = action.payload;
    },
    fetchCitiesFailure: (state, action) => {
      state.cities = [];
      state.error = action.payload.code;
    },
    fetchStates: (state) => {
      state.error = null;
    },
    fetchStatesSuccess: (state, action) => {
      state.states = action.payload;
    },
    fetchStatesFailure: (state, action) => {
      state.states = [];
      state.error = action.payload.code;
    },
    requestInviteCustomer: (state) => {
      state.error = null;
    },
    requestInviteCustomerSuccess: (state, action) => {
      state.createdCustomer = action.payload.data;
    },
    requestInviteCustomerFailure: (state, action) => {
      state.error = action.payload;
    },
    requestEditCustomer: (state) => {
      state.error = null;
    },
    requestEditCustomerSuccess: (state, action) => {
      state.createdCustomer = action.payload;
    },
    requestEditCustomerFailure: (state, action) => {
      state.error = action.payload;
    },
    requestDeleteCustomer: (state) => {
      state.error = null;
    },
    requestDeleteCustomerSuccess: () => { },
    requestDeleteCustomerFailure: (state, action) => {
      state.error = action.payload.code;
    },
    requestSuspendCustomer: (state) => {
      state.error = null;
    },
    requestSuspendCustomerSuccess: () => { },
    requestSuspendCustomerFailure: (state, action) => {
      state.error = action.payload.code;
    },
    requestRestoreCustomer: (state) => {
      state.error = null;
    },
    requestRestoreCustomerSuccess: () => { },
    requestRestoreCustomerFailure: (state, action) => {
      state.error = action.payload.code;
    },
    clearSuccessAndError: (state) => {
      state.error = null;
      state.createdCustomer = null;
    },
    requestUpdateCustomerFeature: (state) => {
      state.error = null;
    },
    requestUpdateCustomerFeatureSuccess: () => { },
    requestUpdateCustomerFeatureFailure: (state, action) => {
      state.error = action.payload.code;
    },
    clearFilteredCustomers: (state) => {
      state.filteredCustomers = [];
    }
  },
  extraReducers: {
    [clearStoreError]: (state) => {
      state.error = null;
      state.createdCustomer = null;
    },
  },
});
export const {
  clearSuccessAndError,
  fetchCustomers,
  fetchCustomersSuccess,
  fetchCustomersFailure,
  filterCustomers,
  filterCustomersSuccess,
  filterCustomersFailure,
  fetchCities,
  fetchCitiesSuccess,
  fetchCitiesFailure,
  fetchStates,
  fetchStatesSuccess,
  fetchStatesFailure,
  requestInviteCustomer,
  requestInviteCustomerSuccess,
  requestInviteCustomerFailure,
  requestEditCustomer,
  requestEditCustomerSuccess,
  requestEditCustomerFailure,
  requestDeleteCustomer,
  requestDeleteCustomerSuccess,
  requestDeleteCustomerFailure,
  requestSuspendCustomer,
  requestSuspendCustomerSuccess,
  requestSuspendCustomerFailure,
  requestRestoreCustomer,
  requestRestoreCustomerSuccess,
  requestRestoreCustomerFailure,
  requestUpdateCustomerFeature,
  requestUpdateCustomerFeatureSuccess,
  requestUpdateCustomerFeatureFailure,
  clearFilteredCustomers

} = CustomersSlice.actions;

export default CustomersSlice.reducer;
