import ManageActivityTrackings from './containers';

const route = [
  {
    path: '/manage/activity-tracking',
    element: <ManageActivityTrackings />,
    private: true,
  },
];
export default route;
