import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/material.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import ForwardToInboxImg from 'assets/images/png/forward_to_inbox.png';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import isNil from 'lodash/isNil';
import Container from '@mui/material/Container';
import { ClientJS } from 'clientjs';
import CountDownResend from './CountDownResend';
import { Modal } from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { requestResendOtp, requestSetOpenModalReachedFirstLimit, requestSetOpenModalReachedLastLimit, requestVerifyOtp, resetReasonLogin, resetRemainingAttempt } from 'modules/auth/slice';
import { COUNTDOWN_RESEND_FIRST, COUNTDOWN_RESEND_LAST, DEVICE_UUID, NUMBER_DAY_REMEMBER_DEVICE, OTP_CODE } from 'modules/auth/constants';
import { getCookie } from 'helpers';

export default function TwoFactorAuthentification({
  handleBackToLogin,
  dataVerifyOtp,
  onSetDataVerifyOtp,
  email
}) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = new ClientJS(); // Create A New Client Object

  const fingerprint = client.getFingerprint(); // Get Client's Fingerprint

  const [isSubmitting, setSubmitting] = useState(false);
  const listOTP = [...Array.from({ length: 6 })];
  const [errVerifyOtp, setErrVerifyOtp] = useState(null);
  const [disabledBtnVerify, setDisabledBtn] = useState(true);
  const reason = useSelector((state) => state.auth.reason);
  const remainingAttempt = useSelector((state) => state.auth.remaining_attempts);
  const isVerifySuccess = useSelector((state) => state.auth.is_success);
  const openModalReachedFirstLimit = useSelector((state) => state.auth.openModalReachedFirstLimit);
  const openModalReachedLastLimit = useSelector((state) => state.auth.openModalReachedLastLimit);
  const isWarningAmountAttempt = !isNil(errVerifyOtp) && (remainingAttempt >= 0);

  const otpRef = useRef([]);

  const onBackTo = () => {
    handleBackToLogin();
    onSetDataVerifyOtp({ otp_code: listOTP });
    setErrVerifyOtp(null);
  }

  useEffect(() => {

    return () => {
      onSetDataVerifyOtp({ otp_code: listOTP });
      dispatch(resetRemainingAttempt());
      setErrVerifyOtp(null);
      dispatch(resetReasonLogin());
    }
  }, []);

  useEffect(() => {
    if (isVerifySuccess) {
      setErrVerifyOtp(null);
      return;
    }
  }, [isVerifySuccess]);

  const renderErrVerifyOtp = useMemo(() => {
    if (!reason) return;

    switch (reason) {
      case OTP_CODE.INCORRECT_CODE:
        setErrVerifyOtp(t('auth:twoFaDialog.messageIncorrectCode'));
        break;
      case OTP_CODE.EXPIRED_CODE:
        setErrVerifyOtp(t('auth:twoFaDialog.messageExpiredCode'));
        break;
      default:
        break;
    }
  }, [reason])

  const onChangeOtp = ({ event, index }) => {
    const next = index + 1;
    const value = event.target.value.replace(/[^0-9]/g, '');

    if (value || value === 0) {
      const newOtpValues = [...dataVerifyOtp.otp_code];
      newOtpValues[index] = Number(value);
      onSetDataVerifyOtp({ otp_code: newOtpValues });
      if (next < 6) {
        otpRef.current[next]?.select();
      } else {
        otpRef.current[index].blur();
      }
      const otpCode = otpRef.current.map((e) => e.value).join('');
      if (otpCode.length === 6) {
        setDisabledBtn(false);
      }
    }
  };

  const onPasteOtp = ({ event, index }) => {
    // If it's a paste event, get the pasted value
    const value = event.type === 'paste' ? event.clipboardData.getData('text').replace(/[^0-9]/g, '') : event.target.value.replace(/[^0-9]/g, '');
    const arrNewOtpValue = value.split('');
    let finalOtpValue;
    if (arrNewOtpValue?.length === 6) {
      finalOtpValue = arrNewOtpValue;
      setDisabledBtn(false);
    } else {
      finalOtpValue = Array.from({ length: 6 }, (_, i) => arrNewOtpValue[i] || undefined);
      setDisabledBtn(true);
    }

    onSetDataVerifyOtp({ otp_code: finalOtpValue });
  };

  const handleOneInputFocus = ({ event, index }) => {
    event.preventDefault();
    otpRef.current[index]?.select();
  };

  const handleBackspace = (index) => {
    setDisabledBtn(true);
    const newOtpValues = [...dataVerifyOtp.otp_code];
    newOtpValues[index] = undefined;
    onSetDataVerifyOtp({ otp_code: newOtpValues });
    const previous = index === 0 ? 0 : index - 1;
    otpRef.current[previous]?.select();
  };

  const handleOnKeyDownCapture = ({ event, index }) => {
    if (event.key === dataVerifyOtp.otp_code[index]?.toString()) {
      const next = index + 1;
      if (next < 6) {
        otpRef.current[next]?.focus();
      } else {
        otpRef.current[index].blur();
      }
    }
  };

  const onSubmitVerify = (submitData) => {
    const deviceUuid = getCookie(DEVICE_UUID);
    const result = { ...submitData, otp_code: submitData.otp_code.join('') };
    result.device_id = fingerprint;
    result.device_uuid = deviceUuid;
    result.email = email;
    dispatch(
      requestVerifyOtp(result)
    );
  };

  const handleVerifyOtp = () => {
    setSubmitting(true);
    const submitData = { ...dataVerifyOtp };

    onSubmitVerify(submitData);
    setTimeout(() => {
      setSubmitting(false);
    }, 600);
  };


  const handleResendCode = () => {
    const deviceUuid = getCookie(DEVICE_UUID);
    dispatch(
      requestResendOtp({
        email,
        device_id: fingerprint,
        device_uuid: deviceUuid
      })
    );
  };

  const renderContentReachedFirstLimit = () => {
    return <Box>
      <Typography>{t('auth:twoFaDialog.contentReachedFirstLimit')}</Typography>
    </Box>
  };

  const renderContentReachedLastLimit = () => {
    return <Box>
      <Typography>{`Your account `}<span style={{ fontWeight: '700' }}>{email}</span>{` has been locked. Please follow instruction in your email or contact your Admin to change password.`}</Typography>
    </Box>
  };

  const onGotItFirstLimit = () => {
    dispatch(requestSetOpenModalReachedFirstLimit(false));
  };

  const onGotItLastLimit = () => {
    dispatch(requestSetOpenModalReachedLastLimit(false));
    handleBackToLogin();
  };

  const getModalReachedFirstLimit = () => {
    return <Modal
      title={t('auth:twoFaDialog.titleReachFirstLimit')}
      showModal={openModalReachedFirstLimit}
      onClose={onGotItFirstLimit}
      isClose={true}
      keep={true}
      content={renderContentReachedFirstLimit}
      extra={[
        <Button onClick={onGotItFirstLimit} variant='outlined' key={'btn-1'}>
          {t('auth:twoFaDialog.gotIt')}
        </Button>
      ]}
    />
  };

  const getModalReachedLastLimit = () => {
    return <Modal
      title={t('auth:twoFaDialog.titleReachLastLimit')}
      showModal={openModalReachedLastLimit}
      onClose={onGotItLastLimit}
      isClose={true}
      keep={true}
      content={renderContentReachedLastLimit}
      extra={[
        <Button onClick={onGotItLastLimit} variant='outlined' key={'btn-1'}>
          {t('auth:twoFaDialog.gotIt')}
        </Button>
      ]}
    />
  };

  const renderModalReachedFirstLimit = useMemo(() => getModalReachedFirstLimit(), [openModalReachedFirstLimit, remainingAttempt]);
  const renderModalReachedLastLimit = useMemo(() => getModalReachedLastLimit(), [openModalReachedLastLimit, remainingAttempt]);

  const renderTwoFaContent = () => (
    <Box sx={{ background: '#fff', width: '568px', borderRadius: '2%' }}>
      <OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'scroll' } }} style={{ maxHeight: '100vh' }}>
        <Box
          component='img'
          sx={{
            padding: '10px',
            height: 'auto',
            maxHeight: '150px',
            maxWidth: '100%',
          }}
          alt='Picture of Forward to inbox'
          src={ForwardToInboxImg}
        />
        <DialogTitle sx={{ fontWeight: 'bold' }}> {t('auth:twoFaDialog.title')}</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: '16px', mb: 1 }}>{t('auth:twoFaDialog.subtitle')}</Typography>
            <Typography sx={{ fontSize: '16px', mb: 3 }}>{t('auth:twoFaDialog.messageSendOpt')} <span style={{ fontWeight: '700' }}>{` ${email}`}</span></Typography>
            <Typography sx={{ fontSize: '16px' }}><span style={{ fontWeight: 'bold' }}>{`*Notice: `}</span> {t('auth:twoFaDialog.warningConditionLockAccount', { number: 5 })}</Typography>
          </Box>
          <Typography sx={{ fontSize: '16px' }}>{t('auth:twoFaDialog.enterTheCode')}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
            {dataVerifyOtp?.otp_code.map((value, index) => (
              <TextField
                autoFocus={index === 0}
                value={value ?? ''}
                inputRef={(el) => {
                  otpRef.current[index] = el;
                }}
                type='text'
                onChange={(event) => {
                  onChangeOtp({ event, index });
                }}
                onPaste={(event) => {
                  onPasteOtp({ event, index });
                }}
                sx={{
                  border: 'none',
                  borderBottom: '2px solid #D1D5DB',
                  maxWidth: '36px', color: '#212529',
                  '& fieldset': { border: 'none' },
                  '&:focus-within fieldset, &:focus-visible fieldset': {
                    border: '0px solid red!important',
                    boxShadow: 'none !important',
                  },
                  margin: '0 4px',
                }}
                key={index}
                inputProps={{ maxLength: 1 }}
                onFocus={(event) => handleOneInputFocus({ event, index })}
                onKeyUp={(e) => {
                  if (e.key === 'Backspace') {
                    e.preventDefault();
                    handleBackspace(index);
                  }
                }}
                onKeyDownCapture={(event) => handleOnKeyDownCapture({ event, index })}
              />
            ))}
          </Box>
          <Box sx={{ mt: 3 }}>
            {
              !isNil(errVerifyOtp) && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                <ErrorOutlineOutlinedIcon sx={{ color: '#d64531' }} />
                <Typography sx={{ fontSize: '14px', ml: 1, color: '#d64531' }}>{errVerifyOtp}</Typography>
              </Box>
            }
            {
              isWarningAmountAttempt && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1 }}>
                <ErrorOutlineOutlinedIcon sx={{ color: '#d64531' }} />
                <Typography sx={{ fontSize: '14px', ml: 1, color: '#d64531' }}>{t('auth:twoFaDialog.warningRemainingAttempt', { attempt: remainingAttempt })}</Typography>
              </Box>
            }
          </Box>

          <Box>
            <FormControlLabel
              sx={{
                '.css-14r8mab-MuiTypography-root': {
                  fontSize: '16px'
                }
              }}
              control={<Checkbox checked={dataVerifyOtp.remember_me}
                onChange={() => onSetDataVerifyOtp({ remember_me: !dataVerifyOtp.remember_me })} />}
              label={t('auth:twoFaDialog.rememberDevice', { day: NUMBER_DAY_REMEMBER_DEVICE })}
            />
          </Box>
          <CountDownResend handleResendCode={handleResendCode} countdownTime={(remainingAttempt >= 3) ? Number(COUNTDOWN_RESEND_FIRST) : Number(COUNTDOWN_RESEND_LAST)} />
        </DialogContent>
        <DialogActions>
          <Button sx={{ mr: 'auto' }} startIcon={<ArrowBackIosIcon sx={{ height: '11px', width: '11px' }} />} variant='outlined' onClick={onBackTo}>
            {t('auth:twoFaDialog.backToLogin')}
          </Button>
          <LoadingButton
            type='submit'
            disabled={disabledBtnVerify}
            loading={isSubmitting}
            onClick={handleVerifyOtp}
            variant='contained'
          >
            {t('auth:twoFaDialog.next')}
          </LoadingButton>
        </DialogActions>
      </OverlayScrollbarsComponent>
    </Box>
  );

  return (
    <>
      {!isVerifySuccess && renderErrVerifyOtp}
      {openModalReachedFirstLimit && renderModalReachedFirstLimit}
      {openModalReachedLastLimit && renderModalReachedLastLimit}
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'black',
          minHeight: '100vh',
          justifyContent: 'center',
          position: 'relative',
        }}
        maxWidth={false}
        disableGutters={true}
      >
        {renderTwoFaContent()}
      </Container>
    </>
  );
}

TwoFactorAuthentification.propTypes = {
  handleBackToLogin: PropTypes.func,
  dataVerifyOtp: PropTypes.object,
  onSetDataVerifyOtp: PropTypes.func,
  email: PropTypes.string
};