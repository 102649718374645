import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { forceLogout } from 'modules/auth/slice';
import { ACCESS_TOKEN } from 'modules/auth/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EXCLUDED_PATHS = ['/users/accept-invite', '/users/reset-password'];

const PublicRoute = ({ children, authed, isAdmin, route, dispatch }) => {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();

  if (route.public) {
    return children ?? <Outlet />;
  }
  if ((isAdmin || authed) && EXCLUDED_PATHS.includes(route.path)) {
    localStorage.removeItem(ACCESS_TOKEN);
    dispatch(forceLogout());
    setTimeout(() => {
      navigator(`${route.path}?token=${searchParams.get('token')}`);
    }, 500);
    return <></>;
  }
  if (isAdmin || authed) {
    return <Navigate to='/' replace />;
  }
  // if (authed) {
  //   return <Navigate to="/" replace />;
  // }
  return children ?? <Outlet />;
};

PublicRoute.propTypes = {
  redirectPath: PropTypes.string,
  authed: PropTypes.bool,
  isAdmin: PropTypes.bool,
  route: PropTypes.object,
  children: PropTypes.element,
  dispatch: PropTypes.func,
};

export default PublicRoute;
