import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestLogout, resetRequireTwoFa } from 'modules/auth/slice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthDataContext } from 'components/AuthProvider';

export default function SignOut() {
  const currentUser = useSelector((state) => state.auth.currentUser);

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const context = useAuthDataContext();
  const [searchParams] = useSearchParams();
  // Handle more logic for logout if need
  useEffect(() => {
    if (currentUser) {
      dispatch(requestLogout());
      context.resetData();
    } else {
      if (searchParams.get('redirect_url') && searchParams.get('from_admin') === 'true') {
        navigator('/sign-in?redirect_url=' + searchParams.get('redirect_url'));
      } else if (searchParams.get('redirect_url')) {
        navigator('/sign-in?redirect_url=' + searchParams.get('redirect_url'));
      } else if (searchParams.get('from_admin')) {
        navigator('/sign-in');
      } else {
        navigator('/sign-in');
      }
      dispatch(resetRequireTwoFa());
      localStorage.clear();
    }
  }, [currentUser]);

  return null;
}
