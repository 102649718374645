import ENV_VARIABLES from 'config/variables';

export const END_POINT = {
  login: {
    url: `${ENV_VARIABLES.API_SERVER}users/session`,
    method: 'POST',
  },
  logout: {
    url: `${ENV_VARIABLES.API_SERVER}users/session`,
    method: 'DELETE',
  },
  verifyOpt: {
    url: `${ENV_VARIABLES.API_SERVER}challenge/totp/verify`,
    method: 'POST',
  },
  resendOpt: {
    url: `${ENV_VARIABLES.API_SERVER}challenge/totp/re-send`,
    method: 'POST',
  },
  profile: {
    url: `${ENV_VARIABLES.API_SERVER}users/me`,
  },
  forgot: {
    url: `${ENV_VARIABLES.API_SERVER}users/forgot-password`,
  },
  checkForgotPasswordToken: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}users/check-reset-password?token=${token}`,
  },
  resetUserPassword: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}users/reset-password?token=${token}`,
  },
  checkInviteToken: {
    url: (token) => `${ENV_VARIABLES.API_SERVER}users/invite-token?token=${token}`,
  },
  acceptInvite: {
    url: `${ENV_VARIABLES.API_SERVER}users/accept-invite`,
  },
};

export const ACCESS_TOKEN = 'access_token';
export const DEVICE_UUID = 'device_uuid';

export const OTP_CODE = {
  SUCCESS_CODE: 0,
  EXPIRED_CODE: 1,
  INCORRECT_CODE: 2
};

export const NUMBER_ATTEMPT = {
  REACHED_FIRST_LIMIT: 2,
  REACHED_LAST_LIMIT: 0
}

export const COUNTDOWN_RESEND_FIRST = 180;
export const COUNTDOWN_RESEND_LAST = 300;
export const REMAINING_ATTEMPT_DEFAULT = 5;
export const NUMBER_DAY_REMEMBER_DEVICE = 30;


