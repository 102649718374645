import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// import io from 'socket.io-client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import MenuItem from '@mui/material/MenuItem';
import { GeneralMachinesList } from 'modules/machines/components/List';

import AddMachine from './AddMachine';
import DebugMachine from './DebugMachine';
import AssignMachine from './AssignMachine';
import ReassignMachine from './ReassignMachine';
import ConfigureMachine from 'modules/machines/containers/NewList/ConfigureMachine';
import ConfirmDialog from 'components/ConfirmDialog';
import SearchBar from 'modules/machines/components/Search';
import Filter from 'modules/machines/components/Filter';

import { openDialog, closeDialog } from 'lib/dialog.js';
import { getDateTime } from 'lib/datetime';
import { getMachineStatus, getConfiguredStatusGeneralMachine, configuredStatus } from 'lib/getStatus';
import { useSocketDataContext } from 'components/WebSocketProvider';
import { requestToast } from 'modules/ui/slice';
import { TOAST_TYPE } from 'modules/ui/constants';

import { DIALOGS } from 'modules/dialogs/constants';
import socketActions from 'components/WebSocketProvider/actions';
import { requestRemoveMachine, fetchMachines, requestLoggingRecordAction, requestUnassignMachine } from 'modules/machines/slice';
import ActionModal from './Modal/ActionModal';
import { UnassignMachineDialog } from 'modules/machines/components/Dialogs';
import ReplaceMachineDialog from 'modules/machines/components/Dialogs/ReplaceMachineDialog';

export default function GeneralList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const socketContext = useSocketDataContext();
  const machines = useSelector((state) => state.machines.machines);
  const isFinishReplaceSuccess = useSelector((state) => state.machines.isFinishReplaceSuccess);

  const token = localStorage.getItem('access_token');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [sortData, setSortData] = useState();
  const [keyword, setSearchKeyword] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [filterType, setFilterType] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [showCalibrateModal, setShowCalibrateModal] = useState(false);

  const createData = (originalData, id, name, serial, type, customer, office, assignedAt, status, configured) => {
    return {
      originalData,
      id,
      name,
      serial,
      type,
      customer,
      office,
      assignedAt,
      status,
      configured,
    };
  };

  const data = useMemo(() =>
    machines.map((data) => {
      return createData(
        data, // original data, use this for actions on the table such as delete, restore, suspend
        data.machine.id,
        data.machine.name,
        data.machine.serial_no,
        data.machine.machine_type,
        data.hcp_name,
        data.office_name,
        data.assigned_at,
        data.machine.status,
        data.machine.config_status
      );
    }), [machines]);

  // dataField has to match fields in createData function
  const headers = [
    {
      id: 'name', //use for sorting
      title: t('machine:machineTable.name'),
      dataField: 'name',
      align: 'left',
      disablePadding: false,
      sortable: true,
      width: '20%',
    },
    {
      id: 'serial',
      title: t('machine:machineTable.serialNo'),
      dataField: 'serial',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '10%',
    },
    {
      id: 'type',
      title: t('machine:machineTable.machineType'),
      dataField: 'type',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '10%',
    },
    {
      id: 'hcp_name',
      title: t('machine:machineTable.customer'),
      dataField: 'customer',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '15%',
    },
    {
      id: 'hcp_office',
      title: t('machine:machineTable.assignOffice'),
      dataField: 'office',
      align: 'left',
      disablePadding: false,
      sortable: false,
      width: '15%',
    },
    {
      id: 'assigned_at',
      title: t('machine:machineTable.assignedOn'),
      dataField: 'assignedAt',
      align: 'left',
      disablePadding: false,
      sortable: true,
      width: '10%',
      format: (value) => getDateTime(value),
    },
    {
      id: 'status',
      title: t('machine:machineTable.status'),
      dataField: 'status',
      align: 'left',
      sortable: false,
      disablePadding: false,
      width: '10%',
      format: (value) => getMachineStatus(value, t),
    },
    {
      id: 'configured',
      title: t('machine:machineTable.configured'),
      dataField: 'configured',
      align: 'left',
      sortable: false,
      disablePadding: false,
      width: '7%',
      format: (value) => getConfiguredStatusGeneralMachine(value, t),
    },
  ];

  const onDebugMachine = (row) => {
    const machine = machines.find((data) => data.machine.id === row.machine.id);
    setSelectedRow(machine);

    openDialog(dispatch, DIALOGS.DEBUG_MACHINE_DIALOG);
  };

  const onCalibrate = (row) => {
    setShowCalibrateModal(true);
    const machine = machines.find((data) => data.machine.id === row.id);
    setSelectedRow(machine);
  };

  const onEnableRemoteAccessAction = (eventName, machineName) => {
    const data = {
      event: eventName,
      data: {
        machine_name: machineName
      },
      module: 'machine_management',
    }

    dispatch(requestLoggingRecordAction({ ...data, token }));
  }

  const enableRemoteAccess = (row) => {
    // setShowCalibrateModal(true);
    const machine = machines.find((data) => data.machine.id === row.id);
    socketContext.sendJsonMessage({
      request: socketActions.REMOTE_ACCESS,
      u12_id: machine.machine.u12_id,
      mac_address: machine.machine.mac_addr,
    });

    onEnableRemoteAccessAction('enable_remote_access', machine?.machine?.name);

    dispatch(requestToast({
      type: TOAST_TYPE.SUCCESS,
      message: 'Remote support is enabled.',
    }))
  };
  // functions for configure machine
  const onConfigureMachine = (row) => {
    const machine = machines.find((data) => data.machine.id === row.id);
    setSelectedRow(machine);
    handleOpenConfigureMachineDialog();
  };

  const handleOpenConfigureMachineDialog = () => {
    openDialog(dispatch, DIALOGS.CONFIGURE_MACHINE_DIALOG);
  };

  // function for assign machine
  const onAssignMachine = (row) => {
    const machine = machines.find((data) => data.machine.id === row.id);
    setSelectedRow(machine);
    handleOpenAssignMachineDialog();
  };

  const handleOpenAssignMachineDialog = () => {
    openDialog(dispatch, DIALOGS.ASSIGN_MACHINE_DIALOG);
  };

  // functions for unassign machine
  const openUnassignDialog = useSelector((state) => state.dialogs[DIALOGS.UNASSIGN_MACHINE_DIALOG]);

  const onUnassignMachine = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.UNASSIGN_MACHINE_DIALOG);
  };

  const handleCloseUnassignDialog = () => {
    closeDialog(dispatch, DIALOGS.UNASSIGN_MACHINE_DIALOG);
  };

  const handleSubmitUnassignDialog = () => {
    dispatch(
      requestUnassignMachine({
        id: selectedRow.id,
        serial: selectedRow.serial,
        sortData: sortData, // keep the current sort
      })
    );
    handleCloseUnassignDialog();
  };

  // functions for replace machine
  const openReplaceDialog = useSelector((state) => state.dialogs[DIALOGS.REPLACE_MACHINE_DIALOG]);


  const onReplaceMachine = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.REPLACE_MACHINE_DIALOG);
  };

  const handleCloseReplaceDialog = () => {
    closeDialog(dispatch, DIALOGS.REPLACE_MACHINE_DIALOG);
  };

  // functions for edit machine
  const onEditMachine = (row) => {
    const machine = machines.find((data) => data.machine.id === row.id);
    setSelectedRow(machine);
    setIsEditMachine(true);
    handleOpenAddMachineDialog();
  };

  // function for reassign machine
  const onReassignMachine = (row) => {
    const machine = machines.find((data) => data.machine.id === row.id);
    setSelectedRow(machine);
    handleOpenReassignMachineDialog();
  };

  const handleOpenReassignMachineDialog = () => {
    openDialog(dispatch, DIALOGS.REASSIGN_MACHINE_DIALOG);
  };

  // functions for remove unassigned machines
  const openConfirmRemoveDialog = useSelector((state) => state.dialogs[DIALOGS.CONFIRM_REMOVE_MACHINE_DIALOG]);

  const onRemoveMachine = (row) => {
    setSelectedRow(row);
    openDialog(dispatch, DIALOGS.CONFIRM_REMOVE_MACHINE_DIALOG);
  };

  const handleCloseConfirmRemoveDialog = () => {
    closeDialog(dispatch, DIALOGS.CONFIRM_REMOVE_MACHINE_DIALOG);
  };

  const handleSubmitRemoveMachine = () => {
    dispatch(
      requestRemoveMachine({
        id: selectedRow.id,
        serial: selectedRow.serial,
        sortData: sortData, // keep the current sort
      })
    );
    handleCloseConfirmRemoveDialog();
  };

  const actions = [
    {
      title: t('machine:machinesActions.view/editMachine'),
      callback: onEditMachine,
    },

    {
      title: t('machine:machinesActions.removeMachine'),
      callback: onRemoveMachine,
    },
  ];

  const getActions = (row, handleClose) => {
    if (!row) {
      return null;
    }
    let newActions = [...actions];

    if (row.status === 0) {
      newActions.unshift({
        title: t('machine:machinesActions.assignMachine'),
        callback: onAssignMachine,
      });
    } else if (row.status === 1) {
      newActions = [actions[0]];
      newActions.unshift({
        title: t('machine:machinesActions.relocateMachine'),
        callback: onUnassignMachine,
      });
      newActions.unshift({
        title: t('machine:machinesActions.repairMachine'),
        callback: onReplaceMachine,
      });
    } else if (row.status === 2) {
      newActions = [actions[0]];
      newActions.unshift({
        title: t('machine:machinesActions.reassignMachine'),
        callback: onReassignMachine,
      });
    }
    else if (row.status === 3) {
      newActions = [actions[0]];
      newActions.unshift({
        title: t('machine:machinesActions.continueReplace'),
        callback: onReplaceMachine,
      });
    }

    if (row.configured === configuredStatus.READY) {
      newActions.unshift({
        title: t('machine:machineTable.configure'),
        callback: onConfigureMachine,
      });
    } else if (row.configured === configuredStatus.YES && row.originalData.online) {
      newActions.unshift({
        title: t('machine:machinesActions:calibrate'),
        callback: onCalibrate,
      });
      newActions.unshift({
        title: t('machine:machinesActions:enableRemoteAccess'),
        callback: enableRemoteAccess,
      });
    }

    return newActions.map((action) => (
      <MenuItem
        key={action.title}
        onClick={() => {
          action.callback(row);
          handleClose();
        }}
      >
        {action.title}
      </MenuItem>
    ));
  };

  useEffect(() => {
    const message = socketContext.messageHistory.length ? socketContext.messageHistory[0] : {};
    console.warn(message, 'websocket message');

    if (!message || !message.u12_id) {
      return;
    }

    // always refresh the list no matter what the message is
    if (
      message.signal === socketActions.MACHINE_CONFIGURED ||
      message.signal === socketActions.CONFIGURATION_FAILED ||
      message.signal === socketActions.MACHINE_DISCONNECTED
    ) {
      setTimeout(() => {
        getMachines();
      }, 1000);
    }

  }, [socketContext.messageHistory]);

  useEffect(() => {
    if (!socketContext?.lastJsonMessage?.signal) {
      return;
    }
    if (isFinishReplaceSuccess && (
      socketContext.lastJsonMessage.signal === socketActions.NEW_MACHINE_CONFIGURED ||
      socketContext.lastJsonMessage.signal === socketActions.MACHINE_CONFIGURED
    )
    ) {
      getMachines();
    }

  }, [socketContext.lastJsonMessage])


  useEffect(() => {
    getMachines();

    if ((keyword && keyword !== '') || (filterStatus && filterStatus >= -1) || filterType) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  }, [keyword, filterStatus, filterType, orderBy, order]);

  const getMachines = () => {
    let data = {};
    data = { ...data, orderBy, order, keyword, filterStatus, filterType };
    setSortData(data);
    dispatch(fetchMachines(data));
  };

  //search bar
  const searchMachines = (keyword) => {
    setSearchKeyword(keyword);
  };
  // filter
  const filterMachineStatus = (status) => {
    setFilterStatus(status);
  };
  const filterMachineType = (type) => {
    setFilterType(type);
  };

  // functions for add machine dialog

  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditMachine, setIsEditMachine] = useState(false);

  const openAddMachineDialog = useSelector((state) => state.dialogs[DIALOGS.ADD_MACHINE_DIALOG]);

  //everytime the invite dialog is closed, reset the edit state
  useEffect(() => {
    if (!openAddMachineDialog) {
      setIsEditMachine(false);
    }
  }, [openAddMachineDialog]);

  const handleOpenAddMachineDialog = () => {
    openDialog(dispatch, DIALOGS.ADD_MACHINE_DIALOG);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleResetSelectedRow = () => {
    setSelectedRow(null)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 3 }}>
        <SearchBar searchFunction={searchMachines} />
        <Filter filterMachineType={filterMachineType} filterMachineStatus={filterMachineStatus} />
        <Button
          startIcon={<AddIcon />}
          sx={{ ml: 'auto' }}
          variant='contained'
          size='large'
          onClick={handleOpenAddMachineDialog}
        >
          {t('machine:addMachine')}
        </Button>
      </Box>
      <GeneralMachinesList
        isSearching={isSearching}
        headers={headers}
        data={data}
        actions={actions}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        overrideGetActions={getActions}
      />
      <AddMachine
        sortData={sortData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isEditMachine={isEditMachine}
      />
      <DebugMachine selectedRow={selectedRow} />
      <AssignMachine sortData={sortData} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      <ReassignMachine sortData={sortData} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      <ConfigureMachine sortData={sortData} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      <UnassignMachineDialog
        keep={true}
        title={`${t('machine:unassignMachineDialog.title')}`}
        confirmBtn={`${t('machine:unassignMachineDialog.confirmBtn')}`}
        considerText={`${t('machine:unassignMachineDialog.considerText')}`}
        openUnassignDialog={openUnassignDialog}
        icon={<WarningRoundedIcon sx={{ color: '#E03131', fontSize: '28px' }} />}
        warningText={`${t('machine:unassignMachineDialog.warningText')}`}
        handleCloseUnassignDialog={handleCloseUnassignDialog}
        handleSubmitUnassignDialog={handleSubmitUnassignDialog}
        selectedRow={selectedRow}
      />
      <ReplaceMachineDialog
        keep={true}
        title={`${t('machine:replaceMachineDialog.title')}`}
        openReplaceDialog={openReplaceDialog}
        handleCloseReplaceDialog={handleCloseReplaceDialog}
        handleSubmitUnassignDialog={handleSubmitUnassignDialog}
        handleResetSelectedRow={handleResetSelectedRow}
        selectedRow={selectedRow}
      />
      <ConfirmDialog
        title={`${t('machine:removeDialog.confirm')} "${selectedRow?.originalData?.machine.serial_no}"?`}
        confirmText='Remove'
        openConfirmDialog={openConfirmRemoveDialog}
        handleCloseConfirmDialog={handleCloseConfirmRemoveDialog}
        handleSubmitConfirmDialog={handleSubmitRemoveMachine}
      />
      <ActionModal
        rowSelected={selectedRow}
        showModal={showCalibrateModal}
        onDebugMachine={onDebugMachine}
        onClose={() => setShowCalibrateModal(false)}
      />
    </Box>
  );
}
