import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import i18n from '../../i18n';
import {
  requestChangeUserPassword,
  requestChangeUserPasswordSuccess,
  requestChangeUserPasswordFailure,
  requestUpdateProfile,
  requestUpdateProfileSuccess,
  requestUpdateProfileFailure,
} from 'modules/profile/slice';
import { fetchUserInfo, forceLogout } from 'modules/auth/slice';
import { requestToast } from 'modules/ui/slice';
import { requestDialog } from 'modules/dialogs/slice';

import { END_POINT } from 'modules/profile/constants';
import { TOAST_TYPE } from 'modules/ui/constants';
import { DIALOGS } from 'modules/dialogs/constants';

let t = null;
i18n.loadNamespaces('user', () => (t = i18n.t));

function* changePassword(action) {
  try {
    const { data } = yield axios.put(END_POINT.changePassword.url, {
      current_password: action.payload.currentPassword,
      new_password: action.payload.newPassword,
    });
    if (data.code === 200) {
      yield put(requestChangeUserPasswordSuccess(data));
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: t('user:password.updateSuccess'),
        })
      );
      yield put(requestDialog({ type: DIALOGS.CHANGE_PASSWORD, open: false }));
      yield put(forceLogout());
    } else {
      yield put(requestChangeUserPasswordFailure(data));
    }
  } catch (e) {
    yield put(requestChangeUserPasswordFailure(e.message));
  }
}

function* updateProfile(action) {
  try {
    const { data } = yield axios.put(END_POINT.updateProfile.url, action.payload);
    if (data.code === 200) {
      yield put(requestUpdateProfileSuccess(data));
      yield put(fetchUserInfo());
      yield put(
        requestToast({
          type: TOAST_TYPE.SUCCESS,
          message: t('user:profile.updateSuccess'),
        })
      );
      yield put(requestDialog({ type: DIALOGS.CHANGE_PROFILE, open: false }));
    } else {
      yield put(requestUpdateProfileFailure(data));
    }
  } catch (e) {
    yield put(requestUpdateProfileFailure(e.message));
  }
}

function* profileSaga() {
  yield takeLatest(requestChangeUserPassword, changePassword);
  yield takeLatest(requestUpdateProfile, updateProfile);
}

export default profileSaga;
