import { all } from 'redux-saga/effects';
import authSaga from 'modules/auth/sagas';
import profileSaga from 'modules/profile/sagas';
import bionexUsersSaga from 'modules/bionexUsers/sagas';
import cutomersSaga from 'modules/customers/sagas';
import softwareUploadSaga from 'modules/softwareUpdate/sagas';
import machinesSaga from 'modules/machines/sagas';
import dialogsSaga from 'modules/dialogs/sagas';
import uiSaga from 'modules/ui/sagas';
import auditLogsSaga from 'modules/auditLogs/sagas';
import activityTrackingsSaga from 'modules/activityTrackings/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    profileSaga(),
    uiSaga(),
    dialogsSaga(),
    bionexUsersSaga(),
    machinesSaga(),
    cutomersSaga(),
    softwareUploadSaga(),
    auditLogsSaga(),
    activityTrackingsSaga()
  ]);
}
