import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export function InputText({ value, onChange, isWidthFull, size, variant, errorSmg, maxLength, error, type }) {
  const [isError, setError] = useState(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (!value) {
      return;
    }

    if (value === textValue) {
      return;
    }

    setTextValue(value);
  }, [value]);

  useEffect(() => {
    setError(error);
  }, [error]);

  const onChangeHandler = (e) => {
    setTextValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <TextField
      onChange={onChangeHandler}
      value={textValue}
      fullWidth={isWidthFull}
      size={size}
      variant={variant || 'outlined'}
      inputProps={{
        maxLength: maxLength,
      }}
      type={type}
      error={isError}
      helperText={isError ? errorSmg : undefined}
    />
  );
}

InputText.propTypes = {
  value: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
  isWidthFull: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  maxLength: PropTypes.number,
  errorSmg: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
};
