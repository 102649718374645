import { useTranslation } from 'react-i18next';

import { Container, Typography, Box } from '@mui/material';
import GeneralAuditLog from './GeneralAuditLog';

export default function ManageAuditLogs() {
  const { t } = useTranslation();

  return (
    <Box>
      <Container
        sx={{
          minHeight: 'calc(100vh - 68px)',
          backgroundColor: 'grey1',
          '.tab-container': {
            px: { xs: 2, sm: 1, md: 0 },
          },
        }}
        maxWidth={false}
      >
        <Typography sx={{ display: 'flex', fontSize: '1.5rem', padding: '24px 0' }} variant='h6' component='div'>
          {t('common:auditLog')}
        </Typography>
        <GeneralAuditLog />
      </Container>
    </Box>
  );
}
